import UpperNavBar from "./global/upperNavBar";
import NavBar from "./global/navBar";
import LineWithCircle from "./global/lineWithCircle";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {CognitoUser} from "amazon-cognito-identity-js";
import {Auth} from "aws-amplify";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import {About, Articles, ContactUs, TermsOfUse} from "./descriptionComponents";
import MobileStoreButton from 'react-mobile-store-button';
import {
    ABOUT_PATH,
    ADMINISTRATION_PATH,
    CHANCE_SHITATI_PATH,
    CONTACT_US_PATH,
    CREDIT_TRANSACTION_PATH,
    FORGOT_PASSWORD_PATH,
    FORM_123_PATH,
    HOME_PAGE_PATH,
    LOTTO_SHITATI_HAZAK_PATH,
    LOTTO_SHITATI_PATH,
    MY_SPACE_PATH,
    PRIVACY_POLICY_PATH,
    RAV_CHANCE_PATH,
    REGULAR_777_PATH,
    REGULAR_CHANCE_PATH,
    REGULAR_LOTTO_PATH,
    RESULTS_PATH,
    SHITATI_777_PATH,
    SIGN_IN_PATH,
    SIGN_UP_PATH,
    TERMS_PATH,
    OWNERS_PATH, RESELLERS_PATH, ARTICLES_PATH,
} from "./consts";
import {SignUp} from "./auth/signUp";
import ForgotPassword from "./auth/forgotPassword";
import {SignIn} from "./auth/signIn";
import {HomePage} from "./homePage";
import MySpace from "./mySpace/mySpace";
import LottoForm from "./lotto/lottoForm";
import {
    ChanceShitati,
    DoubleLottoShitati,
    DoubleLottoShitatiHazak,
    LottoShitati,
    LottoShitatiHazak,
    RavChance,
    Regular123,
    Regular777,
    RegularChance,
    RegularDoubleLotto,
    RegularLotto,
    Shitati777
} from "./games";
import {
    BLUE_COLOR, FB_SPACER_BOLD,
    FB_SPACER_REGULAR,
    getCircleCss,
    getFontCss,
    GREEN_COLOR,
    ORANGE_COLOR,
    PINK_COLOR_SEVEN,
    RED_COLOR,
    WHITE_COLOR
} from "./styles";
import ChanceForm from "./lotto/chanceForm";
import FormSeven from "./lotto/formSeven";
import Form123 from "./lotto/form123";
import AdminPage from "./administration/adminPage";
import {StyleSheet, Text, View} from "react-native";
import {get123Results, get777Results, getChanceResults, getLottoResults,getExtraResults, getTimes} from "./server/results";
import {useAsync} from "./server/utils";
import TableContainer from "@material-ui/core/TableContainer";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import AutoSizeText from "./global/autoSizeText";
import Bottomdetails from "./global/bottomDetails";
import {LottoNumber} from "./lotto/lottoBalls";
import {getUserBalance} from "./server/user";
import _ from "lodash";
import { creditTransaction } from "./transaction";
import GuardedRoute from "./GuardedRoute";

import { DisabledTabs } from "./administration/adminPage"
import { AlertHelper } from "./AlertHelper";
import Popup from "./PopUp";
import {OwnersContainer} from "./owner-board/ownersContainer";
import { GamesTypes } from "./homePage";
import {Layout, Menu, Space} from "antd";
import {
    AppstoreAddOutlined,
    ContactsOutlined,
    CrownOutlined,
    PhoneOutlined,
    QuestionCircleOutlined, UserOutlined
} from "@ant-design/icons";
import {useMediaQuery} from "react-responsive";
import {ResellersContainer} from "./resellers/resellersContainer";
import {ARTICLE_TEXT} from "./descriptionConsts";

const { Header, Content, Footer, Sider } = Layout;

export type TimesType = {
    [key in GamesTypes]: { time: string; win_amount: string; };
};
type User = {
    times: TimesType | undefined,
    balance: number,
    user: any
    setUser: (user: any) => void,
    isAuthenticated: boolean
    setIsAuthenticated: (isAuthenticated: boolean) => void,
    username: string,
    isAdmin: boolean,
    isReseller: boolean,
    jwtToken: string
    isAuthenticating: boolean
    setIsisAuthenticating: (isAuthenticating: boolean) => void,
    updateBalance: () => void
}

export const UserContext = React.createContext<User>({} as User)
const ComponentBuilder = ({Component, isUpperNav = true, isLine = true, isNavBar = true}: { Component: () => JSX.Element, isUpperNav?: boolean, isLine?: boolean, isNavBar?: boolean }) => {

    return (
        <>
            {isUpperNav && < UpperNavBar/>}
            {isNavBar && <NavBar/>}
            {isLine && <LineWithCircle/>}
            <Component/>
        </>
    )
}


export const AppSwitch = () => {

    const [user, setUser] = useState({} as CognitoUser)
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isAuthenticating, setIsisAuthenticating] = useState(true)
    const username = useMemo(() => user?.attributes?.name || 'אורח', [user])
    const [balance, setBalance] = useState<number|undefined>()
    const isAdmin = useMemo(() =>  user?.signInUserSession?.accessToken?.payload['cognito:groups']?.includes('admins') || false, [user])
    const isReseller = useMemo(() =>  user?.signInUserSession?.accessToken?.payload['cognito:groups']?.includes('resellers') || false, [user])
    const jwtToken = useMemo(() => user?.signInUserSession?.accessToken?.jwtToken, [user])
    const {value: times} = useAsync<object>(getTimes, true)

    const updateBalance = useCallback(async (jwt) => {
        if(jwtToken || jwt){
            setBalance(await getUserBalance(jwtToken || jwt))
        }
    }, [jwtToken])

    useEffect(() => {
        new Promise(async () => {
            const cognitoUser = await Auth.currentAuthenticatedUser({bypassCache: true});
            await cognitoUser.getSignInUserSession();
            setIsAuthenticated(true);
            setIsisAuthenticating(false);
            setUser(cognitoUser);
            setBalance(await getUserBalance(cognitoUser?.signInUserSession?.accessToken?.jwtToken));

        }).catch((err) => {})
    }, [])

    useEffect(()=>{
        window.localStorage.setItem('isAdmin',isAdmin)
        window.localStorage.setItem('isReseller',isReseller)
    },[isAdmin, isReseller])

    const isMobile = useMediaQuery({ maxDeviceWidth: 780 })

    const routes =  (
        <Switch>
            <Route exact component={About} path={ABOUT_PATH}/>
            <Route exact component={Articles} path={ARTICLES_PATH}/>
            <Route exact render={() => <ComponentBuilder Component={creditTransaction} isLine={false} isUpperNav={false} isNavBar={false}/>} path={CREDIT_TRANSACTION_PATH}/>
            <Route exact component={ContactUs} path={CONTACT_US_PATH}/>
            <Route exact component={() => <TermsOfUse isPrivacyPolicy={true} />} path={PRIVACY_POLICY_PATH}/>
            <Route exact component={() => <TermsOfUse isPrivacyPolicy={false} />} path={TERMS_PATH}/>
            <Route exact component={() => <SignUp isMobile={isMobile}/>} path={SIGN_UP_PATH}/>
            <Route exact component={() => <ForgotPassword isMobile={isMobile}/>} path={FORGOT_PASSWORD_PATH}/>
            <Route exact component={() => <SignIn isMobile={isMobile}/>} path={SIGN_IN_PATH}/>
            <Route exact component={HomePage} path={HOME_PAGE_PATH}/>
            <Route exact component={Results} path={RESULTS_PATH}/>
            <Route exact component={MySpace} path={MY_SPACE_PATH}/>
            <Route exact component={() => <LottoForm FormComponent={() => <RegularLotto isMobile={isMobile}/>} DoubleFormComponent={() => <RegularDoubleLotto isMobile={isMobile}/>} color={RED_COLOR} isMobile={isMobile}/>} path={REGULAR_LOTTO_PATH}/>
            <Route exact component={() => <LottoForm FormComponent={() => <LottoShitati isMobile={isMobile}/>} DoubleFormComponent={() => <DoubleLottoShitati isMobile={isMobile}/>} color={RED_COLOR} isMobile={isMobile}/>} path={LOTTO_SHITATI_PATH}/>
            <Route exact component={() => <LottoForm FormComponent={() => <LottoShitatiHazak isMobile={isMobile}/>} DoubleFormComponent={() => <DoubleLottoShitatiHazak isMobile={isMobile}/>} color={RED_COLOR} isMobile={isMobile}/>} path={LOTTO_SHITATI_HAZAK_PATH}/>
            <Route exact component={() => <ChanceForm FormComponent={RegularChance} color={GREEN_COLOR}/>} path={REGULAR_CHANCE_PATH}/>
            <Route exact component={() => <ChanceForm FormComponent={RavChance} color={GREEN_COLOR}/>} path={RAV_CHANCE_PATH}/>
            <Route exact component={() => <ChanceForm FormComponent={ChanceShitati} color={GREEN_COLOR}/>} path={CHANCE_SHITATI_PATH}/>
            <Route exact component={() => <FormSeven FormComponent={Regular777} color={PINK_COLOR_SEVEN}/>} path={REGULAR_777_PATH}/>

            <Route exact component={() => <FormSeven FormComponent={Shitati777} color={PINK_COLOR_SEVEN}/>} path={SHITATI_777_PATH}/>
            <Route exact component={() => <Form123 FormComponent={Regular123} color={ORANGE_COLOR}/>} path={FORM_123_PATH}/>
            <GuardedRoute exact path={ADMINISTRATION_PATH} Component={AdminPage} auth={isAdmin}/>
            <GuardedRoute exact path={OWNERS_PATH} Component={OwnersContainer} auth={isAdmin}/>
            <GuardedRoute exact path={RESELLERS_PATH} Component={ResellersContainer} auth={isReseller} type={'isReseller'}/>
        </Switch>
    );

    return (
        <UserContext.Provider value={{
            times: _.isEmpty(times)? undefined : times,
            updateBalance,
            balance: Number(balance),
            user,
            setUser,
            isAuthenticated,
            setIsAuthenticated,
            username,
            isAdmin,
            isReseller,
            jwtToken,
            isAuthenticating,
            setIsisAuthenticating
        }}>
            <SideMenuLayout router={routes}/>
        </UserContext.Provider>
    )
}


export interface SideMenuLayoutProps {
    router: JSX.Element;
}

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const SideMenuLayout: React.FC<SideMenuLayoutProps> = (props) => {
    const { children, router } = props;
    const isMobile = useMediaQuery({ maxDeviceWidth: 780 })
    const [ isTransactionPath, setIsTransactionPath ] = useState<boolean>(false);
    const isDesktopOrLaptop = useMediaQuery(
        { minDeviceWidth: 1224 },
        { deviceWidth: 1600 } // `device` prop
    )
    console.log(`mobile: ${isMobile} isDesktopOrLaptop: ${isDesktopOrLaptop}`);
    const history = useHistory()
    let location = useLocation()
    let query = useQuery();

    const useStyles = makeStyles(() => ({
        id: {
            '& .ant-layout-sider-zero-width-trigger': {
                right: -90,
                width: 90,
                height: 78,
                fontSize: 74
            },
            '& .ant-menu': {
                fontSize: '50px !important',
                '& .ant-menu-item': {
                    marginTop: 44
                },
                '& .anticon': {
                    fontSize: 30
                }
            }
        }
    }));
    useEffect(() => {
        const operation: string = query.get('inIframe') || '';
        console.log('xxxx' + operation);
        if (operation === 'true') {
            console.log(location);
            // setIsTransactionPath(true);
        }
    }, [location, query])
    const { isAuthenticated } = useContext(UserContext)
    const classes = useStyles();
    return (
        <>
        {isTransactionPath ?
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360, background: '#fff' }}>
                {router}
            </div> :
            <Layout>
                <Header className="site-layout-sub-header-background" style={{ padding: 10, background: '#fff', height: isMobile ? 133 : 87 }} >
                    <UpperNavBar isMobile={isMobile}/>
                </Header>
                <Layout>
                    <Sider
                        breakpoint="xxl"
                        collapsedWidth="0"
                        onBreakpoint={broken => {
                            console.log(broken);
                            // setIsMobile(broken)
                        }}
                        onCollapse={(collapsed, type) => {
                            console.log(collapsed, type);
                        }}
                        width={isMobile ? 400 : 270}
                        style={isMobile ? {fontSize: 50, borderTopRightRadius: '13px'} : {textAlign: 'right', borderTopRightRadius: '13px'}}
                        className={isMobile ? classes.id : ''}
                    >
                        <div className="logo" style={{margin: 16, background: 'rgba(255, 255, 255, 0.2)'}} >
                            <img onClick={() => history.push(HOME_PAGE_PATH)} alt={''}
                                 src={require('../../assets/lottomtaicLogov2.png')} style={{ height: '100%', width: '100%'}}/>
                        </div>
                        <Menu theme="dark" mode="inline" style={{textAlign: 'center', fontSize: 24 }}>
                            <Menu.Item key={1} onClick={() => history.push(HOME_PAGE_PATH)}>
                                <AppstoreAddOutlined />
                                <span>כל המשחקים</span>
                            </Menu.Item>
                            <Menu.Item key={2} onClick={() => history.push(RESULTS_PATH)}>
                                <CrownOutlined />
                                <span>תוצאות</span>
                            </Menu.Item>
                            <Menu.Item key={3} onClick={() => history.push(TERMS_PATH)}>
                                <QuestionCircleOutlined />
                                <span>עזרה</span>
                            </Menu.Item>
                            <Menu.Item key={4} onClick={() => history.push(ABOUT_PATH)}>
                                <ContactsOutlined />
                                <span>אודות</span>
                            </Menu.Item>
                            <Menu.Item key={5} onClick={() => history.push(ARTICLES_PATH)}>
                                <ContactsOutlined />
                                <span>מאמרים</span>
                            </Menu.Item>
                            <Menu.Item key={6} onClick={() => history.push(CONTACT_US_PATH)}>
                                <PhoneOutlined />
                                <span>צור קשר</span>
                            </Menu.Item>
                            { isAuthenticated && <Menu.Item key={7} onClick={() => history.push(MY_SPACE_PATH)}>
                                <UserOutlined />
                                <span>אזור אישי</span>
                            </Menu.Item> }
                        </Menu>
                        <Space style={{width: '97%', marginTop: 222}}>
                            <MobileStoreButton
                              store="ios"
                              url={'https://l5k.me/byluB_lPfzH'}
                              linkProps={{ title: 'iOS Store Button' }}
                            />
                        </Space>
                    </Sider>
                    <Content style={{  overflow: 'scroll' }}>
                        <div className="site-layout-background" style={{ padding: 24, minHeight: 360, background: '#fff' }}>
                            {router}
                        </div>
                    </Content>
                </Layout>
                <Footer style={{ textAlign: 'center' }}>AS.HR ADVANCE MARKETING LTD ©2022 Created by NiviX</Footer>
            </Layout>
        }
    </>
        // <Layout className={classes.outerContainer}>
        //     <Layout.Sider className={classes.side}>
        //         {children}
        //         <MenuVersion />
        //     </Layout.Sider>
        //     <Layout.Content className={classes.content}>{router}</Layout.Content>
        // </Layout>
    );
};



const LottoResults = () => {
    const useStyles = makeStyles((theme) => ({
        paper: {
            marginBottom: theme.spacing(2),
        },
        tablePagination: {
            borderBottom: "none",
            ...getFontCss(FB_SPACER_REGULAR, '0.875rem', BLUE_COLOR)
        },
        headerCell: {
            background: BLUE_COLOR,
        }
    }));
    const classes = useStyles()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const style: { text: object } = {
        text: {...getFontCss(FB_SPACER_REGULAR, 21, BLUE_COLOR)}
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const {value} = useAsync(getLottoResults)
    // @ts-ignore
    const data = value?.data || {
        headers: ['מספר הגרלה', 'תאריך', 'תוצאות'],
        rows: []
    }

    return <Paper dir={"rtl"} className={classes.paper}>
        <TableContainer>
            <Table size={'medium'}>
                <TableHead>
                    <TableRow>
                        {
                            data['headers'].map((header: string, ind : number) =>
                                <TableCell align={"right"}
                                           className={classes.headerCell} key={ind}>
                                    {
                                        <Text key={ind}
                                            style={{...getFontCss(FB_SPACER_REGULAR, 21, WHITE_COLOR)}}>{header}</Text>
                                    }
                                </TableCell>)
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data['rows'].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, ind : number) =>
                            <TableRow key={ind++}>
                                <TableCell align={"right"} key={ind}>
                                    <Text key={ind++} style={style.text}>
                                        {row[data['headers'][0]]}
                                    </Text>
                                </TableCell>
                                <TableCell key={ind++} align={"right"}>
                                    <Text style={style.text}>
                                        {row[data['headers'][1]]}
                                    </Text>
                                </TableCell>
                                <TableCell key={ind++} align={"right"}>
                                    {
                                        <ResultBalls key={ind++} ballColor={RED_COLOR} numbers={row[data['headers'][2]]['numbers']}
                                                     strongNumbers={row[data['headers'][2]]['strong_number']}/>
                                    }
                                </TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            dir={"ltr"}
            width={'inherit'}
            rowsPerPageOptions={[10, 25]}
            count={data['rows'].length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage={'שורות'}
            className={classes.tablePagination}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />

    </Paper>
}
const ExtraResults = () => {
    const useStyles = makeStyles((theme) => ({
        paper: {
            marginBottom: theme.spacing(2),
        },
        tablePagination: {
            borderBottom: "none",
            ...getFontCss(FB_SPACER_REGULAR, '0.875rem', BLUE_COLOR)
        },
        headerCell: {
            background: BLUE_COLOR,
        }
    }));
    const classes = useStyles()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const style: { text: object } = {
        text: {...getFontCss(FB_SPACER_REGULAR, 21, BLUE_COLOR)}
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const {value} = useAsync(getExtraResults)
    // @ts-ignore
    const data = value?.data || {
        headers: ['מספר הגרלה', 'תאריך', 'תוצאות'],
        rows: []
    }

    return <Paper dir={"rtl"} className={classes.paper}>
        <TableContainer>
            <Table size={'medium'}>
                <TableHead>
                    <TableRow>
                        {
                            data['headers'].map((header: string) =>
                                <TableCell align={"right"}
                                           className={classes.headerCell}>
                                    {
                                        <Text
                                            style={{...getFontCss(FB_SPACER_REGULAR, 21, WHITE_COLOR)}}>{header}</Text>
                                    }
                                </TableCell>)
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data['rows'].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) =>
                            <TableRow>
                                <TableCell align={"right"}>
                                    <Text style={style.text}>
                                        {row[data['headers'][0]]}
                                    </Text>
                                </TableCell>
                                <TableCell align={"right"}>
                                    <Text style={style.text}>
                                        {row[data['headers'][1]]}
                                    </Text>
                                </TableCell>
                                <TableCell align={"right"}>
                                    {
                                        <ResultBalls ballColor={RED_COLOR} numbers={row[data['headers'][2]]['numbers']}
                                                     strongNumbers={row[data['headers'][2]]['strong_number']}/>
                                    }
                                </TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            dir={"ltr"}
            width={'inherit'}
            rowsPerPageOptions={[10, 25]}
            count={data['rows'].length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage={'שורות'}
            className={classes.tablePagination}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />

    </Paper>
}


type ResultBallsType = {
    ballColor?: string
    numbers: string[]
    strongNumbers?: string[] | string
}

const ResultBalls: React.FC<ResultBallsType> = ({numbers, strongNumbers, ballColor}) => {

    const style = StyleSheet.create({
        ballRow: {
            flexDirection: "row-reverse",
            width: 'inherit',
            marginBottom: '4px',
            paddingLeft: '16px'
        },
        ball: {
            ...getCircleCss(40, 40, 20),
            borderWidth: 2,
            borderColor: ballColor || BLUE_COLOR,
            justifyContent: "center",
            alignItems: "center",
            marginRight: '4px'
        },
        ballText: {
            ...getFontCss(FB_SPACER_REGULAR, 20, ballColor || BLUE_COLOR)
        },
        strongBall: {
            ...getCircleCss(40, 40, 20),
            borderWidth: 2,
            borderColor: ORANGE_COLOR,
            justifyContent: "center",
            alignItems: "center",
            marginRight: '4px'
        },
        strongBallText: {
            ...getFontCss(FB_SPACER_REGULAR, 20, ORANGE_COLOR)
        }
    })

    const fixedStrongNumber = typeof strongNumbers === 'string' ? [strongNumbers] : strongNumbers

    return <View>
        <View style={style.ballRow}>
            {numbers.map(number => <LottoNumber lottoNumber={number} ballStyle={style.ball}
                                                ballTextStyle={style.ballText}/>)}
        </View>
        {fixedStrongNumber && <View style={style.ballRow}>
            {fixedStrongNumber.map(number => <LottoNumber lottoNumber={number} ballStyle={style.strongBall}
                                                          ballTextStyle={style.strongBallText}/>)}
        </View>}
    </View>

}

type ChanceResultBallsType = {
    ballColor?: string
    numbers: string[]
}

const ChanceResultBalls: React.FC<ChanceResultBallsType> = ({numbers, ballColor}) => {

    const style = StyleSheet.create({
        ballRow: {
            flexDirection: "row-reverse",
            width: 'inherit',
            marginBottom: '4px',
            paddingLeft: '16px'
        },
        ball: {
            ...getCircleCss(40, 40, 20),
            borderWidth: 2,
            borderColor: ballColor || BLUE_COLOR,
            justifyContent: "center",
            alignItems: "center",
            marginRight: '4px'
        },
        ballText: {
            ...getFontCss(FB_SPACER_REGULAR, 20, ballColor || BLUE_COLOR)
        },
        strongBall: {
            ...getCircleCss(40, 40, 20),
            borderWidth: 2,
            borderColor: ORANGE_COLOR,
            justifyContent: "center",
            alignItems: "center",
            marginRight: '4px'
        },
        strongBallText: {
            ...getFontCss(FB_SPACER_REGULAR, 20, ORANGE_COLOR)
        }
    })

    const getHebrewName = (index: number) => {
        if (index === 0) {
            return 'תלתן'
        }
        if (index === 1) {
            return 'יהלום'
        }
        if (index === 2) {
            return 'לב'
        }
        if (index === 3) {
            return 'עלה'
        }
    }

    return <View>
        <View style={style.ballRow}>
               {numbers.map((item, index) =>  <View style={{marginRight: '4px', alignItems: "center"}}>
                <Text style={{...getFontCss(FB_SPACER_BOLD, 14, BLUE_COLOR)}}>{getHebrewName(index)}</Text>
                <LottoNumber lottoNumber={item} ballStyle={style.ball} ballTextStyle={style.ballText}/>
            </View>)}
        </View>
    </View>

}


const ChanceResults = () => {
    const useStyles = makeStyles((theme) => ({
        paper: {
            marginBottom: theme.spacing(2),
        },
        tablePagination: {
            borderBottom: "none",
            ...getFontCss(FB_SPACER_REGULAR, '0.875rem', BLUE_COLOR)
        },
        headerCell: {
            background: BLUE_COLOR,
        }
    }));
    const classes = useStyles()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const style: { text: object } = {
        text: {...getFontCss(FB_SPACER_REGULAR, 21, BLUE_COLOR)}
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const {value} = useAsync(getChanceResults, true)
    // @ts-ignore
    const data = value?.data || {
        headers: ['מספר הגרלה', 'תאריך', 'תוצאות'],
        rows: []
    }


    return <Paper dir={"rtl"} className={classes.paper}>
        <TableContainer>
            <Table size={'medium'}>
                <TableHead>
                    <TableRow>
                        {
                            data['headers'].map((header: string) =>
                                <TableCell align={"right"}
                                           className={classes.headerCell}>
                                    {
                                        <Text
                                            style={{...getFontCss(FB_SPACER_REGULAR, 21, WHITE_COLOR)}}>{header}</Text>
                                    }
                                </TableCell>)
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data['rows'].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) =>
                            <TableRow>
                                <TableCell align={"right"}>
                                    <Text style={style.text}>
                                        {row[data['headers'][0]]}
                                    </Text>
                                </TableCell>
                                <TableCell align={"right"}>
                                    <Text style={style.text}>
                                        {row[data['headers'][1]]}
                                    </Text>
                                </TableCell>
                                <TableCell align={"right"}>
                                    {
                                        <ChanceResultBalls ballColor={GREEN_COLOR} numbers={row[data['headers'][2]]['numbers']}/>
                                    }
                                </TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            dir={"ltr"}
            width={'inherit'}
            rowsPerPageOptions={[10, 25]}
            count={data['rows'].length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage={'שורות'}
            className={classes.tablePagination}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />

    </Paper>
}


const Results777 = () => {
    const useStyles = makeStyles((theme) => ({
        paper: {
            marginBottom: theme.spacing(2),
        },
        tablePagination: {
            borderBottom: "none",
            ...getFontCss(FB_SPACER_REGULAR, '0.875rem', BLUE_COLOR)
        },
        headerCell: {
            background: BLUE_COLOR,
        }
    }));
    const classes = useStyles()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const style: { text: object } = {
        text: {...getFontCss(FB_SPACER_REGULAR, 21, BLUE_COLOR)}
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const {value} = useAsync(get777Results, true)
    // @ts-ignore
    const data = value?.data || {
        headers: ['מספר הגרלה', 'תאריך', 'תוצאות'],
        rows: []
    }


    return <Paper dir={"rtl"} className={classes.paper}>
        <TableContainer>
            <Table size={'medium'}>
                <TableHead>
                    <TableRow>
                        {
                            data['headers'].map((header: string) =>
                                <TableCell align={"right"}
                                           className={classes.headerCell}>
                                    {
                                        <Text
                                            style={{...getFontCss(FB_SPACER_REGULAR, 21, WHITE_COLOR)}}>{header}</Text>
                                    }
                                </TableCell>)
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data['rows'].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) =>
                            <TableRow>
                                <TableCell align={"right"}>
                                    <Text style={style.text}>
                                        {row[data['headers'][0]]}
                                    </Text>
                                </TableCell>
                                <TableCell align={"right"}>
                                    <Text style={style.text}>
                                        {row[data['headers'][1]]}
                                    </Text>
                                </TableCell>
                                <TableCell align={"right"}>
                                    {
                                        <ResultBalls ballColor={PINK_COLOR_SEVEN} numbers={row[data['headers'][2]]['numbers']} strongNumbers={row[data['headers'][2]]['strong_number']}/>
                                    }
                                </TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            dir={"ltr"}
            width={'inherit'}
            rowsPerPageOptions={[10, 25]}
            count={data['rows'].length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage={'שורות'}
            className={classes.tablePagination}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />

    </Paper>
}


const Results123 = () => {
    const useStyles = makeStyles((theme) => ({
        paper: {
            marginBottom: theme.spacing(2),
        },
        tablePagination: {
            borderBottom: "none",
            ...getFontCss(FB_SPACER_REGULAR, '0.875rem', BLUE_COLOR)
        },
        headerCell: {
            background: BLUE_COLOR,
        }
    }));
    const classes = useStyles()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const style: { text: object } = {
        text: {...getFontCss(FB_SPACER_REGULAR, 21, BLUE_COLOR)}
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const {value} = useAsync(get123Results, true)
    // @ts-ignore
    const data = value?.data || {
        headers: ['מספר הגרלה', 'תאריך', 'תוצאות'],
        rows: []
    }


    return <Paper dir={"rtl"} className={classes.paper}>
        <TableContainer>
            <Table size={'medium'}>
                <TableHead>
                    <TableRow>
                        {
                            data['headers'].map((header: string) =>
                                <TableCell align={"right"}
                                           className={classes.headerCell}>
                                    {
                                        <Text
                                            style={{...getFontCss(FB_SPACER_REGULAR, 21, WHITE_COLOR)}}>{header}</Text>
                                    }
                                </TableCell>)
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data['rows'].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) =>
                            <TableRow>
                                <TableCell align={"right"}>
                                    <Text style={style.text}>
                                        {row[data['headers'][0]]}
                                    </Text>
                                </TableCell>
                                <TableCell align={"right"}>
                                    <Text style={style.text}>
                                        {row[data['headers'][1]]}
                                    </Text>
                                </TableCell>
                                <TableCell align={"right"}>
                                    {
                                        <ResultBalls ballColor={ORANGE_COLOR} numbers={row[data['headers'][2]]['numbers']}
                                                     strongNumbers={row[data['headers'][2]]['strong_number']}/>
                                    }
                                </TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            dir={"ltr"}
            width={'inherit'}
            rowsPerPageOptions={[10, 25]}
            count={data['rows'].length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage={'שורות'}
            className={classes.tablePagination}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />

    </Paper>
}


export const HowItWorks: React.FC = () => {
    const history = useHistory()
    return <AutoSizeText
                text={'לאחר התשלום אנו בלוטומטיק מקבלים את הטופס שמילאתם ושולחים אותו עבורכם בנקודת מכירה מורשית של מפעל הפיס, את הטופס שמילאנו עבורכם בנקודה אנו סורקים ושולחים לכם לתיבת הדואר האלקטרוני ומעלים את הטופס הסרוק לאזור האישי שלכם באפליקציה. הטופס המקורי יישמר אצלנו במשרדי החברה ובמידה וזכיתם בסכום העולה על 11,000 ש״ח יימסר לכם הטופס באופן אישי. במידה וסכום הזכייה שלכם הינו מ11,000 ש״ח או נמוך ממנו החשבון שלכם באתר יזוכה באופן אוטומטי ותקבלו על כך הודעה, אתם תוכלו למשוך את הכסף מהחשבון שכלם באתר לתוך חשבון הבנק שלכם או שתבחרו לנצל את היתרה לשליחת טפסים חדשים. אנו מאחלים לכם חוויה שימוש והנאה מרבית מהשירות שלנו ומלאי תקווה שנביא לכם את המזל. בהצלחה!'}
                title={'איך זה עובד'} onPress={() => history.push(ABOUT_PATH)}/>
}

export const AboutUs = () => {
    const history = useHistory()
    return <AutoSizeText
                text={'לוטומטיק פשוט לשלוח בקליק מכל מקום בכל מצב ובכל רגע נתון. העיקרון המנחה שלנו הינו הנוחות שלכם, כלומר אנו בלוטומטיק נעשה כל שביכולתנו כדי לספק לכם את החוויה למלא טפסי לוטו/ צ׳אנס/ 777/ 123 מבלי להטריח את עצמכם ונעניק לכם את האפשרות לעשות זאת מכל מקום בכל שעה וכל זה בלחיצת כפתור, בלי ללכת לנקודה של מפעל הפיס בלי לעמוד בתור בלי לצאת מהבית ובלי להיות מוגבלים בזמן- הכל נעשה באפליקציה או דרך אתר האינטרנט שלנו. כל שלכם נותר לעשות הוא פשוט להיכנס לאתר/ לאפליקציה שלנו להזין את הפרטים שלכם לבחור את סוג הטופס שבו אתם חפצים לבחור את המספרים שלכם ו/או לבחור באפשרות של מילוי טופס באופן אוטומטי, ללחוץ על שליחת טופס לשלם בכרטיס האשראי והינה הטופס שמילאתם כבר נסרק ובדרך לתיבת המייל שלכם ולאיזור האישי שלכם באפליקציה.'}
                title={'קצת עלינו'} onPress={() => history.push(ABOUT_PATH)}/>
}

export const Results = () => {
    let tab_state = (parseInt(new URLSearchParams(location.search).get("num") as string)) ? (parseInt(new URLSearchParams(location.search).get("num") as string)) : 0
    const [componentNumber, setComponentNumber] = useState(tab_state)
    const components = [() => <LottoResults/>,() => <ExtraResults/>, () => <ChanceResults/>, () => <Results777/>, () => <Results123/>]
    const Component = components[componentNumber]

    const changeTab = (value : number)=> {
        setComponentNumber(value)
    }

    return <View style={{marginRight: '12%', marginLeft: '12%', marginVertical: 50}}>
        <DisabledTabs onPress={changeTab} tabs={['לוטו','אקסטרא', 'צ\'אנס', "777", '123']}/>
        <Component/>
        <Bottomdetails>
            <HowItWorks/>
            <AboutUs/>
        </Bottomdetails>
    </View>
}
