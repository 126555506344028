export const FB_SPACER_BOLD = 'FbSpacerBold'
export const FB_SPACER_REGULAR = 'FbSpacerRegular'
export const RED_COLOR = '#E62321'
export const WHITE_COLOR = '#FFFFFF'
export const BLUE_COLOR = '#263742'
export const ORANGE_COLOR = '#FF6B00'
export const SEVEN_ORANGE_COLOR = '#FBB03B'
export const YELLOW_COLOR = '#FCEE21'
export const YELLOW_123_COLOR = '#FBB03B'
export const LIGHT_GREEN_COLOR = '#8CC63F'
export const SUCCESS_GREEN_COLOR = '#39B54A'
export const GREEN_COLOR = '#009943'
export const DARK_RED_COLOR = '#c1272d'
export const PINK_COLOR = '#FF838C'
export const PINK_COLOR_SEVEN = '#EB2874'
export const BLUE_GRAY_COLOR = '#4f8498'
export const LIGHT_BLUE_COLOR = '#00AEEF'
export const GRAY_COLOR = '#BFBFBF'
export const GRAY_BANNER_COLOR = '#F2F2F2'


export const UNDERLINE_DECORATION = 'underline'
export const getFontCss = (fontFamily: string, fontSize: number | string, color: string, underline?: string): object => {
    return {
        fontFamily: fontFamily,
        fontSize: fontSize,
        color: color,
        textDecorationLine: underline
    }

}
export const getCircleCss = (height: string | number | undefined, width: string | number | undefined, borderRadius: number | undefined) => {
    return {
        ...getHeightWidthCss(height, width),
        borderRadius: borderRadius
    }

}
export const getHeightWidthCss = (height: string | number | undefined, width: string | number | undefined) => {
    return {
        height: height,
        width: width,
    }

}
export const getBorder = () => {
    return {
        borderWidth: 0
    }
}
