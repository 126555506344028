
import Constants from 'expo-constants';


export const prodUrl = new URL("https://www.lottomatic.io");

const ENV = {
  dev: {
    apiUrl: new URL("http://localhost:5000"),
    frontEndUrl: new URL("http://localhost:19006")
  },
  qa: {
    apiUrl: new URL("http://localhost:5000"),
    frontEndUrl: new URL("http://localhost:19006")
  },
  staging: {
    apiUrl: new URL("https://staging.lotto-matic.com:8080"),
    frontEndUrl: new URL("https://staging.lotto-matic.com")
  },
  prod: {
    apiUrl: prodUrl,
    frontEndUrl: new URL("https://www.lottomatic.io")
  }
};

const getEnvVars = (env:string) => {
  if (env == 'dev') {
    return ENV.dev;
  }else if (env === 'qa') {
    return ENV.qa;
  } else if (env === 'staging') {
    return ENV.staging;
  } else if (env === 'prod' || env === 'production') {
    return ENV.prod;
  }
}

export default getEnvVars;