import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {
    Button,
    Input,
    Space,
    Table,
    DatePicker,
    message,
    Modal,
    Typography,
    Select,
    Tooltip,
    Descriptions,
    Collapse
} from "antd";
import {RocketOutlined, SearchOutlined} from "@ant-design/icons";
import {changeLotteryNumber, changeStatus, OwnersFormType} from "../server/owners";
import Highlighter from 'react-highlight-words';
import { sum, capitalize } from 'lodash';
import moment, {Moment} from "moment";
import {EnglishHebrewFormTypeMapping} from "../types";
import {UserContext} from "../appSwitch";
import {makeStyles} from "@material-ui/core/styles";
import ReactJson from "react-json-view";

const { RangePicker } = DatePicker;

interface IProps {
    allForms: OwnersFormType[],
    isLoading: boolean,
    setTotalPrice: (sum: number) => void,
    setNumberOfForms: (num: number) => void,
    setTotalOriginPrice?: (sum: number) => void,
}

interface FormModalProps {
    form: OwnersFormType;
    isModalVisible: boolean;
    handleOk: () => void;
    handleCancel: () => void;
}

export const useTableStyles = makeStyles((theme) => ({
    usersTable: {
        '& .ant-table-row:hover': {
            cursor: 'pointer'
        },
    }
}));

export const statuses = ['pending', 'printed', 'scanned', 'won', 'finished', 'lost', 'failed', 'pulled', 'archived']

const { Text } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

export const FormPopup: React.FC<FormModalProps> = ({form, isModalVisible, handleOk, handleCancel}) => {
    const {jwtToken} = useContext(UserContext)
    const [changedStatus, setChangedStatus] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [newStatus, setNewStatus] = useState<string | undefined>();
    const [newLotteryNumber, setNewLotteryNumber] = useState<string>('');
    const [fuckingLotteryNumber, setFuckingLotteryNumber] = useState<string | undefined>();

    useEffect(() => {
        setFuckingLotteryNumber(undefined)
        setNewStatus(undefined)
        setNewLotteryNumber('')
        setChangedStatus('')
    }, [isModalVisible])

    const handleSelect = useCallback((val: string) => setChangedStatus(val), [])

    const updateStatus = useCallback(async () => {
        setIsLoading(true)
        const res = await changeStatus(jwtToken, form.id, changedStatus);
        setNewStatus(res);
        setIsLoading(false);
    }, [changedStatus])

    const updateLotteryNumber = useCallback(async () => {
        setIsLoading(true)
        const res = await changeLotteryNumber(jwtToken, form.id, newLotteryNumber);
        setFuckingLotteryNumber(res);
        setIsLoading(false);
    }, [newLotteryNumber]);

    return (
        <Modal width={'50%'} title={'Form Details'} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <Space direction={'vertical'}>
                <Descriptions title="Form Info">
                    <Descriptions.Item label="Status" labelStyle={{fontWeight: 'bold'}}>{newStatus ?? form.status}</Descriptions.Item>
                    <Descriptions.Item label="Form kind" labelStyle={{fontWeight: 'bold'}}>{form.form_kind}</Descriptions.Item>
                    <Descriptions.Item label="Id" labelStyle={{fontWeight: 'bold'}}>{form.id}</Descriptions.Item>
                    <Descriptions.Item label="Price" labelStyle={{fontWeight: 'bold'}}>{form.price}</Descriptions.Item>
                    <Descriptions.Item label="Origin price" labelStyle={{fontWeight: 'bold'}}>{form.origin_price}</Descriptions.Item>
                    <Descriptions.Item label="Prize" labelStyle={{fontWeight: 'bold'}}>{form.prize}</Descriptions.Item>
                    <Descriptions.Item label="Reseller Code" labelStyle={{fontWeight: 'bold'}}>{form.reseller_code}</Descriptions.Item>
                    <Descriptions.Item label="Lottery Number" labelStyle={{fontWeight: 'bold'}}>{fuckingLotteryNumber ?? form.lottery_number}</Descriptions.Item>
                </Descriptions>
                <Space>
                    <Text>Change status to - </Text>
                    <Select placeholder="choose new status" style={{ width: 240 }} onSelect={handleSelect}>
                        {statuses.map((s, i) => <Option value={s} disabled={s === form.status} key={i}>{capitalize(s)}</Option>)}
                    </Select>
                    <Tooltip title={'עדכן סטטוס'}>
                        <Button disabled={!changedStatus} shape={'round'} icon={<RocketOutlined />} onClick={updateStatus} loading={isLoading}/>
                    </Tooltip>
                </Space>
                <Space>
                    <Text>Change lottery number to - </Text>
                    <Input placeholder="new lottery number" style={{ width: 240 }} onChange={(e) => setNewLotteryNumber(e.target.value)}/>
                    <Tooltip title={'עדכן מספר הגרלה'}>
                        <Button disabled={!newLotteryNumber} shape={'round'} icon={<RocketOutlined />} onClick={updateLotteryNumber} loading={isLoading}/>
                    </Tooltip>
                </Space>
                <Space>
                    <Collapse>
                      <Panel header="raw data" key="1">
                          <ReactJson src={form.marks} />
                      </Panel>
                    </Collapse>;
                </Space>
            </Space>
        </Modal>
    )
}

export const OverviewTable: React.FC<IProps> = ({allForms, isLoading, setTotalPrice, setTotalOriginPrice, setNumberOfForms}) => {
    const classes = useTableStyles()
    const [searchText, setSearchText] = useState('')
    // const [searchDate, setSearchDate] = useState(['', ''])
    const [searchedColumn, setSearchedColumn] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedForm, setSelectedForm] = useState<OwnersFormType>();

    let searchInput: import("antd").InputRef | null;

    const getColumnDateProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8, display: 'flex', flexDirection: 'column' }}>
            <RangePicker
                value={selectedKeys[0] ? selectedKeys[0].split('$').map(item => moment.utc(item)) : ''}
                onChange={(values: any, formatString: [string, string]) => {
                    if (values !== null) {
                        setSelectedKeys([formatString.join('$')]);
                    }
                }}
            />
            <Space style={{marginTop: 8}}>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                    confirm({ closeDropdown: false });
                    setSearchText([selectedKeys[0], selectedKeys[0]].join('$'));
                    setSearchedColumn(dataIndex);
                }}
              >
                Filter
              </Button>
            </Space>
          </div>
        ),
        // filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => {
            const dates = value.split('$')
            const startDate = moment.utc(dates[0])
            const endDate = moment.utc(dates[1])
            return record[dataIndex] ? moment.utc(record[dataIndex]).isBetween(startDate, endDate) : false
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible && searchInput) {
            setTimeout(() => searchInput.select(), 100);
          }
        },
    });
    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                    confirm({ closeDropdown: false });
                    setSearchText(selectedKeys[0]);
                    setSearchedColumn(dataIndex);
                }}
              >
                Filter
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : false,
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => searchInput.select(), 100);
          }
        },
        render: (text: { toString: () => any; }) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={[searchText]}
                  autoEscape
                  textToHighlight={text ? text.toString() : ''}
                />
              ) : (
                text
              ),
    });

    const handleSearch = (selectedKeys: React.SetStateAction<string>[], confirm: () => void, dataIndex: string) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('')
    };


    const showModal = (row: OwnersFormType) => {
        setSelectedForm(row);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleChange = (pagination: any, filters: any, sorter: any, extra: { currentDataSource: Array<OwnersFormType> }) => {
        console.log('Various parameters', extra.currentDataSource);
        setTotalPrice(sum(extra.currentDataSource.map(form => form.price)))
        setNumberOfForms(extra.currentDataSource.length);
        if (setTotalOriginPrice) {
            setTotalOriginPrice(sum(extra.currentDataSource.map(form => form.origin_price)))
        }
    }

    const formTypeOptions = Object.entries(EnglishHebrewFormTypeMapping).map(([key, value]) => ({
        text: value,
        value: key
    }))

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Username',
            dataIndex: 'user_name',
            key: 'user_name',
            ...getColumnSearchProps('user_name'),
        },
        {
            title: 'Lottery Number',
            dataIndex: 'lottery_number',
            key: 'lottery_number',
            ...getColumnSearchProps('lottery_number'),
        },
        {
            title: 'Date',
            dataIndex: 'time',
            key: 'time',
            ...getColumnDateProps('time'),
            render: text => text ? new Date(text).toLocaleString('en-GB', { hour12: false }) : ''
        },
        {
            title: 'Game Name',
            dataIndex: 'name',
            key: 'name',
            filters: formTypeOptions,
            onFilter: (value, record) => record.form_kind.indexOf(value) === 0,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a: OwnersFormType, b: OwnersFormType) => {
                if(a.status < b.status) { return -1; }
                if(a.status > b.status) { return 1; }
                return 0;
            },
            filters: statuses.map(status => ({
                text: status,
                value: status
            })),
            onFilter: (value, record) => record.status.indexOf(value) === 0,
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Origin Price',
            dataIndex: 'origin_price',
            key: 'origin_price',
            visible: setTotalOriginPrice
        },
        {
            title: 'Prize',
            dataIndex: 'prize',
            key: 'prize',
        },
        {
            title: 'Reseller Code',
            dataIndex: 'reseller_code',
            key: 'reseller_code',
            ...getColumnSearchProps('reseller_code'),
            sorter: (a: OwnersFormType, b: OwnersFormType) => {
                if(a.reseller_code < b.reseller_code) { return -1; }
                if(a.reseller_code > b.reseller_code) { return 1; }
                return 0;
            },
        },
    ];

    const onRowClick = useCallback((row, i) => ({onClick: () => showModal(row)}),[]);

    const finalCol = useMemo(() => setTotalOriginPrice ? columns : columns.filter(col => col.key !== 'origin_price'), [setTotalOriginPrice])

    return (
        <>
            <Table className={classes.usersTable} columns={finalCol} onRow={onRowClick} dataSource={allForms} loading={isLoading} onChange={handleChange} />
            {selectedForm && <FormPopup isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel} form={selectedForm} />}
        </>
    )
}