import {
    HEBREW_MAIN_TITLE,
    HEBREW_SECOND_STEP_TITLE,
    INIT_TABLE_INDEX,
    MULTI_LOTTERY,
    NON_NUMBER,
    NUMBERS,
    PARTICIPANT_AMOUNT,
    STRICT,
    TABLES
} from "../../consts";
import React, {useCallback, useContext, useEffect, useMemo, useReducer, useState} from "react";
import FirstSectionInstructions, {
    InstructionStep,
    StepBigText,
    StepCircle
} from "./additionalSections/firstSectionInstructions";
import {NumberOfSevenLotteriesBall} from "./regular777Form";
import {
    CalcPriceFunction,
    EnglishHebrewFormTypeMapping,
    FormType,
    LevelType,
    LottoNumberType,
    MultiLotterySevenType,
    PostFormFunction,
    UserFormType
} from "../../types";
import {Image, StyleSheet, Text, View} from "react-native";
import {
    DARK_RED_COLOR,
    FB_SPACER_BOLD,
    FB_SPACER_REGULAR,
    getBorder,
    getFontCss,
    getHeightWidthCss,
    RED_COLOR,
    SEVEN_ORANGE_COLOR,
    WHITE_COLOR,
    YELLOW_123_COLOR,
    YELLOW_COLOR
} from "../../styles";
import {AutomateRenew, QuestionMark} from "./additionalSections/formAdditions";
import {PipeLine} from "../../genaricComponents";
import {Payment} from "./additionalSections/sumAndSubmitSection";
import UserInputFormHeader, {UserInputFormUpperHeaderChildren} from "./inputForm/userInputFormHeader";
import {DeleteAndFillNumbersSection} from "./inputForm/userInputForm";
import {FillingRow, UserFormBorders, UserFormContainer} from "./userForm/userForm";
import {ChanceMediumText, ChanceRowBalls} from "./regularChanceForm";
import {TouchableLottoRowBalls} from "../lottoBalls";
import {UserContext} from "../../appSwitch";

import { AlertHelper } from '../../AlertHelper';

let _ = require('lodash')
const SUB_TITLE = 'בחר מספר'

type ActionType123 = {
    type: string
    multiLotteryValue?: MultiLotterySevenType,
    tableIndex?: number,
    value?: number
    subTableIndex?: string
    participantAmount?: number
}
export type Table123 = { [NUMBERS]: { [key: string]: number } }
export type MarksType123 = {
    [TABLES]: Table123[],
    [MULTI_LOTTERY]: MultiLotterySevenType,
    [PARTICIPANT_AMOUNT]: number
}
type Regular123FormType = {
    calcPriceFunction: CalcPriceFunction<MarksType123>,
    postFormFunction: PostFormFunction<MarksType123>,
    initForm: MarksType123,
    color: string,
    tablesNumber: number,
    formType: FormType,
    numbersLength: number
}


type SumAndSubmit123SectionType = {
    bottonColor: string,
    calcPriceFunction: () => Promise<{ price: number }>,
    postFunction: () => Promise<Response>
    validateForm: (lvl?: 'strict' | 'lax') => boolean,
    color: string,
    marks: MarksType123,
    formType: FormType,
    fullTables:number,
}

export const form123Reducer = (state: MarksType123, action: ActionType123): MarksType123 => {
    switch (action.type) {
        case 'setMultiLottery':
            state[MULTI_LOTTERY] = action?.multiLotteryValue || NON_NUMBER
            return {...state}
        case 'popTableNumbers':
            if (action.tableIndex !== undefined) {
                state[TABLES][action.tableIndex][NUMBERS] = {"1": NON_NUMBER, "2": NON_NUMBER, "3": NON_NUMBER}
            }
            return {...state}
        case 'tableAutoInsertion':
            if (action.tableIndex !== undefined) {
                for (let key of Object.keys(state[TABLES][action.tableIndex][NUMBERS])) {
                    if (state[TABLES][action.tableIndex][NUMBERS][key] === NON_NUMBER) {
                        state[TABLES][action.tableIndex][NUMBERS][key] = _.random(0, 9)
                    }
                }
            }
            return {...state}
        case 'addNumber':
            if (action.tableIndex !== undefined && action.subTableIndex !== undefined && action.value !== undefined) {
                if (Object.values(state[TABLES][action.tableIndex][NUMBERS]).filter((number) => number !== NON_NUMBER).length !== 3) {
                    state[TABLES][action.tableIndex][NUMBERS][action.subTableIndex] = action.value
                }

            }
            return {...state}
        case 'removeNumber':
            if (action.tableIndex !== undefined && action.subTableIndex !== undefined) {
                state[TABLES][action.tableIndex][NUMBERS][action.subTableIndex] = NON_NUMBER
            }
            return {...state}
        case 'setParticipantAmount':
            state[PARTICIPANT_AMOUNT] = action.participantAmount || NON_NUMBER
            return {...state}
        case 'resetParticipantAmount':
            state[PARTICIPANT_AMOUNT] = NON_NUMBER
            return {...state}
        default:
            throw new Error('Unhandled lottoNumbers action')
    }
}

const Regular123Form = ({
                            initForm,
                            postFormFunction,
                            calcPriceFunction,
                            color,
                            tablesNumber,
                            formType,
                            numbersLength
                        }: Regular123FormType) => {
    const [tableIndex, setTableIndex] = useState<number>(INIT_TABLE_INDEX)
    const [marks, dispatchMarks] = useReducer(form123Reducer, initForm)

    let subTableIndex = useMemo(() => (Object.values(marks[TABLES][tableIndex][NUMBERS]).filter((number) => number !== NON_NUMBER).length % 3 + 1).toString(), [marks, tableIndex])
    const fullTables = marks[TABLES].filter( table=> Object.values(table[NUMBERS]).indexOf(-1)).length
    
    const validateForm = (lvl: LevelType = STRICT): boolean => {
        for (let table of marks[TABLES]) {
            if (Object.values(table[NUMBERS]).length !== numbersLength) {
                if(lvl === STRICT){
                    AlertHelper.show("יש דשה ריק",
                    () => AlertHelper.hide())
                }
                return false
            }
        }
        if (marks[PARTICIPANT_AMOUNT] === NON_NUMBER) {
            if(lvl === STRICT){
                AlertHelper.show("בחר סוג השקעה",
                () => AlertHelper.hide(),'sendGameForm')
            }
            return false
        }
        return true
    }

    const setMultiLottery = (number: MultiLotterySevenType) => {
        dispatchMarks({
            type: 'setMultiLottery',
            multiLotteryValue: (number as MultiLotterySevenType)
        })
    }

    const {jwtToken} = useContext(UserContext)

    const postFunction = useCallback(() => postFormFunction(marks, jwtToken), [marks])

    const calcFunction = useCallback(() => calcPriceFunction(marks, jwtToken), [marks])

    const tableAutoInsertion = () => {
        dispatchMarks({
            type: 'tableAutoInsertion',
            tableIndex: tableIndex,
        })
    }

    const addNumber = useCallback((number: LottoNumberType) => {
        dispatchMarks({type: 'addNumber', value: (number as number), tableIndex: tableIndex, subTableIndex})
    }, [tableIndex, subTableIndex])

    const removeNumber = useCallback((number: LottoNumberType) => {
        dispatchMarks({type: 'removeNumber', value: (number as number), tableIndex: tableIndex, subTableIndex})
    }, [tableIndex, subTableIndex])

    const popTableNumbers = useCallback(() => {
        dispatchMarks({
            type: 'popTableNumbers',
            tableIndex: tableIndex,
        })
    }, [tableIndex])

    const setParticipantAmount = (value: number) => {
        dispatchMarks({type: 'setParticipantAmount', participantAmount: value})
    }

    const resetParticipantAmount = () => {
        dispatchMarks({type: 'resetParticipantAmount'})
    }

    const updateIndex = () => {
        if (Object.values(marks[TABLES][tableIndex][NUMBERS]).filter((number) => number !== NON_NUMBER).length === numbersLength) {
            if (tableIndex + 1 !== tablesNumber) {
                setTableIndex(Number(tableIndex) + 1)
            }
        }
    }

    useEffect(updateIndex, [marks])

    const incrementTableIndex = useCallback(() => {
        if (Number(tableIndex) + 1 === tablesNumber) {
            setTableIndex(0)
            return
        }
        setTableIndex(Number(tableIndex) + 1)
    }, [tableIndex])

    const decrementTableIndex = useCallback(() => {
        if (Number(tableIndex) - 1 < INIT_TABLE_INDEX) {
            setTableIndex(tablesNumber - 1)
            return
        }
        setTableIndex(Number(tableIndex) - 1)
    }, [tableIndex])

    return (
        <>
            <FirstSectionInstructions mainTitle={HEBREW_MAIN_TITLE} subTitle={SUB_TITLE} bacgroundColor={color}/>
            <SevenUserInput marks={marks} color={color} tableIndex={tableIndex} addNumber={addNumber}
                            incrementTableIndex={incrementTableIndex} removeNumber={removeNumber}
                            setParticipantAmount={setParticipantAmount}
                            decrementTableIndex={decrementTableIndex} tableAutoInsertion={tableAutoInsertion}
                            resetParticipantAmount={resetParticipantAmount}
                            popTableNumbers={popTableNumbers} numbersLength={numbersLength}
                            subTableIndex={Number(subTableIndex)} tablesNumber={tablesNumber}/>
            <InstructionStep marginTop={35} containerHeight={72} stepText={HEBREW_SECOND_STEP_TITLE} stepNumber={2}
                             stepColor={color}/>
            <FormAdditions123 setMultiLottery={setMultiLottery} marks={marks}/>
            <SumAndSubmit123Section marks={marks} color={color} calcPriceFunction={calcFunction}
                                    formType={formType}
                                    fullTables={fullTables}
                                    postFunction={postFunction} validateForm={validateForm}
                                    bottonColor={YELLOW_123_COLOR}/>
        </>
    )
}

export const SevenUserInput = ({
                                   setParticipantAmount,
                                   resetParticipantAmount,
                                   addNumber,
                                   removeNumber,
                                   numbersLength,
                                   incrementTableIndex,
                                   decrementTableIndex,
                                   color,
                                   tablesNumber,
                                   tableAutoInsertion,
                                   popTableNumbers,
                                   marks,
                                   tableIndex,
                                   subTableIndex
                               }: {
    setFormType?: (value: number) => void,
    resetFormType?: () => void
    tableIndex: number,
    subTableIndex: number,
    incrementTableIndex: () => void,
    decrementTableIndex: () => void,
    setParticipantAmount: (value: number) => void,
    resetParticipantAmount: () => void
    color: string
    popTableNumbers: () => void,
    tableAutoInsertion: () => void,
    marks: MarksType123
    isShitati?: boolean
    numbersLength: number
    tablesNumber: number
    addNumber: (lottoNumber: LottoNumberType) => void,
    removeNumber: (lottoNumber: LottoNumberType) => void,

}) => {

    const style = StyleSheet.create({
        formTablesContainer: {
            flexDirection: "row-reverse",
            height: 394,
            ...getBorder(),
            paddingRight: '1%',
            paddingLeft: '1%'
        },
        innerFormTablesrightContainer: {
            width: '50%',
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: WHITE_COLOR,
            borderRadius: 15
        },
        innerFormTablesleftContainer: {
            width: '50%',
            ...getBorder(),
            paddingRight: '2.6%',
            paddingLeft: '8%'
        }
    })
    return (
        <View style={style.formTablesContainer}>
            <View style={style.innerFormTablesrightContainer}>
                <UserInputFormHeader>
                    <UserInputFormUpperHeaderChildren incrementTableIndex={incrementTableIndex}
                                                      decrementTableIndex={decrementTableIndex} color={color}
                                                      tableIndex={tableIndex}/>
                </UserInputFormHeader>
                <DeleteAndFillNumbersSection color={color} popTableNumbers={popTableNumbers}
                                             tableAutoInsertion={tableAutoInsertion}/>
                <UserInput777NumbersForm isReadOnly={false} addNumber={addNumber} removeNumber={removeNumber}
                                         numbersLength={numbersLength} numbersAmount={9} numbersPerRow={3}/>
            </View>
            <UserFormContainer>
                <UserForm123 tablesNumber={5} numbersLength={3} strongNumbersLength={0} marks={marks}
                             tableIndex={tableIndex} isReadOnly={false}/>
                <View style={{marginTop: 60, marginRight: '20%'}}>
                    <ChanceMediumText text={'בחר את סכום ההשקעה'}/>
                    <ChanceRowBalls numbers={[1, 2, 3, 5, 10, 25]} setNumber={setParticipantAmount}
                                    removeNumber={resetParticipantAmount}
                                    isClicked={(number) => marks[PARTICIPANT_AMOUNT] === number} isShitati={false}
                                    ballTextColor={color}/>
                </View>
            </UserFormContainer>
        </View>
    )
}

export const UserForm123 = ({
                                marks,
                                tableIndex,
                                tablesNumber,
                                strongNumbersLength,
                                numbersLength,
                                isReadOnly
                            }: UserFormType<MarksType123>) => {
    const style = StyleSheet.create({
        fillingFormLine: {
            backgroundColor: WHITE_COLOR,
            ...getHeightWidthCss(1, '67%'),
            marginLeft: '14.5%',
            marginTop: 4
        }
    })

    const getFillingRows = () => {
        return _.range(tablesNumber).map((index: number) => <FillingRow rowColor={DARK_RED_COLOR}
                                                                        successColor={SEVEN_ORANGE_COLOR}
                                                                        isReadOnly={isReadOnly}
                                                                        numbersLength={numbersLength}
                                                                        tableNumber={index + 1}
                                                                        isLink={tableIndex == index}
                                                                        numbers={Object.values(marks[TABLES][index][NUMBERS]).filter(number => number !== NON_NUMBER)}
                                                                        strongNumbers={[]}
                                                                        strongNumbersLength={strongNumbersLength}/>)
    }

    return (
        <>
            {getFillingRows()}
            <View style={style.fillingFormLine}/>
            <UserFormBorders tablesNumber={tablesNumber}/>

        </>
    )
}

export const UserInput777NumbersForm = ({
                                            numbersPerRow,
                                            isReadOnly,
                                            numbersAmount,
                                            addNumber,
                                            removeNumber,
                                            numbersLength
                                        }: { isReadOnly?: boolean, numbersLength: number, addNumber: (number: number | string) => void, removeNumber: (number: number | string) => void, numbersAmount: number, numbersPerRow: number }) => {

    const style = {
        numbersContainer: {
            height: 306,
            paddingTop: '1.5%',
            ...getBorder()
        },
        pickBallContainer: {
            flexDirection: "row",
            height: 65,
            width: '100%',
            marginBottom: '1%',
            alignItems: "center",
            justifyContent: "center"
        },
        pickBallCircle: {
            backgroundColor: WHITE_COLOR,
            ...getHeightWidthCss('90%', '14%'),
            borderRadius: '100%',
            marginRight: '3%',
            alignItems: "center",
            justifyContent: "center"
        },
        pickedBallText: {
            ...getFontCss(FB_SPACER_REGULAR, 35, RED_COLOR),
        }
    }
    return (
        <View style={style.numbersContainer}>
            {_.range(1, numbersAmount, numbersPerRow).map((number: number) => <TouchableLottoRowBalls
                removeNumber={removeNumber}
                isClicked={(number: number | string) => false}
                disabled={false}
                rowLength={0}
                onPress={addNumber}
                numbers={_.range(number, number + numbersPerRow < numbersAmount ? number + numbersPerRow : numbersAmount + 1)}
                style={style.pickBallContainer}
                ballStyle={style.pickBallCircle}
                ballTextStyle={style.pickedBallText}/>)}
            <TouchableLottoRowBalls
                removeNumber={removeNumber}
                isClicked={(number: number | string) => false}
                disabled={false}
                rowLength={1}
                onPress={addNumber}
                numbers={[0]}
                style={style.pickBallContainer}
                ballStyle={style.pickBallCircle}
                ballTextStyle={style.pickedBallText}/>
        </View>
    )
}

export const SumAndSubmit123Section = ({
                                           color,
                                           bottonColor,
                                           calcPriceFunction,
                                           postFunction,
                                           validateForm,
                                           formType,
                                           fullTables,
                                           marks
                                       }: SumAndSubmit123SectionType) => {

    const style = StyleSheet.create({
        container: {
            flexDirection: "row-reverse",
            alignItems: "center",
            height: 141,
            paddingTop: 23,
            ...getBorder()
        },
        sumText: {
            ...getFontCss(FB_SPACER_REGULAR, 24, YELLOW_COLOR),
            marginRight: '5%',
            whiteSpace: 'nowrap',
            overflowWrap: 'normal',
        },
        paymentText: {
            position: "absolute",
            left: '27.2%',
            ...getFontCss(FB_SPACER_BOLD, 20, WHITE_COLOR)
        }
    })

    return (
        <View style={style.container}>
            <StepCircle text={'₪'} textColor={color}/>
            <View>
                <StepBigText text={'סיכום ושליחת טופס'}/>
                <Text style={style.sumText}>{`סוג ${EnglishHebrewFormTypeMapping[formType]} | הגרלות ${marks.multi_lottery != -1? marks.multi_lottery : '1' } | טבלאות ${fullTables}`}</Text>
            </View>
            <View style={{
                flexDirection: "row",
                width: 'fit-content',
                left: '3.7%',
                position: "absolute",
                alignItems: "center"
            }}>
                <Payment bottonColor={bottonColor} validateForm={validateForm} postFunction={postFunction}
                         calcPriceFunction={calcPriceFunction} marks={marks}/>
            </View>
        </View>
    )
}

export const FormAdditions123 = ({
                                     setMultiLottery,
                                     marks
                                 }: { setMultiLottery: (number: MultiLotterySevenType) => void, marks: MarksType123 }) => {

    const style = StyleSheet.create({
        container: {
            flexDirection: "row-reverse",
            height: 85,
            alignItems: "center",
            justifyContent: "center",
            ...getBorder()
        },
        numberlotteriesText: {
            ...getFontCss(FB_SPACER_BOLD, 24, WHITE_COLOR),
            marginRight: '3.7%',
            marginLeft: '1.2%'
        },
        textStyle: {
            ...getFontCss(FB_SPACER_BOLD, 12, WHITE_COLOR)
        },
        imageConatiner: {
            flexDirection: "row-reverse",
            alignItems: "center",
            ...getBorder(),
            width: '100%'
        }
    })


    return (
        <View style={style.container}>
            <Text style={style.numberlotteriesText}>מספר הגרלות</Text>
            <NumberOfSevenLotteriesBall multiLottery={marks[MULTI_LOTTERY]} number={2}
                                        setMultiLottery={setMultiLottery}/>
            <NumberOfSevenLotteriesBall multiLottery={marks[MULTI_LOTTERY]} number={3}
                                        setMultiLottery={setMultiLottery}/>
            <NumberOfSevenLotteriesBall multiLottery={marks[MULTI_LOTTERY]} number={4}
                                        setMultiLottery={setMultiLottery}/>
            <QuestionMark/>
            <PipeLine marginLeft={'1.6%'}/>
            {/* <AutomateRenew text={'אוטומטי מתחדש'}/>
            <QuestionMark/> 
            <PipeLine marginLeft={'1.6%'}/>*/}
            <View style={{width: '18%'}}>
                <View style={style.imageConatiner}>
                    <Image source={require("../../../../assets/whiteV.png")}
                           style={{width: 11.7, height: 8.56, marginLeft: '2%'}}/>
                    <Text style={style.textStyle}>המשחק הקל של מפעל הפיס</Text>
                </View>
                <View style={style.imageConatiner}>
                    <Image source={require("../../../../assets/whiteV.png")}
                           style={{width: 11.7, height: 8.56, marginLeft: '2%'}}/>
                    <Text style={style.textStyle}>הפרס הינו 600 כפול סכום ההשקעה</Text>
                </View>
                <View style={style.imageConatiner}>
                    <Image source={require("../../../../assets/whiteV.png")}
                           style={{width: 11.7, height: 8.56, marginLeft: '2%'}}/>
                    <Text style={style.textStyle}>נחש 3 מספרים מתוך 10</Text>
                </View>
            </View>

        </View>
    )
}

export default Regular123Form