import React from 'react';

import {Amplify} from "aws-amplify"
import AWSConfig from "./aws-exports.js"
import "@expo/match-media";
import WebApp from "./components/webApp";
import './App.css'
import 'typeface-roboto'
import {Helmet} from "react-helmet";
import "antd/dist/antd.css";
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module'
import OutbrainTracking from 'react-tracking-outbrain';


Amplify.configure(AWSConfig)

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

ReactPixel.init('709186713835465',undefined, options);
ReactPixel.pageView()
// ReactPixel.fbq('track', 'PageView');

const tagManagerArgs = {
    gtmId: 'GTM-PRMKM7Z'
}

TagManager.initialize(tagManagerArgs)


export default function App() {
    OutbrainTracking.initialize('00b6684c991c4ab920d32eebfdfff25244');
    OutbrainTracking.trackPageview();
    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=0.1, shrink-to-fit=no"/>
                <meta name="facebook-domain-verification" content="pr4n0gwug9gpxbg4al7zj3m934m2dl" />
                {/*// <!-- Global site tag (gtag.js) - Google Ads -->*/}
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10898665051"/>
                <script>
                    {`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments)}gtag('js', new Date());gtag('config', 'AW-10898665051');`}
                </script>
            </Helmet>
            <WebApp/>
        </>
    );

}
