import React from "react";
import {View} from "react-native";
import {
    FB_SPACER_REGULAR,
    getBorder,
    getFontCss,
    getHeightWidthCss,
    RED_COLOR,
    WHITE_COLOR,
    YELLOW_COLOR
} from "../../../styles";
import {TouchableLottoRowBalls} from "../../lottoBalls";
import {TableType} from "../../../types";
import {NUMBERS, STRONG_NUMBERS} from "../../../consts";

let _ = require('lodash')

interface UserInputNumbersProps {
    isReadOnly?: boolean,
    numbersLength: number,
    table: TableType,
    addNumber: (number: number | string) => void,
    removeNumber: (number: number | string) => void,
    numbersAmount: number,
    numbersPerRow: number,
    isMobile?: boolean,
}

export const UserInputNumbersForm = ({numbersPerRow, isReadOnly, numbersAmount, addNumber, table, removeNumber, numbersLength, isMobile}: UserInputNumbersProps) => {

    const style = {
        numbersContainer: {
            height: 306,
            paddingLeft: '12.5%',
            paddingTop: '1.5%',
            ...getBorder()
        },
        numbersContainerMobile: {
            paddingLeft: '6.5%',
            paddingTop: '2.5%',
            paddingBottom: 23,
            ...getBorder()
        },
        pickBallContainer: {
            flexDirection: "row",
            height: 41,
            marginBottom: '1%',
            alignItems: "center"
        },
        pickBallContainerMobile: {
            flexDirection: "row",
            height: 94,
            marginBottom: '1%',
            alignItems: "center"
        },
        pickBallCircle: {
            backgroundColor: WHITE_COLOR,
            ...getHeightWidthCss('90%', '9.45%'),
            borderRadius: '100%',
            marginRight: '3%',
            alignItems: "center",
            justifyContent: "center"
        },
        pickBallCircleMobile: {
            backgroundColor: WHITE_COLOR,
            ...getHeightWidthCss('100%', '10.45%'),
            borderRadius: '100%',
            marginRight: '3%',
            alignItems: "center",
            justifyContent: "center"
        },
        pickedBallText: {
            ...getFontCss(FB_SPACER_REGULAR, 35, RED_COLOR),
        },
        pickedBallTextMobile: {
            ...getFontCss(FB_SPACER_REGULAR, 114, RED_COLOR),
        }
    }
    return (
        <View style={isMobile ? style.numbersContainerMobile : style.numbersContainer}>
            {_.range(1, numbersAmount, numbersPerRow).map((number: number) => <TouchableLottoRowBalls
                removeNumber={removeNumber}
                isClicked={(number: number | string) => table[NUMBERS].includes(Number(number))}
                disabled={isReadOnly || numbersLength === table[NUMBERS].length}
                rowLength={numbersAmount - number < numbersPerRow ? numbersAmount - number : numbersPerRow}
                onPress={addNumber}
                numbers={_.range(number, number + numbersPerRow < numbersAmount ? number + numbersPerRow : numbersAmount + 1)}
                style={isMobile ? style.pickBallContainerMobile : style.pickBallContainer}
                ballStyle={isMobile ? style.pickBallCircleMobile : style.pickBallCircle}
                ballTextStyle={isMobile ? style.pickedBallTextMobile : style.pickedBallText}/>)}
        </View>
    )
}

type PickStrongNumber = {
    table: TableType,
    removeStrongNumber: (number: number | string) => void,
    addStrongNumber: (number: number | string) => void
    strongNumbersLength: number,
    isMobile?: boolean
}
export const PickStrongNumber = ({addStrongNumber, table, removeStrongNumber, strongNumbersLength, isMobile}: PickStrongNumber) => {

    const style = {
        pickStrongNumbersContainer: {
            paddingTop: '1%'
        },
        pickStrongNumbersContainerMobile: {
            paddingTop: '2%',
            marginBottom: 31
        },
        pickBallContainer: {
            flexDirection: "row",
            height: 41,
            alignItems: "center",
            justifyContent: "center"
        },
        pickBallContainerMobile: {
            flexDirection: "row",
            height: 106,
            alignItems: "center",
            justifyContent: "center"
        },
        pickBallCircle: {
            backgroundColor: YELLOW_COLOR,
            ...getHeightWidthCss('100%', '9.45%'),
            borderRadius: '100%',
            marginRight: '3%',
            alignItems: "center",
            justifyContent: "center"
        },
        pickedBallText: {
            ...getFontCss(FB_SPACER_REGULAR, 32, RED_COLOR),
        },
        pickedBallTextMobile: {
            ...getFontCss(FB_SPACER_REGULAR, 70, RED_COLOR),
        }
    }

    return (
        <View style={isMobile ? style.pickStrongNumbersContainerMobile : style.pickStrongNumbersContainer}>
            <TouchableLottoRowBalls rowLength={7} numbers={_.range(1, 8)} style={isMobile ? style.pickBallContainerMobile : style.pickBallContainer}
                                    ballTextStyle={isMobile ? style.pickedBallTextMobile : style.pickedBallText}
                                    ballStyle={style.pickBallCircle} onPress={addStrongNumber}
                                    removeNumber={removeStrongNumber}
                                    isClicked={(number: number | string) => table[STRONG_NUMBERS].includes(Number(number))}
                                    disabled={table[STRONG_NUMBERS].length === strongNumbersLength}/>
        </View>
    )
}

export default UserInputNumbersForm
