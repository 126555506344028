import React, {CSSProperties, useContext, useState} from "react";
import {Image, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {
    FB_SPACER_BOLD,
    getBorder,
    getFontCss,
    GREEN_COLOR,
    ORANGE_COLOR,
    PINK_COLOR_SEVEN,
    RED_COLOR,
    WHITE_COLOR
} from "./styles";
import {NextLotteryPriceText} from "./lotto/regular_lotto/lottoInformation/nextLotteryPrice";
import {Archive, LottoTimer} from "./lotto/regular_lotto/lottoInformation/nextLotteryTimer";
import {Line} from "./genaricComponents";
import {useHistory} from 'react-router-dom'
import {
    CHANCE_PARAM,
    FORM_123_PATH,
    FORM_123_TYPE,
    FORM_777_TYPE,
    HEBREW_CHANCE,
    HEBREW_LOTTO,
    LOTTO_PARAM,
    REGULAR_777_PARAM,
    REGULAR_123_PARAM,
    REGULAR_777_PATH,
    REGULAR_CHANCE_PATH,
    REGULAR_LOTTO_PATH, RESULTS_PATH, HOME_PAGE_PATH, MY_SPACE_PATH
} from "./consts";
import Bottomdetails from "./global/bottomDetails";
import {AboutUs, HowItWorks, UserContext} from "./appSwitch";
import {useMediaQuery} from "react-responsive";
import {Layout, Menu, MenuProps} from "antd";
import {Helmet} from "react-helmet";

export const numberWithCommas = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const masterStyle = StyleSheet.create({
    container: {
        flexDirection: "row-reverse",
        marginTop: 26,
        paddingBottom: 19,
        ...getBorder()
    },
    containerMobile: {
        flexDirection: "row-reverse",
        marginTop: 26,
        paddingBottom: 19,
        alignItems: 'center',
        ...getBorder()
    },
    subContainer: {
        width: '50%',
        ...getBorder(),
    },
    subContainerMobile: {
        width: '44%',
        ...getBorder(),
        justifyContent: "space-between",
    },
    lottoImageContainer: {
        flexDirection: "row-reverse",
    },
    lottoImageContainerMobile: {
        flexDirection: "row-reverse",
        justifyContent: "center",
    },
    lottoImage: {
        marginRight: '43.4%',
        height: 56,
        width: 132
    },
    lottoImageMobile: {
        height: 238,
        width: 527,
        marginBottom: 31,
    },
    lottoBallsImage: {
        height: 140,
        width: 341,
        marginRight: '28.4%'
    },
    timeContainer: {
        width: '50%',
        ...getBorder(),
        alignItems: 'center'
    },
    playNowText: {
        ...getFontCss(FB_SPACER_BOLD, 22, WHITE_COLOR)
    },
    playNowTextMobile: {
        ...getFontCss(FB_SPACER_BOLD, 62, WHITE_COLOR)
    },
    menuItem: {

    }
})

export type GamesTypes = 'לוטו' | "צ'אנס" | "123" | "777"

interface TimeContainerProps {
    color: string,
    type: GamesTypes,
    path: string,
    param: number,
}

type GameContainerProps = TimeContainerProps & { logoPath: any, isMobile: boolean }

const TimeContainer: React.FC<TimeContainerProps> = ({color, type, path, param}) => {
    const {times} = useContext(UserContext)
    const history = useHistory()

    const style = StyleSheet.create({
        playNowContainer: {
            width: 126,
            height: 36,
            borderRadius: 12,
            marginRight: '2.6%',
            backgroundColor: color,
            justifyContent: "center",
            alignItems: "center"
        },
    })

    const goToLottoPage = () => {
        history.push(path)
    }
    return <View style={masterStyle.timeContainer}>
        <LottoTimer backgroundColor={color} endTime={times && times[type] && times[type]['time'] || ''}/>
        <View style={{flexDirection: "row", alignItems: "center", marginTop: 30, marginRight: 207}}>
            <TouchableOpacity style={style.playNowContainer} onPress={goToLottoPage}>
                <Text style={masterStyle.playNowText}>שחק עכשיו</Text>
            </TouchableOpacity>
            <Archive color={color} param={param}/>
        </View>
    </View>
}

const GameSection: React.FC<GameContainerProps> = ({color, path, param, type, logoPath, isMobile}) => {
    const {times} = useContext(UserContext)
    const history = useHistory()

    const style = StyleSheet.create({
        playNowContainer: {
            width: 415,
            height: 102,
            borderRadius: 12,
            marginRight: '2.6%',
            backgroundColor: color,
            justifyContent: "center",
            alignItems: "center"
        },
    })

    const goToLottoPage = () => {
        history.push(path)
    }

    return ( isMobile ?
        <>
            <View style={masterStyle.containerMobile}>
                <View style={masterStyle.subContainerMobile}>
                    <View style={masterStyle.lottoImageContainerMobile}>
                        <Image source={logoPath} style={masterStyle.lottoImageMobile}/>
                    </View>
                    <View style={{flexDirection: "column", alignItems: "center"}}>
                        <TouchableOpacity style={style.playNowContainer} onPress={goToLottoPage}>
                            <Text style={masterStyle.playNowTextMobile}>שחק עכשיו</Text>
                        </TouchableOpacity>
                        <Archive color={color} param={param} isMobile={isMobile}/>
                    </View>
                </View>
                <LottoTimer backgroundColor={color} endTime={times && times[type] && times[type]['time'] || ''} isMobile={isMobile}/>
            </View>
            <Line height={5} backgroundColor={color}/>
        </>
        :
        <>
            <View style={masterStyle.container}>
                <View style={masterStyle.subContainer}>
                    <View style={masterStyle.lottoImageContainer}>
                        <Image source={logoPath} style={masterStyle.lottoImage}/>
                    </View>
                    <View style={{flexDirection: "row-reverse"}}>
                        <NextLotteryPriceText price={times && times[type] && numberWithCommas(+times[type]['win_amount'])}
                                              boldText={times && times[type] && +times[type]['win_amount'] && "עד" || ''}
                                              marginRight={'43.4%'}
                                              formType={type} boldTextColor={color}/>
                    </View>
                </View>
                <TimeContainer color={color} type={type} path={path} param={param}/>
            </View>
            <Line height={5} backgroundColor={color}/>
        </>
    )
}

const { Header, Content, Footer, Sider } = Layout;

interface IHomeProps {

}

export const HomePage: React.FC<IHomeProps> = () => {
    const isMobile = useMediaQuery({ maxDeviceWidth: 780 })
    return (
        <>
            <Helmet>
                <title>לוטומטיק</title>
                <meta name={'כל המשחקים אונליין במקום אחד'} content={'לפניכם כל המשחקים אותם תוכלו לשחק אונליין ולזכות – הגרלת לוטו, הגרלת צ\'אנס, הגרלת 777, הגרלת 123 ואחרות. היכנסו ותתחילו להרוויח בלי לצאת מהבית ובכמה קליקים!'}/>
            </Helmet>
            <GameSection
                type={HEBREW_LOTTO}
                color={RED_COLOR}
                param={LOTTO_PARAM}
                path={REGULAR_LOTTO_PATH}
                logoPath={require('../../assets/logo02.png')}
                isMobile={isMobile}/>
            <GameSection
                type={HEBREW_CHANCE}
                color={GREEN_COLOR}
                param={CHANCE_PARAM}
                path={REGULAR_CHANCE_PATH}
                logoPath={require('../../assets/logo01.png')}
                isMobile={isMobile}/>
            <GameSection
                type={FORM_777_TYPE}
                color={PINK_COLOR_SEVEN}
                param={REGULAR_777_PARAM}
                path={REGULAR_777_PATH}
                isMobile={isMobile}
                logoPath={require('../../assets/logo03.png')}/>
            <GameSection
                type={FORM_123_TYPE}
                color={ORANGE_COLOR}
                param={REGULAR_123_PARAM}
                path={FORM_123_PATH}
                isMobile={isMobile}
                logoPath={require('../../assets/logo04.png')}/>
            <Bottomdetails>
                <HowItWorks/>
                <AboutUs/>
            </Bottomdetails>
        </>
    )
}
