import { Fab, Button } from "@material-ui/core"
import React, { useState, useContext, useCallback } from "react"
import { Text, Alert, TextInput, View } from "react-native"
import { UserContext } from "../appSwitch"
import AddIcon from "@material-ui/icons/Add"
import { SERVER_URL } from "../server/configurations"
import { handleErrors } from "../server/utils"
import { getFontCss, FB_SPACER_REGULAR, BLUE_COLOR } from "../styles"
import { SignInField } from "../auth/utils"
import { updateLocale } from "moment"
import { AlertHelper } from '../AlertHelper';

export const BatchUpload = ({batchId}) => {

    const [file, setFile] = useState<undefined | File>(undefined)
    const { jwtToken } = useContext(UserContext)

    const handleChange = (event: any) => {
        // console.log(event.target.files);
        
        setFile(event.target.files[0])
    }
    const onClick = useCallback(async () => {
        const fd = new FormData();
        let filePath = new URL(`/admin/batch/${batchId}`, SERVER_URL).href;
        if (file  && batchId) {
            fd.append('file', file)
            return await handleErrors(fetch(filePath, {
                method: "POST",
                headers: {
                    Authorization: jwtToken,
                },
                body: fd,
            })).then(() =>{
                AlertHelper.show('הועלה בהצלחה',
                () => AlertHelper.hide())
            }).catch(err => {})
        } else {
            AlertHelper.show('הכנס מספר Batch',
            () => AlertHelper.hide())
        }
    }, [file, batchId, jwtToken])

    return (
        <View style={{ flexDirection: "row-reverse", alignItems: "center" }}>
            <label htmlFor={`upload-photo-${batchId}`} title={'העלה קובץ zip'}>
                <input
                    style={{ display: 'none' }}
                    id={`upload-photo-${batchId}`}
                    name={`upload-photo-${batchId}`}
                    type="file"
                    accept={'.zip'}
                    onChange={handleChange}
                />
                <Fab id="roundedButton" color="primary" size="small" component="span" aria-label="add">
                    <AddIcon/>
                </Fab>
            </label>
            {/* <Text style={{...getFontCss(FB_SPACER_REGULAR, 16, BLUE_COLOR), marginRight: 10}}>{file ? file.name : 'העלה קובץ zip'}</Text> */}
            {file && <Button variant="contained" color="primary" onClick={onClick} title={'העלה קובץ zip'}>העלה קובץ zip</Button>}
        </View>
    )

}