import {
    GET_MY_SPACE_ACTIVE_FORMS,
    GET_MY_SPACE_FORMS_HISTORY,
    GET_MY_SPACE_PAYMENT_HISTORY,
    GET_MY_SPACE_PULLINGS,
    GET_MY_SPACE_REFUNDS,
    GET_MY_SPACE_WINS
} from "./configurations";
import {MyFormsHistoryType, MyPullingsType} from "../types";
import {MyRefundsType} from "../mySpace/myRefunds";
import {MyPaymentHistoryType} from "../mySpace/myPaymentHistory";
import {MyActiveFormsType} from "../mySpace/myActiveForms";
import {MyWinsType} from "../mySpace/myWins";
import {handleErrors} from "./utils";

export const getUserPullings = async (jwtToken: string, page:number, per_page:number): Promise<MyPullingsType> => {
    return await handleErrors(fetch(`${GET_MY_SPACE_PULLINGS.href}/${page}/${per_page}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        }
    }))
};
export const getUserRefunds = async (jwtToken: string): Promise<MyRefundsType> => {
    return await handleErrors(fetch(GET_MY_SPACE_REFUNDS.href, {
        headers: {
            Authorization: jwtToken
        }
    }))
};
export const getUserPaymentHistory = async (jwtToken: string, page:number, per_page:number): Promise<MyPaymentHistoryType> => {
    return await handleErrors(fetch(`${GET_MY_SPACE_PAYMENT_HISTORY.href}/${page}/${per_page}`, {
        headers: {
            Authorization: jwtToken
        }
    }))
};
export const getUserActiveForms = async (jwtToken: string): Promise<MyActiveFormsType> => {
    return await handleErrors(fetch(GET_MY_SPACE_ACTIVE_FORMS.href, {
        headers: {
            Authorization: jwtToken
        }
    }))
};
export const getUserFormsHistory = async (jwtToken: string): Promise<MyFormsHistoryType> => {
    return await handleErrors(fetch(GET_MY_SPACE_FORMS_HISTORY.href, {
        headers: {
            Authorization: jwtToken
        }
    }))
};
export const getUserWins = async (jwtToken: string): Promise<MyWinsType> => {
    return await handleErrors(fetch(GET_MY_SPACE_WINS.href, {
        headers: {
            Authorization: jwtToken
        }
    }))
};