import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Button, Input, Space, Table, DatePicker, Modal, Card, InputNumber, message, Popconfirm} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {addBalance, getAllUsers, getUserBalance, getUserByLottomaticId, removeBalance, User} from "../server/owners";
import Highlighter from 'react-highlight-words';
import moment from "moment";
import {UserContext} from "../appSwitch";
import {makeStyles} from "@material-ui/core/styles";
import { CSVLink } from "react-csv";
import Search from "antd/es/input/Search";

const { RangePicker } = DatePicker;

interface UsersProps {

}

interface UserModalProps {
    user: User;
    isModalVisible: boolean;
    handleOk: () => void;
    handleCancel: () => void;
}

const useStyles = makeStyles((theme) => ({
    usersTable: {
        '& .ant-table-row:hover': {
            cursor: 'pointer'
        },
    }
}));

export const UserPopup: React.FC<UserModalProps> = ({user, isModalVisible, handleOk, handleCancel}) => {
    const {jwtToken} = useContext(UserContext)
    const [isLoading, setIsLoading] = useState(false);
    const [userBalance, setUserBalance] = useState(0);
    const [lottomaticId, setLottomaticId] = useState('');
    const [value, setValue] = useState<number>(0);
    const [removeValue, setRemoveValue] = useState<number>(0);
    const [popConfirmVisibleAdd, setPopConfirmVisibleAdd] = React.useState(false)
    const [popConfirmVisibleRemove, setPopConfirmVisibleRemove] = React.useState(false)
    const showPopConfirmAdd = () => {
        setPopConfirmVisibleAdd(true);
    };
    const showPopConfirmRemove = () => {
        setPopConfirmVisibleRemove(true);
    };
    const getBalance = useCallback(async () => {
        if (jwtToken) {
            const {balance, lottomaticUserId} =  await getUserBalance(jwtToken, user.cognitoId)
            setUserBalance(balance)
            setLottomaticId(lottomaticUserId)
            setIsLoading(false)
        }
    }, [jwtToken, user.cognitoId, isModalVisible])

    useEffect(() => {
        setUserBalance(0)
        setIsLoading(true)
        getBalance()
    }, [user.cognitoId, isModalVisible])

    const addBalanceToUser = useCallback(async () => {
        const res = await addBalance(jwtToken, user.cognitoId, value)
        if(res > 0) {
            setUserBalance(res);
            message.info('Balance Updated')
        }
        else {
            message.info('Something wrong happen');
        }
        setIsLoading(false)
        setPopConfirmVisibleAdd(false);
    }, [value, jwtToken, user.cognitoId])

    const handleAddBalancePopConfirmOk = async () => {
        setIsLoading(true)
        await addBalanceToUser();
    };

    const handleAddBalancePopConfirmCancel = () => {
        setPopConfirmVisibleAdd(false);
    };

    const removeBalanceToUser = useCallback(async () => {
        const res = await removeBalance(jwtToken, user.cognitoId, removeValue)
        if(res > 0) {
            setUserBalance(res);
            message.info('Balance Updated')
        }
        else {
            message.info('Something wrong happen');
        }
        setIsLoading(false)
        setPopConfirmVisibleRemove(false);
    }, [removeValue, jwtToken, user.cognitoId])

    const handleRemoveBalancePopConfirmOk = async () => {
        setIsLoading(true)
        await removeBalanceToUser();
    };

    const handleRemoveBalancePopConfirmCancel = () => {
        setPopConfirmVisibleRemove(false);
    };

    return (
        <Modal title={'User Details'} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <Card title={`${user.firstName} ${user.familyName}`}>
                <p>Email: {user.email}</p>
                <p>Phone: {user.phone}</p>
                <p>Cognito id: {user.cognitoId}</p>
                <p>Balance: {userBalance}</p>
                <p>Balanced synced: {user.isBalanceSync ? 'Yes' : 'No'}</p>
                <p>Lottomatic User Id: {lottomaticId}</p>
                <div>
                    <span>Add balance to user: </span>
                    <InputNumber min={0} value={value} onChange={setValue} />
                    <Popconfirm
                        title="Are you sure ?"
                        visible={popConfirmVisibleAdd}
                        onConfirm={handleAddBalancePopConfirmOk}
                        okButtonProps={{ loading: isLoading }}
                        onCancel={handleAddBalancePopConfirmCancel}
                    >
                        <Button type={'primary'} onClick={showPopConfirmAdd}>Add</Button>
                    </Popconfirm>
                </div>
                <div>
                    <span>Withdraw balance from user: </span>
                    <InputNumber min={0} value={removeValue} onChange={setRemoveValue} />
                    <Popconfirm
                        title="Are you sure ?"
                        visible={popConfirmVisibleRemove}
                        onConfirm={handleRemoveBalancePopConfirmOk}
                        okButtonProps={{ loading: isLoading }}
                        onCancel={handleRemoveBalancePopConfirmCancel}
                    >
                        <Button type={'primary'} onClick={showPopConfirmRemove}>Withdraw</Button>
                    </Popconfirm>
                </div>
            </Card>
        </Modal>
    )
}

export const UsersTable: React.FC<UsersProps> = () => {
    const classes = useStyles()
    const {jwtToken} = useContext(UserContext)
    const [users, setAllUsers] = useState<User[]>([])
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const [isLoading, setIsLoading] = useState(false);

    const usersWithLeadingZero = useMemo(() => users.map(user => ({...user, phone: `=""${user?.phone?.replace('+972', '0')}""`})), [users])

    const allFormsGetter = useCallback(async () => {
        if (jwtToken) {
            const users =  await getAllUsers(jwtToken)
            // setTotalPrice(sum(af.map(form => form.price)))
            // setTotalOriginPrice(sum(af.map(form => form.origin_price)))
            // setNumberOfForms(af.length);
            setAllUsers(users)
            setIsLoading(false)
        }
    }, [jwtToken, setIsLoading])

    useEffect( () => {
        setIsLoading(true)
        allFormsGetter()
    }, [])

    let searchInput: import("antd").InputRef | null;
    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                    confirm({ closeDropdown: false });
                    setSearchText(selectedKeys[0]);
                    setSearchedColumn(dataIndex);
                }}
              >
                Filter
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : false,
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => searchInput.select(), 100);
          }
        },
        render: (text: { toString: () => any; }) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={[searchText]}
                  autoEscape
                  textToHighlight={text ? text.toString() : ''}
                />
              ) : (
                text
              ),
    });

    const handleSearch = (selectedKeys: React.SetStateAction<string>[], confirm: () => void, dataIndex: string) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('')
    };

    const handleChange = (pagination: any, filters: any, sorter: any, extra: { currentDataSource: Array<User> }) => {
        console.log('Various parameters', extra.currentDataSource);
        // setTotalPrice(sum(extra.currentDataSource.map(form => form.price)))
        // setTotalOriginPrice(sum(extra.currentDataSource.map(form => form.origin_price)))
        // setNumberOfForms(extra.currentDataSource.length);
    }

    const getColumnDateProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8, display: 'flex', flexDirection: 'column' }}>
            <RangePicker
                value={selectedKeys[0] ? selectedKeys[0].split('$').map(item => moment.utc(item)) : ''}
                onChange={(values: any, formatString: [string, string]) => {
                    if (values !== null) {
                        setSelectedKeys([formatString.join('$')]);
                    }
                }}
            />
            <Space style={{marginTop: 8}}>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                    confirm({ closeDropdown: false });
                    setSearchText([selectedKeys[0], selectedKeys[0]].join('$'));
                    setSearchedColumn(dataIndex);
                }}
              >
                Filter
              </Button>
            </Space>
          </div>
        ),
        // filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => {
            const dates = value.split('$')
            const startDate = moment.utc(dates[0])
            const endDate = moment.utc(dates[1])
            return record[dataIndex] ? moment.utc(record[dataIndex]).isBetween(startDate, endDate) : false
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible && searchInput) {
            setTimeout(() => searchInput.select(), 100);
          }
        },
    });

    const onRowClick = useCallback((row, i) => {
            return {
                onClick: () => showModal(row)
            };
    },[]);

    const onSearch = useCallback(async (value: string) => {
        const res = await getUserByLottomaticId(jwtToken, value);
        showModal(res);
    }, [jwtToken])

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState<User>();

    const showModal = (row: User) => {
        setSelectedUser(row);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const columns = [
    {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        ...getColumnSearchProps('id'),
    },
    {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName',
        ...getColumnSearchProps('firstName'),
    },
    {
        title: 'Last Name',
        dataIndex: 'familyName',
        key: 'familyName',
    },
    {
        title: 'Created',
        dataIndex: 'created',
        key: 'created',
        ...getColumnDateProps('created'),
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
    },
];
    return (
        <>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <CSVLink data={usersWithLeadingZero}>Export Users</CSVLink>
                <Search placeholder="search user by lottomatic id" allowClear onSearch={onSearch} style={{ marginLeft: 20, width: 340 }} />
            </div>
            <Table className={classes.usersTable} onRow={onRowClick} columns={columns} dataSource={users} loading={isLoading} onChange={handleChange} />
            {selectedUser && <UserPopup isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel} user={selectedUser} />}
        </>
    )
}