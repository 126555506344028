import React, {useCallback} from "react";
import {Button, Image, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {ContainerWithText, PipeLine} from "../../../genaricComponents";
import {
    FB_SPACER_BOLD,
    FB_SPACER_REGULAR,
    getBorder,
    getCircleCss,
    getFontCss,
    PINK_COLOR,
    RED_COLOR,
    WHITE_COLOR,
    YELLOW_COLOR
} from "../../../styles";
import {LottoMarksType, MultiLotteryChanceType, MultiLotteryType} from "../../../types";
import {EXTRA, MULTI_LOTTERY, NON_NUMBER} from "../../../consts";
import { IconButton, Tooltip } from "@material-ui/core";
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';

type AutomateRenew = { text: string }
type NumberOfLotteriesBallType = { multiLottery: MultiLotteryType | MultiLotteryChanceType, number: MultiLotteryType | MultiLotteryChanceType, setMultiLottery: (number: MultiLotteryType | MultiLotteryChanceType) => void, isMobile?: boolean }
type FormAdditionsType = {
    setMultiLottery: (number: MultiLotteryChanceType | MultiLotteryType) => void,
    isExtra: () => void,
    marks: LottoMarksType,
    isMobile?: boolean
}


export const NumberOfLotteriesBall = ({number, setMultiLottery, multiLottery, isMobile}: NumberOfLotteriesBallType) => {

    const clickedButtonCss = multiLottery === number ? {
        borderWidth: 1,
        borderColor: 'rgba(255, 255, 255, 0.5)',
        backgroundColor: WHITE_COLOR,
        boxShadow: '0 0 2px 2px rgba(255, 255, 255, 0.5)'
    } : {}
    const clickedTextCss = multiLottery === number ? {
        color: 'rgba(0, 0, 0, 0.5)'
    } : {}


    const style = StyleSheet.create({
        containerStyle: {
            ...getCircleCss(33, 33, 16.5),
            borderWidth: 3,
            borderColor: WHITE_COLOR,
            backgroundColor: multiLottery === number ? PINK_COLOR : undefined,
            justifyContent: "center",
            alignItems: "center",
            marginLeft: '1%',
            ...clickedButtonCss
        },
        textStyle: {
            ...getFontCss(FB_SPACER_REGULAR, 34, WHITE_COLOR),
            ...clickedTextCss
        },
        containerStyleMobile: {
            ...getCircleCss(66, 76, 16.5),
            borderWidth: 3,
            borderColor: WHITE_COLOR,
            backgroundColor: multiLottery === number ? PINK_COLOR : undefined,
            justifyContent: "center",
            alignItems: "center",
            marginLeft: '1%',
            ...clickedButtonCss
        },
        textStyleMobile: {
            ...getFontCss(FB_SPACER_REGULAR, 70, WHITE_COLOR),
            ...clickedTextCss
        },
    })

    const onPress = useCallback(() => {
        if (multiLottery !== number) {
            setMultiLottery(number)
        } else {
            setMultiLottery(NON_NUMBER)
        }
    }, [multiLottery])

    return (
        <TouchableOpacity style={isMobile ? style.containerStyleMobile : style.containerStyle} onPress={onPress}>
            <Text style={isMobile ? style.textStyleMobile : style.textStyle}>{number}</Text>
        </TouchableOpacity>
    )
}

export const QuestionMark = ({text="?", description='אם ברצונך לשלוח טופס זה גם בהגרלות הבאות - אנא בחר מס הגרלות ואנו נדאג לשלוח עבורך', isMobile = false}) => {
    const style = StyleSheet.create({
        containerStyle: {
            ...getCircleCss(11, 11, 5.5),
            backgroundColor: WHITE_COLOR,
            justifyContent: "center",
            alignItems: "center",
            marginLeft: '1.5%',
        },
        textStyle: {
            ...getFontCss(FB_SPACER_BOLD, 12, RED_COLOR)
        }
    })

    return (
        <>
            {isMobile ?
             <ContainerWithText text={text} containerStyle={style.containerStyle} textStyle={style.textStyle}/>
             :
            <Tooltip title={description}>
                <IconButton size={'medium'}>
                    <HelpRoundedIcon style={{ color: 'white' }} />
                </IconButton>
            </Tooltip> }
        </>
    )
}

export const AutomateRenew = ({text}: AutomateRenew) => {
    const style = StyleSheet.create({
        containerStyle: {
            ...getCircleCss(24, 140, 12),
            borderColor: WHITE_COLOR,
            borderWidth: 1,
            borderStyle: "solid",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: '1.6%',
        },
        textStyle: {
            ...getFontCss(FB_SPACER_BOLD, 15, WHITE_COLOR)
        }
    })

    return (
        <ContainerWithText text={text} containerStyle={style.containerStyle} textStyle={style.textStyle}/>
    )
}

const FormAdditions = ({isExtra, marks, setMultiLottery, isMobile}: FormAdditionsType) => {

    const style = StyleSheet.create({
        container: {
            flexDirection: "row-reverse",
            height: 85,
            alignItems: "center",
            justifyContent: "center",
            ...getBorder()
        },
        numberlotteriesText: {
            ...getFontCss(FB_SPACER_BOLD, 24, WHITE_COLOR),
            marginRight: '3.7%',
            marginLeft: '1.2%'
        },
        yellowCircle: {
            ...getCircleCss(20, 20, 10),
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: YELLOW_COLOR,
            backgroundColor: marks[EXTRA] ? YELLOW_COLOR : undefined

        },
        extraImage: {
            height: 42,
            width: 86,
            marginLeft: '1.5%'
        },
        containerMobile: {
            flexDirection: "row-reverse",
            height: 85,
            alignItems: "center",
            justifyContent: "center",
            ...getBorder()
        },
    })


    return (
        <View style={isMobile ? style.containerMobile : style.container}>
            <MultiLottery multiLotteryValue={marks[MULTI_LOTTERY]} setMultiLottery={setMultiLottery} isMobile={isMobile}/>
            <QuestionMark isMobile={isMobile}/>
            <PipeLine marginLeft={'1.6%'} isMobile={isMobile}/>
            {/* <AutomateRenew text={'אוטומטי מתחדש'}/>
            <QuestionMark/>
            <PipeLine marginLeft={'1.6%'}/> */}
            <Extra extra={marks[EXTRA]} isExtra={isExtra} isMobile={isMobile}/>
        </View>
    )
}

export const MultiLottery = ({multiLotteryValue, setMultiLottery, isReadOnly, isMobile}: {
    setMultiLottery: (number: MultiLotteryType | MultiLotteryChanceType) => void,
    multiLotteryValue: MultiLotteryType | MultiLotteryChanceType
    isReadOnly?: boolean,
    isMobile?: boolean,
}) => {
    const style = StyleSheet.create({
        numberlotteriesText: {
            ...getFontCss(FB_SPACER_BOLD, 24, WHITE_COLOR),
            marginRight: '3.7%',
            marginLeft: '1.2%'
        },
        numberlotteriesTextMobile: {
            ...getFontCss(FB_SPACER_BOLD, 50, WHITE_COLOR),
            marginRight: '3.7%',
            marginLeft: '1.2%'
        }
    })
    const multiLotteriesValues: MultiLotteryType[] = [2, 4, 6, 8, 10]
    return (
        <>
            <Text style={isMobile ? style.numberlotteriesTextMobile : style.numberlotteriesText}>מספר הגרלות</Text>
            {!isReadOnly && multiLotteriesValues.map((number) => <NumberOfLotteriesBall multiLottery={multiLotteryValue}
                                                                                        number={number}
                                                                                        setMultiLottery={setMultiLottery} isMobile={isMobile}/>)}
            {isReadOnly && multiLotteryValue !== NON_NUMBER &&
            <NumberOfLotteriesBall multiLottery={multiLotteryValue} number={multiLotteryValue}
                                   setMultiLottery={setMultiLottery} isMobile={isMobile}/>}
        </>
    )
}
export const Extra = ({extra, isExtra, isMobile}: {
    isExtra: () => void,
    extra: boolean,
    isMobile?: boolean
}) => {

    const style = StyleSheet.create({
        yellowCircle: {
            ...getCircleCss(20, 20, 10),
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: YELLOW_COLOR,
            backgroundColor: extra ? YELLOW_COLOR : undefined

        },
        extraImage: {
            height: 42,
            width: 86,
            marginLeft: '1.5%'
        },
        yellowCircleMobile: {
            ...getCircleCss(34, 34, 14),
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: YELLOW_COLOR,
            backgroundColor: extra ? YELLOW_COLOR : undefined

        },
        extraImageMobile: {
            height: 69,
            width: 142,
            marginLeft: '1.5%'
        },
    })
    return (
        <>
            <Image source={require('../../../../../assets/Extra.png')} style={isMobile ? style.extraImageMobile : style.extraImage}/>
            <QuestionMark description={`הגרלה נפרדת בו המחשב מגריל מס בן 6 ספרות ( בין 1-6 )  "extra"`}/>
            <TouchableOpacity onPress={isExtra} style={isMobile ? style.yellowCircleMobile : style.yellowCircle}/>
        </>
    )
}


export default FormAdditions