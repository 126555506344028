import React, {FC, useContext} from "react";
import NextRegularLotteryInfo from "./regular_lotto/lottoInformation/nextRegularLotteryInfo";
import {StyleSheet, View} from "react-native";
import LottoFormNavigation from "./lottoFormNavigation";
import {getBorder, getHeightWidthCss} from "../styles";
import {Line} from "../genaricComponents";
import {CHANCE_SHITATI_PATH, HEBREW_CHANCE, RAV_CHANCE_PATH, REGULAR_CHANCE_PATH} from "../consts";
import {UserContext} from "../appSwitch";

const CHANCE_NAVIGATION_TITLES = {
    "צ'אנס": REGULAR_CHANCE_PATH,
    "רב צ'אנס": RAV_CHANCE_PATH,
    "צ'אנס שיטתי": CHANCE_SHITATI_PATH
}

const ChanceForm = ({FormComponent, color}: { color: string, FormComponent: FC }) => {

    const style = StyleSheet.create({
        lottoFormContainer: {
            height: 899,
            ...getBorder()
        },
        formContainer: {
            backgroundColor: color,
            height: 895,
            marginLeft: '12.4%',
            marginRight: '12.4%',
            ...getBorder()
        },
        imgStyle: {
            position: "absolute",
            alignSelf: "center",
            right: '64%',
            ...getHeightWidthCss(131, 114)
        }
    })
    const {times} = useContext(UserContext)


    return (
        <>
            <NextRegularLotteryInfo color={color} formType={HEBREW_CHANCE}
                                    img={require('../../../assets/chance-logo-form.png')}
                                    imgStyle={style.imgStyle} endTime={times && times[`צ'אנס`] && times[`צ'אנס`]['time'] || ''}/>
            <View style={style.lottoFormContainer}>
                <Line height={4} backgroundColor={color}/>
                <View style={style.formContainer}>
                    <LottoFormNavigation titles={CHANCE_NAVIGATION_TITLES} backgroundColor={color}/>
                    <FormComponent/>
                </View>
            </View>
        </>
    )
}

export default ChanceForm