import React, {useCallback, useContext, useEffect, useState} from "react";
import {Button, Input, Space, Table, DatePicker, Card} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {getAllTransactions, Transaction} from "../server/owners";
import Highlighter from 'react-highlight-words';
import moment, {Moment} from "moment";
import {UserContext} from "../appSwitch";
import { sum } from 'lodash';

const { RangePicker } = DatePicker;

interface ITransactionStatsProps {
    totalPrice: number;
    isLoading: boolean;
}
interface IProps {
}

export const statuses = ['init', 'success', 'failure', 'approved_await_token', 'await_approval', 'await_refund', 'declined_await_token', 'declined']
const operations = ['deposit_no_create', 'deposit_with_create', 'create_only', 'token_charge', 'token_withdraw', 'game_withdraw', 'withdraw_with_create', 'manual_charge', 'win_deposit', 'manual_withdraw']

export const TransactionStats: React.FC<ITransactionStatsProps> = ({totalPrice, isLoading}) => {
    return (
        <Space direction="horizontal" size="large" style={{ display: 'flex' }}>
            <Card title="Total Amount" size="small" loading={isLoading}>
                <p>{totalPrice.toFixed(2)}</p>
            </Card>
        </Space>
    );
}

export const TransactionsTable: React.FC<IProps> = () => {
    const {jwtToken} = useContext(UserContext)
    const [allTransactions, setAllTransactions] = useState<Transaction[]>([])
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const [totalAmount, setTotalAmount] = useState(0)

    const [isLoading, setIsLoading] = useState(false);

    const allTransGetter = useCallback(async () => {
        if (jwtToken) {
            const trans =  await getAllTransactions(jwtToken);
            setTotalAmount(trans.reduce((total, t) => total + parseFloat(t.sumAmount), 0))
            setAllTransactions(trans)
            setIsLoading(false)
        }
    }, [jwtToken, setIsLoading])

    useEffect( () => {
        setIsLoading(true)
        allTransGetter()
    }, [])

    let searchInput: import("antd").InputRef | null;
    const getColumnDateProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8, display: 'flex', flexDirection: 'column' }}>
            <RangePicker
                value={selectedKeys[0] ? selectedKeys[0].split('$').map(item => moment.utc(item)) : ''}
                onChange={(values: any, formatString: [string, string]) => {
                    if (values !== null) {
                        setSelectedKeys([formatString.join('$')]);
                    }
                }}
            />
            <Space style={{marginTop: 8}}>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                    confirm({ closeDropdown: false });
                    setSearchText([selectedKeys[0], selectedKeys[0]].join('$'));
                    setSearchedColumn(dataIndex);
                }}
              >
                Filter
              </Button>
            </Space>
          </div>
        ),
        // filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => {
            const dates = value.split('$')
            const startDate = moment.utc(dates[0])
            const endDate = moment.utc(dates[1])
            return record[dataIndex] ? moment.utc(record[dataIndex]).isBetween(startDate, endDate) : false
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible && searchInput) {
            setTimeout(() => searchInput.select(), 100);
          }
        },
    });
    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                    confirm({ closeDropdown: false });
                    setSearchText(selectedKeys[0]);
                    setSearchedColumn(dataIndex);
                }}
              >
                Filter
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : false,
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => searchInput.select(), 100);
          }
        },
        render: (text: { toString: () => any; }) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={[searchText]}
                  autoEscape
                  textToHighlight={text ? text.toString() : ''}
                />
              ) : (
                text
              ),
    });

    const handleSearch = (selectedKeys: React.SetStateAction<string>[], confirm: () => void, dataIndex: string) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('')
    };

    const handleChange = (pagination: any, filters: any, sorter: any, extra: { currentDataSource: Array<Transaction> }) => {
        console.log('Various parameters', extra.currentDataSource);
        setTotalAmount(sum(extra.currentDataSource.map(t => t.sumAmount)));
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Lottomatic User Id',
            dataIndex: 'userId',
            key: 'userId',
            ...getColumnSearchProps('userId'),
        },
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            ...getColumnDateProps('created'),
            render: text => text ? new Date(text).toLocaleString('en-GB', { hour12: false }) : ''
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            key: 'operation',
            filters: operations.map(status => ({
                text: status,
                value: status
            })),
            onFilter: (value, record) => record.operation.indexOf(value) === 0,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a: Transaction, b: Transaction) => {
                if(a.status < b.status) { return -1; }
                if(a.status > b.status) { return 1; }
                return 0;
            },
            filters: statuses.map(status => ({
                text: status,
                value: status
            })),
            onFilter: (value, record) => record.status.indexOf(value) === 0,
        },
        {
            title: 'Sum',
            dataIndex: 'sumAmount',
            key: 'sumAmount',
        },
        {
            title: 'Currency',
            dataIndex: 'coin',
            key: 'coin',
        },
        {
            title: 'CC Token Id',
            dataIndex: 'ccTokenId',
            key: 'ccTokenId',
        },
        {
            title: 'Updated',
            dataIndex: 'updated',
            key: 'updated',
            render: text => text ? new Date(text).toLocaleString('en-GB', { hour12: false }) : ''
        },
    ];
    return <>
        <TransactionStats totalPrice={totalAmount} isLoading={isLoading}/>
        <Table columns={columns} dataSource={allTransactions} loading={isLoading} onChange={handleChange} />
    </>
}