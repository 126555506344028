import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import React, {useCallback, useState, useContext, useMemo} from "react";
import {useHistory} from "react-router-dom";
import {Auth} from "aws-amplify";
import {useAsync} from "../server/utils";
import {ISignUpResult} from "amazon-cognito-identity-js";
import {HOME_PAGE_PATH, SIGN_IN_PATH, TERMS_PATH} from "../consts";
import {
    BLUE_COLOR,
    FB_SPACER_BOLD,
    FB_SPACER_REGULAR,
    getCircleCss,
    getFontCss,
    RED_COLOR,
    WHITE_COLOR,
    YELLOW_123_COLOR
} from "../styles";
import {DateField, GenderSelection, SignInField} from "./utils";
import Checkbox from "@material-ui/core/Checkbox";
import { IconButton, Tooltip } from "@material-ui/core";
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';
import {useQuery, UserContext} from "../appSwitch";

export const CONFIRMATION_ERROR_MESSAGE = 'קוד אימות שגוי'
const CONFIRMATION_CODE_MESSAGE = "קוד אימות נשלח לטלפון, אנא הזן אותו כאן"

export const SignUp = ({isMobile} : {isMobile: boolean}) => {

    const style = StyleSheet.create({
        container: {
            // width: '34.3%',
            marginHorizontal: 400,
            backgroundColor: BLUE_COLOR,
            alignItems: "center",
            justifyContent: "center",
            marginVertical: 50,
            alignSelf: "center",
            borderRadius: 12,
            height: 'fit-content',
            paddingBottom: 50,
        },
        containerMobile: {
            width: '100%',
            // marginHorizontal: 400,
            backgroundColor: BLUE_COLOR,
            alignItems: "center",
            justifyContent: "center",
            marginVertical: 50,
            alignSelf: "center",
            borderRadius: 12,
            height: 'fit-content',
            paddingBottom: 50,
        },
        questionContainer: {
            flexDirection: "row-reverse",
            width: "100%",
            marginRight: "22%"
        },
        questionContainerMobile: {
            flexDirection: "row-reverse",
            width: "100%",
            marginRight: "13%"
        },
        headerText: {
            ...getFontCss(FB_SPACER_BOLD, 35, WHITE_COLOR),
            marginBottom: 50,
            marginTop: 10,
            alignSelf: "flex-end",
            marginRight: '2%'
        },
        headerTextMobile: {
            ...getFontCss(FB_SPACER_BOLD, 60, WHITE_COLOR),
            marginBottom: 50,
            marginTop: 10,
            alignSelf: "flex-end",
            marginRight: '2%'
        }
    })
    let query = useQuery();

    const resellerCode = useMemo(() => query.get('resellerCode')?.replace(',', '') || '', [query])
    const [userData, setUserData] = useState<{ [key: string]: string }>({
        password: '',
        confirmationPassword: '',
        phone_number: '',
        email: '',
        family_name: '',
        confirmationCode: '',
        address: '',
        birthdate: '',
        gender: 'זכר',
        name: '',
        ID: '',
        resellerCode,
    })
    const [error, setError] = useState('')
    const history = useHistory()
    const [agree, setAgree] = useState(false)
    const [over18, setOver18] = useState(false)

    const {setIsAuthenticated, setUser, setIsisAuthenticating,  updateBalance} = useContext(UserContext)
    const signIn = useCallback(() =>
    Auth.signIn(
        userData['email'].toLowerCase(),
        userData['password']
        ).then(user => {
            history.goBack()
            setUser(user)
            setIsisAuthenticating(false)
            setIsAuthenticated(true)
            updateBalance(user?.signInUserSession?.accessToken?.jwtToken)
            return user
        })
        , [userData])
    const signUp = useCallback(() => {
        return Auth.signUp(
            {
                username: userData['email'].toLowerCase(),
                password: userData['password'],
                attributes: {
                    email: userData['email'].toLowerCase(),
                    phone_number: userData['phone_number'].replace('0', '+972'),
                    family_name: userData['family_name'],
                    address: userData['address'],
                    gender: userData['gender'],
                    birthdate: userData['birthdate'],
                    name: userData['name'],
                    given_name: userData['ID'],
                    'custom:ID': userData['ID'],
                    'custom:balance': "0",
                    'custom:reseller_code': userData['resellerCode']
                }
            }
        ).then(res=> {
            return res;
        }).catch(err=> {
            switch(err.code){
                case "UsernameExistsException":
                    setError("קיים משתמש עם אימייל זהה במערכת")
                    throw new Error(err.code);

                case "InvalidPasswordException":
                    setError("אנא הזן סיסמה באורך של 8 תווים או יותר")
                    throw new Error(err.code);
                case "UserLambdaValidationException":
                    if(err.message == "PreSignUp failed with error User Id exists."){
                        setError("תעודת זהות קיימת במערכת")
                        throw new Error(err.code);
                    }else if(err.message == "PreSignUp failed with error Invalid Id.") {
                        setError("תעודת זהות אינה תקינה ")
                        throw new Error(err.code);
                    }

                default:
                    setError(err.code)
                    throw new Error(err.code)
            }
        })

    }, [userData, agree, over18])
    const {
        execute: executeSignUp,
        status: statusSignUp
    } = useAsync<ISignUpResult, { message: string }>(signUp, false)


    const confirmSignUp = useCallback(() => {
        return Auth.confirmSignUp(userData['email'].toLowerCase(), userData['confirmationCode'])
            .then(() => {
                
                signIn()
            })
    }, [userData])
    const {
        execute: executeConfirmation,
        error: errorConfirmation,
        status: statusConfirmation
    } = useAsync<any, { message: string }>(confirmSignUp, false)

    const onChangeText = (key: string, value: string) => {
        setUserData({...userData, [key]: value})
    }
    const passwordVerification = useCallback(() => {
        if (!userData.name) {
            setError('מלא שם פרטי')
            window.scrollTo(0, 0);
            return;
        }
        if (!userData.family_name) {
            setError('מלא שם משפחה')
            window.scrollTo(0, 0);
            return;
        }
        if (!userData.address) {
            setError('מלא כתובת')
            window.scrollTo(0, 0);
            return;
        }
        if (!userData['ID']) {
            setError('מלא תעודת זהות')
            window.scrollTo(0, 0);
            return;
        }
        if (userData['ID'].length != 9) {
            setError('תעודת זהות אינה תקינה')
            window.scrollTo(0, 0);
            return;
        }
        if (!userData.phone_number) {
            setError('מלא מספר פלאפון')
            window.scrollTo(0, 0);
            return;
        }
        if (!userData.phone_number.startsWith('0')) {
            setError('מספר פלאפון אינו תקין')
            window.scrollTo(0, 0);
            return;
        }
        if (userData.phone_number.length != 10) {
            setError('מספר פלאפון אינו תקין')
            window.scrollTo(0, 0);
            return;
        }
        if (!userData.email) {
            setError('מלא אימייל')
            window.scrollTo(0, 0);
            return;
        }
        if (!userData.email.includes('@')) {
            setError('אימייל אינו תקין')
            window.scrollTo(0, 0);
            return;
        }
        if (!userData.confirmationPassword) {
            setError('מלא אימות סיסמה')
            window.scrollTo(0, 0);
            return;
        }
        if (userData.confirmationPassword !== userData.password) {
            setError('שגיאה באימות הסיסמה')
            window.scrollTo(0, 0);
            return;
        }
        if (!userData.birthdate) {
            setError('מלא תאריך לידה')
            window.scrollTo(0, 0);
            return;
        }
        if (!agree) {
            setError('אשר את הנהלים ותקנון האתר')
            window.scrollTo(0, 0);
            return
        }
        if (!over18) {
            setError('אשר היותך מעל גיל 18')
            window.scrollTo(0, 0);
            return
        }

        // executeSignUp()
    }, [userData, agree, over18])

    const QuestionMark = ({description}) => {
        const style = StyleSheet.create({
            containerStyle: {
                ...getCircleCss(11, 11, 5.5),
                backgroundColor: WHITE_COLOR,
            },
            textStyle: {
                ...getFontCss(FB_SPACER_BOLD, 12, RED_COLOR)
            }
        })
    
        return (
            <>
                <Tooltip title={description}>
                    <IconButton>
                        <HelpRoundedIcon style={{ color: 'white' }} />
                    </IconButton>
                </Tooltip>
            </>
        )
    }

    return (
        <View style={isMobile ? style.containerMobile : style.container}>
            {statusSignUp !== 'success' &&
            <Text style={isMobile ? style.headerTextMobile : style.headerText}>הרשמה - לקוח חדש</Text>}
            {error && statusSignUp !== 'success' &&
            <Text
                style={isMobile ? {...getFontCss(FB_SPACER_BOLD, 55, RED_COLOR)} : {...getFontCss(FB_SPACER_BOLD, 20, RED_COLOR)}}>{error}</Text>}

            {statusSignUp !== 'success' && <>
                <SignInField onChangeText={(value) => onChangeText('name', value)} placeholder={'שם'} autoCompleteType={'name'} isMobile={isMobile}/>
                <SignInField onChangeText={(value) => onChangeText('family_name', value)} placeholder={'שם משפחה'} isMobile={isMobile}/>
                <SignInField onChangeText={(value) => onChangeText('address', value)} placeholder={'כתובת'} isMobile={isMobile}/>
                <View style={isMobile ? style.questionContainerMobile : style.questionContainer}>
                    <SignInField onChangeText={(value) => onChangeText('ID', value)} placeholder={'תעודת זהות'}
                             keyboardType={"numeric"} maxLength={9} isMobile={isMobile}/>
                    <QuestionMark description={`ת"ז מלאה (9 ספרות). לא ניתן להירשם עם אותה ת"ז פעמיים`}/>
                </View>    
                <SignInField onChangeText={(value) => onChangeText('phone_number', value)}
                             placeholder={'מספר פלאפון'} maxLength={10} isMobile={isMobile}/>
                <View style={isMobile ? style.questionContainerMobile : style.questionContainer}>
                    <SignInField onChangeText={(value) => onChangeText('email', value)} placeholder={'אימייל'}
                             autoCompleteType={'email'} textContentType={'emailAddress'} isMobile={isMobile}/>
                    <QuestionMark description={`אנא הזן מייל פעיל. לא ניתן להירשם עם אותו מייל פעמיים`}/>
                </View> 
                <View style={isMobile ? style.questionContainerMobile : style.questionContainer}>
                    <SignInField onChangeText={(value) => onChangeText('password', value)} placeholder={'סיסמה'}
                             secureTextEntry={true} isMobile={isMobile}/>
                    <QuestionMark description={`סיסמה צריכה להיות בת 8 תווים לפחות`}/>
                </View>
                <SignInField onChangeText={(value) => onChangeText('confirmationPassword', value)}
                             placeholder={'אימות סיסמה'}
                             secureTextEntry={true} isMobile={isMobile}/>
                <DateField onChange={(value) => onChangeText('birthdate', value.replace('-', '.'))}
                           label={'תאריך לידה'} isMobile={isMobile} id={'userBirthday'}/>
                <SignInField onChangeText={(value) => onChangeText('resellerCode', value)} placeholder={'קוד משווק'} isMobile={isMobile} defaultValue={resellerCode} disabled={resellerCode !== ''}/>
                {!isMobile && <GenderSelection onChange={(value) => onChangeText('gender', value)}/>}
                <View style={isMobile ?
                    {
                    flexDirection: "row-reverse",
                    alignItems: "center",
                    paddingHorizontal: '12.5%',
                    alignSelf: "flex-end",
                    marginTop: 50
                } : {
                    flexDirection: "row-reverse",
                    alignItems: "center",
                    paddingHorizontal: '12.5%',
                    alignSelf: "flex-end"
                }}>
                    <Checkbox style={isMobile ? {transform: "scale(2)", marginLeft: 26}: {}} onChange={() => setAgree(!agree)} checked={agree} title={'לחץ לאישור'}/>
                    <Text style={isMobile ? {...getFontCss(FB_SPACER_REGULAR, 45, WHITE_COLOR)} : {...getFontCss(FB_SPACER_REGULAR, 14, WHITE_COLOR)}}>אני מאשר את תנאי השימוש באתר, את {
                        <a href={TERMS_PATH} style={{color: WHITE_COLOR}}>התקנון</a>}, ולקבל הודעות דיוור מהאתר.</Text>
                </View>
                <View style={{
                    flexDirection: "row-reverse",
                    alignItems: "center",
                    paddingHorizontal: '12.5%',
                    alignSelf: "flex-end"
                }}>
                    <Checkbox style={isMobile ? {transform: "scale(2)", marginLeft: 26}: {}} onChange={() => setOver18(!over18)} checked={over18} title={'לחץ לאישור'}/>
                    <Text style={isMobile ? {...getFontCss(FB_SPACER_REGULAR, 45, WHITE_COLOR)} : {...getFontCss(FB_SPACER_REGULAR, 14, WHITE_COLOR)}}><a href={'/'}>{}</a>אני מעל גיל 18</Text>
                </View>
                {isMobile ?
                    <View style={{flexDirection: "row", alignItems: "center", marginTop: 20}}>
                    <TouchableOpacity style={{
                        ...getCircleCss(67, 208, 12),
                        borderWidth: 2,
                        borderColor: WHITE_COLOR,
                        alignItems: "center",
                        alignSelf: "center",
                        backgroundColor: YELLOW_123_COLOR
                    }} onPress={passwordVerification}>
                        <Text style={{...getFontCss(FB_SPACER_BOLD, 64, WHITE_COLOR)}}>אישור</Text>
                    </TouchableOpacity>
                    <View style={{width: 10}}/>
                    <a href={SIGN_IN_PATH} style={{...getFontCss(FB_SPACER_REGULAR, 44, WHITE_COLOR)}}>היכנס</a>
                </View> :<View style={{flexDirection: "row", alignItems: "center", marginTop: 20}}>
                    <TouchableOpacity style={{
                        ...getCircleCss(39, 208, 12),
                        borderWidth: 2,
                        borderColor: WHITE_COLOR,
                        alignItems: "center",
                        alignSelf: "center",
                        backgroundColor: YELLOW_123_COLOR
                    }} onPress={passwordVerification}>
                        <Text style={{...getFontCss(FB_SPACER_BOLD, 34, WHITE_COLOR)}}>אישור</Text>
                    </TouchableOpacity>
                    <View style={{width: 10}}/>
                    <a href={SIGN_IN_PATH} style={{...getFontCss(FB_SPACER_REGULAR, 14, WHITE_COLOR)}}>היכנס</a>
                </View>}
            </>}
            {statusSignUp === 'success' &&
            <Text style={isMobile ? {...getFontCss(FB_SPACER_BOLD, 65, '#2196f3')} : {...getFontCss(FB_SPACER_BOLD, 35, '#2196f3')}}>Lottomatic Confirmation</Text>}
            {errorConfirmation && statusConfirmation !== 'success' &&
            <Text style={isMobile ? {...getFontCss(FB_SPACER_BOLD, 55, RED_COLOR)} : {...getFontCss(FB_SPACER_BOLD, 20, RED_COLOR)}}>{CONFIRMATION_ERROR_MESSAGE}</Text>}


            {statusSignUp === 'success' && <>
                <SignInField onChangeText={(value) => onChangeText('confirmationCode', value)}
                             placeholder={'קוד אימות'} isMobile={isMobile}/>
                        <Text
                            style={isMobile ? {margin: 10, ...getFontCss(FB_SPACER_REGULAR, 44, RED_COLOR)} : {margin: 10, ...getFontCss(FB_SPACER_REGULAR, 14, RED_COLOR)}}>{CONFIRMATION_CODE_MESSAGE}
                        </Text>
                <TouchableOpacity style={isMobile ?
                    {
                    ...getCircleCss(67, 208, 12),
                    borderWidth: 2,
                    borderColor: WHITE_COLOR,
                    alignItems: "center",
                    alignSelf: "center",
                    backgroundColor: YELLOW_123_COLOR
                } : {
                    ...getCircleCss(39, 208, 12),
                    borderWidth: 2,
                    borderColor: WHITE_COLOR,
                    alignItems: "center",
                    alignSelf: "center",
                    backgroundColor: YELLOW_123_COLOR
                }} onPress={executeConfirmation}>
                    <Text style={isMobile ? {...getFontCss(FB_SPACER_BOLD, 44, WHITE_COLOR)} : {...getFontCss(FB_SPACER_BOLD, 34, WHITE_COLOR)}}>אישור</Text>
                </TouchableOpacity>
            </>}
        </View>
    )
}