import { Button, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@material-ui/core";
import React, { useState, useContext, useCallback, useEffect } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { UserContext } from "../appSwitch";
import { DEFAULT_REFUND_REQUESTS_DATA, HEBREW_DATE_AND_TIME, HEBREW_PULL_STATUS, HEBREW_STATUS, ID } from "../consts";
import { getPendingRequests, RefundRequestsHeadersType, RefundRequestsRows, AdminFromsTypePagination, changeStatusDecliend, changeStatusApproved } from "../server/admin";
import { useAsync } from "../server/utils";
import { getFontCss, FB_SPACER_REGULAR, BLUE_COLOR, WHITE_COLOR, FB_SPACER_BOLD, GREEN_COLOR, RED_COLOR } from "../styles";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    button: {
        backgroundColor: GREEN_COLOR,
        justifyContent: "center",
        alignItems: "center",
        position: "relative",


        borderWidth: 1,
        borderColor: BLUE_COLOR,
        borderStyle: "solid",

    },
    errorButton: {
        backgroundColor: RED_COLOR,
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        borderWidth: 1,
        borderColor: BLUE_COLOR,
        borderStyle: "solid",

    }
}));
const RefundRequestsTableHead = ({ headers }: { headers: RefundRequestsHeadersType }) => {
    const useStyles = makeStyles((theme) => ({
        headerCell: {
            background: BLUE_COLOR,
        }
    }));
    const classes = useStyles()

    return (
        <TableHead>
            <TableRow>
                {
                    headers.map((header: string) => <TableCell align={"right"}
                        className={classes.headerCell}>
                        {
                            <Text
                                style={{ ...getFontCss(FB_SPACER_REGULAR, 21, WHITE_COLOR) }}>{header}</Text>
                        }
                    </TableCell>)
                }

            </TableRow>
        </TableHead>
    )
}


const RefundRequestsTableBody = ({
    rows,
    headers,
    changeStatus
}: {
    rows: RefundRequestsRows, headers: RefundRequestsHeadersType,
    changeStatus: (id: string, rtn_status: string, status: boolean) => Promise<void>
}) => {


    const classes = useStyles()
    const style: { text: object, buttonText: object } = {
        text: { ...getFontCss(FB_SPACER_REGULAR, 20, BLUE_COLOR) },
        buttonText: { ...getFontCss(FB_SPACER_BOLD, 17, BLUE_COLOR) },

    }


    return (
        <TableBody>
            {
                rows.map((row) =>
                    <TableRow key={row.id}>
                        <TableCell align={"right"}>
                            <Text style={style.text}>
                                {row[headers[0]]}
                            </Text>
                        </TableCell>
                        <TableCell align={"right"}>
                            <Text style={style.text}>
                                {row[headers[1]]}
                            </Text>
                        </TableCell>
                        <TableCell align={"right"}>
                            <View style={{ flexDirection: "row", alignItems: "center" }}>
                                <Text style={style.text}>
                                    {row[headers[2]]}
                                </Text>
                            </View>
                        </TableCell>
                        <TableCell align={"right"}>
                            <Text style={style.text}>
                                {row[headers[3]]}
                            </Text>
                        </TableCell>
                        <TableCell align={"right"}>
                            <Text style={style.text}>
                                {row[headers[4]]}
                            </Text>
                        </TableCell>
                        <TableCell align={"right"}>
                            <Text style={style.text}>
                                {row[headers[5]]}
                            </Text>
                        </TableCell>
                        <TableCell align={"right"} className={classes.root}>


                            {(row[HEBREW_PULL_STATUS] != 'אושר' && row[HEBREW_PULL_STATUS] != 'נדחה') && <Button
                                onClick={() => changeStatus(row['id'], row['trn_id'], true)}
                                className={classes.button}
                                variant="contained"
                                style={style.button} >
                                אישור
                            </Button>}

                            {(row[HEBREW_PULL_STATUS] != 'אושר' && row[HEBREW_PULL_STATUS] != 'נדחה') && <Button
                                onClick={() => changeStatus(row['id'], row['trn_id'], false)}
                                className={classes.errorButton}
                                variant="contained"
                                style={style.errorButton}
                            >
                                דחיה
                            </Button>
                            }


                        </TableCell>
                    </TableRow>
                )
            }
        </TableBody>
    )
}


export const RefundRequests = ({changeTab }:{changeTab:(value:number)=>void}) => {

    const useStyles = makeStyles((theme) => ({
        paper: {
            marginBottom: theme.spacing(2),
        },
        tablePagination: {
            borderBottom: "none",
            ...getFontCss(FB_SPACER_REGULAR, '0.875rem', BLUE_COLOR)
        },
        headerCell: {
            background: BLUE_COLOR,
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            width: '75%'
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        button: {
            margin: theme.spacing(1),
            width: 200
        }
    }));
    const classes = useStyles()
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const style: { text: object } = {
        text: { ...getFontCss(FB_SPACER_REGULAR, 21, BLUE_COLOR) }
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage+1);
    };


    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const { jwtToken } = useContext(UserContext)


    const [data, setData] = useState<AdminFromsTypePagination>(DEFAULT_REFUND_REQUESTS_DATA);

    const PendingRequests = useCallback(async () => {
        if (jwtToken) {
            return await getPendingRequests(jwtToken, page, rowsPerPage)
        }
    }, [jwtToken, page, rowsPerPage])

    useEffect(() => {

        PendingRequests().then(res => {
            setData(res)
        })


    }, [page, rowsPerPage]);

    const setStatus = useCallback((isApproved: boolean, trn_id: string) => {
        const x = {
            ...data,
            rows: data.rows.map(row => {
                if (row.id == trn_id) {
                    row[HEBREW_PULL_STATUS] = isApproved ? 'אושר' : 'נדחה'
                }
                return row
            })
        }
        setData(x)
    }, [data])

    const changeStatus = useCallback(async (trn_id, rtn_value, status) => {
        if (jwtToken) {
            if (!status) {
                changeStatusDecliend(trn_id, rtn_value, jwtToken).then(res => {
                    setStatus(status, trn_id)
                }).catch(err=>{})
            } else {
                changeStatusApproved(trn_id, rtn_value, jwtToken).then(res => {
                    setStatus(status, trn_id)
                }).catch(err=>{})
            }
        }
    }, [jwtToken, data])

    return (
        <View style={{ width: '100%' }}>

            <View style={{ alignItems: "center", justifyContent: "center", marginBottom: 40 }}>

            </View>

            <Paper dir={"rtl"} className={classes.paper}>
                <TableContainer>
                    <Table size={'medium'}>
                        <RefundRequestsTableHead headers={DEFAULT_REFUND_REQUESTS_DATA.headers} />
                        <RefundRequestsTableBody rows={data.rows} headers={data.headers} changeStatus={changeStatus} />
                    </Table>
                </TableContainer>
                <TablePagination
                    dir={"ltr"}
                    width={'inherit'}
                    rowsPerPageOptions={[10, 25]}
                    count={data.total}
                    rowsPerPage={rowsPerPage}
                    page={page-1}
                    labelRowsPerPage={'שורות'}
                    className={classes.tablePagination}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    nextIconButtonProps={{ disabled: !data.has_next }}
                    backIconButtonProps={{ disabled: !data.has_prev }}
                    labelDisplayedRows={({ from, to, count }) => `סה"כ: ${count} תוצאות מציג: ${ from  } - ${ to } `}
                />

            </Paper>
        </View>
    );
}