import {
    GET_CHANCE_SHITATI_FORM_PRICE,
    GET_DOUBLE_LOTTO_SHITATI_FORM_PRICE,
    GET_DOUBLE_LOTTO_SHITATI_HAZAK_FORM_PRICE,
    GET_LOTTO_SHITATI_FORM_PRICE,
    GET_LOTTO_SHITATI_HAZAK_FORM_PRICE,
    GET_PRIVACY_LINK,
    GET_RAV_CHANCE_FORM_PRICE,
    GET_REGULAR_123_FORM_PRICE,
    GET_REGULAR_777_FORM_PRICE,
    GET_REGULAR_CHANCE_FORM_PRICE,
    GET_REGULAR_DOUBLE_LOTTO_FORM_PRICE,
    GET_REGULAR_LOTTO_FORM_PRICE,
    GET_SHITATI_777_FORM_PRICE,
    GET_TERMS_OF_USE_LINK,
    POST_CHANCE_SHITATI_FORM,
    POST_DOUBLE_LOTTO_SHITATI_FORM,
    POST_DOUBLE_LOTTO_SHITATI_HAZAK_FORM,
    POST_LOTTO_SHITATI_FORM,
    POST_LOTTO_SHITATI_HAZAK_FORM,
    POST_RAV_CHANCE_FORM,
    POST_REGULAR_123_FORM,
    POST_REGULAR_777_FORM,
    POST_REGULAR_CHANCE_FORM,
    POST_REGULAR_DOUBLE_LOTTO_FORM,
    POST_REGULAR_LOTTO_FORM,
    POST_SHITATI_777_FORM
} from "./configurations";
import {ChanceMarksType, LottoMarksType, SevenMarksType} from "../types";
import {TABLES} from "../consts";
import {handleErrors, normalizeTablesData} from "./utils";
import {MarksType123} from "../lotto/regular_lotto/regular123Form";

export const postRegularLottoForm = async (data: LottoMarksType, jwtToken: string): Promise<Response> => {
    return await handleErrors(fetch(POST_REGULAR_LOTTO_FORM.href, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        },
        body: JSON.stringify({...data, [TABLES]: normalizeTablesData(data[TABLES])})
    }))
};

export const getRegularLottoPrice = async (data: LottoMarksType, jwtToken: string): Promise<{ price: number }> => {
    return await handleErrors(fetch(GET_REGULAR_LOTTO_FORM_PRICE.href, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        },
        body: JSON.stringify(data)
    }))
};

export const postRegularDoubleLottoForm = async (data: LottoMarksType, jwtToken: string): Promise<Response> => {
    return await handleErrors(fetch(POST_REGULAR_DOUBLE_LOTTO_FORM.href, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        },
        body: JSON.stringify({...data, [TABLES]: normalizeTablesData(data[TABLES])})
    }))
};

export const getRegularDoubleLottoPrice = async (data: LottoMarksType, jwtToken: string): Promise<{ price: number }> => {
    return await handleErrors(fetch(GET_REGULAR_DOUBLE_LOTTO_FORM_PRICE.href, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        },
        body: JSON.stringify(data)
    }))
};

export const postLottoShitatiForm = async (data: LottoMarksType, jwtToken: string): Promise<Response> => {
    return await handleErrors(fetch(POST_LOTTO_SHITATI_FORM.href, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        },
        body: JSON.stringify({...data, [TABLES]: normalizeTablesData(data[TABLES])})
    }))
};

export const getLottoShitatiPrice = async (data: LottoMarksType, jwtToken: string): Promise<{ price: number }> => {
    return await handleErrors(fetch(GET_LOTTO_SHITATI_FORM_PRICE.href, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        },
        body: JSON.stringify(data)
    }))
};

export const postDoubleLottoShitatiForm = async (data: LottoMarksType, jwtToken: string): Promise<Response> => {
    return await handleErrors(fetch(POST_DOUBLE_LOTTO_SHITATI_FORM.href, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        },
        body: JSON.stringify({...data, [TABLES]: normalizeTablesData(data[TABLES])})
    }))
};

export const getDoubleLottoShitatiPrice = async (data: LottoMarksType, jwtToken: string): Promise<{ price: number }> => {
    return await handleErrors(fetch(GET_DOUBLE_LOTTO_SHITATI_FORM_PRICE.href, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        },
        body: JSON.stringify(data)
    }))
};

export const postLottoShitatiHazakForm = async (data: LottoMarksType, jwtToken: string): Promise<Response> => {
    return await handleErrors(fetch(POST_LOTTO_SHITATI_HAZAK_FORM.href, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        },
        body: JSON.stringify({...data, [TABLES]: normalizeTablesData(data[TABLES])})
    }))
};

export const getLottoShitatiHazakPrice = async (data: LottoMarksType, jwtToken: string): Promise<{ price: number }> => {
    return await handleErrors(fetch(GET_LOTTO_SHITATI_HAZAK_FORM_PRICE.href, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        },
        body: JSON.stringify(data)
    }))
};

export const postDoubleLottoShitatiHazakForm = async (data: LottoMarksType, jwtToken: string): Promise<Response> => {
    return await handleErrors(fetch(POST_DOUBLE_LOTTO_SHITATI_HAZAK_FORM.href, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        },
        body: JSON.stringify({...data, [TABLES]: normalizeTablesData(data[TABLES])})
    }))
};

export const getDoubleLottoShitatiHazakPrice = async (data: LottoMarksType, jwtToken: string): Promise<{ price: number }> => {
    return await handleErrors(fetch(GET_DOUBLE_LOTTO_SHITATI_HAZAK_FORM_PRICE.href, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        },
        body: JSON.stringify(data)
    }))
};

export const postRegularChanceForm = async (data: ChanceMarksType, jwtToken: string): Promise<Response> => {
    return await handleErrors(fetch(POST_REGULAR_CHANCE_FORM.href, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        },
        body: JSON.stringify(data)
    }))
};

export const getRegularChancePrice = async (data: ChanceMarksType, jwtToken: string): Promise<{ price: number }> => {
    return await handleErrors(fetch(GET_REGULAR_CHANCE_FORM_PRICE.href, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        },
        body: JSON.stringify(data)
    }))
};

export const postRavChanceForm = async (data: ChanceMarksType, jwtToken: string): Promise<Response> => {
    return await handleErrors(fetch(POST_RAV_CHANCE_FORM.href, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        },
        body: JSON.stringify(data)
    }))
};

export const getRavChancePrice = async (data: ChanceMarksType, jwtToken: string): Promise<{ price: number }> => {
    return await handleErrors(fetch(GET_RAV_CHANCE_FORM_PRICE.href, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        },
        body: JSON.stringify(data)
    }))
};

export const postChanceShitatiForm = async (data: ChanceMarksType, jwtToken: string): Promise<Response> => {
    return await handleErrors(fetch(POST_CHANCE_SHITATI_FORM.href, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        },
        body: JSON.stringify(data)
    }))
};

export const getChanceShitatiPrice = async (data: ChanceMarksType, jwtToken: string): Promise<{ price: number }> => {
    return await handleErrors(fetch(GET_CHANCE_SHITATI_FORM_PRICE.href, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        },
        body: JSON.stringify(data)
    }))
};

export const postRegularSevenForm = async (data: SevenMarksType, jwtToken: string): Promise<Response> => {
    return await handleErrors(fetch(POST_REGULAR_777_FORM.href, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        },
        body: JSON.stringify({...data, [TABLES]: normalizeTablesData(data[TABLES])})
    }))
};

export const getRegularSevenPrice = async (data: SevenMarksType, jwtToken: string): Promise<{ price: number }> => {
    return await handleErrors(fetch(GET_REGULAR_777_FORM_PRICE.href, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        },
        body: JSON.stringify(data)
    }))
};

export const postShitatiSevenForm = async (data: SevenMarksType, jwtToken: string): Promise<Response> => {
    return await handleErrors(fetch(POST_SHITATI_777_FORM.href, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        },
        body: JSON.stringify({...data, [TABLES]: normalizeTablesData(data[TABLES])})
    }))
};

export const getShitatiSevenPrice = async (data: SevenMarksType, jwtToken: string): Promise<{ price: number }> => {
    return await handleErrors(fetch(GET_SHITATI_777_FORM_PRICE.href, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        },
        body: JSON.stringify(data)
    }))
};

export const post123Form = async (data: MarksType123, jwtToken: string): Promise<Response> => {
    return await handleErrors(fetch(POST_REGULAR_123_FORM.href, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        },
        body: JSON.stringify({...data, [TABLES]: normalizeTablesData(data[TABLES])})
    }))
};

export const get123Price = async (data: MarksType123, jwtToken: string): Promise<{ price: number }> => {
    return await handleErrors(fetch(GET_REGULAR_123_FORM_PRICE.href, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        },
        body: JSON.stringify(data)
    }))
};

export const getTermsOfUse = async (): Promise<{url: string}> => {
    return await handleErrors(fetch(GET_TERMS_OF_USE_LINK.href))
};

export const getPrivacyPolicy = async (): Promise<{url: string}> => {
    return await handleErrors(fetch(GET_PRIVACY_LINK.href))
};