import React, {useCallback, useContext, useReducer, useState} from "react";
import {
    CalcPriceFunction,
    ChanceMarksType,
    EnglishHebrewFormTypeMapping,
    FormType,
    LevelType,
    MultiLotteryChanceType,
    MultiLotteryType,
    PostFormFunction
} from "../../types";
import FirstSectionInstructions, {
    InstructionStep,
    StepBigText,
    StepCircle
} from "./additionalSections/firstSectionInstructions";
import {
    CARDS,
    CLOVER,
    DIAMOND,
    FORM_TYPE,
    HEART,
    HEBREW_MAIN_TITLE,
    HEBREW_SECOND_STEP_TITLE,
    LEAF,
    MULTI_LOTTERY,
    NON_NUMBER,
    PARTICIPANT_AMOUNT,
    STRICT
} from "../../consts";
import {Image, ImageSourcePropType, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {
    FB_SPACER_BOLD,
    FB_SPACER_REGULAR,
    getBorder,
    getCircleCss,
    getFontCss,
    getHeightWidthCss,
    GREEN_COLOR,
    RED_COLOR,
    WHITE_COLOR,
    YELLOW_COLOR
} from "../../styles";
import {Payment} from "./additionalSections/sumAndSubmitSection";
import {PipeLine} from "../../genaricComponents";
import {AutomateRenew, NumberOfLotteriesBall, QuestionMark} from "./additionalSections/formAdditions";
import UserInputFormHeader, {UserInputFormHeaderText} from "./inputForm/userInputFormHeader";
import {DeleteAndFillNumbersSection} from "./inputForm/userInputForm";
import {TouchableLottoNumber} from "../lottoBalls";
import {UserContext} from "../../appSwitch";

import { AlertHelper } from '../../AlertHelper';

let _ = require('lodash')

type ChanceRowBallsType = {
    ballTextColor?: string
    numbers: number[],
    setNumber: (value: number) => void,
    removeNumber: () => void,
    isClicked: (number: number) => boolean,
    isShitati: boolean
}
export type RegularChanceFormType = {
    calcPriceFunction: CalcPriceFunction<ChanceMarksType>,
    postFormFunction: PostFormFunction<ChanceMarksType>,
    initForm: ChanceMarksType,
    color: string,
    isFormType: boolean,
    formType: FormType,
    isShitati: boolean
}
type CardTypes = 'clover' | 'heart' | 'leaf' | 'diamond'
export type ChanceActionType = {
    multiLotteryValue?: MultiLotteryChanceType;
    type: string,
    value: number,
    formType?: number,
    participantAmount?: number,
    cardType?: CardTypes,
    cardValue?: string
}
type ChanceFormAdditions = {
    setMultiLottery: (number: MultiLotteryChanceType | MultiLotteryType) => void,
    marks: ChanceMarksType

}
type SumAndSubmitChanceSectionType = {
    bottonColor: string,
    calcPriceFunction: () => Promise<{ price: number }>,
    postFunction: () => Promise<Response>
    validateForm: (lvl?: 'strict' | 'lax') => boolean,
    color: string,
    marks: ChanceMarksType,
    formType: FormType
}
type PickCardType = {
    cardNumber: string,
    addCard: () => void,
    removeCard: () => void,
    isClicked: boolean
    disabled: boolean
}
type PickNumberRowType = {
    imgPath: ImageSourcePropType,
    cards: string[],
    imgWidth: number | string,
    imgHeight: number | string,
    pickCard: (card: string) => void,
    removeCard: (card: string) => void,
    isClicked: (card: string) => boolean,
    isDisabled: (card: string) => boolean,

}
type PickCardTableType = {
    cards: string[],
    addHeart: (card: string) => void
    addLeaf: (card: string) => void
    addDiamond: (card: string) => void
    addClover: (card: string) => void,
    removeHeart: (card: string) => void
    removeLeaf: (card: string) => void
    removeDiamond: (card: string) => void
    removeClover: (card: string) => void,
    marks: ChanceMarksType
}
type ChanceUserInputType = {
    participantValues: number[]
    isShitati: boolean,
    popTableNumbers: () => void,
    tableAutoInsertion: () => void,
    isFormType: boolean,
    marks: ChanceMarksType,
    color: string,
    setFormType: (value: number) => void,
    resetFormType: () => void
    setParticipantAmount: (value: number) => void,
    resetParticipantAmount: () => void
    addHeart: (card: string) => void
    addLeaf: (card: string) => void
    addDiamond: (card: string) => void
    addClover: (card: string) => void
    removeHeart: (card: string) => void
    removeLeaf: (card: string) => void
    removeDiamond: (card: string) => void
    removeClover: (card: string) => void
}

export const SumAndSubmitChanceSection = ({
                                              color,
                                              bottonColor,
                                              calcPriceFunction,
                                              postFunction,
                                              validateForm,
                                              formType,
                                              marks
                                          }: SumAndSubmitChanceSectionType) => {

    const style = StyleSheet.create({
        container: {
            flexDirection: "row-reverse",
            alignItems: "center",
            height: 141,
            paddingTop: 23,
            ...getBorder()
        },
        sumText: {
            ...getFontCss(FB_SPACER_REGULAR, 24, YELLOW_COLOR),
            marginRight: '5%',
            whiteSpace: 'nowrap',
            overflowWrap: 'normal',
        },
        paymentText: {
            position: "absolute",
            left: '27.2%',
            ...getFontCss(FB_SPACER_BOLD, 20, WHITE_COLOR)
        }
    })

    return (
        <View style={style.container}>
            <StepCircle text={'₪'} textColor={color}/>
            <View>
                <StepBigText text={'סיכום ושליחת טופס'}/>
                <Text style={style.sumText}>{`סוג ${EnglishHebrewFormTypeMapping[formType]} | הגרלות ${marks.multi_lottery != -1? marks.multi_lottery : '1' } | טבלאות ${marks[FORM_TYPE] != 5?marks[FORM_TYPE]:4}`}</Text>
            </View>
            <View style={{
                flexDirection: "row",
                width: 'fit-content',
                left: '3.7%',
                position: "absolute",
                alignItems: "center"
            }}>
                <Payment bottonColor={bottonColor} validateForm={validateForm} postFunction={postFunction}
                         calcPriceFunction={calcPriceFunction} marks={marks}/>
            </View>
        </View>
    )
}

export const ChanceFormAdditions = ({setMultiLottery, marks}: ChanceFormAdditions) => {

    const style = StyleSheet.create({
        container: {
            flexDirection: "row-reverse",
            height: 85,
            alignItems: "center",
            justifyContent: "center",
            ...getBorder()
        },
        numberlotteriesText: {
            ...getFontCss(FB_SPACER_BOLD, 24, WHITE_COLOR),
            marginRight: '3.7%',
            marginLeft: '1.2%'
        },
        textStyle: {
            ...getFontCss(FB_SPACER_BOLD, 12, WHITE_COLOR)
        },
        imageConatiner: {
            flexDirection: "row-reverse",
            alignItems: "center",
            ...getBorder(),
            width: '100%'
        }
    })


    return (
        <View style={style.container}>
            <Text style={style.numberlotteriesText}>מספר הגרלות</Text>
            <NumberOfLotteriesBall multiLottery={marks[MULTI_LOTTERY]} number={2} setMultiLottery={setMultiLottery}/>
            <NumberOfLotteriesBall multiLottery={marks[MULTI_LOTTERY]} number={3} setMultiLottery={setMultiLottery}/>
            <NumberOfLotteriesBall multiLottery={marks[MULTI_LOTTERY]} number={4} setMultiLottery={setMultiLottery}/>
            <NumberOfLotteriesBall multiLottery={marks[MULTI_LOTTERY]} number={5} setMultiLottery={setMultiLottery}/>
            <NumberOfLotteriesBall multiLottery={marks[MULTI_LOTTERY]} number={7} setMultiLottery={setMultiLottery}/>
            <NumberOfLotteriesBall multiLottery={marks[MULTI_LOTTERY]} number={10} setMultiLottery={setMultiLottery}/>
            <NumberOfLotteriesBall multiLottery={marks[MULTI_LOTTERY]} number={12} setMultiLottery={setMultiLottery}/>
            <QuestionMark/>
            <PipeLine marginLeft={'1.6%'}/>
            {/* <AutomateRenew text={'אוטומטי מתחדש'}/>
            <QuestionMark/> 
            <PipeLine marginLeft={'1.6%'}/>*/}
            <View style={{width: '18%'}}>
                <View style={style.imageConatiner}>
                    <Image source={require("../../../../assets/whiteV.png")}
                           style={{width: 11.7, height: 8.56, marginLeft: '2%'}}/>
                    <Text style={style.textStyle}>סיכויי הזכייה גבוהים מאוד</Text>
                </View>
                <View style={style.imageConatiner}>
                    <Image source={require("../../../../assets/whiteV.png")}
                           style={{width: 11.7, height: 8.56, marginLeft: '2%'}}/>
                    <Text style={style.textStyle}>קבל פרס על ניחוש חלקי</Text>
                </View>
                <View style={style.imageConatiner}>
                    <Image source={require("../../../../assets/whiteV.png")}
                           style={{width: 11.7, height: 8.56, marginLeft: '2%'}}/>
                    <Text style={style.textStyle}>נחש קלף אחד</Text>
                </View>
            </View>

        </View>
    )
}

export const PickCard = ({cardNumber, addCard, isClicked, disabled, removeCard}: PickCardType) => {

    const circleStyle = {
        circle: {
            ...getCircleCss('50%', 23, 12),
            backgroundColor: WHITE_COLOR
        }
    }
    const style = StyleSheet.create({
        container: {
            ...getBorder(),
            alignItems: "center",
            marginRight: '3%'
        },
        text: {
            ...getFontCss(FB_SPACER_REGULAR, 17, WHITE_COLOR)
        }
    })

    return (
        <View style={style.container}>
            <TouchableOpacity onPress={addCard}>
                <Text style={style.text}>{cardNumber}</Text>
            </TouchableOpacity>
            <TouchableLottoNumber onPress={addCard} isClicked={isClicked} removeNumber={removeCard}
                                  ballStyle={circleStyle.circle} ballTextStyle={{}} disabled={disabled}
                                  lottoNumber={''}/>
        </View>
    )
}

const PickCardRow = ({
                         imgPath,
                         cards,
                         imgWidth,
                         imgHeight,
                         pickCard,
                         isClicked,
                         isDisabled,
                         removeCard
                     }: PickNumberRowType) => {

    const style = StyleSheet.create({
        container: {
            justifyContent: "center",
            flexDirection: "row",
            height: 71.5,
            ...getBorder()
        },
        img: {
            marginLeft: '2%',
            width: imgWidth,
            height: imgHeight,
            alignSelf: "center"
        }
    })

    return (
        <View style={style.container}>
            {_.range(cards.length).map((cardNumber: number) => <PickCard
                removeCard={() => removeCard(cards[cardNumber])} cardNumber={cards[cardNumber]}
                addCard={() => pickCard(cards[cardNumber])} disabled={isDisabled(cards[cardNumber])}
                isClicked={isClicked(cards[cardNumber])}/>)}
            <Image source={imgPath} style={style.img}/>
        </View>
    )
}

export const PickCardTable = ({
                                  cards,
                                  addHeart,
                                  addClover,
                                  addDiamond,
                                  addLeaf,
                                  marks,
                                  removeClover,
                                  removeDiamond,
                                  removeHeart,
                                  removeLeaf
                              }: PickCardTableType) => {

    const style = StyleSheet.create({
        container: {
            height: 287,
            ...getBorder()
        }
    })

    return (
        <View style={style.container}>
            <PickCardRow cards={cards} imgPath={require("../../../../assets/leaf.png")} removeCard={removeLeaf}
                         imgHeight={42} imgWidth={41} pickCard={addLeaf}
                         isClicked={(card: string) => marks[CARDS][LEAF].includes(card)} isDisabled={() => false}/>
            <PickCardRow cards={cards} removeCard={removeHeart}
                         imgPath={require("../../../../assets/heart.png")} imgHeight={38} imgWidth={41}
                         pickCard={addHeart}
                         isClicked={(card: string) => marks[CARDS][HEART].includes(card)} isDisabled={() => false}/>
            <PickCardRow cards={cards} removeCard={removeDiamond}
                         imgPath={require("../../../../assets/diamond.png")} imgHeight={47} imgWidth={41}
                         pickCard={addDiamond} isClicked={(card: string) => marks[CARDS][DIAMOND].includes(card)}
                         isDisabled={() => false}/>
            <PickCardRow cards={cards} removeCard={removeClover}
                         imgPath={require("../../../../assets/clover.png")} imgHeight={39} imgWidth={41}
                         pickCard={addClover} isClicked={(card: string) => marks[CARDS][CLOVER].includes(card)}
                         isDisabled={() => false}/>
        </View>
    )
}

export const ChanceRowBalls = ({
                                   numbers,
                                   setNumber,
                                   removeNumber,
                                   isClicked,
                                   isShitati,
                                   ballTextColor
                               }: ChanceRowBallsType) => {

    const style: { ballStyle: object, ballText: object, ravChance: object, ravText: object } = {
        ballStyle: {
            marginRight: '3%',
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: WHITE_COLOR,
            ...getCircleCss(32, 32, 16)
        },
        ballText: {
            ...getFontCss(FB_SPACER_REGULAR, 23, ballTextColor || GREEN_COLOR)
        },
        ravChance: {
            marginRight: '3%',
            justifyContent: "center",
            alignItems: "center",
            ...getHeightWidthCss(40, 40)
        },
        ravText: {
            ...getFontCss(FB_SPACER_REGULAR, 17, WHITE_COLOR)
        },
    }


    return (
        <View style={{height: 51, flexDirection: "row-reverse", alignItems: "center", ...getBorder()}}>
            {
                _.range(numbers.length).map((index: number) => <TouchableLottoNumber lottoNumber={numbers[index]}
                                                                                     ballStyle={style.ballStyle}
                                                                                     ballTextStyle={style.ballText}
                                                                                     onPress={() => setNumber(numbers[index])}
                                                                                     isClicked={isClicked(numbers[index])}
                                                                                     disabled={false}
                                                                                     removeNumber={removeNumber}/>).reverse()
            }
            {
                isShitati && <TouchableLottoNumber lottoNumber={"רב צ'אנס"}
                                                   ballStyle={style.ravChance}
                                                   ballTextStyle={style.ravText}
                                                   onPress={() => setNumber(5)}
                                                   isClicked={isClicked(5)}
                                                   disabled={false}
                                                   removeNumber={removeNumber}/>
            }
        </View>
    )
}

export const ChanceMediumText = ({
                                     text,
                                     marginTop,
                                     marginLeft
                                 }: { marginLeft?: string | number, text: string, marginTop?: string | number }) => {
    return (
        <View style={{
            height: 32,
            flexDirection: "row-reverse",
            marginTop: marginTop || 15,
            paddingRight: '1.5%',
            marginLeft: marginLeft || 0
        }}>
            <Text style={{...getFontCss(FB_SPACER_BOLD, 23, WHITE_COLOR), ...getBorder()}}>{text}</Text>
        </View>
    )
}

export const ChanceUserInput = ({
                                    participantValues,
                                    isShitati,
                                    popTableNumbers,
                                    tableAutoInsertion,
                                    isFormType,
                                    removeClover,
                                    removeDiamond,
                                    removeHeart,
                                    removeLeaf,
                                    color,
                                    setFormType,
                                    resetFormType,
                                    resetParticipantAmount,
                                    setParticipantAmount,
                                    marks,
                                    addHeart,
                                    addClover,
                                    addDiamond,
                                    addLeaf
                                }: ChanceUserInputType) => {

    const style = StyleSheet.create({
        formTablesContainer: {
            flexDirection: "row-reverse",
            height: 380,
            ...getBorder(),
            paddingRight: '1%',
            paddingLeft: '1%'
        },
        innerFormTablesrightContainer: {
            width: '50%',
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: WHITE_COLOR,
            borderRadius: 15
        },
        innerFormTablesleftContainer: {
            width: '50%',
            ...getBorder(),
            paddingRight: '2.6%',
            paddingLeft: '8%'
        }
    })

    return (
        <View style={style.formTablesContainer}>
            <View style={style.innerFormTablesrightContainer}>
                <UserInputFormHeader>
                    <UserInputFormHeaderText text={'בחר קלף'} color={color}/>
                </UserInputFormHeader>
                <DeleteAndFillNumbersSection color={color} popTableNumbers={popTableNumbers}
                                             tableAutoInsertion={tableAutoInsertion}/>
                <PickCardTable marks={marks} cards={['7', '8', '9', '10', 'J', 'Q', 'K', 'A']} addHeart={addHeart}
                               addClover={addClover} addDiamond={addDiamond} addLeaf={addLeaf}
                               removeClover={removeClover} removeDiamond={removeDiamond} removeHeart={removeHeart}
                               removeLeaf={removeLeaf}/>
            </View>
            <View style={style.innerFormTablesleftContainer}>
                <ChanceUserInputLeftTable marks={marks} setFormType={setFormType} isShitati={isShitati}
                                          isFormType={isFormType} setParticipantAmount={setParticipantAmount}
                                          resetParticipantAmount={resetParticipantAmount}
                                          resetFormType={resetFormType} participantValues={participantValues}/>

            </View>
        </View>
    )
}

export const ChanceUserInputLeftTable = ({
                                             participantValues,
                                             isFormType,
                                             marks,
                                             resetParticipantAmount,
                                             setParticipantAmount,
                                             resetFormType,
                                             isShitati,
                                             setFormType
                                         }: {
    participantValues: number[]
    isShitati: boolean,
    isFormType: boolean,
    marks: ChanceMarksType,
    setFormType: (value: number) => void,
    resetFormType: () => void
    setParticipantAmount: (value: number) => void,
    resetParticipantAmount: () => void
}) => {
    return (
        <View style={{
            ...getBorder(),
            height: 291,
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: WHITE_COLOR,
            borderRadius: 15
        }}>
            {
                isFormType && <>
                    <ChanceMediumText text={'בחר את סוג הטופס'}/>
                    <ChanceRowBalls setNumber={setFormType} numbers={[1, 2, 3, 4]} removeNumber={resetFormType}
                                    isClicked={(number: number) => marks[FORM_TYPE] === number} isShitati={isShitati}/>
                </>
            }
            <ChanceMediumText text={'בחר את סכום ההשקעה'}/>
            <ChanceRowBalls numbers={participantValues} setNumber={setParticipantAmount}
                            removeNumber={resetParticipantAmount}
                            isClicked={(number) => marks[PARTICIPANT_AMOUNT] === number} isShitati={false}/>
        </View>
    )
}


export const regularChanceReducer = (state: ChanceMarksType, action: ChanceActionType): ChanceMarksType => {
    switch (action.type) {
        case 'addCard':
            if (state[FORM_TYPE] === NON_NUMBER) {
                // alert('Pick form type')
                AlertHelper.show("בחר סוג טופס",
                () => AlertHelper.hide(),'sendGameForm')
            }
            if (action.cardType) {
                if (state[CARDS][action.cardType].length < 1 && Object.values(state[CARDS]).map((numbers) => numbers.length).reduce((previousValue, currentValue) => previousValue + currentValue) < state[FORM_TYPE]) {
                    action.cardValue && state[CARDS][action.cardType].push(action.cardValue)
                }
            }
            return {...state}
        case 'removeCard':
            if (action.cardType) {
                action.cardValue && _.pull(state[CARDS][action.cardType], action.cardValue)
            }
            return {...state}
        case 'setFormType':
            if (state[FORM_TYPE] > (action.formType || NON_NUMBER)) {
                state[CARDS] = {[CLOVER]: [], [DIAMOND]: [], [HEART]: [], [LEAF]: []}
            }
            state[FORM_TYPE] = action.formType || NON_NUMBER
            return {...state}
        case 'resetFormType':
            state[FORM_TYPE] = NON_NUMBER
            return {...state}
        case 'setParticipantAmount':
            state[PARTICIPANT_AMOUNT] = action.participantAmount || NON_NUMBER
            return {...state}
        case 'resetParticipantAmount':
            state[PARTICIPANT_AMOUNT] = NON_NUMBER
            return {...state}
        case 'setMultiLottery':
            state[MULTI_LOTTERY] = action?.multiLotteryValue || NON_NUMBER
            return {...state}
        case 'popTableNumbers':
            state[CARDS] = {[CLOVER]: [], [DIAMOND]: [], [HEART]: [], [LEAF]: []}
            return {...state}
        case 'tableAutoInsertion':
            const cardValues = _.shuffle(['7', '8', '9', '10', 'J', 'Q', 'K', 'A'])
            state[CARDS] = {[CLOVER]: [], [DIAMOND]: [], [HEART]: [], [LEAF]: []}
            let unfilledCards: string[][] = Object.values(state[CARDS]).filter((cards) => cards.length === 0)
            let filledCards = 4 - unfilledCards.length
            unfilledCards = _.shuffle(unfilledCards)
            for (let cards of unfilledCards) {
                if (filledCards < state[FORM_TYPE]) {
                    cards.push(_.sample(cardValues))
                    filledCards++
                }
            }
            return {...state}
        default:
            throw new Error('Unhandled lottoNumbers action')
    }
}

const RegularChanceForm = ({
                               initForm,
                               color,
                               isFormType,
                               isShitati,
                               calcPriceFunction,
                               formType,
                               postFormFunction
                           }: RegularChanceFormType) => {
    
    const [marks, dispatchMarks] = useReducer(regularChanceReducer, initForm)
    const setFormType = (value: number) => {
        dispatchMarks({type: 'setFormType', value: NON_NUMBER, formType: value})
    }

    const {jwtToken} = useContext(UserContext)
    const postFunction = useCallback(() => postFormFunction(marks, jwtToken), [marks])

    const calcFunction = useCallback(() => calcPriceFunction(marks, jwtToken), [marks])

    // Different between forms.
    const validateForm = (lvl: LevelType = STRICT): boolean => {
        const pickedCardsAmount = Object.values(marks[CARDS]).map((numbers) => numbers.length).reduce((previousValue, currentValue) => previousValue + currentValue)
        if (marks[FORM_TYPE] === NON_NUMBER) {
            if(lvl === STRICT){
                AlertHelper.show("בחר סוג טופס",
                () => AlertHelper.hide(),'sendGameForm')
            }
            return false
        }
        if (pickedCardsAmount !== marks[FORM_TYPE]) {
            if(lvl === STRICT){
                AlertHelper.show(`בחר לפחות סוג אחד מ - ${marks[FORM_TYPE]} סוגים `,
                () => AlertHelper.hide(),'sendGameForm')
            }
            return false
        }
        if (marks[PARTICIPANT_AMOUNT] === NON_NUMBER) {
            if(lvl === STRICT){
                AlertHelper.show("בחר סכום השקעה",
                () => AlertHelper.hide(),'sendGameForm')
            }
            return false
        }
        return true
    }

    const removeFormType = () => {
        dispatchMarks({type: 'resetFormType', value: NON_NUMBER})
    }

    const setParticipantAmount = (value: number) => {
        dispatchMarks({type: 'setParticipantAmount', value: NON_NUMBER, participantAmount: value})
    }

    const resetParticipantAmount = () => {
        dispatchMarks({type: 'resetParticipantAmount', value: NON_NUMBER})
    }

    const addHeart = (card: string) => {
        dispatchMarks({type: 'addCard', cardType: HEART, cardValue: card, value: NON_NUMBER})
    }

    const addLeaf = (card: string) => {
        dispatchMarks({type: 'addCard', cardType: LEAF, cardValue: card, value: NON_NUMBER})
    }

    const addDiamond = (card: string) => {
        dispatchMarks({type: 'addCard', cardType: DIAMOND, cardValue: card, value: NON_NUMBER})
    }

    const addClover = (card: string) => {
        dispatchMarks({type: 'addCard', cardType: CLOVER, cardValue: card, value: NON_NUMBER})
    }

    const removeHeart = (card: string) => {
        dispatchMarks({type: 'removeCard', cardType: HEART, cardValue: card, value: NON_NUMBER})
    }

    const removeLeaf = (card: string) => {
        dispatchMarks({type: 'removeCard', cardType: LEAF, cardValue: card, value: NON_NUMBER})
    }

    const removeDiamond = (card: string) => {
        dispatchMarks({type: 'removeCard', cardType: DIAMOND, cardValue: card, value: NON_NUMBER})
    }

    const removeClover = (card: string) => {
        dispatchMarks({type: 'removeCard', cardType: CLOVER, cardValue: card, value: NON_NUMBER})
    }

    const setMultiLottery = (number: MultiLotteryType | MultiLotteryChanceType) => {
        dispatchMarks({
            type: 'setMultiLottery',
            value: NON_NUMBER,
            multiLotteryValue: (number as MultiLotteryChanceType)
        })
    }

    const tableAutoInsertion = () => {
        dispatchMarks({type: 'tableAutoInsertion', value: NON_NUMBER})
    }

    const popTableNumbers = () => {
        dispatchMarks({type: 'popTableNumbers', value: NON_NUMBER})
    }


    return <>
        <FirstSectionInstructions mainTitle={HEBREW_MAIN_TITLE}
                                  subTitle={`מלא ${marks[FORM_TYPE] === NON_NUMBER ? '' : marks[FORM_TYPE]}`}
                                  bacgroundColor={color}/>
        <ChanceUserInput color={color} setFormType={setFormType} resetFormType={removeFormType}
                         resetParticipantAmount={resetParticipantAmount} setParticipantAmount={setParticipantAmount}
                         marks={marks} addHeart={addHeart} addLeaf={addLeaf} addDiamond={addDiamond}
                         addClover={addClover} removeLeaf={removeLeaf} removeHeart={removeHeart}
                         removeDiamond={removeDiamond} removeClover={removeClover} isFormType={isFormType}
                         tableAutoInsertion={tableAutoInsertion} popTableNumbers={popTableNumbers}
                         isShitati={isShitati} participantValues={[5, 10, 25, 50, 70, 100, 250, 500]}/>
        <InstructionStep marginTop={35} containerHeight={72} stepText={HEBREW_SECOND_STEP_TITLE} stepNumber={2}
                         stepColor={color}/>
        <ChanceFormAdditions marks={marks} setMultiLottery={setMultiLottery}/>
        <SumAndSubmitChanceSection bottonColor={RED_COLOR} validateForm={validateForm} color={color}
                                   calcPriceFunction={calcFunction}
                                   formType={formType}
                                   postFunction={postFunction} marks={marks}/>
    </>


}

export default RegularChanceForm
