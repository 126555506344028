import React from "react";
import {View} from "react-native";
import BottomNavBar from "./bottomNavBar";
import ColoredLine from "./coloredLine";
import SocialMedia from "./socialMedia";

type BottomdetailsType = {}

const Bottomdetails: React.FC<BottomdetailsType> = ({children}) => {
    return (
        <View style={{marginTop: 50}}>
            <View style={{paddingHorizontal: '13.35%', position: "relative"}}>
                {children}
            </View>
            <View style={{paddingHorizontal: '10.9%'}}>
                <BottomNavBar/>
            </View>
            <ColoredLine/>
            <SocialMedia/>
        </View>
    )
}

export default Bottomdetails