import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {UserContext} from "../appSwitch";
import {getAllFormsOwners, OwnersFormType} from "../server/owners";
import {Menu, Space} from 'antd';
// @ts-ignore
import { sum } from 'lodash';
import {OverviewTable} from "./overviewTable";
import {Stats} from "./stats";
import {FormOutlined, TransactionOutlined, UserOutlined} from '@ant-design/icons';
import {UsersTable} from "./usersTable";
import {TransactionsTable} from "./transactionsTable";
import {BatchesTable} from "./batchesTable";


export const OwnersContainer: React.FC = (props) => {
    const {jwtToken} = useContext(UserContext)
    const [allForms, setAllForms] = useState<OwnersFormType[]>([])
    const [isLoading, setIsLoading] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalOriginPrice, setTotalOriginPrice] = useState(0);
    const [numberOfForms, setNumberOfForms] = useState(0);
    const [currentTab, setCurrentTab] = useState('gameForms')

    const allFormsGetter = useCallback(async () => {
        if (jwtToken) {
            const af =  await getAllFormsOwners(jwtToken)
            setTotalPrice(sum(af.map(form => form.price)))
            setTotalOriginPrice(sum(af.map(form => form.origin_price)))
            setNumberOfForms(af.length);
            setAllForms(af)
        }
    }, [jwtToken, setIsLoading])

    useEffect( () => {
        if (currentTab === 'gameForms') {
            setIsLoading(true)
            allFormsGetter().then(() => setIsLoading(false))
        }
    }, [currentTab, allFormsGetter])

    const handleClick = (e: { key: any; }) => {
        console.log('click ', e);
        setCurrentTab(e.key);
      };

    const getTable = useMemo(() => {
        if (currentTab === 'gameForms') {
            return (
                <>
                    <Stats totalPrice={totalPrice} totalOriginPrice={totalOriginPrice} numberOfForms={numberOfForms} isLoading={isLoading}/>
                    <OverviewTable
                        allForms={allForms}
                        isLoading={isLoading}
                        setTotalPrice={setTotalPrice}
                        setTotalOriginPrice={setTotalOriginPrice}
                        setNumberOfForms={setNumberOfForms}/>
                </>
            )
        }
        else if (currentTab === 'users') {
            return (
                <>
                    {/*<Stats totalPrice={totalPrice} totalOriginPrice={totalOriginPrice} numberOfForms={numberOfForms} isLoading={isLoading}/>*/}
                    <UsersTable/>
                </>
            )
        }
        else if (currentTab === 'transactions') {
            return (
                <>
                    {/*<Stats totalPrice={totalPrice} totalOriginPrice={totalOriginPrice} numberOfForms={numberOfForms} isLoading={isLoading}/>*/}
                    <TransactionsTable/>
                </>
            )
        }
        else if (currentTab === 'batches') {
            return <BatchesTable/>
        }
        else {
            return <div>Invalid choise</div>
        }
    }, [currentTab, allForms, isLoading, totalPrice, totalOriginPrice, numberOfForms])


    return (
        <>
            <Menu onClick={handleClick} selectedKeys={[currentTab]} mode="horizontal">
                <Menu.Item key="gameForms" icon={<FormOutlined />}>
                  Games Forms
                </Menu.Item>
                <Menu.Item key="users" icon={<UserOutlined />}>
                  Users
                </Menu.Item>
                <Menu.Item key="transactions" icon={<TransactionOutlined />}>
                  Transactions
                </Menu.Item>
                <Menu.Item key="batches" icon={<TransactionOutlined />}>
                  Batches
                </Menu.Item>
            </Menu>
            <Space direction={"vertical"} size="large" style={{ display: 'flex', padding: 24 }}>
                {getTable}
            </Space>
        </>
    )
}