import React, {useCallback, useContext, useEffect, useState} from "react";
import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {StepBigText, StepCircle} from "./firstSectionInstructions";
import {
    FB_SPACER_BOLD,
    FB_SPACER_REGULAR,
    getBorder,
    getCircleCss,
    getFontCss,
    WHITE_COLOR,
    YELLOW_COLOR
} from "../../../styles";
import {useAsync} from "../../../server/utils";
import {EnglishHebrewFormTypeMapping, FormType, GenericFormType, LottoMarksType} from "../../../types";
import {LAX, NUMBERS, HOME_PAGE_PATH, SIGN_IN_PATH, STRONG_NUMBERS, TABLES} from "../../../consts";
import {isFullTable} from "../../utils";
import {UserContext} from "../../../appSwitch";
import {useHistory, useLocation} from "react-router-dom";

import { AlertHelper } from '../../../AlertHelper';
import {OpenChargeButton} from '../../../mySpace/chargeButton'
import {Alert, Button, message, Modal, Result, Spin} from "antd";
import {PaymentModal} from "../../../paymentModal";

type SumAndSubmitSectionType = {
    bottonColor: string,
    numbersLength: number,
    strongNumbersLength: number,
    color: string,
    calcPriceFunction: () => Promise<{ price: number }>,
    postFunction: () => Promise<Response>,
    marks: LottoMarksType,
    formType: FormType,
    validateForm: (lvl?: 'strict' | 'lax') => boolean,
    isMobile?: boolean,
    lottomaticFormData?: string,
}

export type ResultStatus = 'success' | 'error' | 'warning' | undefined;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const SubmitButton = ({
                                 text,
                                 validateForm,
                                 postFunction,
                                 color, isMobile, lottomaticFormData, formType, price
                             }: { color: string, postFunction: () => Promise<Response>, text: string, validateForm: (lvl?: 'strict' | 'lax') => boolean, isMobile?: boolean, lottomaticFormData?: string, formType?: FormType, price?: number }) => {
    const style = StyleSheet.create({
        containerStyle: {
            ...getCircleCss(31, 169, 12),
            backgroundColor: color,
            borderColor: WHITE_COLOR,
            borderWidth: 3,
            borderStyle: "solid",
            justifyContent: "center",
            alignItems: "center",
        },
        textStyle: {
            ...getFontCss(FB_SPACER_BOLD, 28, WHITE_COLOR)
        },
        containerStyleMobile: {
            ...getCircleCss(58, 234, 12),
            backgroundColor: color,
            borderColor: WHITE_COLOR,
            borderWidth: 3,
            borderStyle: "solid",
            justifyContent: "center",
            alignItems: "center",
        },
        textStyleMobile: {
            ...getFontCss(FB_SPACER_BOLD, 48, WHITE_COLOR)
        }
    })
    const {execute, error, status} = useAsync<any, {error: string, error_details: string}>(postFunction, false)
    const [openCharge, setOpenCharge] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [titleName, setTitleName] = useState('')
    const [resultMsg, setResultMsg] = useState<string | undefined>(undefined)
    const [resultStatus, setResultStatus] = useState<ResultStatus>(undefined)

    const {isAuthenticated, updateBalance} = useContext(UserContext)
    const history = useHistory()
    let query = useQuery();

    const onPress = () => {
        if (!isAuthenticated) {
            history.push(SIGN_IN_PATH)
        } else {
            if (validateForm()) {
                setIsLoading(true);
                setShowModal(true);
                execute().then(() => {
                    setIsLoading(false);
                })
            }
        }
    }

    useEffect(() => {
        if (query.get('lottomaticStatus')) {
            setShowModal(true);
            setIsLoading(false);
            const status = query.get('lottomaticStatus');
            setResultStatus(status as ResultStatus)
            setTitleName(status === 'success' ? 'הטופס נשמר בהצלחה בלוטומטיק' : 'אופס משהו קרה')
        }
    }, [])

    useEffect(() => {
        if (status === 'success') {
            message.success('הטופס נשמר בהצלחה בלוטומטיק !');
            setTitleName('הטופס נשמר בלוטומטיק');
            setResultStatus('success')
        }
        if(status === 'error'){
            setTitleName('אופס משהו קרה');
            setResultStatus('error');
            setOpenCharge(error?.error === 'paymentError')
            setResultMsg(`${error?.error} - ${error?.error_details}`);
        }
        
    }, [error, status])

    const onClose = useCallback(() => {
        updateBalance()
        setShowModal(false);
    }, [])

    return (
        <>
            <TouchableOpacity style={isMobile ? style.containerStyleMobile : style.containerStyle} onPress={onPress}>
                <Text style={isMobile ? style.textStyleMobile : style.textStyle}>{text}</Text>
            </TouchableOpacity>
            <PaymentModal isLoading={isLoading} isMobile={isMobile} showModal={showModal} resultStatus={resultStatus} titleName={titleName} resultMsg={resultMsg} onClose={onClose}/>
            {openCharge && <OpenChargeButton lottomaticFormData={lottomaticFormData} formType={formType} isMobile={isMobile} setIsLoading={setIsLoading} setStatus={setResultStatus} setMsg={setResultMsg} setTitleName={setTitleName} price={price}/>}
        </>
    )
}

const SumAndSubmitSection = ({
                                 marks,
                                 validateForm,
                                 postFunction,
                                 calcPriceFunction,
                                 color,
                                 numbersLength,
                                 strongNumbersLength,
                                 formType,
                                 bottonColor, isMobile, lottomaticFormData
                             }: SumAndSubmitSectionType) => {
    const style = StyleSheet.create({
        container: {
            flexDirection: "row-reverse",
            alignItems: "center",

            height: 141,
            paddingTop: 23,
            ...getBorder()
        },
        sumText: {
            ...getFontCss(FB_SPACER_REGULAR, 24, YELLOW_COLOR),
            marginRight: '5%',
            whiteSpace: 'nowrap',
            overflowWrap: 'normal',
        },
        sumTextMobile: {
            ...getFontCss(FB_SPACER_REGULAR, 44, YELLOW_COLOR),
            marginRight: '5%',
            whiteSpace: 'nowrap',
            overflowWrap: 'normal',
        }
    })

    const fullTables = marks[TABLES].filter((mark) => isFullTable(mark[NUMBERS], mark[STRONG_NUMBERS], numbersLength, strongNumbersLength))

    return (
        <View style={style.container}>
            <StepCircle text={'₪'} textColor={color}/>
            <View>
                <StepBigText text={'סיכום ושליחת טופס'} isMobile={isMobile}/>
                {/* <Text style={style.sumText}>{`סוג: ${EnglishHebrewFormTypeMapping[formType]} `}</Text> */}
                <Text style={isMobile ? style.sumTextMobile : style.sumText}>{`סוג: ${EnglishHebrewFormTypeMapping[formType]} | סך הכל ${fullTables.length} טבלאות | ${marks.multi_lottery != -1? marks.multi_lottery : '1' } הגרלות`}</Text>
            </View>
            <View style={{
                flexDirection: "row",
                width: 'fit-content',
                left: '3.7%',
                position: "absolute",
                alignItems: "center"
            }}>
                <Payment postFunction={postFunction} bottonColor={bottonColor} validateForm={validateForm} marks={marks}
                         calcPriceFunction={calcPriceFunction} isMobile={isMobile} lottomaticFormData={lottomaticFormData} formType={formType}/>
            </View>
        </View>
    )
}

export const Payment = ({bottonColor, validateForm, postFunction, marks, calcPriceFunction, isMobile, lottomaticFormData, formType}: {
    bottonColor: string,
    validateForm: (lvl?: 'strict' | 'lax') => boolean
    postFunction: () => Promise<Response>,
    calcPriceFunction: () => Promise<{ price: number }>,
    marks: GenericFormType,
    isMobile?: boolean,
    lottomaticFormData?: string,
    formType?: FormType,
}) => {

    const style = StyleSheet.create({
        paymentText: {
            marginLeft: 20,
            whiteSpace: 'nowrap',
            overflowWrap: 'normal',
            ...getFontCss(FB_SPACER_BOLD, 20, WHITE_COLOR)
        },
        paymentTextMobile: {
            marginLeft: 20,
            whiteSpace: 'nowrap',
            overflowWrap: 'normal',
            ...getFontCss(FB_SPACER_BOLD, 46, WHITE_COLOR)
        }
    })
    const {execute, value} = useAsync<{ price: number }>(calcPriceFunction, false)

    useEffect(() => {
        if (validateForm(LAX)) {
            execute()
        }
    }, [marks])

    return (
        <>
            <SubmitButton postFunction={postFunction} text={'שלח טופס'} validateForm={validateForm} color={bottonColor} isMobile={isMobile} lottomaticFormData={lottomaticFormData} formType={formType} price={value?.price}/>
            {value?.price && <Text style={isMobile ? style.paymentTextMobile : style.paymentText}>לתשלום: {value?.price} ₪</Text>}
        </>
    )
}

export default SumAndSubmitSection