import {handleErrors} from "./utils";
import {GET_ALL_BATCHES, GET_ALL_FORMS_OWNERS, GET_ALL_TRANSACTIONS, GET_ALL_USERS, SERVER_URL} from "./configurations";

export type OwnersFormType = {
    marks: any;
    id: string,
    status: string,
    price: number,
    origin_price: number,
    form_kind: string,
    reseller_code: string,
    prize: string,
    lottery_number: string,
}


export type User = {
    id: string,
    firstName: string,
    familyName: string,
    email: string,
    phone: string,
    created: string,
    cognitoId: string,
    isBalanceSync: boolean,
    balance: number,
}

export type ResellerUser = {
    full_name: string;
    created: string,
}

export type Transaction = {
    id: string,
    userId: string,
    ccTokenId: string,
    operation: string,
    sumAmount: string,
    coin: string,
    status: string,
    created: string,
    updated: string,
}

export interface formDetails {
    status: string;
    form_id: number;
}


export type Batch = {
    id: string,
    totalPrice: number,
    status: string,
    date: string,
    forms: formDetails[],
}

export const getAllFormsOwners = async (jwtToken: string, resellerId: string | undefined = undefined): Promise<OwnersFormType[]> => {
    let formData = new FormData();
    if (resellerId) {
        formData.append('resellerId', resellerId);
    }
    return await handleErrors(fetch(GET_ALL_FORMS_OWNERS.href, {
        method: "POST",
        headers: {
            Authorization: jwtToken
        },
        body: formData
    }))
};

export const getAllUsers = async (jwtToken: string, resellerId: string | undefined = undefined): Promise<User[]> => {
    let formData = new FormData();
    if (resellerId) {
        formData.append('resellerCode', resellerId);
    }
    return await handleErrors(fetch(GET_ALL_USERS.href, {
        method: "POST",
        headers: {
            Authorization: jwtToken
        },
        body: formData
    }))
};

export const getAllTransactions= async (jwtToken: string): Promise<Transaction[]> => {
    return await handleErrors(fetch(GET_ALL_TRANSACTIONS.href, {
        headers: {
            Authorization: jwtToken
        }
    }))
};

export const getAllBatches = async (jwtToken: string): Promise<Batch[]> => {
    return await handleErrors(fetch(GET_ALL_BATCHES.href, {
        headers: {
            Authorization: jwtToken
        }
    }))
};

export const getUserBalance = async (jwtToken: string, cognito_id: string): Promise<{balance: number, lottomaticUserId: string}> => {
    const url = new URL(`/admin/get_user_balance/${cognito_id}`, SERVER_URL).href;
    return await handleErrors(fetch(url, {
        headers: {
            Authorization: jwtToken
        }
    }))
};

export const getUserByLottomaticId = async (jwtToken: string, lottomatic_id: string): Promise<User> => {
    const url = new URL(`/admin/get_user_by_lottomatic_id/${lottomatic_id}`, SERVER_URL).href;
    return await handleErrors(fetch(url, {
        headers: {
            Authorization: jwtToken
        }
    }))
};

export const addBalance = async (jwtToken: string, cognito_id: string, balance: number): Promise<number> => {
    let formData = new FormData();
    formData.append('sum', ""+balance);
    const url = new URL(`/admin/add_balance/${cognito_id}`, SERVER_URL).href;
    return await handleErrors(fetch(url, {
        method: "POST",
        headers: {
            Authorization: jwtToken
        },
        body: formData
    }))
};

export const removeBalance = async (jwtToken: string, cognito_id: string, balance: number): Promise<number> => {
    let formData = new FormData();
    formData.append('sum', ""+balance);
    const url = new URL(`/admin/remove_balance/${cognito_id}`, SERVER_URL).href;
    return await handleErrors(fetch(url, {
        method: "POST",
        headers: {
            Authorization: jwtToken
        },
        body: formData
    }))
};
export const changeStatus = async (jwtToken: string, formId: string, status: string): Promise<string> => {
    let formData = new FormData();
    formData.append('status', status);
    const url = new URL(`/admin/change_status/${formId}`, SERVER_URL).href;
    return await handleErrors(fetch(url, {
        method: "POST",
        headers: {
            Authorization: jwtToken
        },
        body: formData
    }))
};

export const changeLotteryNumber = async (jwtToken: string, formId: string, newLotteryNumber: string): Promise<string> => {
    let formData = new FormData();
    formData.append('newLotteryNumber', newLotteryNumber);
    const url = new URL(`/admin/change_lottery_number/${formId}`, SERVER_URL).href;
    return await handleErrors(fetch(url, {
        method: "POST",
        headers: {
            Authorization: jwtToken
        },
        body: formData
    }))
};
