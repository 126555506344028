import React, {useCallback, useContext, useReducer, useState} from "react";
import {
    CARDS,
    CLOVER,
    DIAMOND,
    FORM_TYPE,
    HEART,
    HEBREW_MAIN_TITLE,
    HEBREW_SECOND_STEP_TITLE,
    LEAF,
    NON_NUMBER,
    PARTICIPANT_AMOUNT,
    STRICT
} from "../../consts";
import {ChanceMarksType, LevelType, MultiLotteryChanceType, MultiLotteryType} from "../../types";
import FirstSectionInstructions, {InstructionStep} from "./additionalSections/firstSectionInstructions";
import {RED_COLOR} from "../../styles";
import {
    ChanceActionType,
    ChanceFormAdditions,
    ChanceUserInput,
    RegularChanceFormType,
    regularChanceReducer,
    SumAndSubmitChanceSection
} from "./regularChanceForm";
import {UserContext} from "../../appSwitch";

import { AlertHelper } from '../../AlertHelper';

let _ = require('lodash')


export const ChanceShitatiReducer = (state: ChanceMarksType, action: ChanceActionType): ChanceMarksType => {

    
    switch (action.type) {
        case 'addCard':
            if (state[FORM_TYPE] === NON_NUMBER) {
                // alert('Pick form type')
                AlertHelper.show("בחר סוג טופס",
                () => AlertHelper.hide(),'sendGameForm')
            }
            if (action.cardType) {
                if (state[CARDS][action.cardType].length < 4 && Object.values(state[CARDS]).map((numbers) => numbers.length).reduce((previousValue, currentValue) => previousValue + currentValue) < state[FORM_TYPE] * 4) {
                    action.cardValue && state[CARDS][action.cardType].push(action.cardValue)
                }
            }
            return {...state}
        case 'tableAutoInsertion':
            const cardValues = _.shuffle(['7', '8', '9', '10', 'J', 'Q', 'K', 'A'])
            state[CARDS] = {[CLOVER]: [], [DIAMOND]: [], [HEART]: [], [LEAF]: []}
            let unfilledCards: string[][] = Object.values(state[CARDS]).filter((cards) => cards.length === 0)
            let filledCards = 4 - unfilledCards.length
            unfilledCards = _.shuffle(unfilledCards)
            for (let cards of unfilledCards) {
                if (filledCards < state[FORM_TYPE]) {
                    let cardsSet = new Set(cards)
                    while (cardsSet.size !== 4) {
                        cardsSet.add(_.sample(cardValues))
                    }
                    // @ts-ignore
                    cards.push(...cardsSet.values())
                    filledCards++
                }
            }
            return {...state}
        default:
            return {...regularChanceReducer(state, action)}
    }
}

const ChanceShitatiForm: React.FC<RegularChanceFormType> = ({
                                                                initForm,
                                                                color,
                                                                isShitati,
                                                                calcPriceFunction,
                                                                formType,
                                                                postFormFunction
                                                            }) => {
    const [marks, dispatchMarks] = useReducer(ChanceShitatiReducer, initForm)

    const setFormType = (value: number) => {
        dispatchMarks({type: 'setFormType', value: NON_NUMBER, formType: value})
    }

    const {jwtToken} = useContext(UserContext)

    const postFunction = useCallback(() => postFormFunction(marks, jwtToken), [marks])

    const calcFunction = useCallback(() => calcPriceFunction(marks, jwtToken), [marks])

    // Different between forms.
    const validateForm = (lvl: LevelType = STRICT): boolean => {
        const pickedCardsAmount = Object.values(marks[CARDS]).map((numbers) => numbers.length).reduce((previousValue, currentValue) => previousValue + currentValue)
        const filledCardsTypes = Object.values(marks[CARDS]).filter((cards) => cards.length !== 0).length
        if (marks[FORM_TYPE] === NON_NUMBER) {
            AlertHelper.show("בחר סוג טופס",
            () => AlertHelper.hide(),'sendGameForm')
            return false
        }
        if (pickedCardsAmount === 0) {
            if(lvl === STRICT){
                AlertHelper.show("בחר לפחות כרטיס אחד",
                () => AlertHelper.hide(),'sendGameForm')
            }
            return false
        }
        if (filledCardsTypes !== (marks[FORM_TYPE] != 5?marks[FORM_TYPE]:4)) {
            if(lvl === STRICT){
                AlertHelper.show(`בחר כרטיסים בדיוק מ - ${(marks[FORM_TYPE] != 5?marks[FORM_TYPE]:4)} סוגים`,
                () => AlertHelper.hide(),'sendGameForm')
            }
            return false
        }
        if (marks[PARTICIPANT_AMOUNT] === NON_NUMBER) {
            if(lvl === STRICT){
                AlertHelper.show("בחר את סכום ההשקעה ",
                () => AlertHelper.hide(),'sendGameForm')
            }
            return false
        }
        return true
    }

    const removeFormType = () => {
        dispatchMarks({type: 'resetFormType', value: NON_NUMBER})
    }

    const setParticipantAmount = (value: number) => {
        dispatchMarks({type: 'setParticipantAmount', value: NON_NUMBER, participantAmount: value})
    }

    const resetParticipantAmount = () => {
        dispatchMarks({type: 'resetParticipantAmount', value: NON_NUMBER})
    }

    const addHeart = (card: string) => {
        dispatchMarks({type: 'addCard', cardType: HEART, cardValue: card, value: NON_NUMBER})
    }

    const addLeaf = (card: string) => {
        dispatchMarks({type: 'addCard', cardType: LEAF, cardValue: card, value: NON_NUMBER})
    }

    const addDiamond = (card: string) => {
        dispatchMarks({type: 'addCard', cardType: DIAMOND, cardValue: card, value: NON_NUMBER})
    }

    const addClover = (card: string) => {
        dispatchMarks({type: 'addCard', cardType: CLOVER, cardValue: card, value: NON_NUMBER})
    }

    const removeHeart = (card: string) => {
        dispatchMarks({type: 'removeCard', cardType: HEART, cardValue: card, value: NON_NUMBER})
    }

    const removeLeaf = (card: string) => {
        dispatchMarks({type: 'removeCard', cardType: LEAF, cardValue: card, value: NON_NUMBER})
    }

    const removeDiamond = (card: string) => {
        dispatchMarks({type: 'removeCard', cardType: DIAMOND, cardValue: card, value: NON_NUMBER})
    }

    const removeClover = (card: string) => {
        dispatchMarks({type: 'removeCard', cardType: CLOVER, cardValue: card, value: NON_NUMBER})
    }

    const setMultiLottery = (number: MultiLotteryType | MultiLotteryChanceType) => {
        dispatchMarks({
            type: 'setMultiLottery',
            value: NON_NUMBER,
            multiLotteryValue: (number as MultiLotteryChanceType)
        })
    }

    const tableAutoInsertion = () => {
        dispatchMarks({type: 'tableAutoInsertion', value: NON_NUMBER})
    }

    const popTableNumbers = () => {
        dispatchMarks({type: 'popTableNumbers', value: NON_NUMBER})
    }


    return <>
        <FirstSectionInstructions mainTitle={HEBREW_MAIN_TITLE}
                                  subTitle={`מלא ${marks[FORM_TYPE] === NON_NUMBER ? '' : marks[FORM_TYPE] != 5?marks[FORM_TYPE]:4 }`}
                                  bacgroundColor={color}/>
        <ChanceUserInput color={color} setFormType={setFormType} resetFormType={removeFormType}
                         resetParticipantAmount={resetParticipantAmount} setParticipantAmount={setParticipantAmount}
                         marks={marks} addHeart={addHeart} addLeaf={addLeaf} addDiamond={addDiamond}
                         addClover={addClover} removeLeaf={removeLeaf} removeHeart={removeHeart}
                         removeDiamond={removeDiamond} removeClover={removeClover} isFormType={true}
                         tableAutoInsertion={tableAutoInsertion} popTableNumbers={popTableNumbers} isShitati={isShitati}
                         participantValues={[5, 10]}/>
        <InstructionStep marginTop={35} containerHeight={72} stepText={HEBREW_SECOND_STEP_TITLE} stepNumber={2}
                         stepColor={color}/>
        <ChanceFormAdditions marks={marks} setMultiLottery={setMultiLottery}/>
        <SumAndSubmitChanceSection bottonColor={RED_COLOR} validateForm={validateForm} color={color}
                                   calcPriceFunction={calcFunction}
                                   formType={formType}
                                   postFunction={postFunction} marks={marks}/>
    </>
}

export default ChanceShitatiForm