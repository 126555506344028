import React from "react";
import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {FB_SPACER_BOLD, FB_SPACER_REGULAR, getCircleCss, getFontCss, WHITE_COLOR} from "../../../styles";


type NumbersFillerHeaderText = { text: string, color: string, isMobile?: boolean }
type NumbersFillerHeaderContainer = { children: JSX.Element[] | JSX.Element, isMobile?: boolean }
type UserInputFormUpperHeaderChildrenType = { color: string, decrementTableIndex: () => void, incrementTableIndex: () => void, tableIndex: number, isMobile?: boolean }


const UserInputFormHeader = ({children, isMobile}: NumbersFillerHeaderContainer) => {
    const style = StyleSheet.create({
        numbersFillerHeaderContainer: {
            flexDirection: "row-reverse",
            height: 40,
            borderRadius: 12,
            backgroundColor: WHITE_COLOR,
            alignItems: "center",
            paddingRight: '2%',
            paddingLeft: '2%'
        },
        numbersFillerHeaderContainerMobile: {
            flexDirection: "row-reverse",
            height: 78,
            borderRadius: 12,
            backgroundColor: WHITE_COLOR,
            alignItems: "center",
            paddingRight: '2%',
            paddingLeft: '2%'
        }
    })

    return (
        <View style={isMobile ? style.numbersFillerHeaderContainerMobile : style.numbersFillerHeaderContainer}>
            {children}
        </View>
    )
}

export const UserInputFormUpperHeaderChildren = ({incrementTableIndex, decrementTableIndex, color, tableIndex, isMobile}: UserInputFormUpperHeaderChildrenType) => {
    const tableSkipperButton: object = {
        borderColor: color,
        borderWidth: 3,
        justifyContent: "center",
        alignItems: "center"
    }
    const style = StyleSheet.create({
        tableSkipperRightButton: {
            ...getCircleCss(24, 24, 12),
            ...tableSkipperButton
        },
        skipText: {
            ...getFontCss(FB_SPACER_REGULAR, 28, color)
        },
        tableSkipperLeftButton: {
            ...getCircleCss(24, 24, 12),
            ...tableSkipperButton,
            position: "absolute",
            left: '2%'
        },
        tableSkipperRightButtonMobile: {
            ...getCircleCss(46, 51, 12),
            ...tableSkipperButton
        },
        skipTextMobile: {
            ...getFontCss(FB_SPACER_REGULAR, 60, color)
        },
        tableSkipperLeftButtonMobile: {
            ...getCircleCss(46, 51, 12),
            ...tableSkipperButton,
            position: "absolute",
            left: '2%'
        },
    })

    return (
        <>
            <TouchableOpacity onPress={decrementTableIndex} style={isMobile ? style.tableSkipperRightButtonMobile : style.tableSkipperRightButton}>
                <Text style={isMobile ? style.skipTextMobile : style.skipText}>{'>'}</Text>
            </TouchableOpacity>
            <UserInputFormHeaderText text={`מלא את טבלה ${tableIndex + 1}`} color={color} isMobile={isMobile}/>
            <TouchableOpacity onPress={incrementTableIndex} style={isMobile ? style.tableSkipperLeftButtonMobile : style.tableSkipperLeftButton}>
                <Text style={isMobile ? style.skipTextMobile : style.skipText}>{'<'}</Text>
            </TouchableOpacity>
        </>
    )
}

export const UserInputFormHeaderText = ({text, color, isMobile}: NumbersFillerHeaderText) => {
    const style = StyleSheet.create({
        fillTableText: {
            ...getFontCss(FB_SPACER_BOLD, 25, color),
            fontWeight: "bold",
            marginRight: '1.5%'
        },
        fillTableTextMobile: {
            ...getFontCss(FB_SPACER_BOLD, 60, color),
            fontWeight: "bold",
            marginRight: '1.5%'
        }
    })

    return (
        <Text style={isMobile ? style.fillTableTextMobile : style.fillTableText}>{text}</Text>
    )
}


export default UserInputFormHeader