import React from "react";
import {View} from "react-native";


type ColoredLineType = {}

const ColoredLine: React.FC<ColoredLineType> = () => {
    return (
        <View style={{flexDirection: "row-reverse", marginVertical: 2}}>
            <View style={{width: '25%', backgroundColor: '#EB2874', height: 2}}/>
            <View style={{width: '25%', backgroundColor: '#009943', height: 2}}/>
            <View style={{width: '25%', backgroundColor: '#E62321', height: 2}}/>
            <View style={{width: '25%', backgroundColor: '#FF6B00', height: 2}}/>
        </View>
    )
}


export default ColoredLine