export class inputHelper {
    static textInput : any
    static selectInput : any

    static setTextInput(textInput : any) {
        this.textInput = textInput
    }

    static setSelectInput(selectInput : any){
        this.selectInput = selectInput
    }

    static resetSelectInput(){
        console.log(this.selectInput.children[1].value, this.selectInput.children[1].defaultValue)
        this.selectInput.children[1].defaultValue = ""
        this.selectInput.children[1].value = ""
    }

    static clear() {
        this.textInput.clear()
    }
    
    static reset(){
        document.getElementById('clearInput1')?.click()
        document.getElementById('clearInput2')?.click()
    }
}