import { handleErrors } from "./utils";
import { CHARGE_CREDIT_PATH, CHARGE_CREDIT_TOKEN_PATH, HAS_CREDIT_TOKEN_PATH, REFUND_PATH, REFUND_TOKEN_PATH } from "./configurations";
import {FormType} from "../types";
import {ResultStatus} from "../lotto/regular_lotto/additionalSections/sumAndSubmitSection";

export type HasTokenType = {
    "token": boolean | string;
    "end_digits"?: string;
}
export type SuccessType = {
    "result": ResultStatus,
    "operation": string
}
export type ErrorType ={
    "error": string,
    "error_details": string
}

export const charge = async (jwtToken: string, amount: number, operation: number, lottomaticFormData?: string, formType?: FormType): Promise<string> => {
    let formData = new FormData();
    formData.append('sum', ""+amount);
    formData.append('product', 'טעינת קרדיט');
    formData.append('operation', ""+operation);
    if (lottomaticFormData && formType) {
        formData.append('lottomaticFormData', ""+lottomaticFormData);
        formData.append('formType', ""+formType);
    }
    return await handleErrors(fetch(CHARGE_CREDIT_PATH.href, {
        method: "POST",
        headers: {
            Authorization: jwtToken
        },
        body: formData
    })).then((res) => res.redirect)
};
export const charge_token = async (jwtToken: string, amount: number, lottomaticFormData?: string, formType?: FormType): Promise< SuccessType | ErrorType> => {
    let formData = new FormData();
    formData.append('sum', ""+amount);
    if (lottomaticFormData && formType) {
        formData.append('lottomaticFormData', ""+lottomaticFormData);
        formData.append('formType', ""+formType);
    }
    return await handleErrors(fetch(CHARGE_CREDIT_TOKEN_PATH.href, {
        method: "POST",
        headers: {
            Authorization: jwtToken
        },
        body: formData
    }))
};

export const refund = async (jwtToken: string, amount: number): Promise<string> => {
    let formData = new FormData();
    formData.append('sum', ""+amount);
    formData.append('product', 'משיכת קרדיט');
    return await handleErrors(fetch(REFUND_PATH.href, {
        method: "POST",
        headers: {
            Authorization: jwtToken
        },
        body: formData
    })).then((res) => res.redirect)
};
export const refund_token = async (jwtToken: string, amount: number): Promise< SuccessType | ErrorType > => {
    let formData = new FormData();
    formData.append('sum', ""+amount);
    return await handleErrors(fetch(REFUND_TOKEN_PATH.href, {
        method: "POST",
        headers: {
            Authorization: jwtToken
        },
        body: formData
    }))
};
export const has_credit_token = async (jwtToken: string): Promise<HasTokenType> => {
    return await handleErrors(fetch(HAS_CREDIT_TOKEN_PATH.href, {
        headers: {
            Authorization: jwtToken
        }
    }))
};
