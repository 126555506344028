import React from 'react';
import {Image, View, TouchableOpacity} from "react-native";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import {WHITE_COLOR} from "../styles";
import {CHANCE_SHITATI_PATH, FORM_123_PATH, REGULAR_777_PATH, REGULAR_LOTTO_PATH} from "../consts";
import {useHistory} from 'react-router-dom'

const LineWithCircle = () => {
    const history = useHistory()

    return (
        <Carousel autoPlay={true} showArrows={false} showStatus={false} infiniteLoop={true} showThumbs={false}>
            <TouchableOpacity onPress={()=>{history.push(CHANCE_SHITATI_PATH)}}>
                <View style={{ width: 'inherit', backgroundColor: WHITE_COLOR}}>
                    <Image source={require('../../../assets/banners/00.png')} resizeMethod={"auto"}
                        resizeMode={"stretch"}
                        style={{width: '100%', height: 265, marginTop: 20}}/>
                </View>

            </TouchableOpacity>
            <TouchableOpacity onPress={()=>{history.push(FORM_123_PATH)}}>
                <View style={{ width: 'inherit', backgroundColor: WHITE_COLOR}}>
                    <Image source={require('../../../assets/banners/01 copy.png')} resizeMethod={"auto"}
                        resizeMode={"stretch"}
                        style={{width: '100%', height: 265, marginTop: 20}}/>
                </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={()=>{history.push(REGULAR_777_PATH)}}>
                <View style={{ width: 'inherit', backgroundColor: WHITE_COLOR}}>
                    <Image source={require('../../../assets/banners/02 copy.png')} resizeMethod={"auto"}
                        resizeMode={"stretch"}
                        style={{width: '100%', height: 265, marginTop: 20}}/>
                </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={()=>{history.push()}}>
                <View style={{ width: 'inherit', backgroundColor: WHITE_COLOR}}>
                    <Image source={require('../../../assets/banners/03 copy.png')} resizeMethod={"auto"}
                        resizeMode={"stretch"}
                        style={{width: '100%', height: 265, marginTop: 20}}/>
                </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={()=>{history.push(REGULAR_LOTTO_PATH)}}>
                <View style={{ width: 'inherit', backgroundColor: WHITE_COLOR}}>
                    <Image source={require('../../../assets/banners/04 copy.png')} resizeMethod={"auto"}
                        resizeMode={"stretch"}
                        style={{width: '100%', height: 265, marginTop: 20}}/>
                </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={()=>{history.push(REGULAR_LOTTO_PATH)}}>
                <View style={{ width: 'inherit', backgroundColor: WHITE_COLOR}}>
                    <Image source={require('../../../assets/banners/05 copy.png')} resizeMethod={"auto"}
                        resizeMode={"stretch"}
                        style={{width: '100%', height: 265, marginTop: 20}}/>
                </View>
            </TouchableOpacity>
        </Carousel>
    )
}


export default LineWithCircle
