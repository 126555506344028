import React, {useState, useEffect} from "react"
import AwesomeAlert from "react-native-awesome-alerts"
import {View, StyleSheet} from 'react-native'

export class CustomAlert extends React.Component {
    constructor() {
        super({})
        this.state = {
            visible: false,
            text: "",
            onPressOk: () => {},
            src : "",
            style : StyleSheet.create({
                topStyle : {
                    position:"absolute",
                    marginRight: "auto",
                    marginLeft: "auto",
                    top:"100px",
                    left: 0,
                    right: 0,
                },
                centerStyle : {
                    position:"absolute",
                    marginRight: "auto",
                    marginLeft: "auto",
                    top:"50%",
                    left: 0,
                    right: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign:"center",
                    minHeight:"100vh"
                },
                gameStyle : {
                    position:"absolute",
                    marginRight: "auto",
                    marginLeft: "auto",
                    top:(screen.height/2)-40+'px',
                    left: 0,
                    right: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    textAlign:"center",
                    minHeight:"100vh",

                }
            })
        }
    }

    componentWillMount = () => {
        this._hide()
    }

    _hide = () => {
        this.setState({
            visible: false,
        })
    }

    _show = (text : string, onPressOk : ()=>void, src ?: string) => {
        this.setState({
            visible: true,
            onPressOk: onPressOk,
            text,
            src
        }, ()=>{
            if(this.state.src === 'normal') window.scrollTo(0, 0)
            else if(this.state.src === 'sendGameForm') window.scrollTo( (screen.width/2)-30, (screen.height/2)-30 )
            else window.scrollTo( screen.width/2, screen.height/2 )
        })
    }

    componentWillUnmount() {
        this._hide()
    }
    render() {
        if (this.state.visible) {
            return (
                <View style={(this.state.src==="") ? this.state.style.centerStyle : (this.state.src==='logout') ? this.state.style.topStyle : this.state.style.gameStyle}>
                    < AwesomeAlert
                        show={this.state.visible}
                        showProgress={false}
                        title={this.state.text}
                        closeOnTouchOutside={true}
                        closeOnHardwareBackPress={false}
                        showCancelButton={true}
                        cancelText="Ok"
                        onCancelPressed={this.state.onPressOk}
                        titleStyle={{ color: 'black' }}
                    />
                </View>
                )
        } else {
            return null
        }
    }
}
export default CustomAlert