import React from 'react'
import {Text, TouchableOpacity, View} from "react-native";
import {BLUE_COLOR, FB_SPACER_REGULAR, getCircleCss, getFontCss, GRAY_BANNER_COLOR, WHITE_COLOR} from "../styles";

type AutoSizeTextType = {
    text: string
    title: string
    textSize?: number
    isExpendButton?: boolean
    onPress?: () => void
}

const AutoSizeText: React.FC<AutoSizeTextType> = ({text, onPress, title, textSize, isExpendButton = true}) => {
    return (
        <View style={{height: 'fit-content', width: '100%', marginTop: 50}}>
            <View style={{flexDirection: "row-reverse", width: '100%', margin: 10}}>
                <Text style={{...getFontCss(FB_SPACER_REGULAR, 37, BLUE_COLOR)}}>
                    {title}
                </Text>
                {isExpendButton && <View style={{
                    flexDirection: "row-reverse",
                    alignSelf: "flex-end",
                    alignItems: "center",
                    position: "absolute",
                    left: 0
                }}>
                    <TouchableOpacity style={{
                        ...getCircleCss(39, 39, 20),
                        backgroundColor: BLUE_COLOR,
                        alignItems: "center",
                        justifyContent: "center"
                    }} onPress={onPress}>
                        <Text style={{...getFontCss(FB_SPACER_REGULAR, 33, WHITE_COLOR)}}>+</Text>
                    </TouchableOpacity>
                    <Text style={{...getFontCss(FB_SPACER_REGULAR, 20, BLUE_COLOR), marginHorizontal: 20}}>עוד...</Text>
                </View>}
            </View>
            <View style={{backgroundColor: GRAY_BANNER_COLOR, width: '100%', height: 'fit-content'}}>
                <Text style={{margin: 10, ...getFontCss(FB_SPACER_REGULAR, textSize || 24, '#000000')}}>
                    {text}
                </Text>
            </View>
        </View>
    )
}

export default AutoSizeText