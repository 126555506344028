import React from "react";
import {StyleSheet, Text, TextStyle, TouchableOpacity, View, ViewStyle} from "react-native";
import {getCircleCss, getFontCss, PINK_COLOR, WHITE_COLOR} from "../styles";
import {ContainerWithText} from "../genaricComponents";
import {NON_NUMBER} from "../consts";

let _ = require("lodash")
type LottoNumber = number | string

export type LottoNumberType = {
    lottoNumber: LottoNumber,
    ballStyle: ViewStyle,
    ballTextStyle: TextStyle,
}
export type TouchableLottoNumberType = LottoNumberType & {
    onPress: () => void,
    isClicked: boolean,
    disabled: boolean
    removeNumber: () => void
}
export type SideBallType = { multiple: number, tableNumber: number }
export type LottoRowNumbersType = {
    rowLength: number,
    numbers: LottoNumber[],
    style: {},
    ballStyle: {},
    ballTextStyle: {}
}
export type TouchableLottoRowBallsType = LottoRowNumbersType & {
    onPress: (event: LottoNumber) => void,
    disabled: boolean,
    removeNumber: (number: LottoNumber) => void,
    isClicked: (number: LottoNumber) => boolean
}


export const SideBall = ({multiple, tableNumber}: SideBallType) => {

    const getTopCss = () => {
        return 1 + tableNumber * 6.7
    }

    const style = StyleSheet.create({
        leftBall: {
            position: 'absolute',
            backgroundColor: PINK_COLOR,
            borderStyle: "solid",
            borderColor: WHITE_COLOR,
            borderWidth: 1,
            ...getCircleCss(28, 28, 14),
            left: '12%',
            top: `${getTopCss()}%`,
            justifyContent: "center",
            alignItems: "center"
        },
        leftBallText: {
            ...getFontCss('Myriad Pro', 13, WHITE_COLOR)
        }
    })

    return (
        <ContainerWithText text={`X${multiple}`} textStyle={style.leftBallText} containerStyle={style.leftBall}/>
    )
}

export const LottoNumber = ({lottoNumber, ballStyle, ballTextStyle}: LottoNumberType) => {
    return (
        <View style={ballStyle}>
            <Text style={ballTextStyle}>{lottoNumber === NON_NUMBER ? '' : lottoNumber}</Text>
        </View>
    )
}

export const TouchableLottoNumber = ({
                                         lottoNumber,
                                         ballStyle,
                                         ballTextStyle,
                                         onPress,
                                         disabled,
                                         isClicked,
                                         removeNumber
                                     }: TouchableLottoNumberType) => {

    const clickedButtonCss = isClicked ? {
        borderWidth: '1px',
        borderColor: 'rgba(255, 255, 255, 0.5)',
        backgroundColor: ballTextStyle.color,
        boxShadow: '0 0 2px 2px rgba(255, 255, 255, 0.5)'
    } : {}
    const clickedTextCss = isClicked ? {
        color: ballStyle.backgroundColor
    } : {}

    const style = StyleSheet.create({
        ballStyle: {
            ...ballStyle,
            ...clickedButtonCss
        },
        ballTextStyle: {...ballTextStyle, ...clickedTextCss}
    })

    const onClick = () => {
        if (isClicked) {
            removeNumber()
        } else {
            onPress()
        }
    }

    return (
        <TouchableOpacity style={style.ballStyle} onPress={onClick} disabled={disabled && !isClicked}>
            <Text style={style.ballTextStyle}>{lottoNumber.toString()}</Text>
        </TouchableOpacity>
    )
}

export const LottoRowBalls = ({rowLength, numbers, style, ballTextStyle, ballStyle}: LottoRowNumbersType) => {

    const getLottoNumbers = () => {
        return _.range(0, rowLength).map((number: number) => <LottoNumber
            lottoNumber={number < rowLength ? numbers[number] : NON_NUMBER} ballStyle={ballStyle}
            ballTextStyle={ballTextStyle}/>)
    }

    return (
        <View style={style}>
            {getLottoNumbers()}
        </View>
    )
}

export const TouchableLottoRowBalls = ({
                                           numbers,
                                           style,
                                           ballTextStyle,
                                           ballStyle,
                                           onPress,
                                           disabled,
                                           removeNumber,
                                           isClicked
                                       }: TouchableLottoRowBallsType) => {


    const getLottoNumbers = () => {
        return numbers.map((number: LottoNumber) => <TouchableLottoNumber lottoNumber={number}
                                                                          ballStyle={ballStyle}
                                                                          ballTextStyle={ballTextStyle}
                                                                          onPress={() => onPress(number)}
                                                                          isClicked={isClicked(number)}
                                                                          disabled={disabled}
                                                                          removeNumber={() => removeNumber(number)}/>)
    }

    return (
        <View style={style}>
            {getLottoNumbers()}
        </View>
    )
}