import React from "react";
import {Image, Linking, Text, TouchableOpacity, View} from "react-native";
import { FACEBOOK_URL, INSTAGRAM_URL } from "../consts";
import {BLUE_COLOR, FB_SPACER_REGULAR, getFontCss} from "../styles";


type SocialMediaType = {}

const SocialMedia: React.FC<SocialMediaType> = () => {
    const handleClick = (url:string) => {
        window.open(url, '_blank');
    };
    return (
        <View style={{height: 141, paddingHorizontal: '10.9%', paddingTop: 29}}>
            <View style={{flexDirection: "row-reverse", width: '100%'}}>
                <FacebookLink handleClick={handleClick}/>
                <View style={{width: '1%'}}/>
                <InstergramLink  handleClick={handleClick}/>
            </View>
            <View style={{flexDirection: "row-reverse", marginTop: 20}}>
                <View>
                    <Text style={{...getFontCss(FB_SPACER_REGULAR, 12, BLUE_COLOR)}}>אתר זה מעניק שירות של משלוח טפסי
                        הגרלה של מפעל הפיס כגוף עצמאי.</Text>
                    <Text style={{...getFontCss(FB_SPACER_REGULAR, 12, BLUE_COLOR)}}>אסורה מכירה למי שטרם מלאו לו
                        18.</Text>
                </View>
                <Text style={{...getFontCss(FB_SPACER_REGULAR, 12, BLUE_COLOR), position: "absolute", left: 0}}>כל
                    הזכויות שמורות ל״אש.הר שיווק מתקדם בע״מ״</Text>
            </View>
        </View>
    )
}

type FacebookLinkType = {
    handleClick: (url:string) => void;
}

const FacebookLink: React.FC<FacebookLinkType> = ({handleClick}) => {  
    return (
        <View style={{flexDirection: "row-reverse", alignItems: "center"}}>
            <TouchableOpacity onPress={()=> {  handleClick(FACEBOOK_URL) } }>
                <Image source={require('../../../assets/Facebook.png')}
                       style={{height: 31, width: 31, marginLeft: '10%'}}/>
            </TouchableOpacity>
            <TouchableOpacity onPress={()=> {  handleClick(FACEBOOK_URL) }}>
                <Text style={{...getFontCss(FB_SPACER_REGULAR, 16, BLUE_COLOR)}}>העמוד שלנו בפייסבוק</Text>
            </TouchableOpacity>
        </View>
    )
}

type InstergramLinkType = {
    handleClick: (url:string) => void;
}

const InstergramLink: React.FC<InstergramLinkType> = ({handleClick}) => {
    return (
        <View style={{flexDirection: "row-reverse", alignItems: "center"}}>
            <TouchableOpacity onPress={()=> {  handleClick(INSTAGRAM_URL) }}>
                <Image source={require('../../../assets/Instegram.png')}
                       style={{height: 31, width: 31, marginLeft: '10%'}}/>
            </TouchableOpacity>
            <TouchableOpacity onPress={()=> {  handleClick(INSTAGRAM_URL) }}>
                <Text style={{...getFontCss(FB_SPACER_REGULAR, 16, BLUE_COLOR)}}>העמוד שלנו באינסטגרם</Text>
            </TouchableOpacity>
        </View>
    )
}


export default SocialMedia