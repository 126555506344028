import React from 'react';
import {StyleSheet, Text, View} from "react-native";
import {FB_SPACER_REGULAR, getBorder, getCircleCss, getFontCss, WHITE_COLOR} from "../../../styles";
import {ContainerWithText} from "../../../genaricComponents";


type InstructionStepType = {
    marginTop?: string | number,
    containerHeight: number | string,
    stepText: string, stepNumber: number,
    stepColor: string,
    isMobile?: boolean
}
type SubInstructionsTextType = {
    text: string,
    marginRight?: string | number
    marginBottom?: string | number
    isMobile?: boolean
}
type StepCircleType = {
    text: string | number
    textColor: string
}
type StepBigTextType = {
    text: string
    isMobile?: boolean
}
type FormInstructionsType = {
    mainTitle: string,
    subTitle: string,
    bacgroundColor: string,
    isMobile?: boolean
}


export const StepCircle = ({text, textColor}: StepCircleType) => {

    const style = StyleSheet.create({
        stepCircleContainer: {
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: WHITE_COLOR,
            ...getCircleCss(72, 72, 36),
            marginRight: '3%'
        },
        stepCircleText: {
            ...getFontCss(FB_SPACER_REGULAR, 63, textColor),
        },
    })

    return (
        <ContainerWithText text={String(text)} textStyle={style.stepCircleText}
                           containerStyle={style.stepCircleContainer}/>
    )
}

export const StepBigText = ({text, isMobile}: StepBigTextType) => {
    const style = StyleSheet.create({
        firstStepText: {
            ...getFontCss(FB_SPACER_REGULAR, 41, WHITE_COLOR),
            marginRight: 7
        },
        firstStepTextMobile: {
            ...getFontCss(FB_SPACER_REGULAR, 52, WHITE_COLOR),
            marginRight: 7
        }
    })
    return <Text style={isMobile ? style.firstStepTextMobile : style.firstStepText}>{text}</Text>
}

export const InstructionStep = ({stepNumber, stepText, containerHeight, marginTop, stepColor, isMobile}: InstructionStepType) => {

    const style = StyleSheet.create({
        instructionsContainer: {
            flexDirection: "row-reverse",
            alignItems: "center",
            height: containerHeight,
            marginTop: marginTop || 0,
            ...getBorder(),
        }
    })

    return (
        <View style={style.instructionsContainer}>
            <StepCircle text={stepNumber} textColor={stepColor}/>
            <StepBigText text={stepText} isMobile={isMobile}/>
        </View>
    )
}

export const SubInstructionsText = ({text, marginRight, marginBottom, isMobile}: SubInstructionsTextType) => {
    const style = StyleSheet.create({
        firstStep: {
            ...getFontCss(FB_SPACER_REGULAR, 28, WHITE_COLOR),
            marginRight: marginRight || 0,
            marginBottom: marginBottom || 0
        },
        firstStepMobile: {
            ...getFontCss(FB_SPACER_REGULAR, 44, WHITE_COLOR),
            marginRight: marginRight || 0,
            marginBottom: 10
        }
    })

    return <Text style={isMobile ? style.firstStepMobile : style.firstStep}>{text}</Text>
}

const FirstSectionInstructions = ({mainTitle, subTitle, bacgroundColor, isMobile}: FormInstructionsType) => {

    const style = StyleSheet.create({
        instructionsContainer: {
            flexDirection: "row-reverse",
            alignItems: "center",
            height: 84,
            ...getBorder(),
            backgroundColor: bacgroundColor
        },
        firstStepContainer: {
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: WHITE_COLOR,
            ...getCircleCss(72, 72, 36),
            marginRight: '3%'
        },
        firstStep: {
            ...getFontCss(FB_SPACER_REGULAR, 63, bacgroundColor),
        },
        firstStepText: {
            ...getFontCss(FB_SPACER_REGULAR, 41, WHITE_COLOR),
            marginRight: 7
        },
        choseNumbersInstructionsText: {
            ...getFontCss(FB_SPACER_REGULAR, 28, WHITE_COLOR),
            marginRight: '4%'
        },
        choseNumbersInstructionsContainer: {
            flexDirection: "row-reverse",
            height: 29,
            backgroundColor: bacgroundColor,
            ...getBorder()
        },
        choseNumbersInstructionsContainerMobile: {
            flexDirection: "row-reverse",
            backgroundColor: bacgroundColor,
            ...getBorder()
        }
    })

    return (
        <View>
            <InstructionStep containerHeight={84} stepNumber={1} stepText={mainTitle} stepColor={bacgroundColor} isMobile={isMobile}/>
            <View style={isMobile ? style.choseNumbersInstructionsContainerMobile : style.choseNumbersInstructionsContainer}>
                <SubInstructionsText marginRight={'4%'} text={subTitle} isMobile={isMobile}/>
            </View>
        </View>
    )
}


export default FirstSectionInstructions