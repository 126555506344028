import React from "react";
import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {CONTACT_US_PATH, HOME_PAGE_PATH, PRIVACY_POLICY_PATH, TERMS_PATH} from "../consts";
import {useHistory} from 'react-router-dom'
import {BLUE_COLOR, FB_SPACER_REGULAR, getFontCss} from "../styles";
import {PipeLine} from "../genaricComponents";

type BottomNavBarType = {}

const BottomNavBar: React.FC<BottomNavBarType> = () => {

    return (
        <View style={{
            flexDirection: "row-reverse",
            alignItems: "center",
            paddingVertical: 15,
            marginTop: 60,
            marginBottom: 30
        }}>
            <BottomNavBarLink text={'הצהרת נגישות'} path={HOME_PAGE_PATH}/>
            <PipeLine marginLeft={10} color={BLUE_COLOR} height={'100%'}/>
            <BottomNavBarLink text={'מדיניות פרטיות'} path={PRIVACY_POLICY_PATH}/>
            <PipeLine marginLeft={10} color={BLUE_COLOR} height={'100%'}/>
            <BottomNavBarLink text={'צור קשר'} path={CONTACT_US_PATH}/>
            <PipeLine marginLeft={10} color={BLUE_COLOR} height={'100%'}/>
            <BottomNavBarLink text={'תקנון'} path={TERMS_PATH}/>
        </View>
    )
}

type BottomNavBarLinkType = {
    text: string
    path: string
}

const BottomNavBarLink: React.FC<BottomNavBarLinkType> = ({text, path}) => {

    const style = StyleSheet.create({
        linkStyle: {
            marginHorizontal: 10
        },
        linkTextStyle: {
            ...getFontCss(FB_SPACER_REGULAR, 16, BLUE_COLOR)
        }
    })
    const history = useHistory()

    return (
        <TouchableOpacity style={style.linkStyle} onPress={() => history.push(path)}>
            <Text style={style.linkTextStyle}>{text}</Text>
        </TouchableOpacity>
    )
}

export default BottomNavBar