import {
    ChanceMarksType,
    LottoMarksType,
    MyFormsHistoryType,
    MyPullingsType,
    SevenMarksType,
    ShitatiMarksType
} from "./types";
import {MyPaymentHistoryType} from "./mySpace/myPaymentHistory";
import {MyActiveFormsType} from "./mySpace/myActiveForms";
import {MyWinsType} from "./mySpace/myWins";
import {MarksType123} from "./lotto/regular_lotto/regular123Form";
import { AdminFromsTypePagination } from "./server/admin";

let _ = require('lodash')

// App pathes.
export const HOME_PAGE_PATH = '/'
export const SIGN_IN_PATH = '/SignIn'
export const SIGN_UP_PATH = '/SignUp'
export const ABOUT_PATH = '/about'
export const ARTICLES_PATH = '/articles'
export const CREDIT_TRANSACTION_PATH = '/transaction'
export const RESULTS_PATH = '/results'
export const CONTACT_US_PATH = '/contact_us'
export const TERMS_PATH = '/terms'
export const PRIVACY_POLICY_PATH = '/privacy-policy'
export const FORGOT_PASSWORD_PATH = '/ForgotPassword'
export const MY_SPACE_PATH = '/my_space'
export const RAV_CHANCE_PATH = '/games/chance/type/rav'
export const LOTTO_SHITATI_PATH = '/games/lotto/type/shitati'
export const REGULAR_LOTTO_PATH = '/games/lotto/type/regular'
export const REGULAR_CHANCE_PATH = '/games/chance/type/regular'
export const CHANCE_SHITATI_PATH = '/games/chance/type/shitati'
export const LOTTO_SHITATI_HAZAK_PATH = '/games/lotto/type/shitati_hazak'
export const REGULAR_777_PATH = '/games/777/type/regular'
export const SHITATI_777_PATH = '/games/777/type/shitati'
export const FORM_123_PATH = '/games/123/type/regular'
export const ADMINISTRATION_PATH = '/administration'
export const OWNERS_PATH = '/owners'
export const RESELLERS_PATH = '/resellers'
export const FACEBOOK_URL = 'https://www.facebook.com/Lotomatic-106951804597405/'
export const INSTAGRAM_URL = 'https://instagram.com/lotto_matic?utm_medium=copy_link'

//Popup variable
export const SHOW_FIRST_TIME_POPUP = true

//paths params
export const ADMIN_WAITING = 0
export const ADMIN_PRINTED = 1
export const ADMIN_BATCH = 2
export const ADMIN_SCANNED = 3
export const ADMIN_WINNERRS = 4
export const ADMIN_LOTTERIES = 5
export const ADMIN_AGENTS = 6
export const ADMIN_RETURNS = 7

export const LOTTO_PARAM = 0
export const EXTRA_PARAM = 1
export const CHANCE_PARAM = 2
export const REGULAR_777_PARAM = 3
export const REGULAR_123_PARAM = 4

export const ID = 'id'
export const LAX = 'lax'
export const LEAF = 'leaf'
export const ROWS = 'rows'
export const EXTRA = 'extra'
export const CARDS = 'cards'
export const HEART = 'heart'
export const CLOVER = 'clover'
export const TABLES = 'tables'
export const STRICT = 'strict'
export const HEADERS = 'headers'
export const DIAMOND = 'diamond'
export const NUMBERS = 'numbers'
export const FORM_777_TYPE = '777'
export const FORM_123_TYPE = '123'
export const TABLE_NUMBER = 'table_number'
export const FORM_TYPE = 'form_type'
export const MULTI_LOTTERY = 'multi_lottery'
export const STRONG_NUMBERS = 'strong_number'

export const PARTICIPANT_AMOUNT = 'participant_amount'
export const NON_NUMBER = -1
export const INIT_TABLE_INDEX = 0
export const LOTTO_NUMBERS_LENGTH = 6
export const LOTTTO_SHITATI_TABLES_NUMBER = 1
export const REGULAR_LOTTTO_TABLES_NUMBER = 14
export const LOTTTO_SHITATI_HAZAK_TABLES_NUMBER = 1
export const LOTTTO_DOUBLE_SHITATI_TABLES_NUMBER = 1
export const REGULAR_DOUBLE_LOTTTO_TABLES_NUMBER = 10
export const LOTTTO_DOUBLE_SHITATI_HAZAK_TABLES_NUMBER = 1


// Hebrew section

export const HEBREW_GAME = 'משחק'
export const HEBREW_FORM = 'טופס'
export const HEBREW_LOTTO = 'לוטו'
export const HEBREW_NUMBER = 'מספר'
export const HEBREW_REFUND = 'הוחזר'
export const HEBREW_CHARGED = 'חויב'
export const HEBREW_STATUS = 'סטטוס'
export const FORM_HEBREW = 'טופס'
export const HEBREW_CHANCE = "צ'אנס"
export const HEBREW_COMPLETED = 'בוצע'
export const HEBREW_UNCHARGED = 'לא חויב'
export const HEBREW_NO_REFUND = 'לא הוחזר'
export const HEBREW_WIN_NUMBER = 'מספר זכיה'
export const HEBREW_FORM_COST = 'עלות הטופס'
export const HEBREW_NOT_COMPLETED = 'לא בוצע'
export const HEBREW_MAIN_TITLE = 'מלא את הטופס'
export const DUPLICATE_OR_WATCH = 'שכפל או צפה'
export const HEBREW_PULL_STATUS = 'סטטוס משיכה'
export const HEBREW_SUM_IN_SHEK = 'סה"כ בשקלים'
export const HEBREW_DUPLICATE_FORM = 'שכפל טופס'
export const HEBREW_DATE_AND_TIME = 'שעת שליחת הטופס במערכת'
export const HEBREW_REFUND_STATUS = 'סטטוס החזרה'
export const HEBREW_SECOND_STEP_TITLE = 'שדרג את הטופס'
export const HEBREW_PAYMENT_HISTORY_STATUS = 'סטטוס חיוב'
export const HEBREW_GAME_AND_LOTTERY_NUMBER = 'משחק ומס׳ הגרלה'
export const HEBREW_SUCCESS_CHARGE = 'טעינת קרדיט הצליחה'
export const HEBREW_ERROR_CHARGE = 'טעינת קרדיט נכשלה'
export const HEBREW_SUCCESS_REFUND = 'משיכת קרדיט הצליחה'
export const HEBREW_ERROR_REFUND = 'משיכת קרדיט נכשלה'


export const ENGLISH_TO_HEBREW_STATUS = (status:string)=>{
    switch(status){
        case 'waiting':
            return 'ממתין'
        case 'declined':
            return 'סורב'
        case 'approved':
            return 'אושר'
        default:
            return ''
    }
}


export const REGULAR_LOTTO_INIT_FORM: LottoMarksType = {
    [TABLES]: new Array(REGULAR_LOTTTO_TABLES_NUMBER).fill({}).map(() => _.cloneDeep({
        [NUMBERS]: [],
        [STRONG_NUMBERS]: []
    })),
    [EXTRA]: false,
    [MULTI_LOTTERY]: NON_NUMBER
}

export const REGULAR_DOUBLE_LOTTO_INIT_FORM: LottoMarksType = {
    [TABLES]: new Array(REGULAR_DOUBLE_LOTTTO_TABLES_NUMBER).fill({}).map(() => _.cloneDeep({
        [NUMBERS]: [],
        [STRONG_NUMBERS]: []
    })),
    [EXTRA]: false,
    [MULTI_LOTTERY]: NON_NUMBER
}

export const LOTTTO_SHITATI_INIT_FORM: ShitatiMarksType = {
    [TABLES]: new Array(LOTTTO_SHITATI_TABLES_NUMBER).fill({}).map(() => _.cloneDeep({
        [NUMBERS]: [],
        [STRONG_NUMBERS]: []
    })),
    [EXTRA]: false,
    [MULTI_LOTTERY]: NON_NUMBER,
    [FORM_TYPE]: '8'
}

export const LOTTTO_DOUBLE_SHITATI_INIT_FORM: ShitatiMarksType = {
    [TABLES]: new Array(LOTTTO_DOUBLE_SHITATI_TABLES_NUMBER).fill({}).map(() => _.cloneDeep({
        [NUMBERS]: [],
        [STRONG_NUMBERS]: []
    })),
    [EXTRA]: false,
    [MULTI_LOTTERY]: NON_NUMBER,
    [FORM_TYPE]: '8'
}

export const LOTTTO_SHITATI_HAZAK_INIT_FORM: ShitatiMarksType = {
    [TABLES]: new Array(LOTTTO_SHITATI_HAZAK_TABLES_NUMBER).fill({}).map(() => _.cloneDeep({
        [NUMBERS]: [],
        [STRONG_NUMBERS]: []
    })),
    [EXTRA]: false,
    [MULTI_LOTTERY]: NON_NUMBER,
    [FORM_TYPE]: '4'
}

export const LOTTTO_DOUBLE_SHITATI_HAZAK_INIT_FORM: ShitatiMarksType = {
    [TABLES]: new Array(LOTTTO_DOUBLE_SHITATI_HAZAK_TABLES_NUMBER).fill({}).map(() => _.cloneDeep({
        [NUMBERS]: [],
        [STRONG_NUMBERS]: []
    })),
    [EXTRA]: false,
    [MULTI_LOTTERY]: NON_NUMBER,
    [FORM_TYPE]: '4'
}

export const REGULAR_CHANCE_INIT_FORM: ChanceMarksType = {
    [FORM_TYPE]: 1,
    [PARTICIPANT_AMOUNT]: NON_NUMBER,
    [MULTI_LOTTERY]: NON_NUMBER,
    [CARDS]: {
        [LEAF]: [],
        [HEART]: [],
        [DIAMOND]: [],
        [CLOVER]: []
    }
}

export const RAV_CHANCE_INIT_FORM: ChanceMarksType = {
    [FORM_TYPE]: 4,
    [PARTICIPANT_AMOUNT]: NON_NUMBER,
    [MULTI_LOTTERY]: NON_NUMBER,
    [CARDS]: {
        [LEAF]: [],
        [HEART]: [],
        [DIAMOND]: [],
        [CLOVER]: []
    }
}

export const SHITATI_CHANCE_INIT_FORM: ChanceMarksType = {
    [FORM_TYPE]: 1,
    [PARTICIPANT_AMOUNT]: NON_NUMBER,
    [MULTI_LOTTERY]: NON_NUMBER,
    [CARDS]: {
        [LEAF]: [],
        [HEART]: [],
        [DIAMOND]: [],
        [CLOVER]: []
    }
}

type Pagination = {
    cur_page: number,
    has_next: boolean,
    has_prev: boolean,
    total: number
}

export const DEFAULT_PAGINATION_DATA: Pagination = {
    cur_page: 1,
    has_next: true,
    has_prev: false,
    total: 0
}
export const DEFAULT_REFUND_REQUESTS_DATA: AdminFromsTypePagination = {
    headers: ['id', 'שם משתמש', 'תעודת זהות', 'סה"כ בשקלים', 'תאריך ושעה', 'סטטוס משיכה','אישור'],
    rows: [],
    ...DEFAULT_PAGINATION_DATA
}

export const DEFAULT_PULLINGS_DATA: MyPullingsType = {
    headers: [HEBREW_NUMBER, HEBREW_DATE_AND_TIME, HEBREW_SUM_IN_SHEK, FORM_HEBREW],
    rows: []
}

export const DEFAULT_REDUNDS_DATA: MyPullingsType = {
    headers: [HEBREW_NUMBER, HEBREW_DATE_AND_TIME, HEBREW_SUM_IN_SHEK, FORM_HEBREW],
    rows: []
}

export const DEFAULT_PAYMENT_HISTORY_DATA: MyPaymentHistoryType = {
    headers: [HEBREW_NUMBER, HEBREW_DATE_AND_TIME, HEBREW_SUM_IN_SHEK, FORM_HEBREW],
    rows: []
}

export const DEFAULT_ACTIVE_FORMS_DATA: MyActiveFormsType = {
    headers: [HEBREW_NUMBER, HEBREW_DATE_AND_TIME, HEBREW_FORM_COST, HEBREW_STATUS, FORM_HEBREW],
    rows: []
}
export const DEFAULT_ACTIVE_FORMS_HISTORY: MyFormsHistoryType = {
    headers: [HEBREW_GAME_AND_LOTTERY_NUMBER, HEBREW_DATE_AND_TIME, HEBREW_FORM_COST, HEBREW_DUPLICATE_FORM],
    rows: []
}
export const DEFAULT_WINS_DATA: MyWinsType = {
    headers: [HEBREW_WIN_NUMBER, HEBREW_DATE_AND_TIME, HEBREW_SUM_IN_SHEK, HEBREW_GAME, FORM_HEBREW],
    rows: []
}
export const REGULAR_SEVEN_INIT_FORM: SevenMarksType = {
    [TABLES]: new Array(3).fill({}).map(() => _.cloneDeep({
        [NUMBERS]: [],
    })),
    [MULTI_LOTTERY]: NON_NUMBER,
    [FORM_TYPE]: 7
}
export const SHITATI_SEVEN_INIT_FORM: SevenMarksType = {
    [TABLES]: new Array(1).fill({}).map(() => _.cloneDeep({
        [NUMBERS]: [],
    })),
    [MULTI_LOTTERY]: NON_NUMBER,
    [FORM_TYPE]: 8
}

export const INIT_FORM_123: MarksType123 = {
    [TABLES]: new Array(5).fill({}).map(() => _.cloneDeep({
        [NUMBERS]: {"1": NON_NUMBER, "2": NON_NUMBER, "3": NON_NUMBER},
    })),
    [MULTI_LOTTERY]: NON_NUMBER,
    [PARTICIPANT_AMOUNT]: NON_NUMBER,
}
