export class AlertHelper {
    static customAlert:any;

    static setAlert(customAlert:any) {
        this.customAlert = customAlert;
    }

    static show(text : string, onPressOk : ()=>void, src ?: string) {
        if (this.customAlert) {
            this.customAlert._show(text, onPressOk, src);
        }
    }

    static timer(time:number){
        setTimeout(this.customAlert._hide(), time)
    }

    static hide() {
        if (this.customAlert) {
            this.customAlert._hide();
        } 
    }
}