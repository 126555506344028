import React, {useState} from "react";
import {StyleSheet, Text, TextStyle, TouchableOpacity, View} from "react-native";
import {
    BLUE_COLOR,
    FB_SPACER_BOLD,
    FB_SPACER_REGULAR,
    getBorder,
    getFontCss,
    getHeightWidthCss,
    GRAY_COLOR,
    LIGHT_BLUE_COLOR,
    WHITE_COLOR
} from "../styles";
import {Line} from "../genaricComponents";
import {MyPullingsTable} from "./myPullings";
import MyRefundsTable from "./myRefunds";
import MyPaymentHistoryTable from "./myPaymentHistory";
import MyActiveFormsTable from "./myActiveForms";
import MyFormsHistoryTable from "./myFormsHistory";
import MyWinsTable from "./myWins";
import {Button, Modal} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import PaymentIcon from '@material-ui/icons/Payment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ChargeButton from "./chargeButton";
import PullButton from "./pullButton";
import {Auth} from "aws-amplify";

type LinksSectionType = {
    links: string[],
    title: string,
    onLinkClick: (text: string) => void,
    pressedHeader: string
}

const PullCredit = () => {
    const useStyles = makeStyles((theme) => ({
        button: {
            margin: theme.spacing(1),
            height: 40,
            width: 120,
            backgroundColor: BLUE_COLOR
        }
    }));
    const classes = useStyles()

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return <>
        <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            startIcon={<AccountBalanceIcon/>}
            onClick={handleOpen}
        >
            משוך קרדיט
        </Button>
    </>
}


const ChargeCredit = () => {
    const useStyles = makeStyles((theme) => ({
        button: {
            margin: theme.spacing(1),
            height: 40,
            width: 120,
            backgroundColor: BLUE_COLOR
        }
    }));
    const classes = useStyles()
    return <>
        <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            startIcon={<PaymentIcon/>}
        >
            טען קרדיט
        </Button>
    </>
}

const MySpaceHeader = () => {
    const [username, setUserName] = React.useState("")
    const [email, setEmail] = React.useState("")
    let [phone_number, setPhoneNumber] = React.useState("")

    const useStyles = makeStyles((theme) => ({
        button: {
            margin: theme.spacing(1),
            height: 40,
            width: 120,
            backgroundColor: BLUE_COLOR
        }
    }));
    const classes = useStyles()
    const style = StyleSheet.create({
        container: {
            ...getHeightWidthCss(230, '100%'),
            backgroundColor: LIGHT_BLUE_COLOR,
            alignItems: "center",
            marginBottom: 15,
            flexDirection: "row-reverse"
        },
        text: {
            marginRight: '11.4%',
            ...getFontCss(FB_SPACER_REGULAR, 52, WHITE_COLOR)
        },
        smallText: {
            // marginTop: "2%",
            ...getFontCss(FB_SPACER_REGULAR, 22, WHITE_COLOR)
        }
    })

    const getUserInfo = async () => {
        let userInfo = await Auth.currentUserInfo()
        setUserName(userInfo.attributes.name)
        setEmail(userInfo.attributes.email)
        let number = userInfo.attributes.phone_number.replace('+972', '0')
        setPhoneNumber(number)
    }
    getUserInfo()
    
    return (
        <View style={style.container}>
            <Text style={style.text}>האיזור האישי שלי</Text>
            <View style={{flexDirection:"column", position: "absolute", right : "12%", bottom: "0"}}>
                <Text style={style.smallText}>שם משתמש : {username}</Text>
                <Text style={style.smallText}>כתובת מייל : {email}</Text>
                <Text style={style.smallText}>מספר טלפון : {phone_number}</Text>
            </View>
            <View style={{flexDirection: "row-reverse", position: "absolute", left: '8%'}}>
                <PullButton/>
                <ChargeButton/>
            </View>
        </View>
    )
}

const MySpaceLink = ({text, textStyle, onPress}: { textStyle: TextStyle, text: string, onPress: () => void }) => {
    return (
        <TouchableOpacity onPress={onPress}>
            <Text style={textStyle}>{text}</Text>
        </TouchableOpacity>
    )
}

const LinksSection = ({links, title, onLinkClick, pressedHeader}: LinksSectionType) => {

    const style = StyleSheet.create({
        container: {
            marginLeft: 5,
            width: '19.2%',
            ...getBorder()
        },
        link: {
            marginBottom: 4,
            ...getFontCss(FB_SPACER_BOLD, 15, BLUE_COLOR,)
        },
        pressedLink: {
            marginBottom: 4,
            ...getFontCss(FB_SPACER_BOLD, 15, LIGHT_BLUE_COLOR)
        },
        header: {
            height: 49,
            backgroundColor: pressedHeader === title ? LIGHT_BLUE_COLOR : BLUE_COLOR,
            justifyContent: "center",
            alignItems: "center"
        },
        headerTitle: {
            ...getFontCss(FB_SPACER_REGULAR, 25, WHITE_COLOR)
        },
        linksContainer: {
            height: 88,
            paddingTop: 8,
            paddingRight: 12,
            backgroundColor: pressedHeader === title ? GRAY_COLOR : undefined
        }

    })

    return (
        <View style={style.container}>
            <View style={style.header}>
                <Text style={style.headerTitle}>{title}</Text>
            </View>
            <View style={style.linksContainer}>
                {links.map((text) => <MySpaceLink textStyle={style.link} text={text}
                                                  onPress={() => onLinkClick(text)}/>)}
            </View>
        </View>
    )
}

const MySpaceBody = () => {

    const style = StyleSheet.create({
        container: {
            ...getHeightWidthCss(585, '100%'),
            ...getBorder()

        },
        text: {
            marginRight: '11.4%',
            marginTop: 52,
            ...getFontCss(FB_SPACER_REGULAR, 52, WHITE_COLOR)
        },
        tableText: {
            ...getFontCss(FB_SPACER_BOLD, 31, LIGHT_BLUE_COLOR)
        }
    })

    const linksToComponents: { [title: string]: { [key: string]: JSX.Element } } = {
        'טפסים': {
            'טפסים פעילים': <MyActiveFormsTable/>,
            'היסטוריית שליחות': <MyFormsHistoryTable/>,
            'הזכיות שלי': <MyWinsTable/>
        },
        'תשלומים': {
            'משיכות': <MyPullingsTable/>,
            'היסטוריית תשלומים': <MyPaymentHistoryTable/>,
        },
        // 'החזרים': {'היסטוריית החזרים': <MyRefundsTable/>}
    }
    const [link, setLink] = useState('טפסים פעילים')
    const [clickedTitle, setTitle] = useState('טפסים')

    return (
        <View style={{height: 'fit-content'}}>
            <View style={{height: 137, flexDirection: "row-reverse", marginBottom: 13}}>
                {
                    Object.keys(linksToComponents).map(title => <LinksSection
                        links={Object.keys(linksToComponents[title])}
                        title={title}
                        pressedHeader={clickedTitle}
                        onLinkClick={(link: string) => {
                            setLink(link)
                            setTitle(title)
                        }}/>)
                }
            </View>
            <View style={{
                paddingRight: '4.2%',
                width: '100%',
                paddingTop: 17
            }}>
                <View style={{marginBottom: 25, ...getBorder()}}>
                    <Text style={style.tableText}>{link}</Text>
                </View>
                <View style={{flexDirection: "row-reverse"}}>
                    {
                        linksToComponents[clickedTitle][link]
                    }
                </View>
            </View>

        </View>
    )
}

const MySpace = () => {

    const style = StyleSheet.create({
        container: {
            ...getHeightWidthCss(585, '100%'),
            paddingRight: '8%',
            paddingLeft: '8%',
            ...getBorder()
        },
        text: {
            marginRight: '11.4%',
            marginTop: 52,
            ...getFontCss(FB_SPACER_REGULAR, 52, WHITE_COLOR)
        }
    })

    return (
        <View>
            <MySpaceHeader/>
            <Line height={4} backgroundColor={LIGHT_BLUE_COLOR}/>
            <View style={style.container}>
                <MySpaceBody/>
            </View>
        </View>
    )
}


export default MySpace
