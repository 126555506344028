import {makeStyles} from "@material-ui/core/styles";
import {
    BLUE_COLOR,
    FB_SPACER_BOLD,
    FB_SPACER_REGULAR,
    getCircleCss,
    getFontCss,
    LIGHT_BLUE_COLOR,
    SUCCESS_GREEN_COLOR,
    WHITE_COLOR
} from "../styles";
import React, {useCallback, useContext, useState, useEffect} from "react";
import {Text, TouchableOpacity, View} from "react-native";
import {Paper, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import {useAsync} from "../server/utils";
import {getUserPaymentHistory} from "../server/mySpaceApi";
import {
    DEFAULT_PAYMENT_HISTORY_DATA,
    HEADERS,
    HEBREW_CHARGED,
    HEBREW_DATE_AND_TIME,
    HEBREW_NUMBER,
    HEBREW_PAYMENT_HISTORY_STATUS,
    HEBREW_SUM_IN_SHEK,
    ID,
    ROWS
} from "../consts";
import {MyPullingsHeadersType} from "../types";
import {UserContext} from "../appSwitch";
import {DownloadReceipt} from "../administration/adminPage";

let _ = require('lodash')

type MyPaymentHistoryTypeHeaders = MyPullingsHeadersType
export type MyPaymentHistoryRowsType = {
    [HEBREW_SUM_IN_SHEK]: number
    [HEBREW_DATE_AND_TIME]: string,
    [HEBREW_SUM_IN_SHEK]: number,
    [HEBREW_PAYMENT_HISTORY_STATUS]: string
    [ID]: string
}[]
export type MyPaymentHistoryType = {
    headers: MyPaymentHistoryTypeHeaders,
    rows: MyPaymentHistoryRowsType,
    cur_page: number,
    total: number,
    has_next: boolean,
    has_prev: boolean,
}

const MyPaymentHistoryTableHead = ({headers}: { headers: MyPaymentHistoryTypeHeaders }) => {
    const useStyles = makeStyles((theme) => ({
        headerCell: {
            background: BLUE_COLOR,
        }
    }));
    const classes = useStyles()

    return (
        <TableHead>
            <TableRow>
                {
                    headers.map((header: string) => <TableCell align={"right"}
                                                               className={classes.headerCell}>
                        {
                            <Text
                                style={{...getFontCss(FB_SPACER_REGULAR, 21, WHITE_COLOR)}}>{header}</Text>
                        }
                    </TableCell>)
                }

            </TableRow>
        </TableHead>
    )
}

const MyPaymentHistoryTableBody = ({
                                       page,
                                       rowsPerPage,
                                       rows,
                                       headers
                                   }: { page: number, rowsPerPage: number, rows: MyPaymentHistoryRowsType, headers: MyPaymentHistoryTypeHeaders }) => {

    const style: { text: object, buttonText: object, button: object } = {
        text: {...getFontCss(FB_SPACER_REGULAR, 21, WHITE_COLOR)},
        buttonText: {...getFontCss(FB_SPACER_BOLD, 15, WHITE_COLOR)},
        button: {
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: '2%', ...getCircleCss(37, 57, 6),
            borderWidth: 1,
            borderColor: WHITE_COLOR,
            borderStyle: "solid"
        }
    }

    return (
        <TableBody>
            {
                rows.map((row) =>
                    <TableRow>
                        <TableCell align={"right"}>
                            <Text style={style.text}>
                                {row[headers[0]]}
                            </Text>
                        </TableCell>
                        <TableCell align={"right"}>
                            <Text style={style.text}>
                                {row[headers[1]]}
                            </Text>
                        </TableCell>
                        <TableCell align={"right"}>
                            <View style={{flexDirection: "row", alignItems: "center"}}>
                                <Text style={style.text}>
                                    {row[headers[2]]}
                                </Text>
                            </View>
                        </TableCell>
                        <TableCell align={"right"}>
                            <Text style={style.text}>
                            {row[HEBREW_SUM_IN_SHEK]}
                            </Text>
                        </TableCell>
                        <TableCell align={"right"}>
                            <Text style={style.text}>
                            {row[HEBREW_PAYMENT_HISTORY_STATUS]}
                            </Text>
                        </TableCell>
                    </TableRow>
                )
            }
        </TableBody>
    )
}

export const MyPaymentHistoryTable = () => {
    const useStyles = makeStyles((theme) => ({
        paper: {
            marginBottom: theme.spacing(2),
            backgroundColor: LIGHT_BLUE_COLOR
        },
        tablePagination: {
            borderBottom: "none",
            ...getFontCss(FB_SPACER_REGULAR, '0.875rem', WHITE_COLOR)
        },
        headerCell: {
            background: BLUE_COLOR,
        }
    }));
    const classes = useStyles()
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const {jwtToken} = useContext(UserContext)
    const [data, setData] = useState<MyPaymentHistoryType>(DEFAULT_PAYMENT_HISTORY_DATA)
    const getPaymentHistoryForms = useCallback(async () => {
        if (jwtToken) {
            return await getUserPaymentHistory(jwtToken, page, rowsPerPage)
        }
    }, [jwtToken, page, rowsPerPage])

    useEffect(() => {
      
        getPaymentHistoryForms().then(res=> {
            setData(res)
        })
    },[page, rowsPerPage]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage+1);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    return (
        <View style={{width: '100%'}}>
            <Paper dir={"rtl"} className={classes.paper}>
               {data &&  <TableContainer>
                    <Table size={'medium'}>
                        <MyPaymentHistoryTableHead headers={data[HEADERS]}/>
                        <MyPaymentHistoryTableBody page={page} rowsPerPage={rowsPerPage} rows={data[ROWS]}
                                                   headers={data[HEADERS]}/>
                    </Table>
                </TableContainer>}
              {data &&   <TablePagination
                    dir={"ltr"}
                    width={'inherit'}
                    rowsPerPageOptions={[10, 25]}
                    count={data.total}
                    rowsPerPage={rowsPerPage}
                    page={page-1}
                    labelRowsPerPage={'שורות'}
                    className={classes.tablePagination}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    nextIconButtonProps={{ disabled: !data.has_next }}
                    backIconButtonProps={{ disabled: !data.has_prev }}
                    labelDisplayedRows={({ from, to, count }) => `סה"כ: ${ count } תוצאות מציג: ${ from } - ${ to } `}
                />}

            </Paper>
        </View>
    );
}

export default MyPaymentHistoryTable