import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {Button, ButtonGroup, TextField} from "@material-ui/core";
import {BLUE_COLOR, FB_SPACER_BOLD, FB_SPACER_REGULAR, getFontCss} from "../styles";
import PaymentIcon from "@material-ui/icons/Payment";
import {StyleSheet, Text, View} from "react-native";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import {UserContext} from "../appSwitch";
import {charge, charge_token, HasTokenType, has_credit_token, SuccessType} from "../server/payment";
import { IframeDialog } from './iframeDialog';
import { CheckboxWithLabel } from '../administration/adminPage';
import { CreditSelection } from '../auth/utils';

import { AlertHelper } from '../../web/AlertHelper';
import {FormType} from "../types";
import {ResultStatus} from "../lotto/regular_lotto/additionalSections/sumAndSubmitSection";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(1),
            height: 40,
            width: 120,
            backgroundColor: BLUE_COLOR
        },
        buttonMobile: {
            margin: theme.spacing(1),
            height: 83,
            width: 326,
            backgroundColor: BLUE_COLOR,
            fontSize: 45,
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            height: 'fit-content',
            width: 400,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        paperMobile: {
            height: 'fit-content',
            width: '90%',
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(12, 6, 13),
        },
    }),
);


type ChargeModalType = {
    handleClose: () => void,
    setCreditState: any,
    creditState: boolean,
    updateSrc: (val:string)=>void,
    setIframeOpen:  (val:boolean)=>void,
    lottomaticFormData?: string,
    formType?: FormType,
    isMobile?: boolean,
    setIsLoading?: (val: boolean) => void,
    setStatus?: (val: ResultStatus) => void,
    setMsg?: (val: string | undefined) => void,
    setTitleName?: (val: string) => void,
    price?: number,
}

const ChargeModal: React.FC<ChargeModalType> = ({ handleClose, updateSrc, setIframeOpen, lottomaticFormData, formType, isMobile, setIsLoading, setStatus, setMsg, setTitleName, price}) => {
    const classes = useStyles();
    const [amount, setAmount] = useState<number | undefined>()
    const { jwtToken, balance } = useContext(UserContext)
    const [saveToken, setSaveToken] = useState(false)
    const [chargeCurrent, setChargeCurrent] = useState(true)
    const [creditToken, setCreditToken] = useState<HasTokenType>({token: false})

     const style = StyleSheet.create({
        textFont: {
            ...getFontCss(FB_SPACER_REGULAR, 14, BLUE_COLOR),
            marginBottom: 16
        },
        textFontMobile: {
            ...getFontCss(FB_SPACER_REGULAR, 54, BLUE_COLOR),
            marginBottom: 16
        },
        headerFont: {
            ...getFontCss(FB_SPACER_REGULAR, 24, BLUE_COLOR),
            marginBottom: 24
        },
        headerFontMobile: {
            ...getFontCss(FB_SPACER_REGULAR, 74, BLUE_COLOR),
            marginBottom: 24
        }
     })

    useEffect(() => {
        if (jwtToken) {
            has_credit_token(jwtToken).then(res => {
                if(res.token == 'not found'){
                    setCreditToken({token: false})
                } else {
                    setCreditToken(res)
                    setChargeCurrent(true)
                }
            })
        }
    }, [jwtToken])

    const onClick = useCallback(() => {
        if (amount && jwtToken) {
            if(!creditToken.token){
                charge(jwtToken, amount, saveToken ? 2 : 1, lottomaticFormData, formType ).then((url) => {
                    url = new URL(url).href;
                    updateSrc(url)
                    setIframeOpen(true)
                })
            }
            else if(creditToken.token && chargeCurrent) {
                if (setIsLoading) {
                    setIsLoading(true)
                }
                handleClose()
                charge_token(jwtToken, amount, lottomaticFormData, formType ).then((res) => {
                        // window.location.href = new URL(url).href
                    if (setIsLoading && setStatus && setMsg && setTitleName) {
                        setIsLoading(false);
                        setStatus((res as SuccessType).result);
                        setMsg(undefined);
                        setTitleName((res as SuccessType).operation);
                    } else {
                        AlertHelper.show(`${(res as SuccessType).result}`,
                        () => AlertHelper.hide())
                    }
                })
            }
            else if(creditToken.token && !chargeCurrent) {
                charge(jwtToken, amount, saveToken? 2:1, lottomaticFormData, formType ).then((url) => {
                    url = new URL(url).href;
                    updateSrc(url)
                    setIframeOpen(true)
                })
            }
           
        }
        
    }, [amount, jwtToken, updateSrc, chargeCurrent, saveToken, lottomaticFormData, formType])

    useEffect(() => price ? setAmount(price - balance + 0.1) : setAmount(0), [price, balance])

    return <View style={{flexDirection: "row-reverse"}}>
        <View style={{width: '100%'}}>
            <Text style={isMobile ? style.headerFontMobile : style.headerFont}>טעינת קרדיט</Text>
            <Text style={isMobile ? style.textFontMobile : style.textFont}>הכנס את הסכום המבוקש לטעינה בשקלים</Text>
            <TextField
                placeholder={'הכנס סכום מבוקש'}
                style={{direction: 'rtl', marginBottom: 16}}
                type={'number'}
                value={amount}
                onChange={(event) => setAmount(Number(event.target.value))}
                InputProps={isMobile ? { style: { fontSize: 40 }} : {style: { fontSize: 20 }}}
                InputLabelProps={isMobile ? { style: { fontSize: 40 } } : {style: { fontSize: 20 }}}
            />
            {!creditToken.token && <CheckboxWithLabel onPress={() => setSaveToken(!saveToken)} title={'שמירה'}
                               label={'שמירת כרטיס'} isMobile={isMobile}/>}
            { creditToken.token && chargeCurrent  && <Text style={isMobile ? style.textFontMobile : style.textFont}> חיוב כרטיס שמסתיים ב: {creditToken.end_digits}</Text>

            }
            {creditToken.token && <CreditSelection isMobile={isMobile} onChange={(val)=>{
                setChargeCurrent(val == "true")
                if(val != "true"){
                    setSaveToken(false)
                }
            }} />}
                {creditToken.token && !chargeCurrent &&  <CheckboxWithLabel onPress={() => setSaveToken(!saveToken)} title={'שמירה'}
                        label={'שמירת כרטיס'} isMobile={isMobile}/>}
                {!chargeCurrent && saveToken && <Text style={isMobile ? style.textFontMobile : style.textFont}>אשראי חדש יחליף את האשראי הקיים במערכת</Text> }
            <ButtonGroup>
            <Button
                variant="contained"
                color="primary"
                size={isMobile ? 'large' : "small"}
                className={isMobile ? classes.buttonMobile : classes.button}
                startIcon={<CreditCardIcon scale={isMobile ? 4 : 1}/>}
                onClick={onClick}
            >
                טען קרדיט
            </Button>
            </ButtonGroup>
            <Text style={isMobile ? style.textFontMobile : style.textFont}>* שימו לב: טעינת קרדיט יכולה לקחת עד 5 דקות.</Text>
            <Text style={isMobile ? style.textFontMobile : style.textFont}>* שים לב: אישור ביצוע טעינת קרדיט אינו אישור שליחת הטופס
לאחר אישור התשלום ,לחץ שלח טופס.</Text>
        </View>
    </View>
}


export default function ChargeButton() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [iframeOpen, setIframeOpen] = useState(false);
    const [src, SetSrc] = useState('')

    const updateSrc = (_src:string) =>{
        SetSrc(_src)
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handelCreditClose = (reason:string) => {
        setIframeOpen(false);
    };

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<PaymentIcon/>}
                onClick={handleOpen}
            >
                טען קרדיט
            </Button>
            <Modal
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <ChargeModal handleClose={handleClose} updateSrc={updateSrc} setCreditState={setIframeOpen} creditState={iframeOpen} setIframeOpen={setIframeOpen}/>
                    </div>
                </Fade>
            </Modal>
             <IframeDialog src={src} open={iframeOpen} onClose={(reason)=> handelCreditClose(reason) }  />
        </>
    );
}

export function OpenChargeButton({lottomaticFormData, formType, isMobile, setIsLoading, setMsg, setStatus, setTitleName, price}: {setIsLoading: (val: boolean) => void, setStatus: (val: ResultStatus) => void, setMsg: (val: string | undefined) => void, setTitleName: (val: string) => void, lottomaticFormData?: string, formType?: FormType, isMobile?: boolean, price?: number}) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [iframeOpen, setIframeOpen] = useState(false);
    const [src, SetSrc] = useState('')
    const [openFrame, setOpenFrame] = useState(false)

    const updateSrc = (_src:string) =>{
        SetSrc(_src)
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handelCreditClose = (reason:string) => {
        setIframeOpen(false);
    };

    return (
        <>
            <Modal
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={isMobile ? classes.paperMobile : classes.paper}>
                        <ChargeModal
                            handleClose={handleClose}
                            updateSrc={updateSrc}
                            setCreditState={setIframeOpen}
                            creditState={iframeOpen}
                            setIframeOpen={setIframeOpen}
                            lottomaticFormData={lottomaticFormData}
                            formType={formType}
                            isMobile={isMobile}
                            setIsLoading={setIsLoading}
                            setMsg={setMsg}
                            setStatus={setStatus}
                            setTitleName={setTitleName}
                            price={price}/>
                    </div>
                </Fade>
            </Modal>
             <IframeDialog src={src} open={iframeOpen} onClose={(reason)=> handelCreditClose(reason) }  />
        </>
    );
}
