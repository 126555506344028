import React, { useEffect } from 'react'
import { StyleSheet, Text, View } from "react-native";
import {useHistory, useLocation} from "react-router-dom";
import getEnvVars  from './server/variables'
const  { frontEndUrl }  = getEnvVars(process.env.REACT_APP_ENV); 
import {
    CREDIT_TRANSACTION_PATH,
    HEBREW_ERROR_CHARGE,
    HEBREW_ERROR_REFUND,
    HEBREW_SUCCESS_CHARGE,
    HEBREW_SUCCESS_REFUND,
    HOME_PAGE_PATH, REGULAR_LOTTO_PATH
} from './consts';
import {
    BLUE_COLOR,
    FB_SPACER_BOLD,
    getFontCss,
    RED_COLOR,
} from "./styles";

export const creditTransaction = () => {
    let query = useQuery();
    const history = useHistory()
    
    const statusByOperation = (operation:string, isSuccess:boolean) => {

        switch(operation){
            case 'charge':
                return isSuccess ? HEBREW_SUCCESS_CHARGE : HEBREW_ERROR_CHARGE;
            case 'refund':
                return isSuccess ? HEBREW_SUCCESS_REFUND : HEBREW_ERROR_REFUND;
            default:
                return ''
        }
    }
    const operation: string = query.get('o') || '';
    const status:boolean = parseInt(query.get('Status') || '-1') === 0 ;
    const statusText:string = statusByOperation(operation, status);
    const lowprofilecode: string = query.get('lowprofilecode') || '';

    useEffect(() => {
        if (window != window.parent) {
            // history.push(HOME_PAGE_PATH)
            const baseUrl = frontEndUrl;
            window.parent.postMessage({method: "transaction_finished"}, '*');
            window.parent.location.href = `${ baseUrl?.href }${REGULAR_LOTTO_PATH +document.location.search}`.replace(/([^:]\/)\/+/g, "$1")
        }
        if(document.location.search){
            const baseUrl = frontEndUrl;
            window.location.herf = `${ baseUrl?.href }${CREDIT_TRANSACTION_PATH +document.location.search}`
        }
    },[]);

   
    const style = StyleSheet.create({
        text: {
            ...getFontCss(FB_SPACER_BOLD, 45, BLUE_COLOR),
            position: "relative",
            marginTop: 0,
            marginRight: 0
        },
        redText: {
            ...getFontCss(FB_SPACER_BOLD, 45, RED_COLOR),
        },
    })
    return <View style={{ margin: 'auto', marginVertical: 50 }}>
        <Text style={style.text}>
            {`${statusText}`}
        </Text>
        <Text style={style.text}>
            {`מזהה עסקה: ${lowprofilecode} ddddd - ${operation}`}
        </Text>
    </View>

}
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
