import { Button, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@material-ui/core";
import React, { useState, useContext, useCallback, useEffect } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { UserContext } from "../appSwitch";
import { DEFAULT_REFUND_REQUESTS_DATA, HEBREW_DATE_AND_TIME, HEBREW_PULL_STATUS, HEBREW_STATUS, ID } from "../consts";
import { AdminFromsTypePagination, getAllBatches } from "../server/admin";
import { handleErrors, useAsync } from "../server/utils";
import { BatchUpload } from './BatchUpload';

import { getFontCss, FB_SPACER_REGULAR, BLUE_COLOR, WHITE_COLOR, FB_SPACER_BOLD, GREEN_COLOR, RED_COLOR } from "../styles";
import { SERVER_URL } from "../server/configurations";

import DeleteIcon from "@material-ui/icons/Delete"

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    button: {
        backgroundColor: GREEN_COLOR,
        justifyContent: "center",
        alignItems: "center",
        position: "relative",


        borderWidth: 1,
        borderColor: BLUE_COLOR,
        borderStyle: "solid",

    },
    errorButton: {
        backgroundColor: RED_COLOR,
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        borderWidth: 1,
        borderColor: BLUE_COLOR,
        borderStyle: "solid",

    }
}));

const FormsBatchTableHead = ({ headers }) => {
    const useStyles = makeStyles((theme) => ({
        headerCell: {
            background: BLUE_COLOR,
        }
    }));
    const classes = useStyles()

    return (
        <TableHead>
            <TableRow>
                {
                    headers.map((header: string, index:number) =>
                     <TableCell key={index} align={"right"}
                        className={classes.headerCell}>
                        {
                            <Text
                                style={{ ...getFontCss(FB_SPACER_REGULAR, 21, WHITE_COLOR) }}>{header}</Text>
                        }
                    </TableCell>)
                }
                 <TableCell align={"right"}
                    className={classes.headerCell}>
                    {
                        <Text
                            style={{ ...getFontCss(FB_SPACER_REGULAR, 21, WHITE_COLOR) }}>הדפסה</Text>
                    }
                </TableCell>
                <TableCell align={"right"}
                    className={classes.headerCell}>
                    {
                        <Text
                            style={{ ...getFontCss(FB_SPACER_REGULAR, 21, WHITE_COLOR) }}>העלאה</Text>
                    }
                </TableCell>
                <TableCell align={"right"}
                    className={classes.headerCell}>
                    {
                        <Text
                            style={{ ...getFontCss(FB_SPACER_REGULAR, 21, WHITE_COLOR) }}>ביטול</Text>
                    }
                </TableCell>
            </TableRow>
        </TableHead>
    )
}


const FormsBatchTableBody = ({ rows }) => {


    const classes = useStyles()
    const style: { text: object, buttonText: object } = {
        text: { ...getFontCss(FB_SPACER_REGULAR, 20, BLUE_COLOR) },
        buttonText: { ...getFontCss(FB_SPACER_BOLD, 17, BLUE_COLOR) },

    }
    const { jwtToken } = useContext(UserContext)
    const onClick = useCallback(async (batch_id) => {
       
        let rePrint = new URL(`/admin/forms/download/batch/${batch_id}`, SERVER_URL).href;
        let forms = await handleErrors(fetch(rePrint, {
            method: "GET",
            headers: {
                Authorization: jwtToken,
            }            
        }))
        const formsIds = forms.map(form => form.form_id)
        return onPrintAll(batch_id, formsIds)
       
    }, [jwtToken])
    const onPrintAll = useCallback((batch_id, ids) => {
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        let file = new URL(`/admin/forms/download`, SERVER_URL).href;
        fetch(file, {method: 'POST', body: JSON.stringify({ids,create: false, batch_id}),
                headers: {
                    'Authorization': jwtToken,
                    'Content-Type': 'application/json'
                }})
            .then(async (response) => {
                const fileName = response.headers.get('Content-Disposition')?.split(';')[1].split('=')[1]
                const blob = await response.blob();
                let objectUrl = window.URL.createObjectURL(blob);
                anchor.href = objectUrl;
                anchor.download = fileName
                anchor.click();
                window.URL.revokeObjectURL(objectUrl);
            });
    }, [jwtToken])

    const deleteBatch = useCallback(async (batch_id) => {
        let batch = new URL(`/admin/forms/download/batch/${batch_id}`, SERVER_URL).href;
        let forms = await handleErrors(fetch(batch, {
            method: "GET",
            headers: {
                Authorization: jwtToken,
            }            
        }))
        const ids = forms.map(form => form.form_id)

        const delBatch = new URL('/admin/forms/delete/batch', SERVER_URL).href
        fetch(delBatch, {method: 'POST', body: JSON.stringify({ids, batch_id}),
        headers: {
            'Authorization': jwtToken,
            'Content-Type': 'application/json'
        }})
    }, [jwtToken])

    return (
        <TableBody>

            {
                rows.map((row) => {
                    return (
                        <TableRow key={row.id}>

                            {
                                Object.keys(row).map((col, index) =>
                                    <TableCell align={"right"} key={index}>
                                        <Text style={style.text}>
                                            {row[col]}
                                        </Text>
                                    </TableCell>
                                )
                            }
                             <TableCell align={"right"} >
                                <Text style={style.text}>
                                    <Button variant="contained" color="primary" onClick={()=> onClick(row[ID])} title={'הדפס batch'}>הדפס batch</Button>
                                </Text>
                            </TableCell>
                            <TableCell align={"right"} >
                                <Text style={style.text}>
                                    <BatchUpload batchId={row[ID]} />
                                </Text>
                            </TableCell>
                            <TableCell align={"right"} >
                                <Text style={style.text}>
                                    <DeleteIcon style={{"cursor":"pointer"}} onClick={()=>deleteBatch(row[ID])} />
                                </Text>
                            </TableCell>
                        </TableRow>)
                }
                )
            }
        </TableBody>
    )
}


export const FormsBatch = ({changeTab, providerId }:{changeTab:(value:number)=>void, providerId: number}) => {

    const useStyles = makeStyles((theme) => ({
        paper: {
            marginBottom: theme.spacing(2),
        },
        tablePagination: {
            borderBottom: "none",
            ...getFontCss(FB_SPACER_REGULAR, '0.875rem', BLUE_COLOR)
        },
        headerCell: {
            background: BLUE_COLOR,
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            width: '75%'
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        button: {
            margin: theme.spacing(1),
            width: 200
        }
    }));
    const classes = useStyles()
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const style: { text: object } = {
        text: { ...getFontCss(FB_SPACER_REGULAR, 21, BLUE_COLOR) }
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage + 1);
    };


    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const { jwtToken } = useContext(UserContext)


    const [data, setData] = useState<AdminFromsTypePagination>(DEFAULT_REFUND_REQUESTS_DATA);

    const AllBatch = useCallback(async () => {
        if (jwtToken) {
            return await getAllBatches(jwtToken, page, rowsPerPage, providerId)
        }
    }, [jwtToken, page, rowsPerPage, providerId])

    useEffect(() => {

        AllBatch().then(res => {
            setData(res)
        })

    }, [page, rowsPerPage]);





    return (
        <View style={{ width: '100%' }}>

            <View style={{ alignItems: "center", justifyContent: "center", marginBottom: 40 }}>

            </View>

            <Paper dir={"rtl"} className={classes.paper}>
                <TableContainer>
                    <Table size={'medium'}>
                        <FormsBatchTableHead headers={data.headers} />
                        <FormsBatchTableBody rows={data.rows} />
                    </Table>
                </TableContainer>
                <TablePagination
                    dir={"ltr"}
                    width={'inherit'}
                    rowsPerPageOptions={[10, 25]}
                    count={data.total}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    labelRowsPerPage={'שורות'}
                    className={classes.tablePagination}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    nextIconButtonProps={{ disabled: !data.has_next }}
                    backIconButtonProps={{ disabled: !data.has_prev }}
                    labelDisplayedRows={({ from, to, count }) => `סה"כ: ${count} תוצאות מציג: ${from} - ${to} `}
                />

            </Paper>
        </View>
    );
}