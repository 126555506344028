import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Auth} from "aws-amplify";
import {useAsync} from "../server/utils";
import {CognitoUser} from "amazon-cognito-identity-js";
import {
    BLUE_COLOR,
    FB_SPACER_BOLD,
    FB_SPACER_REGULAR,
    getCircleCss,
    getFontCss,
    RED_COLOR,
    WHITE_COLOR,
    YELLOW_123_COLOR
} from "../styles";
import {SignInField} from "./utils";
import {FORGOT_PASSWORD_PATH, HOME_PAGE_PATH, SIGN_UP_PATH} from "../consts";
import {PipeLine} from "../genaricComponents";
import {UserContext} from "../appSwitch";

const SIGN_IN_MESSAGE = "יוזר או סיסמה לא תקינים"

export const SignIn = ({isMobile}: {isMobile?: boolean}) => {

    const style = StyleSheet.create({
        container: {
            // width: '34.7%',
            backgroundColor: BLUE_COLOR,
            alignItems: "center",
            justifyContent: "center",
            marginVertical: 50,
            marginHorizontal: 450,
            marginBottom: 40,
            alignSelf: "center",
            borderRadius: 12,
            height: 347
        },
        containerMobile: {
            width: '100%',
            backgroundColor: BLUE_COLOR,
            alignItems: "center",
            justifyContent: "center",
            marginVertical: 50,
            marginBottom: 40,
            alignSelf: "center",
            borderRadius: 12,
            // height: 347
        }
    })
    const [userData, setUserData] = useState<{ [key: string]: string }>({
        password: '',
        username: '',
        confirmationCode: ''
    })
    const {setIsAuthenticated, setUser, setIsisAuthenticating,  updateBalance} = useContext(UserContext)
    const history = useHistory()
    const signIn = useCallback(() =>
            Auth.signIn(
                userData['username'].toLowerCase(),
                userData['password']
            ).then(user => {
                history.goBack()
                setUser(user)
                setIsisAuthenticating(false)
                setIsAuthenticated(true)
                updateBalance(user?.signInUserSession?.accessToken?.jwtToken)
                return user
            })
        , [userData])
    const {
        execute: executeSignIn,
        error: errorSignIn,
        status: statusSignIn
    } = useAsync<CognitoUser, { message: string }>(signIn, false)

    const onChangeText = (key: string, value: string) => {
        setUserData({...userData, [key]: value})
    }
    return (
        <View style={isMobile ? style.containerMobile : style.container}>
            {statusSignIn !== 'success' &&
            <Text style={isMobile ? {
                ...getFontCss(FB_SPACER_BOLD, 55, WHITE_COLOR),
                marginBottom: 17,
                marginTop: 52,
                alignSelf: "flex-end",
                marginRight: '2%'
            } : {
                ...getFontCss(FB_SPACER_BOLD, 35, WHITE_COLOR),
                marginBottom: 50,
                marginTop: 10,
                alignSelf: "flex-end",
                marginRight: '2%'
            }}>היכנס</Text>}
            {errorSignIn && statusSignIn !== 'success' &&
            <Text style={isMobile ? {...getFontCss(FB_SPACER_BOLD, 55, RED_COLOR)} : {...getFontCss(FB_SPACER_BOLD, 20, RED_COLOR)}}>{SIGN_IN_MESSAGE}</Text>}
            {statusSignIn !== 'success' && <>
                <SignInField onChangeText={(value) => onChangeText('username', value)} placeholder={'אימייל'}
                             autoCompleteType={'email'} isMobile={isMobile}/>
                <SignInField onChangeText={(value) => onChangeText('password', value)} placeholder={'סיסמה'}
                             autoCompleteType={'password'}
                             secureTextEntry={true} isMobile={isMobile}/>
                <View style={{
                    flexDirection: "row-reverse",
                    marginBottom: 5,
                    width: '100%',
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <a href={SIGN_UP_PATH} style={isMobile ? {...getFontCss(FB_SPACER_REGULAR, 45, WHITE_COLOR)} : {...getFontCss(FB_SPACER_REGULAR, 14, WHITE_COLOR)}}>הירשם</a>
                    <PipeLine height={15} color={WHITE_COLOR} marginLeft={'10%'} marginRight={'10%'}/>
                    <a href={FORGOT_PASSWORD_PATH} style={isMobile ? {...getFontCss(FB_SPACER_REGULAR, 45, WHITE_COLOR)} : {...getFontCss(FB_SPACER_REGULAR, 14, WHITE_COLOR)}}>?שכחת
                        סיסמא</a>
                </View>
                <View style={{flexDirection: "row", alignItems: "center", width: '25%', justifyContent: "center"}}>
                    <TouchableOpacity style={isMobile ? {
                        ...getCircleCss(67, 208, 12),
                        borderWidth: 2,
                        borderColor: WHITE_COLOR,
                        alignItems: "center",
                        alignSelf: "center",
                        backgroundColor: YELLOW_123_COLOR,
                        marginBottom: 26
                    } : {
                        ...getCircleCss(39, 208, 12),
                        borderWidth: 2,
                        borderColor: WHITE_COLOR,
                        alignItems: "center",
                        alignSelf: "center",
                        backgroundColor: YELLOW_123_COLOR
                    }} onPress={executeSignIn}>
                        <Text style={isMobile ? {...getFontCss(FB_SPACER_BOLD, 55, WHITE_COLOR)} : {...getFontCss(FB_SPACER_BOLD, 34, WHITE_COLOR)}}>אישור</Text>
                    </TouchableOpacity>
                </View>

            </>}

        </View>
    )
}
