import {MultiLotteryType, ShitatiMarksType} from "../../types";
import {regularLottoReducer} from "./regularLottoReducer";
import {FORM_TYPE, NON_NUMBER} from "../../consts";

let _ = require('lodash')

type ActionType = {
    type: string,
    value: number | string,
    tableIndex: number,
    multiLotteryValue?: MultiLotteryType,
    numbersLength: number
    strongNumbersLength: number
    formType?: string | number
}

export const shitatiReducer = (state: ShitatiMarksType, action: ActionType): ShitatiMarksType => {
    switch (action.type) {
        case 'setFormType':
            state[FORM_TYPE] = action.formType || NON_NUMBER
            return {...state}
        case 'resetFormType':
            state[FORM_TYPE] = NON_NUMBER
            return {...state}
        default:
            return {...regularLottoReducer({...state}, action), [FORM_TYPE]: state[FORM_TYPE]}
    }
}