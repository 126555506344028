import {Image, StyleSheet, Text, View} from "react-native";
import {
    DARK_RED_COLOR,
    FB_SPACER_REGULAR,
    getBorder,
    getCircleCss,
    getFontCss,
    getHeightWidthCss,
    LIGHT_GREEN_COLOR,
    RED_COLOR,
    WHITE_COLOR,
    YELLOW_COLOR
} from "../../../styles";
import React from "react";
import {LottoMarksType, UserFormType} from "../../../types";
import {NON_NUMBER, NUMBERS, STRONG_NUMBERS, TABLES} from "../../../consts";
import {LottoRowBalls, SideBall} from "../../lottoBalls";
import {isFullTable} from "../../utils";

let _ = require('lodash')

type FillingRowType = {
    successColor?: string,
    rowColor?: string,
    numbers: number[],
    strongNumbers: number[],
    tableNumber: number,
    isLink?: boolean,
    numbersLength: number
    strongNumbersLength: number,
    isReadOnly?: boolean,
    isMobile?: boolean,
    setTableIndex: (arg0: number) => void
}
type StrongNumberType = { strongNumbers: number[], strongNumbersLength: number, marginRight?: number | string, isMobile?: boolean }
const FILLING_ROW_HEIGHT = 34
const FILLING_ROW_HEIGHT_MOBILE = 90

export const StrongNumber = ({strongNumbers, strongNumbersLength, marginRight, isMobile}: StrongNumberType) => {

    const style = StyleSheet.create({
        container: {
            backgroundColor: YELLOW_COLOR,
            borderStyle: "solid",
            borderColor: DARK_RED_COLOR,
            borderWidth: 1,
            ...getCircleCss(21, 22, 12.5),
            justifyContent: "center",
            alignItems: "center"
        },
        text: {
            ...getFontCss(FB_SPACER_REGULAR, 21, RED_COLOR)
        },
        containerMobile: {
            backgroundColor: YELLOW_COLOR,
            borderStyle: "solid",
            borderColor: DARK_RED_COLOR,
            borderWidth: 1,
            ...getCircleCss(56, 58, 12.5),
            justifyContent: "center",
            alignItems: "center"
        },
        textMobile: {
            ...getFontCss(FB_SPACER_REGULAR, 64, RED_COLOR)
        },
    })

    return <LottoRowBalls ballTextStyle={isMobile ? style.textMobile : style.text} numbers={strongNumbers} rowLength={strongNumbersLength}
                          ballStyle={isMobile ? style.containerMobile : style.container}
                          style={{flexDirection: 'row', marginRight: marginRight || '13%'}}/>
}

export const FillingRow = ({
                               rowColor,
                               successColor,
                               numbersLength,
                               tableNumber,
                               isLink = false,
                               numbers,
                               strongNumbers,
                               strongNumbersLength,
                               isReadOnly,
                               isMobile,
                               setTableIndex
                           }: FillingRowType) => {

    const isFilled = isFullTable(numbers, strongNumbers, numbersLength, strongNumbersLength)
    const style = StyleSheet.create({
        mainContainer: {
            flexDirection: "row",
            ...getHeightWidthCss(FILLING_ROW_HEIGHT, '100%'),
            alignItems: "center",
            marginTop: 2,
            ...getBorder(),
        },
        mainContainerMobile: {
            ...getHeightWidthCss(FILLING_ROW_HEIGHT_MOBILE, '100%'),
            alignItems: "center",
            marginTop: 2,
            ...getBorder(),
        },
        container: {
            backgroundColor: isFilled ? successColor || LIGHT_GREEN_COLOR : rowColor || DARK_RED_COLOR,
            flexDirection: "row",
            alignItems: "center",
            ...getCircleCss('inherit', '85%', 8),
            left: '14.5%',
            paddingLeft: '4%',
            ...getBorder()
        },
        containerMobile: {
            backgroundColor: isFilled ? successColor || LIGHT_GREEN_COLOR : rowColor || DARK_RED_COLOR,
            flexDirection: "row",
            alignItems: "center",
            ...getCircleCss('inherit', '85%', 8),
            // left: '14.5%',
            paddingLeft: '4%',
            ...getBorder()
        },
        row: {
            width: '50%',
            flexDirection: "row",
            justifyContent: "center",
            ...getBorder(),
        },
        ball: {
            marginRight: '2.1%',
            backgroundColor: WHITE_COLOR,
            ...getCircleCss(20, 20, 12.5),
            justifyContent: "center",
            alignItems: "center"
        },
        ballMobile: {
            marginRight: '2.1%',
            backgroundColor: WHITE_COLOR,
            ...getCircleCss(56, 67, 12.5),
            justifyContent: "center",
            alignItems: "center"
        },
        ballText: {
            ...getFontCss(FB_SPACER_REGULAR, 22, RED_COLOR)
        },
        ballTextMobile: {
            ...getFontCss(FB_SPACER_REGULAR, 64, RED_COLOR)
        },
        tableText: {
            position: "absolute",
            right: '2%',
            ...getFontCss(FB_SPACER_REGULAR, 17, WHITE_COLOR)
        },
        tableTextMobile: {
            position: "absolute",
            right: '2%',
            ...getFontCss(FB_SPACER_REGULAR, 60, WHITE_COLOR)
        },
        fillingFormLineConnection: {
            position: "absolute",
            right: 4,
            bottom:0,
            ...getHeightWidthCss(2, '18.5%'),
            backgroundColor: WHITE_COLOR
        },
        filledTableImage: {
            position: "absolute",
            left: '6%',
            width: 17,
            height: 13
        }
    })

    return (
        <View style={isMobile ? style.mainContainerMobile : style.mainContainer} onTouchEnd={() => setTableIndex(tableNumber - 1)}>
            {!isMobile && isFilled && !isReadOnly &&
            <Image source={require('../../../../../assets/FilledTableV.png')} style={style.filledTableImage}/>}
            <View style={isMobile ? style.containerMobile : style.container}>
                <StrongNumber strongNumbers={strongNumbers || NON_NUMBER} strongNumbersLength={strongNumbersLength} isMobile={isMobile}/>
                <LottoRowBalls rowLength={numbersLength} numbers={numbers} style={style.row} ballStyle={isMobile ? style.ballMobile : style.ball}
                               ballTextStyle={isMobile ? style.ballTextMobile : style.ballText}/>
                <Text style={isMobile ? style.tableTextMobile : style.tableText}>{`טבלה ${tableNumber}`}</Text>
            </View>
            {isLink && !isReadOnly && <View style={style.fillingFormLineConnection}/>}
        </View>
    )
}

export const ShitatiHazakFillingRow = ({
                                           numbersLength,
                                           tableNumber,
                                           isLink = false,
                                           numbers,
                                           strongNumbers,
                                           strongNumbersLength,
                                           isReadOnly, isMobile
                                       }: FillingRowType) => {

    const isFilled = isFullTable(numbers, strongNumbers, numbersLength, strongNumbersLength)
    const style = StyleSheet.create({
        mainContainer: {
            flexDirection: "row",
            ...getHeightWidthCss(55, '100%'),
            alignItems: "center",
            marginTop: 2,
            ...getBorder(),
        },
        mainContainerMobile: {
            flexDirection: "row",
            // ...getHeightWidthCss(55, '100%'),
            alignItems: "center",
            marginTop: 2,
            ...getBorder(),
        },
        container: {
            backgroundColor: isFilled ? LIGHT_GREEN_COLOR : DARK_RED_COLOR,
            flexDirection: "row",
            alignItems: "center",
            ...getCircleCss('inherit', '85%', 8),
            left: '14.5%',
            paddingLeft: '4%',
            ...getBorder()
        },
        containerMobile: {
            backgroundColor: isFilled ? LIGHT_GREEN_COLOR : DARK_RED_COLOR,
            flexDirection: "row",
            alignItems: "center",
            paddingVertical: 24,
            ...getCircleCss('inherit', '85%', 8),
            left: '6.5%',
            paddingLeft: '4%',
            ...getBorder()
        },
        row: {
            marginTop: '2%',
            flexDirection: "row",
            ...getBorder(),
        },
        ball: {
            marginRight: '2.1%',
            backgroundColor: WHITE_COLOR,
            ...getCircleCss(20, 20, 12.5),
            justifyContent: "center",
            alignItems: "center"
        },
        ballMobile: {
            marginRight: '2.1%',
            backgroundColor: WHITE_COLOR,
            ...getCircleCss(56, 67, 12.5),
            justifyContent: "center",
            alignItems: "center"
        },
        ballText: {
            ...getFontCss(FB_SPACER_REGULAR, 22, RED_COLOR)
        },
        ballTextMobile: {
            ...getFontCss(FB_SPACER_REGULAR, 64, RED_COLOR)
        },
        tableText: {
            position: "absolute",
            right: '2%',
            ...getFontCss(FB_SPACER_REGULAR, 17, WHITE_COLOR)
        },
        tableTextMobile: {
            position: "absolute",
            right: '2%',
            top: 0,
            ...getFontCss(FB_SPACER_REGULAR, 60, WHITE_COLOR)
        },
        fillingFormLineConnection: {
            position: "absolute",
            right: 4,
            bottom:0,
            ...getHeightWidthCss(2, '18.5%'),
            backgroundColor: WHITE_COLOR
        },
        fillingFormLineConnectionMobile: {
            position: "absolute",
            right: 4,
            top: 91,
            bottom:0,
            ...getHeightWidthCss(4, '18.5%'),
            backgroundColor: WHITE_COLOR
        },
        filledTableImage: {
            position: "absolute",
            left: '6%',
            width: 17,
            height: 13
        }
    })

    return (
        <View style={isMobile ? style.containerMobile : style.mainContainer}>
            {isFilled && !isReadOnly &&
            <Image source={require('../../../../../assets/FilledTableV.png')} style={style.filledTableImage}/>}
            <View style={isMobile ? style.containerMobile : style.container}>
                <Text style={isMobile ? style.tableTextMobile : style.tableText}>{`טבלה ${tableNumber}`}</Text>
                <View>
                    <StrongNumber strongNumbers={strongNumbers || NON_NUMBER}
                                  strongNumbersLength={strongNumbersLength} marginRight={'2.1%'} isMobile={isMobile}/>
                    <LottoRowBalls rowLength={numbersLength} numbers={numbers} style={style.row} ballStyle={isMobile ? style.ballMobile : style.ball}
                                   ballTextStyle={isMobile ? style.ballTextMobile : style.ballText}/>
                </View>
            </View>
            {isLink && !isReadOnly && <View style={isMobile ? style.fillingFormLineConnectionMobile : style.fillingFormLineConnection}/>}
        </View>
    )
}


export const UserFormBorders = ({tablesNumber, isMobile}: { tablesNumber: number, isMobile?: boolean }) => {

    const style = StyleSheet.create({
        container: {
            position: "absolute",
            left: '14.5%',
            ...getCircleCss(FILLING_ROW_HEIGHT * tablesNumber + 60, '85%', 12),
            borderColor: WHITE_COLOR,
            borderStyle: "solid",
            borderWidth: 3,
        },
        containerMobile: {
            position: "absolute",
            left: '6.5%',
            ...getCircleCss(210 * tablesNumber + 60, '85%', 12),
            borderColor: WHITE_COLOR,
            borderStyle: "solid",
            borderWidth: 3,
        }
    })

    return (
        <View style={isMobile ? style.containerMobile : style.container}/>
    )
}

const UserForm = ({marks, tableIndex, tablesNumber, numbersLength, strongNumbersLength, isReadOnly, isMobile, setTableIndex}: UserFormType<LottoMarksType> & {isMobile?: boolean, setTableIndex: (arg0: number) => void}) => {
    const style = StyleSheet.create({
        fillingFormLine: {
            backgroundColor: WHITE_COLOR,
            ...getHeightWidthCss(1, '85%'),
            marginLeft: '14.5%',
            marginTop: 4
        },
        mobileContainer: {
            justifyContent: 'center',
            alignItems: 'center'
        }
    })

    const getFillingRows = () => {
        return _.range(tablesNumber).map((index: number) => marks[TABLES][index] && <FillingRow isReadOnly={isReadOnly}
                                                                        numbersLength={numbersLength}
                                                                        tableNumber={index + 1}
                                                                        isLink={tableIndex == index}
                                                                        numbers={marks[TABLES][index][NUMBERS]}
                                                                        strongNumbers={marks[TABLES][index][STRONG_NUMBERS]}
                                                                        strongNumbersLength={strongNumbersLength} isMobile={isMobile} setTableIndex={setTableIndex}/>)
    }

    const getSideBalls = () => {
        return _.range(tablesNumber).map((index: number) => <SideBall multiple={index - index % 2 + 2}
                                                                      tableNumber={index}/>)
    }

    return (
        <View style={style.mobileContainer}>
            {getFillingRows()}
            {/* <View style={style.fillingFormLine}/> */}
            {!isMobile && <UserFormBorders tablesNumber={tablesNumber}/>}
            {!isReadOnly && !isMobile && getSideBalls()}
        </View>
    )
}

export const ShitatiHazakUserForm = ({
                                         marks,
                                         tableIndex,
                                         tablesNumber,
                                         numbersLength,
                                         strongNumbersLength,
                                         isReadOnly, isMobile
                                     }: UserFormType<LottoMarksType> & {isMobile?: boolean}) => {
    const style = StyleSheet.create({
        fillingFormLine: {
            backgroundColor: WHITE_COLOR,
            ...getHeightWidthCss(1, '85%'),
            marginLeft: '14.5%',
            marginTop: 4
        },
        fillingFormLineMobile: {
            backgroundColor: WHITE_COLOR,
            ...getHeightWidthCss(3, '85%'),
            marginLeft: '6.5%',
            marginTop: 4
        }
    })

    const getFillingRows = () => {
        return _.range(tablesNumber).map((index: number) => <ShitatiHazakFillingRow isReadOnly={isReadOnly}
                                                                                    numbersLength={numbersLength}
                                                                                    tableNumber={index + 1}
                                                                                    isLink={tableIndex == index}
                                                                                    numbers={marks[TABLES][index][NUMBERS]}
                                                                                    strongNumbers={marks[TABLES][index][STRONG_NUMBERS]}
                                                                                    strongNumbersLength={strongNumbersLength} isMobile={isMobile}/>)
    }

    return (
        <>
            {getFillingRows()}
            <View style={isMobile ? style.fillingFormLineMobile : style.fillingFormLine}/>
            <UserFormBorders tablesNumber={tablesNumber} isMobile={isMobile}/>
        </>
    )
}

export const UserFormContainer = ({
                                      children,
                                      backgroundColor,
                                      width,
                                      marginBottom
                                  }: { marginBottom?: string | number, width?: string | number, backgroundColor?: string, children: JSX.Element | JSX.Element[] }) => {
    const style = StyleSheet.create({
        innerFormTablesContainer: {
            width: width || '50%',
            borderRadius: 6,
            backgroundColor: backgroundColor,
            marginBottom: marginBottom || 35,
            ...getBorder()
        }
    })


    return (
        <View style={style.innerFormTablesContainer}>
            {children}
        </View>
    )
}

export default UserForm