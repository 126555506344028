import React, {useCallback, useContext, useEffect, useState} from "react";
import {Button, Input, Space, Table, DatePicker, Modal, Descriptions, Select, Tooltip, List} from "antd";
import {RocketOutlined, SearchOutlined} from "@ant-design/icons";
import {Batch, changeStatus, getAllBatches, OwnersFormType} from "../server/owners";
import Highlighter from 'react-highlight-words';
import moment, {Moment} from "moment";
import {UserContext} from "../appSwitch";
import {FormPopup, useTableStyles} from "./overviewTable";
import {DownloadReceipt} from "../administration/adminPage";

const { RangePicker } = DatePicker;

interface IProps {
}

const statuses = ['completed', 'cancelled', 'scanned_awaiting_tagging', 'pending']

export const BatchesTable: React.FC<IProps> = () => {
    const classes = useTableStyles()
    const {jwtToken} = useContext(UserContext)
    const [allBatches, setAllBatches] = useState<Batch[]>([])
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedBatch, setSelectedBatch] = useState<Batch>();

    const [isLoading, setIsLoading] = useState(false);

    const allBatchesGetter = useCallback(async () => {
        if (jwtToken) {
            const batches =  await getAllBatches(jwtToken)
            setAllBatches(batches)
            setIsLoading(false)
        }
    }, [jwtToken, setIsLoading])

    useEffect( () => {
        setIsLoading(true)
        allBatchesGetter()
    }, [])

    let searchInput: import("antd").InputRef | null;
    const getColumnDateProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8, display: 'flex', flexDirection: 'column' }}>
            <RangePicker
                value={selectedKeys[0] ? selectedKeys[0].split('$').map(item => moment.utc(item)) : ''}
                onChange={(values: any, formatString: [string, string]) => {
                    if (values !== null) {
                        setSelectedKeys([formatString.join('$')]);
                    }
                }}
            />
            <Space style={{marginTop: 8}}>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                    confirm({ closeDropdown: false });
                    setSearchText([selectedKeys[0], selectedKeys[0]].join('$'));
                    setSearchedColumn(dataIndex);
                }}
              >
                Filter
              </Button>
            </Space>
          </div>
        ),
        // filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => {
            const dates = value.split('$')
            const startDate = moment.utc(dates[0])
            const endDate = moment.utc(dates[1])
            return record[dataIndex] ? moment.utc(record[dataIndex]).isBetween(startDate, endDate) : false
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible && searchInput) {
            setTimeout(() => searchInput.select(), 100);
          }
        },
    });
    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                    confirm({ closeDropdown: false });
                    setSearchText(selectedKeys[0]);
                    setSearchedColumn(dataIndex);
                }}
              >
                Filter
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : false,
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => searchInput.select(), 100);
          }
        },
        render: (text: { toString: () => any; }) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={[searchText]}
                  autoEscape
                  textToHighlight={text ? text.toString() : ''}
                />
              ) : (
                text
              ),
    });

    const handleSearch = (selectedKeys: React.SetStateAction<string>[], confirm: () => void, dataIndex: string) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('')
    };

    const handleChange = (pagination: any, filters: any, sorter: any, extra: { currentDataSource: Array<Batch> }) => {
        console.log('Various parameters', extra.currentDataSource);
    }

    const showModal = (row: Batch) => {
        setSelectedBatch(row);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            ...getColumnSearchProps('id'),
        },
        {
            title: 'totalPrice',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            ...getColumnSearchProps('totalPrice'),
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            ...getColumnDateProps('date'),
            render: text => text ? new Date(text).toLocaleString('en-GB', { hour12: false }) : ''
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a: Batch, b: Batch) => {
                if(a.status < b.status) { return -1; }
                if(a.status > b.status) { return 1; }
                return 0;
            },
            filters: statuses.map(status => ({
                text: status,
                value: status
            })),
            onFilter: (value, record) => record.status.indexOf(value) === 0,
        },
    ];

    const onRowClick = useCallback((row, i) => ({onClick: () => showModal(row)}),[]);

    return <>
        <Table className={classes.usersTable} columns={columns} dataSource={allBatches} loading={isLoading} onChange={handleChange} onRow={onRowClick}/>
        {selectedBatch && <BatchPopup isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel} batch={selectedBatch} />}
    </>
}

interface FormModalProps {
    batch: Batch;
    isModalVisible: boolean;
    handleOk: () => void;
    handleCancel: () => void;
}

export const BatchPopup: React.FC<FormModalProps> = ({batch, isModalVisible, handleOk, handleCancel}) => {
    return (
        <Modal width={'50%'} title={'Batch Details'} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <Space direction={'vertical'}>
                <Descriptions title="Batch Info">
                    <Descriptions.Item label="Id" labelStyle={{fontWeight: 'bold'}}>{batch.id}</Descriptions.Item>
                    <Descriptions.Item label="Status" labelStyle={{fontWeight: 'bold'}}>{batch.status}</Descriptions.Item>
                    <Descriptions.Item label="Date" labelStyle={{fontWeight: 'bold'}}>{batch.date}</Descriptions.Item>
                    <Descriptions.Item label="Total Price" labelStyle={{fontWeight: 'bold'}}>{batch.totalPrice}</Descriptions.Item>
                </Descriptions>
                {batch.forms && <List
                  header={<div>Forms</div>}
                  bordered
                  dataSource={batch.forms}
                  renderItem={item => (
                    <List.Item actions={[<a key="download receipt">Download receipt</a>, <DownloadReceipt formId={JSON.stringify(item.form_id)}/>]}>
                        id: {item.form_id}, status: {item.status}
                    </List.Item>
                  )}
                />}
            </Space>
        </Modal>
    )
}