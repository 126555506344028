import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Image, StyleSheet, Text, TouchableOpacity, View} from 'react-native'
import {
    BLUE_COLOR,
    FB_SPACER_BOLD,
    FB_SPACER_REGULAR,
    getBorder,
    getCircleCss,
    getFontCss,
    ORANGE_COLOR,
    WHITE_COLOR
} from "../styles";

import {useHistory} from 'react-router-dom'
import {
    ADMINISTRATION_PATH,
    HOME_PAGE_PATH,
    MY_SPACE_PATH,
    SIGN_IN_PATH,
    SIGN_UP_PATH,
    OWNERS_PATH,
    RESELLERS_PATH
} from "../consts";
import {useAsync} from "../server/utils";

import {Auth} from 'aws-amplify'
import {CognitoUser} from "amazon-cognito-identity-js";
import {UserContext} from "../appSwitch";
import { AlertHelper } from '../AlertHelper';

interface UpperNavBarProps {
    isMobile?: boolean;
}

const UpperNavBar: React.FC<UpperNavBarProps> = ({isMobile}) => {

    const style = StyleSheet.create({
        container: {
            flexDirection: "row",
            ...getBorder(),
            alignItems: "center",
            // height: 35,
            marginLeft: '6.75%',
            marginRight: '6.75%',
            marginTop: 29,
            // marginBottom: 112
        },
        containerMobile: {
            flexDirection: "row",
            ...getBorder(),
            alignItems: "center",
            // height: 35,
            marginLeft: '1.75%',
            marginRight: '1.75%',
            marginTop: 52,
            // marginBottom: 112
        },
        signInText: {
            ...getFontCss(FB_SPACER_BOLD, 21, ORANGE_COLOR, 'underline'),
            textAlign: "center",
        },
        signInTextMobile: {
            ...getFontCss(FB_SPACER_BOLD, 45, ORANGE_COLOR, 'underline'),
            textAlign: "center",
        },
        greetingMobile: {
            ...getFontCss(FB_SPACER_BOLD, 50, BLUE_COLOR),
            marginRight: 10},
        singInLink: {
            ...getBorder(),
            marginRight: 10,
        },
        selfSpaceText: {
            ...getFontCss(FB_SPACER_BOLD, 22, WHITE_COLOR),
        },
        selfSpaceLink: {
            ...getCircleCss(29, 102, 12),
            backgroundColor: BLUE_COLOR,
            marginRight: 10,
            textAlign: "center",
            justifyContent: "center",
            ...getBorder()
        },
        selfSpaceLinkMobile: {
            ...getCircleCss(48, 210, 12),
            backgroundColor: BLUE_COLOR,
            marginRight: 10,
            textAlign: "center",
            justifyContent: "center",
            ...getBorder()
        },
        noteText: {
            ...getFontCss(FB_SPACER_REGULAR, 15, BLUE_COLOR),
            ...getBorder()
        },
        noteTextMobile: {
            ...getFontCss(FB_SPACER_REGULAR, 34, BLUE_COLOR),
            ...getBorder()
        },
        noteImage: {
            marginLeft: '1%',
            width: 23.5,
            height: 23.5
        },
        noteImageMobile: {
            marginLeft: '1%',
            width: 31.5,
            height: 38.5
        },
        administrationText: {
            ...getFontCss(FB_SPACER_BOLD, 20, WHITE_COLOR),
        }
    })
    const {
        balance,
        isAuthenticated,
        username,
        isAdmin,
        isReseller,
        setIsAuthenticated,
        setUser,
        setIsisAuthenticating
    } = useContext(UserContext)

    const getPath = useCallback((path: string) => {
        return isAuthenticated ? path : SIGN_IN_PATH
    }, [isAuthenticated])

    const logout = () => {
        return Auth.signOut().then(() => {
            AlertHelper.show(`להתראות ${username}`,
            () => AlertHelper.hide(), 'normal')
            setIsAuthenticated(false)
            setIsisAuthenticating(true)
            setUser({} as CognitoUser)

            localStorage.removeItem('isAdmin')
            history.push(HOME_PAGE_PATH)
        })
    }
    const history = useHistory()
    const redirect = (path: string) => {
        history.push(path)
    }
    const {execute: executeSignOut} = useAsync<void>(logout, false)

    const greeting = username === 'אורח' ? '' : `שלום ${username} ${balance ? `₪${balance}` : '' }`

    return (
        <View style={isMobile ? style.containerMobile : style.container}>
            <View style={{flexDirection: "row", ...getBorder(), position: "absolute", right: 0, alignItems: "center"}}>
                {!isAuthenticated && <TouchableOpacity onPress={() => redirect(SIGN_IN_PATH)} style={style.singInLink}>
                    <Text style={isMobile ? style.signInTextMobile : style.signInText}>התחבר</Text>
                </TouchableOpacity>}
                {isAuthenticated && <TouchableOpacity onPress={executeSignOut} style={style.singInLink}>
                    <Text style={isMobile ? style.signInTextMobile : style.signInText}>התנתק</Text>
                </TouchableOpacity>}
                <Text style={isMobile ? style.greetingMobile : {...getFontCss(FB_SPACER_BOLD, 16, BLUE_COLOR), marginRight: 10}}>{greeting}</Text>
                {isAuthenticated && !isMobile &&
                <TouchableOpacity onPress={() => redirect(getPath(MY_SPACE_PATH))} style={style.selfSpaceLink}>
                    <Text style={style.selfSpaceText}>אזור אישי</Text>
                </TouchableOpacity>}
                {!isAuthenticated &&
                <TouchableOpacity onPress={() => redirect(SIGN_UP_PATH)} style={isMobile ? style.selfSpaceLinkMobile : style.selfSpaceLink}>
                    <Text style={isMobile ? {...getFontCss(FB_SPACER_BOLD, 45, WHITE_COLOR)} : {...getFontCss(FB_SPACER_BOLD, 18, WHITE_COLOR)}}>הירשם</Text>
                </TouchableOpacity>}
                {isAdmin && !isMobile &&
                <TouchableOpacity onPress={() => redirect(getPath(ADMINISTRATION_PATH))} style={style.selfSpaceLink}>
                    <Text style={style.administrationText}>ממשק ניהול</Text>
                </TouchableOpacity>}
                {isAdmin && !isMobile &&
                <TouchableOpacity onPress={() => redirect(getPath(OWNERS_PATH))} style={style.selfSpaceLink}>
                    <Text style={style.administrationText}>ניהול בכיר</Text>
                </TouchableOpacity>}
                {isReseller && !isMobile &&
                <TouchableOpacity onPress={() => redirect(getPath(RESELLERS_PATH))} style={style.selfSpaceLink}>
                    <Text style={style.administrationText}>ניהול משווק</Text>
                </TouchableOpacity>}
            </View>
            <div style={{ alignItems: 'center', width: '59%', display: 'flex' }}>
                <Text style={isMobile ? style.noteTextMobile : style.noteText}>אתר זה מעניק שירותי שליחה של הגרלות מפעל הפיס והינו גוף עצמאי.<br/>אסורה מכירה
                    למי שטרם מלאו לו 18 שנים</Text>
                <Image source={require('../../../assets/Warning.png')} style={isMobile ? style.noteImageMobile : style.noteImage}/>
            </div>
        </View>
    )
}


export default UpperNavBar
