import {makeStyles} from "@material-ui/core/styles";
import {
    BLUE_COLOR,
    FB_SPACER_BOLD,
    FB_SPACER_REGULAR,
    getCircleCss,
    getFontCss,
    LIGHT_BLUE_COLOR,
    WHITE_COLOR
} from "../styles";
import React, {useCallback, useContext, useState} from "react";
import {Text, TouchableOpacity, View} from "react-native";
import {Card, CardContent, Paper, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import {useAsync} from "../server/utils";
import {
    DEFAULT_ACTIVE_FORMS_HISTORY,
    DUPLICATE_OR_WATCH,
    FORM_TYPE,
    HEADERS,
    HEBREW_DUPLICATE_FORM,
    ID,
    ROWS
} from "../consts";
import {MyFormsHistoryRowsType, MyFormsHistoryRowType, MyFormsHistoryType, MyFormsHistoryTypeHeaders} from "../types";
import {getUserFormsHistory} from "../server/mySpaceApi";
import {getFormComponentByType} from "./utils";
import {UserContext} from "../appSwitch";
import {DownloadReceipt} from "../administration/adminPage";

let _ = require('lodash')

const MyFormsHistoryTableHead = ({headers}: { headers: MyFormsHistoryTypeHeaders }) => {
    const useStyles = makeStyles((theme) => ({
        headerCell: {
            background: BLUE_COLOR,
        }
    }));
    const classes = useStyles()

    return (
        <TableHead>
            <TableRow>
                {
                    headers.map((header: string) => <TableCell align={"right"}
                                                               className={classes.headerCell}>
                        {
                            <Text
                                style={{...getFontCss(FB_SPACER_REGULAR, 21, WHITE_COLOR)}}>{header}</Text>
                        }
                    </TableCell>)
                }
                <TableCell align={"right"} className={classes.headerCell}>
                    {
                        <Text
                            style={{...getFontCss(FB_SPACER_REGULAR, 21, WHITE_COLOR)}}>{HEBREW_DUPLICATE_FORM}</Text>
                    }
                </TableCell>
            </TableRow>
        </TableHead>
    )
}

const MyFormsHistoryTableBody = ({
                                     page,
                                     rowsPerPage,
                                     rows,
                                     headers,
                                     setFormComponent
                                 }: { setFormComponent: (func: () => () => JSX.Element | undefined) => void, page: number, rowsPerPage: number, rows: MyFormsHistoryRowsType, headers: MyFormsHistoryTypeHeaders }) => {

    const style: { text: object, buttonText: object, button: object } = {
        text: {...getFontCss(FB_SPACER_REGULAR, 21, WHITE_COLOR)},
        buttonText: {...getFontCss(FB_SPACER_BOLD, 15, WHITE_COLOR)},
        button: {
            marginLeft: '1%',
            justifyContent: "center",
            alignItems: "center",
            ...getCircleCss(37, 88, 6),
            borderWidth: 1,
            borderColor: WHITE_COLOR,
            borderStyle: "solid"
        }
    }
    const [clicked, setClicked] = useState('')
    const {jwtToken} = useContext(UserContext)

    const onPress = (row: MyFormsHistoryRowType) => {
        if (clicked !== row[headers[1]]) {
            setClicked(row[headers[1]]);
            setFormComponent(getFormComponentByType(row[FORM_TYPE], row['marks'], jwtToken, row["lottery_number"]))
        } else {
            setClicked('');
            setFormComponent(() => () => undefined)
        }
    }

    return (
        <TableBody>
            {
                rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) =>
                    <>
                        <TableRow>
                            {
                                _.range(3).map((number: 1 | 2 | 3) => <TableCell align={"right"}>
                                    <Text style={style.text}>
                                        {row[headers[number]]}
                                    </Text>
                                </TableCell>)
                            }
                            <TableCell align={"right"}>
                                <Text style={style.text}>
                                    <DownloadReceipt formId={row[ID]} />
                                </Text>
                            </TableCell>

                            <TableCell align={"right"}>
                                <TouchableOpacity
                                    onPress={() => onPress(row)}
                                    style={{
                                        ...style.button,
                                        backgroundColor: row[headers[1]] === clicked ? BLUE_COLOR : undefined
                                    }}>
                                    <Text style={style.buttonText}>
                                        {DUPLICATE_OR_WATCH}
                                    </Text>
                                </TouchableOpacity>
                            </TableCell>
                        </TableRow>
                    </>
                )
            }
        </TableBody>
    )
}

export const MyFormsHistoryTable = () => {

    // state
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [formComponent, setFormComponent] = useState<() => JSX.Element | undefined>(() => () => undefined)

    // Server request
    const {jwtToken} = useContext(UserContext)
    const getHistoryForms = useCallback(async () => {
        if (jwtToken) {
            return await getUserFormsHistory(jwtToken)
        }
    }, [jwtToken])
    const {value} = useAsync<MyFormsHistoryType>(getHistoryForms, true)

    // Styles
    const useStyles = makeStyles((theme) => ({
        paper: {
            width: formComponent() ? '50%' : '100%',
            marginBottom: 10,
            backgroundColor: LIGHT_BLUE_COLOR,
            height: 'fit-content'
        },
        tablePagination: {
            borderBottom: "none",
            ...getFontCss(FB_SPACER_REGULAR, '0.875rem', WHITE_COLOR)
        },
        headerCell: {
            background: BLUE_COLOR,
        },
        card: {
            backgroundColor: BLUE_COLOR,
            height: 'fit-content',
            width: '50%',
            borderRadius: 12,
            marginBottom: 10,
            marginRight: 10
        }
    }));
    const classes = useStyles()

    // Set up data
    const data: MyFormsHistoryType = value || DEFAULT_ACTIVE_FORMS_HISTORY


    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <View style={{flexDirection: "row-reverse", width: '100%'}}>
            <Paper dir={"rtl"} className={classes.paper}>
                <TableContainer>
                    <Table size={'medium'}>
                        <MyFormsHistoryTableHead headers={data[HEADERS]}/>
                        <MyFormsHistoryTableBody page={page} rowsPerPage={rowsPerPage} rows={data[ROWS]}
                                                 headers={data[HEADERS]} setFormComponent={setFormComponent}/>
                    </Table>
                </TableContainer>
                <TablePagination
                    dir={"ltr"}
                    rowsPerPageOptions={[10, 25]}
                    count={data['rows'].length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage={'שורות'}
                    className={classes.tablePagination}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />

            </Paper>

            {
                formComponent() &&
                <Card className={classes.card}>
                    <CardContent>
                        {
                            formComponent()
                        }
                    </CardContent>
                </Card>
            }
        </View>
    );
}

export default MyFormsHistoryTable