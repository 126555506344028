import React from "react";
import { Space, Card } from 'antd';

interface IProps {
    totalPrice: number,
    isLoading: boolean,
    numberOfForms: number,
    totalOriginPrice?: number,
}

export const Stats: React.FC<IProps> = ({totalPrice, totalOriginPrice, isLoading, numberOfForms}) => {
    return (
        <Space direction="horizontal" size="large" style={{ display: 'flex' }}>
            <Card title="Total Price" size="small" loading={isLoading}>
                <p>{totalPrice.toFixed(2)}</p>
            </Card>
            {totalOriginPrice && <><Card title="Total Origin Price" size="small" loading={isLoading}>
                <p>{totalOriginPrice.toFixed(2)}</p>
            </Card>
                <Card title="Total Revenue" size="small" loading={isLoading}>
                <p>{(totalPrice - totalOriginPrice).toFixed(2)}</p>
            </Card></>}
            <Card title="Number Of Forms" size="small" loading={isLoading}>
                <p>{numberOfForms}</p>
            </Card>
        </Space>
    );
}