import React from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native'
import {BLUE_COLOR, FB_SPACER_REGULAR, getBorder, getFontCss} from "../styles";
import {useHistory} from "react-router-dom";
import {ABOUT_PATH, CONTACT_US_PATH, HOME_PAGE_PATH, RESULTS_PATH} from "../consts";

const NavBar = () => {

    const style = StyleSheet.create({
        container: {
            position: "relative",
            flexDirection: "row-reverse",
            ...getBorder(),
            height: 50,
            marginLeft: '6.75%',
            marginRight: '6.75%',
        },
        linkStyle: {
            marginLeft: 35,
            height: 23,
            ...getBorder()
        },
        linkTextStyle: {
            ...getFontCss(FB_SPACER_REGULAR, 23, BLUE_COLOR),
        }
    })
    const history = useHistory()


    return (
        <>

            <View style={style.container}>
                <TouchableOpacity style={style.linkStyle} onPress={() => history.push(HOME_PAGE_PATH)}>
                    <Text style={style.linkTextStyle}> כל המשחקים</Text>
                </TouchableOpacity>
                <TouchableOpacity style={style.linkStyle} onPress={() => history.push(RESULTS_PATH)}>
                    <Text style={style.linkTextStyle}>תוצאות</Text>
                </TouchableOpacity>
                <TouchableOpacity style={style.linkStyle} onPress={() => history.push(HOME_PAGE_PATH)}>
                    <Text style={style.linkTextStyle}>עזרה</Text>
                </TouchableOpacity>
                <TouchableOpacity style={style.linkStyle} onPress={() => history.push(ABOUT_PATH)}>
                    <Text style={style.linkTextStyle}>אודות</Text>
                </TouchableOpacity>
                <TouchableOpacity style={style.linkStyle} onPress={() => history.push(CONTACT_US_PATH)}>
                    <Text style={style.linkTextStyle}>צור קשר</Text>
                </TouchableOpacity>

            </View>
            <TouchableOpacity style={{
                display: 'flex', position: 'absolute',
                left: '6.75%',
                top: '80px'
            }}>
                <img onClick={() => history.push(HOME_PAGE_PATH)} alt={''}
                     src={require('../../../assets/loto logo01.png')} style={{ height: 150, width: 300}}/>
            </TouchableOpacity>
        </>
    )
}


export default NavBar