import React from 'react';
import {ImageSourcePropType, ImageStyle, StyleSheet, View} from "react-native";
import NextLotteryTimer from "./nextLotteryTimer";
import NextLotteryPrice from "./nextLotteryPrice";
import {getBorder} from "../../../styles";


type NextRegularLotteryInfo = {
    img?: ImageSourcePropType,
    imgStyle?: ImageStyle,
    color: string,
    formType: string,
    boldText?: string,
    price?: string,
    endTime: string
}


const NextRegularLotteryInfo: React.FC<NextRegularLotteryInfo> = ({
                                                                      endTime,
                                                                      color,
                                                                      formType,
                                                                      img,
                                                                      imgStyle,
                                                                      price,
                                                                      boldText
                                                                  }) => {

    const style = StyleSheet.create({
        container: {
            flexDirection: 'row',
            height: 193,
            marginLeft: '12.4%',
            marginRight: '12.4%',
            ...getBorder()
        }
    })


    return (
        <View style={style.container}>
            <NextLotteryTimer color={color} endTime={endTime}/>
            <NextLotteryPrice boldText={boldText} price={price} formType={formType} color={color} img={img}
                              imgStyle={imgStyle}/>
        </View>
    )
}


export default NextRegularLotteryInfo