import React from "react";
import {StyleSheet, Text, TextStyle, View, ViewStyle} from "react-native";
import {getHeightWidthCss, WHITE_COLOR} from "./styles";

type PipeLineType = {
    marginLeft?: string | number,
    marginRight?: string | number,
    color?: string
    height?: string | number,
    isMobile?: boolean,
}
type ContainerWithTextType = {
    text: string,
    containerStyle: ViewStyle,
    textStyle: TextStyle
}


export const ContainerWithText = ({text, containerStyle, textStyle}: ContainerWithTextType) => {

    return (
        <View style={containerStyle}>
            <Text style={textStyle}>{text}</Text>
        </View>
    )
}

export const PipeLine = ({marginLeft, marginRight, color, height, isMobile}: PipeLineType) => {

    const style = StyleSheet.create({
        pipeLine: {
            ...getHeightWidthCss(height || 33, 1),
            backgroundColor: color || WHITE_COLOR,
            marginLeft: marginLeft || 0,
            marginRight: marginRight || 0
        },
        pipeLineMobile: {
            ...getHeightWidthCss(69, 6),
            backgroundColor: color || WHITE_COLOR,
            marginLeft: marginLeft || 0,
            marginRight: marginRight || 0
        }
    })

    return (<View style={isMobile ? style.pipeLineMobile : style.pipeLine}/>)
}

export const Line = ({height, backgroundColor}: { height: string | number, backgroundColor: string }) => {
    const style = StyleSheet.create({
            line: {
                backgroundColor: backgroundColor,
                height: height,
                width: '100%'
            }
        }
    )

    return (
        <View style={style.line}/>
    )
}