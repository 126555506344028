import React from 'react';
import {Image, ImageSourcePropType, ImageStyle, StyleSheet, Text, View} from "react-native";
import {BLUE_COLOR, FB_SPACER_BOLD, getBorder, getFontCss, getHeightWidthCss} from "../../../styles";


type NextLotteryPriceTextType = {
    boldTextColor: string,
    boldText?: string
    price?: string
    formType: string,
    marginRight?: number | string,
    marginTop?: number | string
}

export const NextLotteryPriceText: React.FC<NextLotteryPriceTextType> = ({
                                                                             marginTop,
                                                                             price,
                                                                             boldText,
                                                                             marginRight,
                                                                             formType,
                                                                             boldTextColor
                                                                         }) => {
    const style = StyleSheet.create({
        text: {
            ...getFontCss(FB_SPACER_BOLD, 45, BLUE_COLOR),
            position: "relative",
            marginTop: marginTop || 0,
            marginRight: marginRight || 0
        },
        redText: {
            ...getFontCss(FB_SPACER_BOLD, 45, boldTextColor),
        },
    })

    return (
        <Text style={style.text}>
            {`הגרלת ${formType}`}<br/><Text style={style.redText}>{boldText && boldText}</Text>{price && price !== '0' && ` ₪${price}`}
        </Text>
    )
}

type NextLotteryPriceType = {
    imgStyle?: ImageStyle,
    img?: ImageSourcePropType,
    color: string,
    formType: string
    boldText?: string
    price?: string
}

const NextLotteryPrice: React.FC<NextLotteryPriceType> = ({formType, boldText, price, color, img, imgStyle}) => {

    const style = StyleSheet.create({
        container: {
            ...getHeightWidthCss('inherit', '50%'),
            ...getBorder(),
            flexDirection: "row-reverse",
        },
        lottoBalls: {
            position: "absolute",
            alignSelf: "center",
            right: '64%',
            ...getHeightWidthCss(128, 128)
        }
    })

    return (
        <View style={style.container}>
            <NextLotteryPriceText boldText={boldText} price={price} marginTop={66} formType={formType}
                                  boldTextColor={color}/>
            {img && <Image source={img} style={imgStyle || {}}/>}
        </View>
    )
}


export default NextLotteryPrice
