import {handleErrors} from "./utils";
import {GET_USER_BALANCE_PATH} from "./configurations";

export const getUserBalance = async (jwtToken: string): Promise<number> => {
    return await handleErrors(fetch(GET_USER_BALANCE_PATH.href, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: jwtToken
        },
    }))
};