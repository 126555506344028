import React from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {
    FB_SPACER_BOLD,
    FB_SPACER_REGULAR,
    getBorder,
    getCircleCss,
    getFontCss,
    RED_COLOR,
    UNDERLINE_DECORATION,
    WHITE_COLOR
} from "../../../styles";


const FormTypeNavigator = ({onPress, isDouble, isMobile}: { onPress: (isDouble: boolean) => void, isDouble: boolean, isMobile?: boolean }) => {

    const style = StyleSheet.create({
        formTypeContainer: {
            flexDirection: 'row-reverse',
            height: 73,
            backgroundColor: RED_COLOR,
            paddingTop: 28,
            ...getBorder()

        },
        formTypeContainerMobile: {
            flexDirection: 'row-reverse',
            backgroundColor: RED_COLOR,
            paddingTop: 28,
            alignItems: 'center',
            justifyContent: 'center',
            ...getBorder()

        },
        choseText: {
            ...getFontCss(FB_SPACER_REGULAR, 18, WHITE_COLOR, UNDERLINE_DECORATION),
            marginRight: '30%',
            marginLeft: '1.2%',
        },
        choseTextMobile: {
            ...getFontCss(FB_SPACER_REGULAR, 49, WHITE_COLOR, UNDERLINE_DECORATION),
            marginLeft: '1.2%',
        },
        unpickedTextContainer: {
            marginLeft: '1.2%',
            backgroundColor: WHITE_COLOR,
            ...getCircleCss(24, 94, 12),
            justifyContent: "center",
            alignItems: "center"
        },
        unpickedTextContainerMobile: {
            marginLeft: '1.2%',
            backgroundColor: WHITE_COLOR,
            ...getCircleCss(39, 135, 12),
            justifyContent: "center",
            alignItems: "center"
        },
        pickedText: {
            ...getFontCss(FB_SPACER_BOLD, 18, RED_COLOR),
        },
        pickedTextMobile: {
            ...getFontCss(FB_SPACER_BOLD, 47, RED_COLOR),
        },
        pickedTextContainer: {
            marginLeft: '1.2%',
            backgroundColor: RED_COLOR,
            borderStyle: "solid",
            borderColor: WHITE_COLOR,
            borderWidth: 1,
            ...getCircleCss(24, 94, 12),
            justifyContent: "center",
            alignItems: "center"
        },
        pickedTextContainerMobile: {
            marginLeft: '1.2%',
            backgroundColor: RED_COLOR,
            borderStyle: "solid",
            borderColor: WHITE_COLOR,
            borderWidth: 1,
            ...getCircleCss(39, 135, 12),
            justifyContent: "center",
            alignItems: "center"
        },
        unpickedText: {
            ...getFontCss(FB_SPACER_BOLD, 18, WHITE_COLOR),
        },
        unpickedTextMobile: {
            ...getFontCss(FB_SPACER_BOLD, 47, WHITE_COLOR),
        }
    })

    const setDouble = () => {
        onPress(true)
    }

    const setRegular = () => {
        onPress(false)
    }


    return (
        <View style={isMobile ? style.formTypeContainerMobile : style.formTypeContainer}>
            <Text style={isMobile ? style.choseTextMobile : style.choseText}>בחר סוג טופס</Text>
            {isMobile ?
                <>
                    <TouchableOpacity style={isDouble ? style.pickedTextContainerMobile : style.unpickedTextContainerMobile} onPress={setRegular}>
                        <Text style={isDouble ? style.unpickedTextMobile : style.pickedTextMobile}>רגיל</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={isDouble ? style.unpickedTextContainerMobile : style.pickedTextContainerMobile} onPress={setDouble}>
                        <Text style={isDouble ? style.pickedTextMobile : style.unpickedTextMobile}>דאבל</Text>
                    </TouchableOpacity>
                </>
                :
                <>
                    <TouchableOpacity style={isDouble ? style.pickedTextContainer : style.unpickedTextContainer} onPress={setRegular}>
                        <Text style={isDouble ? style.unpickedText : style.pickedText}>רגיל</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={isDouble ? style.unpickedTextContainer : style.pickedTextContainer} onPress={setDouble}>
                        <Text style={isDouble ? style.pickedText : style.unpickedText}>דאבל</Text>
                    </TouchableOpacity>
                </>
            }
        </View>
    )
}


export default FormTypeNavigator