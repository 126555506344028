import {makeStyles} from "@material-ui/core/styles";
import {
    BLUE_COLOR,
    FB_SPACER_BOLD,
    FB_SPACER_REGULAR,
    getCircleCss,
    getFontCss,
    LIGHT_BLUE_COLOR,
    SUCCESS_GREEN_COLOR,
    WHITE_COLOR
} from "../styles";
import React, {useCallback, useContext, useState} from "react";
import {Text, TouchableOpacity, View} from "react-native";
import {Card, CardContent, Paper, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import {useAsync} from "../server/utils";
import {
    DEFAULT_ACTIVE_FORMS_DATA,
    HEADERS,
    HEBREW_COMPLETED,
    HEBREW_DATE_AND_TIME,
    HEBREW_FORM_COST,
    HEBREW_NUMBER,
    HEBREW_STATUS,
    HEBREW_DUPLICATE_FORM,
    ID,
    ROWS,
    FORM_TYPE,
    DUPLICATE_OR_WATCH
} from "../consts";
import {MyActiveFormsHeadersType} from "../types";
import {getUserActiveForms} from "../server/mySpaceApi";
import {UserContext} from "../appSwitch";
import {DownloadReceipt} from "../administration/adminPage";
import { getFormComponentByType } from "./utils";

let _ = require('lodash')

export type MyActiveFormsRowsType = {
    [HEBREW_NUMBER]: number
    [HEBREW_DATE_AND_TIME]: string,
    [HEBREW_FORM_COST]: number,
    [HEBREW_STATUS]: string,
    [HEBREW_DUPLICATE_FORM]: string,
    [ID]: string
}[]
export type MyActiveFormsType = {
    headers: MyActiveFormsHeadersType,
    rows: MyActiveFormsRowsType
}

const MyActiveFormsTableHead = ({headers}: { headers: MyActiveFormsHeadersType }) => {
    const useStyles = makeStyles((theme) => ({
        headerCell: {
            background: BLUE_COLOR,
        }
    }));
    const classes = useStyles()
    return (
        <TableHead>
            <TableRow>
                {
                    headers.map((header: string) => <TableCell align={"right"}
                                                               className={classes.headerCell}>
                        {
                            <Text
                                style={{...getFontCss(FB_SPACER_REGULAR, 21, WHITE_COLOR)}}>{header}</Text>
                        }
                    </TableCell>)
                }
            </TableRow>
        </TableHead>
    )
}

const MyActiveFormsTableBody = ({
                                    page,
                                    rowsPerPage,
                                    rows,
                                    headers,
                                    setFormComponent
                                }: { page: number, rowsPerPage: number, rows: MyActiveFormsRowsType, headers: MyActiveFormsHeadersType }) => {

    const style: { text: object, buttonText: object, button: object } = {
        text: {...getFontCss(FB_SPACER_REGULAR, 21, WHITE_COLOR)},
        buttonText: {...getFontCss(FB_SPACER_BOLD, 15, WHITE_COLOR)},
        button: {
            justifyContent: "center",
            alignItems: "center",
            ...getCircleCss(37, 88, 6),
            borderWidth: 1,
            borderColor: WHITE_COLOR,
            borderStyle: "solid"
        }
    }
    const [clicked, setClicked] = useState('')
    const {jwtToken} = useContext(UserContext)

    const onPress = (row: MyActiveFormsRowsType) => {
        if(!row){
            setClicked('');
            setFormComponent(() => () => undefined)
            return
        }
        if (clicked !== row[headers[1]]) {
            setClicked(row[headers[1]]);
            setFormComponent(getFormComponentByType(row[FORM_TYPE], row['marks'], jwtToken, row["lottery_number"],false,onPress))
        } else {
            setClicked('');
            setFormComponent(() => () => undefined)
        }
    }

    return (
        <TableBody>
            {
                rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) =>
                    <TableRow>
                        <TableCell align={"right"}>
                            <Text style={style.text}>
                                {row[headers[0]]}
                            </Text>
                        </TableCell>
                        <TableCell align={"right"}>
                            <Text style={style.text}>
                                {row[headers[1]]}
                            </Text>
                        </TableCell>
                        <TableCell align={"right"}>
                            <Text style={style.text}>
                                {row[headers[2]]}
                            </Text>
                        </TableCell>
                        <TableCell align={"right"}>
                            <TouchableOpacity style={{
                                ...style.button,
                                backgroundColor: row[headers[3]] === HEBREW_COMPLETED ? SUCCESS_GREEN_COLOR : undefined
                            }}>
                                <Text style={style.buttonText}>
                                    {row[headers[3]]}
                                </Text>
                            </TouchableOpacity>
                        </TableCell>
                        <TableCell align={"right"}>
                            <Text style={style.text}>
                                <DownloadReceipt formId={row[ID]} />
                            </Text>
                        </TableCell>
                        <TableCell align={"right"}>
                                <TouchableOpacity
                                    onPress={() => onPress(row)}
                                    style={{
                                        ...style.button,
                                        backgroundColor: row[headers[1]] === clicked ? BLUE_COLOR : undefined
                                    }}>
                                    <Text style={style.buttonText}>
                                        {DUPLICATE_OR_WATCH}
                                    </Text>
                                </TouchableOpacity>
                            </TableCell>
                    </TableRow>
                )
            }
        </TableBody>
    )
}

export const MyActiveFormsTable = () => {
    const [formComponent, setFormComponent] = useState<() => JSX.Element | undefined>(() => () => undefined)
    const useStyles = makeStyles((theme) => ({
        paper: {
            width: formComponent() ? '50%' : '100%',    
            marginBottom: theme.spacing(2),
            backgroundColor: LIGHT_BLUE_COLOR,
            height: 'fit-content'
        },
        tablePagination: {
            borderBottom: "none",
            ...getFontCss(FB_SPACER_REGULAR, '0.875rem', WHITE_COLOR)
        },
        headerCell: {
            background: BLUE_COLOR,
        },
        card: {
            backgroundColor: BLUE_COLOR,
            height: 'fit-content',
            width: '50%',
            borderRadius: 12,
            marginBottom: 10,
            marginRight: 10
        }
    }));
    const classes = useStyles()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const {jwtToken} = useContext(UserContext)
    const getActiveForms = useCallback(async () => {
        if (jwtToken) {
            return await getUserActiveForms(jwtToken)
        }
    }, [jwtToken])
    const {value} = useAsync<MyActiveFormsType>(getActiveForms, true)
    const data: MyActiveFormsType = value || DEFAULT_ACTIVE_FORMS_DATA

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <View style={{flexDirection: "row-reverse", width: '100%'}}>
            <Paper dir={"rtl"} className={classes.paper}>
                <TableContainer>
                    <Table size={'medium'}>
                        <MyActiveFormsTableHead headers={data[HEADERS]}/>
                        <MyActiveFormsTableBody page={page} rowsPerPage={rowsPerPage} rows={data[ROWS]}
                                                headers={data[HEADERS]} setFormComponent={setFormComponent}/>
                    </Table>
                </TableContainer>
                <TablePagination
                    dir={"ltr"}
                    width={'inherit'}
                    rowsPerPageOptions={[10, 25]}
                    count={data['rows'].length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage={'שורות'}
                    className={classes.tablePagination}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />

            </Paper>
            {
                formComponent() &&
                <Card className={classes.card}>
                    <CardContent>
                        {
                            formComponent()
                        }
                    </CardContent>
                </Card>
            }
        </View>
    );
}

export default MyActiveFormsTable