import React, {useEffect, useState} from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {
    BLUE_COLOR,
    FB_SPACER_BOLD,
    FB_SPACER_REGULAR,
    getBorder,
    getCircleCss,
    getFontCss,
    getHeightWidthCss,
    WHITE_COLOR
} from "../../../styles";
import {useHistory} from "react-router-dom";
import {RESULTS_PATH} from "../../../consts";
import moment from 'moment'

interface LottoTimerProps {
    endTime: string,
    marginLeft?: number | string,
    backgroundColor: string,
    isMobile?: boolean,
}

export const LottoTimer = ({marginLeft, backgroundColor, endTime, isMobile}: LottoTimerProps) => {
    const timerBlock: object = {
        backgroundColor: backgroundColor,
        ...getCircleCss(45, 43, 10),
        justifyContent: "center",
        alignItems: 'center'
    }
    const timerBlockMobile: object = {
        backgroundColor: backgroundColor,
        justifyContent: "center",
        padding: 19,
        alignItems: 'center',
        borderRadius: 10,
    }
    const style = StyleSheet.create({
        mainContainer: {
            marginLeft: marginLeft || 0
        },
        timerContainer: {
            flexDirection: "row",
            ...getBorder()
        },
        timerCoupleContainer: {
            flexDirection: "row",
            marginRight: 13
        },
        timerCoupleContainerMobile: {
            flexDirection: "row",
            marginRight: 21
        },
        timerBlockLeft: {
            ...timerBlock,
            marginRight: 4
        },
        timerBlock: {
            ...timerBlock,
        },
        timerBlockLeftMobile: {
            ...timerBlockMobile,
            marginRight: 8
        },
        timerBlockMobile: {
            ...timerBlockMobile,
        },
        timerText: {
            ...getFontCss(FB_SPACER_REGULAR, 35, WHITE_COLOR),
        },
        timerTextMobile: {
            ...getFontCss(FB_SPACER_REGULAR, 101, WHITE_COLOR),
        },
        timeUnitsContainer: {
            flexDirection: "row",
        },
        timeUnitsContainerMobile: {
            flexDirection: "row",
            marginRight: 17,
        },
        timeUnitsText: {
            ...getFontCss(FB_SPACER_BOLD, 21, BLUE_COLOR),
            marginLeft: 30,
            marginRight: 31
        },
        timeUnitsTextMobile: {
            ...getFontCss(FB_SPACER_BOLD, 54, BLUE_COLOR),
            marginLeft: 30,
            marginRight: 46
        }
    })
    const [time, setTime] = useState(moment())
    const newTime = moment(endTime)

    useEffect(() => {
        const clockInterval = setInterval(() => setTime(moment()), 1000)

        return () => clearInterval(clockInterval)
    })


    const duration = moment.duration(newTime.diff(time))
    const days = newTime > time ? duration.get('days'): 0;
    const hours = newTime > time ? duration.get('hours'): 0;
    const minutes = newTime > time ? duration.get('minutes'): 0;
    const seconds = newTime > time ? duration.get('seconds'): 0;


    return (
        <View style={style.mainContainer}>
            <View style={style.timerContainer}>
                <View style={isMobile ? style.timerCoupleContainerMobile : style.timerCoupleContainer}>
                    <View style={isMobile ? style.timerBlockLeftMobile : style.timerBlockLeft}>
                        <Text style={isMobile ? style.timerTextMobile : style.timerText}>{Math.floor(days / 10)}</Text>
                    </View>
                    <View style={isMobile ? style.timerBlockMobile : style.timerBlock}>
                        <Text style={isMobile ? style.timerTextMobile : style.timerText}>{Math.floor(days % 10)}</Text>
                    </View>
                </View>
                <View style={isMobile ? style.timerCoupleContainerMobile : style.timerCoupleContainer}>
                    <View style={isMobile ? style.timerBlockLeftMobile : style.timerBlockLeft}>
                        <Text style={isMobile ? style.timerTextMobile : style.timerText}>{Math.floor(hours / 10)}</Text>
                    </View>
                    <View style={isMobile ? style.timerBlockMobile : style.timerBlock}>
                        <Text style={isMobile ? style.timerTextMobile : style.timerText}>{Math.floor(hours % 10)}</Text>
                    </View>
                </View>
                <View style={isMobile ? style.timerCoupleContainerMobile : style.timerCoupleContainer}>
                    <View style={isMobile ? style.timerBlockLeftMobile : style.timerBlockLeft}>
                        <Text style={isMobile ? style.timerTextMobile : style.timerText}>{Math.floor(minutes / 10)}</Text>
                    </View>
                    <View style={isMobile ? style.timerBlockMobile : style.timerBlock}>
                        <Text style={isMobile ? style.timerTextMobile : style.timerText}>{Math.floor(minutes % 10)}</Text>
                    </View>
                </View>
                <View style={isMobile ? style.timerCoupleContainerMobile : style.timerCoupleContainer}>
                    <View style={isMobile ? style.timerBlockLeftMobile : style.timerBlockLeft}>
                        <Text style={isMobile ? style.timerTextMobile : style.timerText}>{Math.floor(seconds / 10)}</Text>
                    </View>
                    <View style={isMobile ? style.timerBlockMobile : style.timerBlock}>
                        <Text style={isMobile ? style.timerTextMobile : style.timerText}>{Math.floor(seconds % 10)}</Text>
                    </View>
                </View>
            </View>
            <View style={style.timeUnitsContainerMobile}>
                <Text style={isMobile ? style.timeUnitsTextMobile : style.timeUnitsText}>ימים</Text>
                <Text style={isMobile ? style.timeUnitsTextMobile : style.timeUnitsText}>שעות</Text>
                <Text style={isMobile ? style.timeUnitsTextMobile : style.timeUnitsText}>דקות</Text>
                <Text style={isMobile ? style.timeUnitsTextMobile : style.timeUnitsText}>שניות</Text>
            </View>
        </View>
    )
}

export const Archive = ({marginTop, color, param, isMobile}: { marginTop?: number | string, color: string, param?: number, isMobile?: boolean }) => {

    const history = useHistory()

    const style = StyleSheet.create({
        archiveContainer: {
            borderStyle: "solid",
            justifyContent: "center",
            alignItems: "center",
            borderColor: color,
            borderWidth: 2,
            ...getCircleCss(30, 115, 12),
            marginTop: marginTop || 0,
        },
        archive: {
            ...getFontCss(FB_SPACER_REGULAR, 18, color),
            fontWeight: "bold",
        },
        archiveContainerMobile: {
            borderStyle: "solid",
            justifyContent: "center",
            alignItems: "center",
            borderColor: color,
            borderWidth: 2,
            borderRadius: 12,
            padding: 10,
            marginTop: 29,
        },
        archiveMobile: {
            ...getFontCss(FB_SPACER_REGULAR, 48, color),
            fontWeight: "bold",
        }
    })

    return (
        <TouchableOpacity onPress={() => {
            history.push({
                pathname : RESULTS_PATH,
                search : '?num='+param
                })
            }} style={isMobile ? style.archiveContainerMobile : style.archiveContainer}>
            <Text style={isMobile ? style.archiveMobile : style.archive}>ארכיון תוצאות</Text>
        </TouchableOpacity>
    )
}

const NextLotteryTimer = ({color, endTime}: { color: string, endTime: string }) => {

    const style = StyleSheet.create({
        innerContainer: {
            ...getHeightWidthCss('inherit', '50%'),
            ...getBorder()
        },
        textContainer: {
            flexDirection: "row",
            height: 48,
            ...getBorder()
        },
        text: {
            ...getFontCss(FB_SPACER_BOLD, 35, BLUE_COLOR),
            marginLeft: 38
        }
    })


    return (
        <View style={style.innerContainer}>
            <View style={style.textContainer}>
                <Text style={style.text}>ההגרלה הבאה בעוד</Text>
            </View>
            <LottoTimer backgroundColor={color} endTime={endTime}/>
            <Archive marginTop={15} color={color}/>
        </View>
    )
}


export default NextLotteryTimer
