import React from 'react';
import { Route, Redirect } from "react-router-dom";

const GuardedRoute = ({ Component, auth, type = 'isAdmin', ...rest }) => {
    return(
        <Route {...rest} render={(props) => (
            (auth===true || window.localStorage.getItem(type)) ? <Component {...props} /> : <Redirect to='/' />
        )} />
    )
}

export default GuardedRoute;