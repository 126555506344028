import React, {useCallback, useContext, useEffect, useReducer, useState} from "react";
import {StyleSheet, View} from "react-native";
import {getBorder, LIGHT_GREEN_COLOR} from "../../styles";
import {
    LevelType,
    LottoMarksType,
    LottoNumberType,
    MultiLotteryChanceType,
    MultiLotteryType,
    RegularLottoFormType,
    UserFormType
} from "../../types";
import {
    HEBREW_MAIN_TITLE,
    HEBREW_SECOND_STEP_TITLE,
    INIT_TABLE_INDEX,
    NON_NUMBER,
    NUMBERS,
    STRICT,
    STRONG_NUMBERS,
    TABLES
} from "../../consts";
import FirstSectionInstructions, {InstructionStep} from "./additionalSections/firstSectionInstructions";
import UserInputForm, {UserInputFormType} from "./inputForm/userInputForm";
import UserForm, {UserFormContainer} from "./userForm/userForm";
import FormAdditions from "./additionalSections/formAdditions";
import SumAndSubmitSection from "./additionalSections/sumAndSubmitSection";
import {regularLottoReducer} from "./regularLottoReducer";
import {UserContext} from "../../appSwitch";

import { AlertHelper } from '../../AlertHelper';
import {normalizeTablesData} from "../../server/utils";

let _ = require('lodash')

const SUB_TITLE = 'בחר שישה מספרים'


const RegularLottoForm = ({numbersLength, tablesNumber, initForm, postFormFunction, calcPriceFunction, strongNumbersLength, formType, color, isMobile}: RegularLottoFormType) => {
    const [tableIndex, setTableIndex] = useState<number>(INIT_TABLE_INDEX)
    const [marks, dispatchMarks] = useReducer(regularLottoReducer, initForm)
    const {jwtToken} = useContext(UserContext)
    const postFunction = useCallback(() => postFormFunction(marks, jwtToken), [marks])

    const calcFunction = useCallback(() => calcPriceFunction(marks, jwtToken), [marks])

    // Different between forms.
    const validateForm = (lvl: LevelType = STRICT): boolean => {
        let filledTables = 0
        for (let table of marks[TABLES]) {
            if (table[STRONG_NUMBERS].length === strongNumbersLength && table[NUMBERS].length === numbersLength) {
                filledTables += 1
            } else {
                if ((table[NUMBERS].length > 0 && table[NUMBERS].length < numbersLength) && (table[STRONG_NUMBERS].length < strongNumbersLength && table[STRONG_NUMBERS].length > 0)) {
                    if(lvl === STRICT){
                        AlertHelper.show("יש לך לפחות שורה אחת ריקה",
                        () => AlertHelper.hide(),'sendGameForm')
                    }
                    return false
                }
            }
        }
        if (filledTables % 2 == 0 && filledTables !== 0) {
            return true
        }
        // TODO: calc 2/4/6/8/10/12/14.
        if(lvl === STRICT){
            AlertHelper.show("הטופס צריך להכיל שורות מלאות 2/4/6/8/10/12/14",
                () => AlertHelper.hide(),'sendGameForm')
        }
        return false
    }

    const updateIndex = () => {
        if (marks[TABLES][tableIndex][NUMBERS].length === numbersLength && marks[TABLES][tableIndex][STRONG_NUMBERS].length === strongNumbersLength) {
            if (tableIndex + 1 !== tablesNumber) {
                setTableIndex(Number(tableIndex) + 1)
            }
        }
    }

    const tableAutoInsertion = () => {
        dispatchMarks({
            type: 'tableAutoInsertion',
            value: NON_NUMBER,
            tableIndex: tableIndex,
            numbersLength,
            strongNumbersLength
        })
    }

    const popTableNumbers = useCallback(() => {
        dispatchMarks({
            type: 'popTableNumbers',
            numbersLength,
            strongNumbersLength,
            value: NON_NUMBER,
            tableIndex: tableIndex
        })
    }, [tableIndex])

    const addNumber = useCallback((number: LottoNumberType) => {
        dispatchMarks({type: 'addNumber', numbersLength, strongNumbersLength, value: number, tableIndex: tableIndex})
    }, [tableIndex])

    const removeNumber = useCallback((number: LottoNumberType) => {
        dispatchMarks({type: 'removeNumber', numbersLength, strongNumbersLength, value: number, tableIndex: tableIndex})
    }, [tableIndex])

    const removeStrongNumber = useCallback((number: LottoNumberType) => {
        dispatchMarks({
            type: 'removeStrongNumber',
            numbersLength,
            strongNumbersLength,
            value: number,
            tableIndex: tableIndex
        })
    }, [tableIndex])

    const addStrongNumber = useCallback((strongNumber: LottoNumberType) => {
        dispatchMarks({
            type: 'addStrongNumber',
            numbersLength,
            strongNumbersLength,
            value: strongNumber,
            tableIndex: tableIndex
        })
    }, [tableIndex])

    const incrementTableIndex = useCallback(() => {
        if (Number(tableIndex) + 1 === tablesNumber) {
            setTableIndex(0)
            return
        }
        setTableIndex(Number(tableIndex) + 1)
    }, [tableIndex])

    const decrementTableIndex = useCallback(() => {
        if (Number(tableIndex) - 1 < INIT_TABLE_INDEX) {
            setTableIndex(tablesNumber - 1)
            return
        }
        setTableIndex(Number(tableIndex) - 1)
    }, [tableIndex])

    const setExtra = () => {
        dispatchMarks({numbersLength, strongNumbersLength, type: 'setExtra', value: NON_NUMBER, tableIndex: tableIndex})
    }

    const setMultiLottery = (number: MultiLotteryType | MultiLotteryChanceType) => {
        dispatchMarks({
            numbersLength,
            strongNumbersLength,
            type: 'setMultiLottery',
            value: NON_NUMBER,
            tableIndex: tableIndex,
            multiLotteryValue: (number as MultiLotteryType)
        })
    }

    useEffect(updateIndex, [marks])

    return (
        <>
            <FirstSectionInstructions mainTitle={HEBREW_MAIN_TITLE} subTitle={SUB_TITLE} bacgroundColor={color} isMobile={isMobile}/>
            <UserInput marks={marks} color={color} popTableNumbers={popTableNumbers}
                       tableAutoInsertion={tableAutoInsertion} removeNumber={removeNumber} numbersLength={numbersLength}
                       strongNumbersLength={strongNumbersLength} tableIndex={tableIndex}
                       table={marks[TABLES][tableIndex]} tablesNumber={tablesNumber}
                       removeStrongNumber={removeStrongNumber} incrementTableIndex={incrementTableIndex}
                       decrementTableIndex={decrementTableIndex} addNumber={addNumber}
                       addStrongNumber={addStrongNumber} isMobile={isMobile} setTableIndex={setTableIndex}/>
            {/*<InstructionStep marginTop={35} containerHeight={72} stepText={HEBREW_SECOND_STEP_TITLE} stepNumber={2}*/}
            {/*                 stepColor={color} isMobile={isMobile}/>*/}
            {/*<FormAdditions isExtra={setExtra} marks={marks} setMultiLottery={setMultiLottery} isMobile={isMobile}/>*/}
            <SumAndSubmitSection marks={marks} validateForm={validateForm} postFunction={postFunction}
                                 calcPriceFunction={calcFunction} color={color}
                                 formType={formType}
                                 strongNumbersLength={strongNumbersLength} numbersLength={numbersLength}
                                 bottonColor={LIGHT_GREEN_COLOR} isMobile={isMobile} lottomaticFormData={JSON.stringify({...marks, [TABLES]: normalizeTablesData(marks[TABLES])})} />
        </>
    )
}
export const UserInput = ({
                              isReadOnly,
                              marks,
                              tableAutoInsertion,
                              popTableNumbers,
                              strongNumbersLength,
                              color,
                              tableIndex,
                              numbersLength,
                              tablesNumber,
                              removeStrongNumber,
                              addNumber,
                              removeNumber,
                              incrementTableIndex,
                              decrementTableIndex,
                              addStrongNumber,
                              isMobile,
                              setTableIndex
                          }: UserInputFormType & UserFormType<LottoMarksType> & {isMobile? :boolean, setTableIndex: (arg0: number) => void}) => {

    const style = StyleSheet.create({
        formTablesContainer: {
            flexDirection: "row-reverse",
            height: 540,
            ...getBorder()
        },
        formTablesContainerMobile: {
            flexDirection: "column",
            ...getBorder()
        }
    })

    return (
        <View style={isMobile ? style.formTablesContainerMobile : style.formTablesContainer}>
            <UserInputForm numbersLength={numbersLength} popTableNumbers={popTableNumbers} addNumber={addNumber}
                           addStrongNumber={addStrongNumber}
                           decrementTableIndex={decrementTableIndex} incrementTableIndex={incrementTableIndex}
                           tableAutoInsertion={tableAutoInsertion} table={marks[TABLES][tableIndex]}
                           removeNumber={removeNumber} removeStrongNumber={removeStrongNumber}
                           strongNumbersLength={strongNumbersLength} color={color} isReadOnly={isReadOnly}
                           tableIndex={tableIndex} isMobile={isMobile}/>
            <UserFormContainer width={isMobile ? '100%' : undefined}>
                <UserForm marks={marks} tableIndex={tableIndex} tablesNumber={tablesNumber}
                          numbersLength={numbersLength} strongNumbersLength={strongNumbersLength} isMobile={isMobile} setTableIndex={setTableIndex}/>
            </UserFormContainer>
        </View>
    )
}
export default RegularLottoForm
