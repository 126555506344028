import {LottoMarksType, MultiLotteryType} from "../../types";
import {EXTRA, MULTI_LOTTERY, NON_NUMBER, NUMBERS, STRONG_NUMBERS, TABLES} from "../../consts";

let _ = require('lodash')

export const pushLottoNumberToPickedNumbers = (number: number, array: number[]) => {
    array.push(number)
    array.sort((a, b) => a - b)

}
type ActionType = {
    type: string,
    value: number | string,
    tableIndex: number,
    multiLotteryValue?: MultiLotteryType,
    numbersLength: number
    strongNumbersLength: number
}


export const regularLottoReducer = (state: LottoMarksType, action: ActionType): LottoMarksType => {
    switch (action.type) {
        case 'addNumber':
            if (state[TABLES][action.tableIndex][NUMBERS].length !== action.numbersLength) {
                if (!state[TABLES][action.tableIndex][NUMBERS].includes(Number(action.value))) {
                    pushLottoNumberToPickedNumbers(Number(action.value), state[TABLES][action.tableIndex][NUMBERS])
                }
            }
            return {...state}
        case 'removeNumber':
            _.pull(state[TABLES][action.tableIndex][NUMBERS], action.value)
            return {...state}
        case 'addStrongNumber':
            if (state[TABLES][action.tableIndex][STRONG_NUMBERS].length !== action.strongNumbersLength) {
                if (!state[TABLES][action.tableIndex][STRONG_NUMBERS].includes(Number(action.value))) {
                    pushLottoNumberToPickedNumbers(Number(action.value), state[TABLES][action.tableIndex][STRONG_NUMBERS])
                }
            }
            return {...state}
        case 'removeStrongNumber':
            _.pull(state[TABLES][action.tableIndex][STRONG_NUMBERS], action.value)
            return {...state}
        case 'popTableNumbers':
            while (state[TABLES][action.tableIndex][NUMBERS].length != 0) {
                state[TABLES][action.tableIndex][NUMBERS].pop()
            }
            while (state[TABLES][action.tableIndex][STRONG_NUMBERS].length != 0) {
                state[TABLES][action.tableIndex][STRONG_NUMBERS].pop()
            }
            return {...state}
        case 'tableAutoInsertion':
            while (state[TABLES][action.tableIndex][STRONG_NUMBERS].length != action.strongNumbersLength) {
                let number = _.random(1, 7)
                if (!state[TABLES][action.tableIndex][STRONG_NUMBERS].includes(number)) {
                    pushLottoNumberToPickedNumbers(number, state[TABLES][action.tableIndex][STRONG_NUMBERS])
                }
            }
            while (state[TABLES][action.tableIndex][NUMBERS].length != action.numbersLength) {
                let number = _.random(1, 37)
                if (!state[TABLES][action.tableIndex][NUMBERS].includes(number)) {
                    pushLottoNumberToPickedNumbers(number, state[TABLES][action.tableIndex][NUMBERS])
                }
            }
            return {...state}
        case 'setExtra':
            state[EXTRA] = !state[EXTRA]
            return {...state}
        case 'setMultiLottery':
            state[MULTI_LOTTERY] = action?.multiLotteryValue || NON_NUMBER
            return {...state}
        default:
            throw new Error('Unhandled lottoNumbers action')
    }
}