import React from "react";
import { StyleSheet, Dimensions, View } from 'react-native';

import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core';
import AutoSizeText from "./global/autoSizeText";
import Bottomdetails from "./global/bottomDetails";
import {
    ABOUT_LOTTOMATIC_TEXT,
    ABOUT_LOTTOMATIC_TITLE, ARTICLE_TEXT, ARTICLES_TITLE,
    CONTACT_US_TEXT,
    CONTACT_US_TITLE,
    TERMS_OF_USE_TEXT,
    TERMS_OF_USE_TITLE
} from "./descriptionConsts";
import { useEffect } from "react";
import { useState } from "react";
import { getPrivacyPolicy, getTermsOfUse } from "./server/serverApi";
import {FB_SPACER_BOLD, getFontCss} from "./styles";

type AboutType = {
    text: string
    title: string
    contactUs?: boolean
}

export const About = () => {
    return <Description title={ABOUT_LOTTOMATIC_TITLE} text={ABOUT_LOTTOMATIC_TEXT}/>
}

export const Articles = () => {
    return <Description title={ARTICLES_TITLE} text={ARTICLE_TEXT}/>
}


export const ContactUs = () => {
    return <Description title={CONTACT_US_TITLE} text={CONTACT_US_TEXT} contactUs={true}/>
}

export const TermsOfUse: React.FC<{isPrivacyPolicy:boolean}> = ({isPrivacyPolicy}) => {
    const [termsUrl, setUrl] = useState("")
    const styles = StyleSheet.create({
        container: {
            flex: 1,
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: 25,
        },
        pdf: {
            flex:1,
            width:Dimensions.get('window').width,
            height:Dimensions.get('window').height,
        }
    });
    useEffect(() => {
        if(isPrivacyPolicy){
            getPrivacyPolicy().then(({url})=>{
                setUrl(url)
            })
        }else {

            getTermsOfUse().then(({url})=>{
                setUrl(url)
            })
        }
    }, [isPrivacyPolicy])
 
    return  (
    <>
        {/* <View style={{paddingHorizontal: '12.5%', marginTop: 30, height: '100%'}}> 
            <iframe src={termsUrl} width="100%" height="100%"/>
        
        </View>  */}
          <View style={styles.container}>
           
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                { termsUrl&& <Viewer fileUrl={termsUrl}/>}
            </Worker>
            </View>
        <Bottomdetails/>
    </>
    )
}

const sendMsg = () =>{
    window.location.replace("https://api.whatsapp.com/send?phone=972504884807&text=%D7%9C%D7%95%D7%98%D7%9E%D7%98%D7%99%D7%A7%20%D7%A9%D7%9C%D7%95%D7%9D,%20%D7%91%D7%9E%D7%94%20%D7%90%D7%A4%D7%A9%D7%A8%20%D7%9C%D7%A2%D7%96%D7%95%D7%A8")
}

const Description: React.FC<AboutType> = ({text, title, contactUs}) => {
    return (
        <>
            <View style={{paddingHorizontal: '12.5%', marginTop: 30}}>
                <AutoSizeText text={text} isExpendButton={false} title={title} textSize={20}/>
            </View>
            {( contactUs && <h3 style={{...getFontCss(FB_SPACER_BOLD, 22, "blue"),cursor:"pointer", textAlign: 'center'}} onClick={sendMsg}>לשליחת הודעה לחץ כאן</h3>)}
            <Bottomdetails/>
        </>
    )
}

export default Description