import React, {useCallback, useContext, useState, useEffect, useReducer} from 'react'
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Checkbox from '@material-ui/core/Checkbox';
import {Text, TouchableOpacity, View, Modal} from "react-native";
import TableContainer from "@material-ui/core/TableContainer";

import { AlertHelper } from '../AlertHelper';

import {
    Button,
    Fab,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, Typography
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {BLUE_COLOR, FB_SPACER_REGULAR, getFontCss, LIGHT_BLUE_COLOR, WHITE_COLOR} from "../styles";
import {handleErrors, useAsync} from "../server/utils";
import {
    addAgent,
    addLottery, addLotteryPrize,
    AdminFromsType,
    changeStatusToCharged,
    changeStatusToFinish,
    changeStatusToPrinted,
    changeStatusToScanned,
    deleteLottery, deleteLotteryPrize, getAllForms,
    getUsersPendingForms,
    getUsersPrintedForms,
    getUsersScannedForms,
    getUsersWinForms
} from "../server/admin";
import {ADMINISTRATION_PATH, ENGLISH_TO_HEBREW_STATUS, HEBREW_FORM, ID} from "../consts";
import {SERVER_URL} from "../server/configurations";
import AddIcon from "@material-ui/icons/Add"
import {DateField, SignInField} from "../auth/utils";
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {UserContext} from "../appSwitch";

import _ from 'lodash'
import {Print} from "@material-ui/icons";
import { RefundRequests } from './RefundRequests';
import { ValidateForm } from './validateForm';
import { FormsBatch } from './FormsBatch';
import { IframeDialog } from '../mySpace/iframeDialog';

import { inputHelper } from './inputHelper';

const AdminPage = () => {

    let tab_state = (parseInt(new URLSearchParams(location.search).get("num") as string)) ? (parseInt(new URLSearchParams(location.search).get("num") as string)) : 0
    const [componentNumber, setComponentNumber] = useState(tab_state)
    const components = [PendingForms, PrintedForms,FormsBatch, ScannedForms, WinForms, AllForms, LotteriesHandler, LotteriesPrizeHandler, AgentsHandler, RefundRequests]
    const Component = components[componentNumber]

    const providers = [{
            name: "lottomatic",
            userId: 0
        },
        {
            name: "Dekel",
            userId: 1
        },
        {
            name: "Test",
            userId: 2547
        },
        {
            name: "Hishline",
            userId: 2854
        },
        {
            name: "Scratch",
            userId: 2855
        },
        {
            name: "Hishgadonline",
            userId: 2856
        },
        {
            name: "lotto-n",
            userId: 3001
        }
    ]
    const [providerId, setProviderId] = useState(providers[0].userId)

    const onChangeProvider = (event: React.ChangeEvent<{ value: unknown }>) => {
        setProviderId(event.target.value as any);
    }

    const changeTab = (value: number) => {
        setComponentNumber(value)
    }

    return <View style={{marginRight: '12%', marginLeft: '12%', marginVertical: 50}}>
        <Select onChange={onChangeProvider} value={providerId} style={{width: '100%'}}>
            {providers.map((provider, index) => {
                return <MenuItem key={index} value={provider.userId}>{provider.name}</MenuItem>
            })}
        </Select>
        <DisabledTabs onPress={changeTab} tabs={[
            'ממתין'
            , 'הודפס'
            , 'Batch'
            , 'נסרק'
            , 'זוכים'
            , 'כל הטפסים'
            , 'הגרלות'
            , 'זכיות לוטו'
            , 'סוכנים'
            ,'בקשות החזר']}/>
        <Component changeTab={changeTab} providerId={providerId}/>
    </View>

}

const AllForms = ({changeTab, providerId }:{changeTab:(value:number)=>void, providerId: number}) => {

    const useStyles = makeStyles((theme) => ({
        paper: {
            marginBottom: theme.spacing(2),
            '& .MuiFormControlLabel-root': {
                marginRight: 0
            }
        },
        tablePagination: {
            borderBottom: "none",
            ...getFontCss(FB_SPACER_REGULAR, '0.875rem', BLUE_COLOR)
        },
        headerCell: {
            background: BLUE_COLOR,
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            width: '75%'
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        button: {
            margin: theme.spacing(1),
            width: 200
        }
    }));
    const classes = useStyles()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const style: { text: object } = {
        text: {...getFontCss(FB_SPACER_REGULAR, 21, BLUE_COLOR)}
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const {jwtToken} = useContext(UserContext)
    const allForms = useCallback(async () => {
        if (jwtToken) {
            return await getAllForms(jwtToken, providerId)
        }
    }, [jwtToken, providerId])
    const {value} = useAsync(allForms, true)
    const data: AdminFromsType = value || {
        headers: ['שם משתמש', 'תעודת זהות', 'מספר הגרלה', 'משחק', 'מספר טופס', 'סוכן'],
        rows: []
    }
    const [filters, setFilters] = useState({'formType': 'all', 'lotteryNumber': '', 'batchId': ''})

    const addFilter = (key: string, value: string) => {
        setFilters({...filters, [key]: value})
    }

    const finalRows = data.rows.filter((item) => {
        return ((item["משחק"] === filters['formType'] || filters['formType'] === 'all')) &&
                (item['batch_id'] == filters["batchId"] || !Boolean(filters["batchId"]))  &&
                (String(item["מספר הגרלה"]).includes(filters["lotteryNumber"]) || !Boolean(filters["lotteryNumber"]))
    })

    return (
        <View style={{width: '100%'}}>
            <FilterFields onChangeSelect={(value: string) => addFilter('formType', value)}
                          onChangeLotteryNumber={(value) => addFilter('lotteryNumber', value)}
                          onChangeBatch={(value) => addFilter('batchId', value)} withBatch={true}>
            </FilterFields>
            <Paper dir={"rtl"} className={classes.paper}>
                <TableContainer>
                    <Table size={'medium'}>
                        <TableHead>
                            <TableRow>
                                {
                                    data['headers'].map((header: string) =>
                                        <TableCell align={"right"}
                                                   className={classes.headerCell}>
                                            {
                                                <Text
                                                    style={{...getFontCss(FB_SPACER_REGULAR, 21, WHITE_COLOR)}}>{header}</Text>
                                            }
                                        </TableCell>)
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                finalRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) =>
                                    <TableRow>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][0]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][1]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][2]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][3]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][4]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][5]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][6]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][7]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            {row[data['headers'][8]]}
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    dir={"ltr"}
                    width={'inherit'}
                    rowsPerPageOptions={[10, 25]}
                    count={finalRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage={'שורות'}
                    className={classes.tablePagination}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />

            </Paper>
        </View>
    );
}

const WinForms = ({changeTab, providerId }:{changeTab:(value:number)=>void, providerId: number}) => {

    const useStyles = makeStyles((theme) => ({
        paper: {
            marginBottom: theme.spacing(2),
            '& .MuiFormControlLabel-root': {
                marginRight: 0
            }
        },
        tablePagination: {
            borderBottom: "none",
            ...getFontCss(FB_SPACER_REGULAR, '0.875rem', BLUE_COLOR)
        },
        headerCell: {
            background: BLUE_COLOR,
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            width: '75%'
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        button: {
            margin: theme.spacing(1),
            width: 200
        }
    }));
    const classes = useStyles()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const style: { text: object } = {
        text: {...getFontCss(FB_SPACER_REGULAR, 21, BLUE_COLOR)}
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const {jwtToken} = useContext(UserContext)
    const getWinUsers = useCallback(async () => {
        if (jwtToken) {
            return await getUsersWinForms(jwtToken, providerId)
        }
    }, [jwtToken, providerId])
    const {value} = useAsync(getWinUsers, true)
    const data: AdminFromsType = value || {
        headers: ['שם משתמש', 'תעודת זהות', 'מספר הגרלה', 'משחק', 'מספר טופס', 'סוכן'],
        rows: []
    }
    const [filters, setFilters] = useState({'formType': 'all', 'lotteryNumber': ''})

    const addFilter = (key: string, value: string) => {
        setFilters({...filters, [key]: value})
    }

    const finalRows = data.rows.filter((item) => {
        return ((item["משחק"] === filters['formType'] || filters['formType'] === 'all')) &&
            (String(item["מספר הגרלה"]).includes(filters["lotteryNumber"]) || !Boolean(filters["lotteryNumber"]))
    })

    const [changeToPrintList, setChangeToPrint] = useState<string[]>([])

    const onCheckboxPress = (rowId: string) => {
        const array = _.clone(changeToPrintList)
        if (changeToPrintList.includes(rowId)) {
            setChangeToPrint(_.pull(array, rowId))
        } else {
            setChangeToPrint(_.concat(array, [rowId]))
        }
    }

    const onSubmit = () => {
        const promises = []
        for (let itemId of changeToPrintList) {
            promises.push(new Promise(() => changeStatusToCharged(itemId, jwtToken)))
        }
        Promise.all(promises)
    }

    return (
        <View style={{width: '100%'}}>
            <FilterFields onChangeSelect={(value: string) => addFilter('formType', value)}
                          onChangeLotteryNumber={(value) => addFilter('lotteryNumber', value)}>
            </FilterFields>
            <View style={{alignItems: "center", justifyContent: "center", marginBottom: 40}}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    startIcon={<SaveIcon/>}
                    onClick={onSubmit}
                >
                    שנה סטטוסים
                </Button>
            </View>

            <Paper dir={"rtl"} className={classes.paper}>
                <TableContainer>
                    <Table size={'medium'}>
                        <TableHead>
                            <TableRow>
                                {
                                    data['headers'].map((header: string) =>
                                        <TableCell align={"right"}
                                                   className={classes.headerCell}>
                                            {
                                                <Text
                                                    style={{...getFontCss(FB_SPACER_REGULAR, 21, WHITE_COLOR)}}>{header}</Text>
                                            }
                                        </TableCell>)
                                }
                                <TableCell align={"right"}
                                           className={classes.headerCell}>
                                    {
                                        <Text
                                            style={{...getFontCss(FB_SPACER_REGULAR, 21, WHITE_COLOR)}}>{'פעולה'}</Text>
                                    }
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                finalRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) =>
                                    <TableRow>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][0]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][1]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][2]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][3]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][4]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][5]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][6]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][7]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <WinFormAction rowId={row[ID]} name={row[data['headers'][0]]}
                                                           formNumber={row[data['headers'][4]]}
                                                           onPress={() => onCheckboxPress(row[ID])}/>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    dir={"ltr"}
                    width={'inherit'}
                    rowsPerPageOptions={[10, 25]}
                    count={finalRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage={'שורות'}
                    className={classes.tablePagination}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />

            </Paper>
        </View>
    );
}

const AgentsHandler = ({changeTab, providerId }:{changeTab:(value:number)=>void, providerId: number}) => {
    const [agentData, setAgentData] = useState({
        "name": ""
    })
    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            width: '75%'
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        button: {
            margin: theme.spacing(1),
        }
    }));
    const classes = useStyles();
    const {jwtToken} = useContext(UserContext)

    const onClickSave = useCallback(() => {
        agentData["name"] && addAgent(agentData["name"], jwtToken).then(() => {
            AlertHelper.show('סוכן נוסף',
            () => AlertHelper.hide())
            inputHelper.clear()
        })
    }, [jwtToken, agentData])


    const addField = (key: string, value: string) => {
        setAgentData({...agentData, [key]: value})
    }

    return (
        <View style={{alignItems: "center", paddingHorizontal: '12.5%', marginBottom: 100}}>
            <SignInField placeholder={'שם סוכן'} onChangeText={(value: string) => addField('name', value)}/>
            <View style={{flexDirection: 'row'}}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    startIcon={<SaveIcon/>}
                    onClick={onClickSave}
                >
                    שמור
                </Button>
            </View>
        </View>
    )
}

const LotteriesPrizeHandler = () => {
    const initialState = {
        'lotteryId': '',
        '3': '',
        '3strong': '',
        '4': '',
        '4strong': '',
        '5': '',
        '5strong': '',
        '6': '',
        '6strong': '',
    }
    const [newLottryData, setNewLottryData] = useState(initialState)
    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            width: '75%'
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        button: {
            margin: theme.spacing(1),
        }
    }));
    const classes = useStyles();
    const {jwtToken} = useContext(UserContext)

    const onClickSave = useCallback(() => {
        for (let [key, value] of Object.entries(newLottryData)) {
            if (value === '') {
                AlertHelper.show('יש שדה ריק!!',
                    () => AlertHelper.hide())
                return
            }
        }

        addLotteryPrize(newLottryData, jwtToken).then(async () =>{
            AlertHelper.show('הפרס עודכן',
                () => AlertHelper.hide())

            inputHelper.reset() // reset time input fields
            setNewLottryData(initialState)
            inputHelper.clear() // reset text input fields
        })
    }, [newLottryData])

    const onClickDelete = useCallback(() => {
        if (newLottryData.lotteryId === '') {
            AlertHelper.show('שדה מספר ההגרלה או שדה אחר ריק',
                () => AlertHelper.hide())
            return
        }

        deleteLotteryPrize(newLottryData, jwtToken).then(() => {
            AlertHelper.show('הפרס נמחק',
                () => AlertHelper.hide())
        })
    }, [newLottryData])

    const addField = (key: string, value: string) => {
        setNewLottryData({...newLottryData, [key]: value})
    }

    return (
        <View style={{alignItems: "center", paddingHorizontal: '12.5%', marginBottom: 100}}>
            <SignInField placeholder={'מספר הגרלה'} onChangeText={(value: string) => addField('lotteryId', value)}
                         keyboardType={"numeric"}/>
            <SignInField placeholder={'3'} onChangeText={(value: string) => addField('3', value)}
                         keyboardType={"numeric"}/>
            <SignInField placeholder={'3 חזק'} onChangeText={(value: string) => addField('3strong', value)}
                         keyboardType={"numeric"}/>
            <SignInField placeholder={'4'} onChangeText={(value: string) => addField('4', value)}
                         keyboardType={"numeric"}/>
            <SignInField placeholder={'4 חזק'} onChangeText={(value: string) => addField('4strong', value)}
                         keyboardType={"numeric"}/>
            <SignInField placeholder={'5'} onChangeText={(value: string) => addField('5', value)}
                         keyboardType={"numeric"}/>
            <SignInField placeholder={'5 חזק'} onChangeText={(value: string) => addField('5strong', value)}
                         keyboardType={"numeric"}/>
            <SignInField placeholder={'6'} onChangeText={(value: string) => addField('6', value)}
                         keyboardType={"numeric"}/>
            <SignInField placeholder={'6 חזק'} onChangeText={(value: string) => addField('6strong', value)}
                         keyboardType={"numeric"}/>
            <View style={{flexDirection: 'row'}}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    startIcon={<SaveIcon/>}
                    onClick={onClickSave}
                >
                    שמור
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    startIcon={<DeleteForeverIcon/>}
                    onClick={onClickDelete}
                >
                    מחק
                </Button>
            </View>
        </View>
    )
}

const LotteriesHandler = () => {
    const [newLottryData, setNewLottryData] = useState({
        'start_time': '',
        'end_time': '',
        'ID': 0,
        'type': '',
        'win_amount': '0'
    })
    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            width: '75%'
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        button: {
            margin: theme.spacing(1),
        }
    }));
    const classes = useStyles();
    const {jwtToken} = useContext(UserContext)

    const onClickSave = useCallback(() => {
        for (let [key, value] of Object.entries(newLottryData)) {
            if (value === '' || value === 0) {
                AlertHelper.show('יש שדה ריק!!',
                () => AlertHelper.hide())
                return
            }
        }

        addLottery(newLottryData, jwtToken).then(async () =>{
            AlertHelper.show('הגרלה החלה',
            () => AlertHelper.hide())

            inputHelper.reset() // reset time input fields
            setNewLottryData({...newLottryData, 'ID': 0, 'type': ''})
            inputHelper.clear() // reset text input fields
        })
    }, [newLottryData])

    const onClickDelete = useCallback(() => {
        if (newLottryData.type === '' || newLottryData.ID === 0) {
            AlertHelper.show('שדה מספר ההגרלה או שדה אחר ריק',
            () => AlertHelper.hide())
            return
        }

        deleteLottery(newLottryData, jwtToken).then(() => {
            AlertHelper.show('ההגרלה נסגרה',
            () => AlertHelper.hide())
        })
    }, [newLottryData])

    const addField = (key: string, value: string) => {
        setNewLottryData({...newLottryData, [key]: value})
    }

    return (
        <View style={{alignItems: "center", paddingHorizontal: '12.5%', marginBottom: 100}}>
            <DateField id="clearInput1" onChange={(value: string) => addField('start_time', value)} label={'התחלת הגרלה'} isTime={true}/>
            <DateField id="clearInput2" onChange={(value: string) => addField('end_time', value)} label={'נעילת הגרלה'} isTime={true}/>
            <SignInField placeholder={'מספר הגרלה'} onChangeText={(value: string) => addField('ID', value)}
                         keyboardType={"numeric"}/>
            <FormControl className={classes.formControl}>
                <InputLabel>סוג</InputLabel>
                <Select ref={ref=>inputHelper.setSelectInput(ref)}
                    value={newLottryData['type']}
                    onChange={(event) => addField('type', event.target.value)}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    <MenuItem value={"לוטו"}>לוטו</MenuItem>
                    <MenuItem value={"צ'אנס"}>צ'אנס</MenuItem>
                    <MenuItem value={"777"}>777</MenuItem>
                    <MenuItem value={"123"}>123</MenuItem>
                </Select>
                <FormHelperText>הכנס סוג טופס</FormHelperText>
            </FormControl>
            {newLottryData['type'] === 'לוטו' &&
            <SignInField placeholder={'סכום זכייה'} textContentType="currency" keyboardType="numeric" defaultValue={newLottryData.win_amount} onChangeText={(value: string) => addField('win_amount', value)}/>}
            <View style={{flexDirection: 'row'}}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    startIcon={<SaveIcon/>}
                    onClick={onClickSave}
                >
                    שמור
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    startIcon={<DeleteForeverIcon/>}
                    onClick={onClickDelete}
                >
                    מחק
                </Button>
            </View>
        </View>
    )
}

const PrintedForms = ({changeTab, providerId }:{changeTab:(value:number)=>void, providerId: number}) => {

    const [dialogOpen, setDialogOpen] = useState(false);
    const [validateId, setValidateId] = useState(-1);
    const useStyles = makeStyles((theme) => ({
        paper: {
            marginBottom: theme.spacing(2),
        },
        tablePagination: {
            borderBottom: "none",
            ...getFontCss(FB_SPACER_REGULAR, '0.875rem', BLUE_COLOR)
        },
        headerCell: {
            background: BLUE_COLOR,
        }
    }));
    const classes = useStyles()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const style: { text: object } = {
        text: {...getFontCss(FB_SPACER_REGULAR, 21, BLUE_COLOR)}
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };
    const handelOpen = (id:number) => {
        setValidateId(id)
        setDialogOpen(true);
    }

    const handelClose = (event:string, reason:string) => {
        setDialogOpen(false);
        setValidateId(-1)
    };
    const {jwtToken} = useContext(UserContext)
    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const getUsersPrinted = useCallback(async () => {
        if (jwtToken) {
            return await getUsersPrintedForms(jwtToken, providerId)
        }
    }, [jwtToken, providerId])
    const {value} = useAsync(getUsersPrinted, true)
    const data: AdminFromsType = value || {
        headers: ['שם משתמש', 'תעודת זהות', 'מספר הגרלה', 'משחק', 'מספר טופס', 'סוכן', 'תאריך'],
        rows: []
    }
    const [filters, setFilters] = useState({'formType': 'all', 'lotteryNumber': ''})

    const addFilter = (key: string, value: string) => {
        setFilters({...filters, [key]: value})
    }
    const finalRows = data.rows.filter((item) => {
        return ((item["משחק"] === filters['formType'] || filters['formType'] === 'all')) &&
            (String(item["מספר הגרלה"]).includes(filters["lotteryNumber"]) || !Boolean(filters["lotteryNumber"]))
    })



    return (
        <View style={{ width: '100%'}}>
            <FilterFields onChangeSelect={(value: string) => addFilter('formType', value)}
            onChangeLotteryNumber={(value) => addFilter('lotteryNumber', value)}/>

            <Paper dir={"rtl"} className={classes.paper}>
                <TableContainer>
                    <Table size={'medium'}>
                        <TableHead>
                            <TableRow>
                                {
                                    data['headers'].map((header: string, index:number) =>
                                        <TableCell align={"right"} key={index}
                                                   className={classes.headerCell}>
                                            {
                                                <Text
                                                    style={{...getFontCss(FB_SPACER_REGULAR, 21, WHITE_COLOR)}}>{header}</Text>
                                            }
                                        </TableCell>)
                                }
                                <TableCell align={"right"}
                                           className={classes.headerCell}>
                                    {
                                        <Text
                                            style={{...getFontCss(FB_SPACER_REGULAR, 21, WHITE_COLOR)}}>{'פעולה'}</Text>
                                    }
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                finalRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) =>
                                    <TableRow key={row.id}>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][0]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][1]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][2]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][3]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][4]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][5]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][6]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                          { (!row[HEBREW_FORM] || row['scan_status'] == 'declined') && <Button variant="contained" color="primary" onClick={()=> handelOpen(row[ID])} title={'אימות טופס'}
                                                style={{marginRight: '2%'}} > הצג טופס לשיוך</Button> }

                                        </TableCell>

                                    </TableRow>
                                )
                            }
                        </TableBody>
                <TablePagination
                    dir={"ltr"}
                    width={'inherit'}
                    rowsPerPageOptions={[10, 25]}
                    count={finalRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage={'שורות'}
                    className={classes.tablePagination}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                </Table>
                </TableContainer>

            </Paper>
            {validateId > 0 && dialogOpen && <ValidateForm open={dialogOpen} onClose={handelClose} id={validateId} operation={'upload'}></ValidateForm>}
        </View>
    );
}

type FilterFieldsType = {
    onChangeSelect: (value: string) => void
    onChangeLotteryNumber: (value: string) => void
    withBatch?: boolean
    onChangeBatch: (value: string) => void
}

const FilterFields: React.FC<FilterFieldsType> = ({onChangeSelect, onChangeLotteryNumber, children, onChangeBatch, withBatch}) => {

    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            width: '50%'
        },
    }));
    const classes = useStyles()

    return <View style={{margin: 20, width: '50%', flexDirection: "row", alignItems: "flex-start"}}>
        <FormControl className={classes.formControl}>
            <InputLabel>סוג טופס</InputLabel>
            <Select
                onChange={(event) => onChangeSelect(event.target.value)} defaultValue="all"
            >
                <MenuItem value="all">
                    כולם
                </MenuItem>
                <MenuItem value={"לוטו רגיל"}>לוטו רגיל</MenuItem>
                <MenuItem value={"דאבל לוטו רגיל"}>דאבל לוטו רגיל</MenuItem>
                <MenuItem value={"לוטו שיטתי"}>לוטו שיטתי</MenuItem>
                <MenuItem value={"דאבל לוטו שיטתי"}>דאבל לוטו שיטתי</MenuItem>
                <MenuItem value={"לוטו שיטתי חזק"}>לוטו שיטתי חזק</MenuItem>
                <MenuItem value={"דאבל לוטו שיטתי חזק"}>דאבל לוטו שיטתי חזק</MenuItem>
                <MenuItem value={"צ'אנס רגיל"}>צ'אנס רגיל</MenuItem>
                <MenuItem value={"צ'אנס שיטתי"}>צ'אנס שיטתי</MenuItem>
                <MenuItem value={"רב צ'אנס"}>רב צ'אנס</MenuItem>
                <MenuItem value={"777 שיטתי"}>777 שיטתי</MenuItem>
                <MenuItem value={"777 רגיל"}>777 רגיל</MenuItem>
                <MenuItem value={"123"}>123</MenuItem>
            </Select>
            <FormHelperText>הכנס סוג טופס</FormHelperText>
        </FormControl>

        {withBatch && <SignInField placeholder={'מספר batch'} onChangeText={(value) => onChangeBatch(value)}
                     keyboardType={'numeric'} width={'50%'}/>}
        <SignInField placeholder={'מספר הגרלה'} onChangeText={(value) => onChangeLotteryNumber(value)}
                     keyboardType={'numeric'} width={'50%'}/>
        {children}
    </View>
}

const PendingForms = ({changeTab, providerId }:{changeTab:(value:number)=>void, providerId: number}) => {

    const useStyles = makeStyles((theme) => ({
        paper: {
            marginBottom: theme.spacing(2),
        },
        tablePagination: {
            borderBottom: "none",
            ...getFontCss(FB_SPACER_REGULAR, '0.875rem', BLUE_COLOR)
        },
        headerCell: {
            background: BLUE_COLOR,
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            width: '75%'
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        button: {
            margin: theme.spacing(1),
            width: 200
        }
    }));
    const classes = useStyles()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const style: { text: object } = {
        text: {...getFontCss(FB_SPACER_REGULAR, 21, BLUE_COLOR)}
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const {jwtToken} = useContext(UserContext)
    const getUsersPending = useCallback(async () => {
        if (jwtToken) {
            return await getUsersPendingForms(jwtToken, providerId)
        }
    }, [jwtToken, providerId])
    const {value} = useAsync(getUsersPending, true)
    const data: AdminFromsType = value || {
        headers: ['שם משתמש', 'תעודת זהות', 'מספר הגרלה', 'משחק', 'מספר טופס', 'סוכן', 'תאריך'],
        rows: []
    }
    const [filters, setFilters] = useState({'formType': 'all', 'lotteryNumber': ''})

    const addFilter = (key: string, value: string) => {
        setFilters({...filters, [key]: value})
    }

    const finalRows = data.rows.filter((item) => {
        return ((item["משחק"] === filters['formType'] || filters['formType'] === 'all')) &&
            (String(item["מספר הגרלה"]).includes(filters["lotteryNumber"]) || !Boolean(filters["lotteryNumber"]))
    })

    // @ts-ignore
    const finalHeaders = data.headers.filter(item => item !== 'origin_price')

    const [changeToPrintList, setChangeToPrint] = useState<string[]>([])

    const getUsersSumUp = () => {
        const pickedForms = data.rows.filter((item) => changeToPrintList.includes(item[ID]))
        // @ts-ignore
        const sumPrice = _.sum(pickedForms.map(item => item['origin_price']))
        const itemsCount = pickedForms.length

        return `סכום לתשלום: ${sumPrice} ש"ח  כמות הטפסים: ${itemsCount}`

    }
    const onCheckboxPress = useCallback((rowId: string) => {
        const array = _.clone(changeToPrintList)
        if (changeToPrintList.includes(rowId)) {
            setChangeToPrint(_.pull(array, rowId))
        } else {
            setChangeToPrint(_.concat(array, [rowId]))
        }
    }, [changeToPrintList])

    const onSubmit = () => {
        const promises = []
        for (let itemId of changeToPrintList) {
            promises.push(new Promise((resolve, reject) => resolve(changeStatusToPrinted(itemId, jwtToken))))
        }

        Promise.all(promises).then(results=>{
            AlertHelper.show(`סטטוס טפסים שנבחרו השתנה`,
            () => AlertHelper.hide())

            setTimeout(function(){location.reload()}, 2000)
        })
    }

    const onPrintAll = useCallback(() => {
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        let file = new URL(`/admin/forms/download/${providerId}`, SERVER_URL).href;
        fetch(file, {method: 'POST', body: JSON.stringify({ids: changeToPrintList, create:true}),
                headers: {
                    'Authorization': jwtToken,
                    'Content-Type': 'application/json'
                }})
            .then(async (response) => {
                const fileName = response.headers.get('Content-Disposition')?.split(';')[1].split('=')[1]
                const blob = await response.blob();
                let objectUrl = window.URL.createObjectURL(blob);
                anchor.href = objectUrl;
                anchor.download = fileName
                anchor.click();
                window.URL.revokeObjectURL(objectUrl);
            });
    }, [jwtToken, changeToPrintList])

    return (
        <View style={{width: '100%'}}>
            <FilterFields onChangeSelect={(value: string) => addFilter('formType', value)}
                          onChangeLotteryNumber={(value) => addFilter('lotteryNumber', value)}>
            </FilterFields>
            <Text style={{...getFontCss(FB_SPACER_REGULAR, 20, BLUE_COLOR)}}>{getUsersSumUp()}</Text>
            <View style={{alignItems: "center", justifyContent: "center", marginBottom: 40, flexDirection: "row"}}>
                {/*<Button*/}
                {/*    variant="contained"*/}
                {/*    color="primary"*/}
                {/*    size="small"*/}
                {/*    className={classes.button}*/}
                {/*    startIcon={<SaveIcon/>}*/}
                {/*    onClick={onSubmit}*/}
                {/*>*/}
                {/*    שנה סטטוס להודפס*/}
                {/*</Button>*/}
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    startIcon={<Print/>}
                    onClick={onPrintAll}
                >
                    הדפס הכל
                </Button>
            </View>

            <Paper dir={"rtl"} className={classes.paper}>
                <TableContainer>
                    <Table size={'medium'}>
                        <TableHead>
                            <TableRow>
                                {
                                    finalHeaders.map((header: string, ind) =>
                                        <TableCell align={"right"}
                                                   className={classes.headerCell} key={ind}>
                                            {
                                                <Text
                                                    style={{...getFontCss(FB_SPACER_REGULAR, 21, WHITE_COLOR)}}>{header}</Text>
                                            }
                                        </TableCell>)
                                }
                                <TableCell align={"right"}
                                           className={classes.headerCell}>
                                    {
                                        <Text
                                            style={{...getFontCss(FB_SPACER_REGULAR, 21, WHITE_COLOR)}}>{'פעולה'}</Text>
                                    }
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                finalRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, ind) =>
                                    <TableRow key={ind}>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[finalHeaders[0]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[finalHeaders[1]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[finalHeaders[2]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[finalHeaders[3]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[finalHeaders[4]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[finalHeaders[5]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[finalHeaders[6]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <PendingFormAction rowId={row[ID]} name={row[finalHeaders[0]]}
                                                               formNumber={row[finalHeaders[4]]}
                                                               onPress={() => onCheckboxPress(row[ID])}/>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    dir={"ltr"}
                    width={'inherit'}
                    rowsPerPageOptions={[10, 25]}
                    count={finalRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage={'שורות'}
                    className={classes.tablePagination}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />

            </Paper>
        </View>
    );
}

const ScannedForms = ({changeTab, providerId }:{changeTab:(value:number)=>void, providerId: number}) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [validateId, setValidateId] = useState(-1);

    const useStyles = makeStyles((theme) => ({
        paper: {
            marginBottom: theme.spacing(2),
        },
        tablePagination: {
            borderBottom: "none",
            ...getFontCss(FB_SPACER_REGULAR, '0.875rem', BLUE_COLOR)
        },
        headerCell: {
            background: BLUE_COLOR,
        },
        button: {
            margin: theme.spacing(1),
            width: 200
        }
    }));
    const classes = useStyles()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const style: { text: object } = {
        text: {...getFontCss(FB_SPACER_REGULAR, 21, BLUE_COLOR)}
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handelOpen = (id:number) => {
        setValidateId(id)
        setDialogOpen(true);
    }

    const handelClose = (event:string, reason:string) => {
        setDialogOpen(false);
        setValidateId(-1)
        if(reason=="refresh" && event)
        {
            changeTab(+event)
        }
    };

    const {jwtToken} = useContext(UserContext)
    const getUsersScanned = useCallback(async () => {
        if (jwtToken) {
            return await getUsersScannedForms(jwtToken, providerId)
        }
    }, [jwtToken, providerId])
    const {value} = useAsync(getUsersScanned, true)
    const data: AdminFromsType = value || {
        headers: ['batch_id','שם משתמש', 'תעודת זהות', 'מספר הגרלה', 'משחק', 'מספר טופס', 'סוכן', 'תאריך'],
        rows: []
    }
    const [filters, setFilters] = useState({'formType': 'all', 'lotteryNumber': '', 'batchId': ''})

    const addFilter = (key: string, value: string) => {
        setFilters({...filters, [key]: value})
    }

    const finalRows = data.rows.filter((item) => {
        return ((item["משחק"] === filters['formType'] || filters['formType'] === 'all')) &&
            (item['batch_id'] == filters["batchId"] || !Boolean(filters["batchId"]))  &&
            (String(item["מספר הגרלה"]).includes(filters["lotteryNumber"]) || !Boolean(filters["lotteryNumber"]))
    })
    const [changeToPrintList, setChangeToPrint] = useState<string[]>([])

    const onCheckboxPress = (rowId: string) => {
        const array = _.clone(changeToPrintList)
        if (changeToPrintList.includes(rowId)) {
            setChangeToPrint(_.pull(array, rowId))
        } else {
            setChangeToPrint(_.concat(array, [rowId]))
        }
    }

    const onSubmit = () => {
        const promises = []
        for (let itemId of changeToPrintList) {
            promises.push(new Promise(() => changeStatusToFinish(itemId, jwtToken)))
        }
        Promise.all(promises)
    }

    return (
        <View style={{width: '100%'}}>
            <FilterFields onChangeSelect={(value: string) => addFilter('formType', value)}
                          onChangeLotteryNumber={(value) => addFilter('lotteryNumber', value)}
                          onChangeBatch={(value) => addFilter('batchId', value)} withBatch={true}/>
            <View style={{alignItems: "center", justifyContent: "center", marginBottom: 40}}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    startIcon={<SaveIcon/>}
                    onClick={onSubmit}
                >
                    שנה סטטוסים
                </Button>
            </View>
            <Paper dir={"rtl"} className={classes.paper}>
                <TableContainer>
                    <Table size={'medium'}>
                        <TableHead>
                            <TableRow>
                                {
                                    data['headers'].map((header: string, ind) =>
                                        <TableCell align={"right"}
                                                   className={classes.headerCell}>
                                            {
                                                <Text
                                                    style={{...getFontCss(FB_SPACER_REGULAR, 21, WHITE_COLOR)}}>{header}</Text>
                                            }
                                        </TableCell>)
                                }
                                <TableCell align={"right"}
                                           className={classes.headerCell}>
                                    {
                                        <Text
                                            style={{...getFontCss(FB_SPACER_REGULAR, 21, WHITE_COLOR)}}>{'פעולה'}</Text>
                                    }
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                finalRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, ind) =>
                                    <TableRow key={ind}>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][0]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][1]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][2]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][3]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][4]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][5]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row[data['headers'][6]]}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            {/* <ScannedFormAction rowId={row[ID]}
                                                               onPress={() => onCheckboxPress(row[ID])}/> */}
                                        </TableCell>

                                        <TableCell align={"right"}>
                                            <Text style={style.text}>
                                                {row['batch_id']}
                                            </Text>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                        { row[HEBREW_FORM] && row['scan_status'] == 'waiting' && <Button variant="contained" color="primary" onClick={()=> handelOpen(row[ID])} title={'אימות טופס'}
                                                style={{marginRight: '2%'}} > הצג טופס לאימות</Button> }
                                        {(row['scan_status'] == 'approved' || row['scan_status'] == 'declined') &&  <Text style={style.text}>
                                                {ENGLISH_TO_HEBREW_STATUS(row['scan_status'])}
                                            </Text> }
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    dir={"ltr"}
                    width={'inherit'}
                    rowsPerPageOptions={[10, 25]}
                    count={finalRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage={'שורות'}
                    className={classes.tablePagination}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />

            </Paper>
            {validateId > 0 && dialogOpen && <ValidateForm open={dialogOpen} onClose={handelClose} id={validateId} ></ValidateForm>}
        </View>
    );
}

const ScannedFormAction = ({rowId, onPress}: { rowId: string, onPress: () => void }) => {

    return (
        <View style={{flexDirection: "row", alignItems: "center"}}>
            <DownloadReceipt formId={rowId}/>
            <CheckboxWithLabel onPress={onPress} title={'הושלם'}
                               label={'שנה סטטוס להושלם'}/>
        </View>
    )

}

type DownloadReceiptType = {
    formId: string
}

export const DownloadReceipt: React.FC<DownloadReceiptType> = ({formId}) => {

    const [iframeOpen, setIframeOpen] = useState(false);
    const [src, setSrc] = useState('');
    const {jwtToken} = useContext(UserContext)

    const handelClose = (reason:string) => {
        setIframeOpen(false);
    };
    const onClickReceipt = useCallback(() => {
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        let file = new URL(`/my_space/receipt/${formId}`, SERVER_URL).href;
        fetch(file, {headers: {'Authorization': jwtToken}})
            .then(response => {
                response.json().then(({file_url})=> {
                   setSrc(file_url)
                   setIframeOpen(true)
                })
                // const fileName =  response?.headers?.get('Content-Disposition')?.split('filename=')[1];
                // if(response.status == 200 && fileName){
                //     response.blob().then(blobby => {
                //         let objectUrl = window.URL.createObjectURL(blobby);
                //         anchor.href = objectUrl;
                //         anchor.download = fileName
                //         anchor.click();
                //         window.URL.revokeObjectURL(objectUrl);
                //     }).catch((err) => {
                //         AlertHelper.show('שגיאה בהורדת הקובץ',
                //         () => AlertHelper.hide())
                //     })
                // }else {
                //     AlertHelper.show('הקבלה עוד לא הועלתה ללוטומטיק',
                //     () => AlertHelper.hide())
                // }
            })
    }, [jwtToken, formId])

    return(
        <View>
            <TouchableOpacity onPress={onClickReceipt}>
                <Text style={{textDecorationLine: "underline", ...getFontCss(FB_SPACER_REGULAR, 16, BLUE_COLOR, 'underline')}}>{'צפה בקבלה'}</Text>
            </TouchableOpacity>
            <IframeDialog src={src} type={'img'} open={iframeOpen} onClose={(reason)=> handelClose(reason) }  />
        </View>
        )
}

export const PrintedFormAction = ({rowId, onClose, OnUploadImage}: { rowId: string, onClose: ()=> void ,OnUploadImage: (url:string)=>void}) => {
    const [file, setFile] = useState<undefined | File>(undefined)
    const {jwtToken} = useContext(UserContext)

    const handleChange = (event: any) => {
        setFile(event.target.files[0])
        OnUploadImage(URL.createObjectURL(event.target.files[0]))
    }
    const onClick = useCallback(() => {
        const fd = new FormData();
        let filePath = new URL(`/admin/receipt/${rowId}`, SERVER_URL).href;
        if (file) {
            fd.append('file', file)
            return handleErrors(fetch(filePath, {
                method: "POST",
                body: fd,
                headers: {
                    'Authorization': jwtToken,
                }
            })).then(() => changeStatusToScanned(rowId, jwtToken)).then(() => {
                onClose()
                AlertHelper.show('הקבלה נשמרה',
                () => AlertHelper.hide())
            })
        } else {
            onClose()
            AlertHelper.show('יש להעלות קבלה',
            () => AlertHelper.hide())
            return
        }
    }, [file, rowId])
    return (
        <View style={{flexDirection: "row", alignItems: "center"}}>
            <label htmlFor={`upload-photo-${rowId}`} title={'העלה קבלה'}>
                <input
                    style={{display: 'none'}}
                    id={`upload-photo-${rowId}`}
                    name={`upload-photo-${rowId}`}
                    type="file"
                    accept={'image/png, image/jpeg'}
                    onChange={handleChange}
                />
                <Fab color="primary" size="small" component="span" aria-label="add">
                    <AddIcon/>
                </Fab>
            </label>
            <Text style={{
                ...getFontCss(FB_SPACER_REGULAR, 16, BLUE_COLOR),
                marginRight: 10
            }}>{file ? file.name : 'העלה קבלה'}</Text>
            {file && <Button variant="contained" color="primary" onClick={onClick} title={'העלה קבלה'}
                             style={{marginRight: '2%'}}>
                העלה קבלה
            </Button>}
        </View>
    )

}

type PendingFormActionType = { rowId: string, name: string, formNumber: number, onPress: () => void }

const PendingFormAction: React.FC<PendingFormActionType> = ({rowId, formNumber, name, onPress}) => {


    return (
        <View style={{flexDirection: "row", alignItems: "center"}}>
            <DownloadForm name={String(formNumber)} rowId={rowId}/>
            <CheckboxWithLabel onPress={onPress} title={'הודפס'}
                               label={'שנה סטטוס להודפס'}/>
        </View>
    )

}

type DownloadForm = {
    rowId: string,
    name: string
}

export const DownloadForm: React.FC<DownloadForm> = ({name, rowId}) => {

    const {jwtToken} = useContext(UserContext)

    const onClick = useCallback(() => {
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        let file = new URL(`/admin/download/${rowId}`, SERVER_URL).href;
        fetch(file, {headers: {'Authorization': jwtToken}})
            .then(response => response.blob())
            .then(blobby => {
                let objectUrl = window.URL.createObjectURL(blobby);
                anchor.href = objectUrl;
                anchor.download = `${name}.pdf`
                anchor.click();
                window.URL.revokeObjectURL(objectUrl);
            });
    }, [jwtToken, rowId])

    return <TouchableOpacity onPress={onClick}>
        <Text
            style={{textDecorationLine: "underline", ...getFontCss(FB_SPACER_REGULAR, 16, BLUE_COLOR, 'underline')}}>{'הורד טופס'}</Text>
    </TouchableOpacity>
}

type WinFormActionType = { rowId: string, name: string, formNumber: number, onPress: () => void }

const WinFormAction: React.FC<WinFormActionType> = ({rowId, formNumber, name, onPress}) => {

    return (
        <View style={{alignItems: "flex-start"}}>
            <DownloadForm rowId={rowId} name={name}/>
            <DownloadReceipt formId={rowId}/>
            <CheckboxWithLabel onPress={onPress} title={'זכה'} label={'זיכוי'}/>
        </View>
    )

}

type CheckboxWithLabelType = { onPress: () => void, label: string, title: string, isMobile?: boolean }

export const CheckboxWithLabel: React.FC<CheckboxWithLabelType> = ({onPress, label, title, isMobile}) => {
    const [clicked, setClicked] = useState(false)
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            formControlLabelMobile: {
                fontSize: 54
            },
            formControlLabel: {
                fontSize: 14
            }
        }),
    );

    const styles = useStyles();

    const onClick = useCallback(() => {
        setClicked(!clicked)
        onPress()
    }, [clicked, onPress])

    return (
        <FormControlLabel
            control={<Checkbox onChange={onClick} checked={clicked} title={title}/>}
            label={<Typography className={isMobile ? styles.formControlLabelMobile : styles.formControlLabel}>{label}</Typography>}
        />

    )
}

export function DisabledTabs({onPress, tabs}: { onPress: (value: number) => void, tabs: string[] }) {
    let tab_state = (parseInt(new URLSearchParams(location.search).get("num") as string)) ? (parseInt(new URLSearchParams(location.search).get("num") as string)) : 0
    const [value, setValue] = useState(tab_state)

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
            setValue(newValue)
            onPress(newValue)
    }

    return (
        <>
            <Paper square dir={"rtl"} style={{marginBottom: 50}}>
                <Tabs
                    value={value}
                    variant="scrollable"
                    scrollButtons='on'
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="disabled tabs example"
                >
                    {tabs.map((tab, ind) => <Tab key={ind} label={tab}/>)}
                </Tabs>
            </Paper>
        </>
    )
}

export default AdminPage
