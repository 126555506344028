import {
    EnglishHebrewFormTypeMapping,
    LevelType,
    LottoNumberType,
    MultiLotterySevenType,
    RegularSevenFormType,
    SevenActionType,
    SevenMarksType,
    SumAndSubmitSevenSectionType,
    UserFormType
} from "../../types";
import React, {useCallback, useContext, useEffect, useMemo, useReducer, useState} from "react";
import {
    FORM_TYPE,
    HEBREW_MAIN_TITLE,
    HEBREW_SECOND_STEP_TITLE,
    INIT_TABLE_INDEX,
    MULTI_LOTTERY,
    NON_NUMBER,
    NUMBERS,
    STRICT,
    TABLES
} from "../../consts";
import {pushLottoNumberToPickedNumbers} from "./regularLottoReducer";
import FirstSectionInstructions, {
    InstructionStep,
    StepBigText,
    StepCircle
} from "./additionalSections/firstSectionInstructions";
import {Image, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {
    FB_SPACER_BOLD,
    FB_SPACER_REGULAR,
    getBorder,
    getCircleCss,
    getFontCss,
    getHeightWidthCss,
    PINK_COLOR,
    SEVEN_ORANGE_COLOR,
    WHITE_COLOR,
    YELLOW_COLOR
} from "../../styles";
import {AutomateRenew, QuestionMark} from "./additionalSections/formAdditions";
import {PipeLine} from "../../genaricComponents";
import {ChanceMediumText, ChanceRowBalls} from "./regularChanceForm";
import {Payment} from "./additionalSections/sumAndSubmitSection";
import {DeleteAndFillNumbersSection} from "./inputForm/userInputForm";
import {FillingRow, UserFormBorders, UserFormContainer} from "./userForm/userForm";
import UserInputFormHeader, {UserInputFormUpperHeaderChildren} from "./inputForm/userInputFormHeader";
import {TouchableLottoRowBalls} from "../lottoBalls";
import {UserContext} from "../../appSwitch";

import { AlertHelper } from '../../AlertHelper';

let _ = require('lodash')


const SUB_TITLE = 'בחר 7 מספרים מתוך 70'

export const sevenReducer = (state: SevenMarksType, action: SevenActionType): SevenMarksType => {
    switch (action.type) {
        case 'addNumber':
            if (state[TABLES][action.tableIndex][NUMBERS].length !== action.numbersLength) {
                if (!state[TABLES][action.tableIndex][NUMBERS].includes(Number(action.value))) {
                    pushLottoNumberToPickedNumbers(Number(action.value), state[TABLES][action.tableIndex][NUMBERS])
                }
            }
            return {...state}
        case 'removeNumber':
            _.pull(state[TABLES][action.tableIndex][NUMBERS], action.value)
            return {...state}
        case 'popTableNumbers':
            while (state[TABLES][action.tableIndex][NUMBERS].length != 0) {
                state[TABLES][action.tableIndex][NUMBERS].pop()
            }
            return {...state}
        case 'tableAutoInsertion':
            while (state[TABLES][action.tableIndex][NUMBERS].length != action.numbersLength) {
                let number = _.random(1, 70)
                if (!state[TABLES][action.tableIndex][NUMBERS].includes(number)) {
                    pushLottoNumberToPickedNumbers(number, state[TABLES][action.tableIndex][NUMBERS])
                }
            }
            return {...state}
        case 'setMultiLottery':
            state[MULTI_LOTTERY] = action?.multiLotteryValue || NON_NUMBER
            return {...state}
        case 'setFormType':
            state[FORM_TYPE] = action.formType || NON_NUMBER
            return {...state}
        case 'resetFormType':
            state[FORM_TYPE] = NON_NUMBER
            return {...state}
        default:
            throw new Error('Unhandled lottoNumbers action')
    }
}

const RegularSevenForm = ({isShitati, initForm, postFormFunction, calcPriceFunction, formType, color}: RegularSevenFormType) => {
    const [tableIndex, setTableIndex] = useState<number>(INIT_TABLE_INDEX)
    const [marks, dispatchMarks] = useReducer(sevenReducer, initForm)

    const {jwtToken} = useContext(UserContext)
    const numbersLength = useMemo(() => isShitati ? marks[FORM_TYPE] === NON_NUMBER ? 8 : marks[FORM_TYPE] : 7, [marks[FORM_TYPE], isShitati])
    const tablesNumber = useMemo(() => isShitati ? 1 : 3, [isShitati])
    // console.log(numbersLength)
    // @ts-ignore
    const fullTables: number = useMemo(() => marks[TABLES].map((table) => table[NUMBERS].length === numbersLength ? 1 : 0).reduce((a, b) => a + b), [marks])

    const validateForm = useCallback((lvl: LevelType = STRICT): boolean => {
        if (fullTables === 0) {
            if(lvl === STRICT){
                AlertHelper.show("יש שדה ריק",
                () => AlertHelper.hide(),'sendGameForm')
            }
            return false
        }
        return true
    }, [fullTables])

    const setMultiLottery = (number: MultiLotterySevenType) => {
        dispatchMarks({
            numbersLength,
            type: 'setMultiLottery',
            value: NON_NUMBER,
            tableIndex: tableIndex,
            multiLotteryValue: (number as MultiLotterySevenType)
        })
    }

    const updateIndex = () => {
        if (marks[TABLES][tableIndex][NUMBERS].length === numbersLength) {
            if (tableIndex + 1 !== tablesNumber) {
                setTableIndex(Number(tableIndex) + 1)
            }
        }
    }

    const setFormType = (value: number) => {
        dispatchMarks({type: 'setFormType', value: NON_NUMBER, formType: value, numbersLength, tableIndex})
        popTableNumbers()
    }

    const removeFormType = () => {
        dispatchMarks({type: 'resetFormType', value: NON_NUMBER, numbersLength, tableIndex})
    }

    const addNumber = useCallback((number: LottoNumberType) => {
        dispatchMarks({type: 'addNumber', numbersLength, value: number, tableIndex: tableIndex})
    }, [tableIndex])

    const removeNumber = useCallback((number: LottoNumberType) => {
        dispatchMarks({type: 'removeNumber', numbersLength, value: number, tableIndex: tableIndex})
    }, [tableIndex])

    const incrementTableIndex = useCallback(() => {
        if (Number(tableIndex) + 1 === tablesNumber) {
            setTableIndex(0)
            return
        }
        setTableIndex(Number(tableIndex) + 1)
    }, [tableIndex])

    const decrementTableIndex = useCallback(() => {
        if (Number(tableIndex) - 1 < INIT_TABLE_INDEX) {
            setTableIndex(tablesNumber - 1)
            return
        }
        setTableIndex(Number(tableIndex) - 1)
    }, [tableIndex])

    const postFunction = useCallback(() => postFormFunction(marks, jwtToken), [marks])

    const calcFunction = useCallback(() => calcPriceFunction(marks, jwtToken), [marks])

    const tableAutoInsertion = () => {
        dispatchMarks({
            type: 'tableAutoInsertion',
            value: NON_NUMBER,
            tableIndex: tableIndex,
            numbersLength
        })
    }

    const popTableNumbers = useCallback(() => {
        dispatchMarks({
            type: 'popTableNumbers',
            numbersLength,
            value: NON_NUMBER,
            tableIndex: tableIndex
        })
    }, [tableIndex])

    useEffect(updateIndex, [marks])

    return (
        <>
            <FirstSectionInstructions mainTitle={HEBREW_MAIN_TITLE} subTitle={SUB_TITLE} bacgroundColor={color}/>
            <SevenUserInput numbersLength={numbersLength} tablesNumber={tablesNumber} isShitati={isShitati || false}
                            resetFormType={removeFormType} marks={marks} color={color}
                            tableAutoInsertion={tableAutoInsertion}
                            popTableNumbers={popTableNumbers} tableIndex={tableIndex}
                            decrementTableIndex={decrementTableIndex} incrementTableIndex={incrementTableIndex}
                            setFormType={setFormType} removeNumber={removeNumber} addNumber={addNumber}/>
            <InstructionStep marginTop={35} containerHeight={72} stepText={HEBREW_SECOND_STEP_TITLE} stepNumber={2}
                             stepColor={color}/>
            <SevenFormAdditions setMultiLottery={setMultiLottery} marks={marks}/>
            <SumAndSubmitSevenSection bottonColor={SEVEN_ORANGE_COLOR} validateForm={validateForm} color={color}
                                      calcPriceFunction={calcFunction}
                                      fullTables={fullTables}
                                      formType={formType}
                                      postFunction={postFunction} marks={marks}/>
        </>
    )
}

export const SevenUserInput = ({
                                   addNumber,
                                   removeNumber,
                                   tablesNumber,
                                   numbersLength,
                                   isShitati,
                                   resetFormType,
                                   setFormType,
                                   incrementTableIndex,
                                   decrementTableIndex,
                                   color,
                                   tableAutoInsertion,
                                   popTableNumbers,
                                   marks,
                                   tableIndex
                               }: {
    setFormType: (value: number) => void,
    resetFormType: () => void
    tableIndex: number,
    incrementTableIndex: () => void,
    decrementTableIndex: () => void,
    color: string
    popTableNumbers: () => void,
    tableAutoInsertion: () => void,
    marks: SevenMarksType
    isShitati: boolean
    numbersLength: number
    tablesNumber: number
    addNumber: (lottoNumber: LottoNumberType) => void,
    removeNumber: (lottoNumber: LottoNumberType) => void,

}) => {

    const style = StyleSheet.create({
        formTablesContainer: {
            flexDirection: "row-reverse",
            height: 394,
            ...getBorder(),
            paddingRight: '1%',
            paddingLeft: '1%'
        },
        innerFormTablesrightContainer: {
            width: '50%',
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: WHITE_COLOR,
            borderRadius: 15
        },
        innerFormTablesleftContainer: {
            width: '50%',
            ...getBorder(),
            paddingRight: '2.6%',
            paddingLeft: '8%'
        }
    })

    return (
        <View style={style.formTablesContainer}>
            <View style={style.innerFormTablesrightContainer}>
                <UserInputFormHeader>
                    <UserInputFormUpperHeaderChildren incrementTableIndex={incrementTableIndex}
                                                      decrementTableIndex={decrementTableIndex} color={color}
                                                      tableIndex={tableIndex}/>
                </UserInputFormHeader>
                <DeleteAndFillNumbersSection color={color} popTableNumbers={popTableNumbers}
                                             tableAutoInsertion={tableAutoInsertion}/>
                <SevenUserInputNumbersForm table={marks[TABLES][tableIndex]} numbersPerRow={10} numbersAmount={70}
                                           numbersLength={numbersLength} addNumber={addNumber}
                                           removeNumber={removeNumber}
                                           isReadOnly={false}/>
            </View>
            <UserFormContainer>
                <SevenUserForm numbersLength={numbersLength} marks={marks} tableIndex={tableIndex}
                               strongNumbersLength={0} tablesNumber={tablesNumber} isReadOnly={false}/>
                {isShitati ? <View style={{marginTop: 60, marginRight: '18%'}}>
                    <ChanceMediumText text={'בחר את סוג הטופס'}/>
                    <ChanceRowBalls setNumber={setFormType} numbers={[8, 9]} removeNumber={resetFormType}
                                    ballTextColor={PINK_COLOR}
                                    isClicked={(number: number) => marks[FORM_TYPE] === number} isShitati={false}/>
                </View> : <></>}
            </UserFormContainer>

        </View>
    )
}

export const SevenUserForm = ({
                                  marks,
                                  tableIndex,
                                  tablesNumber,
                                  numbersLength,
                                  strongNumbersLength,
                                  isReadOnly
                              }: UserFormType<SevenMarksType>) => {
    const style = StyleSheet.create({
        fillingFormLine: {
            backgroundColor: WHITE_COLOR,
            ...getHeightWidthCss(1, '67%'),
            marginLeft: '14.5%',
            marginTop: 4
        }
    })

    const getFillingRows = () => {
        return _.range(tablesNumber).map((index: number) => {
            if (!marks[TABLES][index]) {
                return;
            }
            return <FillingRow rowColor={'#aa1b55'}
                        successColor={SEVEN_ORANGE_COLOR}
                        isReadOnly={isReadOnly}
                        numbersLength={numbersLength}
                        tableNumber={index + 1}
                        isLink={tableIndex == index}
                        numbers={marks[TABLES][index][NUMBERS]}
                        strongNumbers={[]}
                        strongNumbersLength={strongNumbersLength}/>
        })
    }

    return (
        <>
            {getFillingRows()}
            <View style={style.fillingFormLine}/>
            <UserFormBorders tablesNumber={tablesNumber}/>

        </>
    )
}

export const SevenUserInputNumbersForm = ({
                                              numbersPerRow,
                                              isReadOnly,
                                              numbersAmount,
                                              addNumber,
                                              table,
                                              removeNumber,
                                              numbersLength
                                          }: { isReadOnly?: boolean, numbersLength: number, table: { [NUMBERS]: number[] }, addNumber: (number: number | string) => void, removeNumber: (number: number | string) => void, numbersAmount: number, numbersPerRow: number }) => {

    const style = {
        numbersContainer: {
            height: 306,
            paddingLeft: '12.5%',
            paddingTop: '1.5%',
            ...getBorder()
        },
        pickBallContainer: {
            flexDirection: "row",
            height: 30,
            marginBottom: '1%',
            alignItems: "center"
        },
        pickBallCircle: {
            backgroundColor: WHITE_COLOR,
            ...getHeightWidthCss('90%', '6%'),
            borderRadius: '100%',
            marginRight: '3%',
            alignItems: "center",
            justifyContent: "center"
        },
        pickedBallText: {
            ...getFontCss(FB_SPACER_REGULAR, 23, PINK_COLOR),
        }
    }
    return (
        <View style={style.numbersContainer}>
            {_.range(1, numbersAmount, numbersPerRow).map((number: number) => <TouchableLottoRowBalls
                removeNumber={removeNumber}
                isClicked={(number: number | string) => table[NUMBERS].includes(Number(number))}
                disabled={isReadOnly || numbersLength === table[NUMBERS].length}
                rowLength={numbersAmount - number < numbersPerRow ? numbersAmount - number : numbersPerRow}
                onPress={addNumber}
                numbers={_.range(number, number + numbersPerRow < numbersAmount ? number + numbersPerRow : numbersAmount + 1)}
                style={style.pickBallContainer}
                ballStyle={style.pickBallCircle}
                ballTextStyle={style.pickedBallText}/>)}
        </View>
    )
}

export const SumAndSubmitSevenSection = ({
                                             color,
                                             bottonColor,
                                             calcPriceFunction,
                                             postFunction,
                                             validateForm,
                                             formType,
                                             fullTables,
                                             marks
                                         }: SumAndSubmitSevenSectionType) => {

    const style = StyleSheet.create({
        container: {
            flexDirection: "row-reverse",
            alignItems: "center",
            height: 141,
            paddingTop: 23,
            ...getBorder()
        },
        sumText: {
            ...getFontCss(FB_SPACER_REGULAR, 24, YELLOW_COLOR),
            marginRight: '5%',
            whiteSpace: 'nowrap',
            overflowWrap: 'normal',
        },
        paymentText: {
            position: "absolute",
            left: '27.2%',
            ...getFontCss(FB_SPACER_BOLD, 20, WHITE_COLOR)
        }
    })

    return (
        <View style={style.container}>
            <StepCircle text={'₪'} textColor={color}/>
            <View>
                <StepBigText text={'סיכום ושליחת טופס'}/>
                <Text style={style.sumText}>{`סוג ${EnglishHebrewFormTypeMapping[formType]} | הגרלות ${marks.multi_lottery != -1? marks.multi_lottery : '1' } | טבלאות ${fullTables}`}</Text>
            </View>
            <View style={{
                flexDirection: "row",
                width: 'fit-content',
                left: '3.7%',
                position: "absolute",
                alignItems: "center"
            }}>
                <Payment bottonColor={bottonColor} validateForm={validateForm} postFunction={postFunction}
                         calcPriceFunction={calcPriceFunction} marks={marks}/>
            </View>
        </View>
    )
}

export const NumberOfSevenLotteriesBall = ({
                                               number,
                                               setMultiLottery,
                                               multiLottery
                                           }: { number: MultiLotterySevenType, setMultiLottery: (number: MultiLotterySevenType) => void, multiLottery: MultiLotterySevenType }) => {
    const style = StyleSheet.create({
        containerStyle: {
            ...getCircleCss(33, 33, 16.5),
            borderWidth: 3,
            borderColor: WHITE_COLOR,
            backgroundColor: multiLottery === number ? PINK_COLOR : undefined,
            justifyContent: "center",
            alignItems: "center",
            marginLeft: '1%'
        },
        textStyle: {
            ...getFontCss(FB_SPACER_REGULAR, 34, WHITE_COLOR)
        },
    })

    const onPress = useCallback(() => {
        if (multiLottery !== number) {
            setMultiLottery(number)
        } else {
            setMultiLottery(NON_NUMBER)
        }
    }, [multiLottery])

    return (
        <TouchableOpacity style={style.containerStyle} onPress={onPress}>
            <Text style={style.textStyle}>{number}</Text>
        </TouchableOpacity>
    )
}

export const SevenFormAdditions = ({
                                       setMultiLottery,
                                       marks
                                   }: { setMultiLottery: (number: MultiLotterySevenType) => void, marks: SevenMarksType }) => {

    const style = StyleSheet.create({
        container: {
            flexDirection: "row-reverse",
            height: 85,
            alignItems: "center",
            justifyContent: "center",
            ...getBorder()
        },
        numberlotteriesText: {
            ...getFontCss(FB_SPACER_BOLD, 24, WHITE_COLOR),
            marginRight: '3.7%',
            marginLeft: '1.2%'
        },
        textStyle: {
            ...getFontCss(FB_SPACER_BOLD, 12, WHITE_COLOR)
        },
        imageConatiner: {
            flexDirection: "row-reverse",
            alignItems: "center",
            ...getBorder(),
            width: '100%'
        }
    })


    return (
        <View style={style.container}>
            <Text style={style.numberlotteriesText}>מספר הגרלות</Text>
            <NumberOfSevenLotteriesBall multiLottery={marks[MULTI_LOTTERY]} number={2}
                                        setMultiLottery={setMultiLottery}/>
            <NumberOfSevenLotteriesBall multiLottery={marks[MULTI_LOTTERY]} number={3}
                                        setMultiLottery={setMultiLottery}/>
            <NumberOfSevenLotteriesBall multiLottery={marks[MULTI_LOTTERY]} number={4}
                                        setMultiLottery={setMultiLottery}/>
            <QuestionMark/>
            <PipeLine marginLeft={'1.6%'}/>
            {/* <AutomateRenew text={'אוטומטי מתחדש'}/>
            <QuestionMark/>
            <PipeLine marginLeft={'1.6%'}/> */}
            <View style={{width: '18%'}}>
                <View style={style.imageConatiner}>
                    <Image source={require("../../../../assets/whiteV.png")}
                           style={{width: 11.7, height: 8.56, marginLeft: '2%'}}/>
                    <Text style={style.textStyle}>תזכה בפרס גם אם לא ניחשת אף ספרה</Text>
                </View>
                <View style={style.imageConatiner}>
                    <Image source={require("../../../../assets/whiteV.png")}
                           style={{width: 11.7, height: 8.56, marginLeft: '2%'}}/>
                    <Text style={style.textStyle}>סיכויי הזכייה יחסית גבוהים</Text>
                </View>
                <View style={style.imageConatiner}>
                    <Image source={require("../../../../assets/whiteV.png")}
                           style={{width: 11.7, height: 8.56, marginLeft: '2%'}}/>
                    <Text style={style.textStyle}>נחש 7 מספרים מתוך 70</Text>
                </View>
            </View>

        </View>
    )
}

export default RegularSevenForm