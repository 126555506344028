import {BrowserRouter} from "react-router-dom";
import {AppSwitch} from "./web/appSwitch";
import React from "react";
import 'typeface-roboto'
import {CustomAlert} from './web/AlertMsg'
import { AlertHelper } from './web/AlertHelper';
const WebApp = () => {

    return <BrowserRouter>
        <AppSwitch/>
        <CustomAlert ref={ref => AlertHelper.setAlert(ref)}/>
    </BrowserRouter>
}

export default WebApp