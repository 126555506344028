import React, {useCallback, useEffect, useState} from "react";
import {SevenTables, TableType} from "../types";
import {TABLE_NUMBER} from "../consts";
import {Table123} from "../lotto/regular_lotto/regular123Form";

import { AlertHelper } from '../../web/AlertHelper';

export const useAsync = <T, E = string>(
    asyncFunction: () => Promise<T>,
    immediate = true
) => {
    const [status, setStatus] = useState<'idle' | 'pending' | 'success' | 'error'>('idle');
    const [value, setValue] = useState<T | null>(null);
    const [error, setError] = useState<E | null>(null);

    // The execute function wraps asyncFunction and
    // handles setting state for pending, value, and error.
    // useCallback ensures the below useEffect is not called
    // on every render, but only if asyncFunction changes.
    const execute = useCallback(() => {
        setStatus('pending');
        setValue(null);
        setError(null);

        return asyncFunction()
            .then((response: any) => {
                setValue(response);
                setStatus('success');
            })
            .catch((error) => {
                setError(error);
                setStatus('error');
            });
    }, [asyncFunction]);

    // Call execute if we want to fire it right away.
    // Otherwise execute can be called later, such as
    // in an onClick handler.
    useEffect(() => {
        if (immediate) {
            execute();
        }
    }, [execute, immediate]);

    return {execute, status, value, error};
};

/**
 * Add table_index to TableType as expected in the backend.
 */
export const normalizeTablesData = (tables: (SevenTables | TableType | Table123)[]) => {

    return tables.map((mark, index) => {
        return {...mark, [TABLE_NUMBER]: index + 1}
    })
}

export const handleErrors = (promise: Promise<Response>): Promise<any> => (
    promise.then((res) => {
        if (res.status === 204) {
            return {}; // That means that we succeeded but the response is No Content (hence no json response)
        }
        // Clone the response so we wont lock the body of res for reading.
        const jsonResponse: any = res.clone().json().catch(() => (
            // Get the text of the response if we cannot get it as a JSON object.
            res.clone().text().then(errorDetails => (
                {error: `UnknownServerError`, error_details: `${res.statusText} - ${errorDetails}`}
            ), err => (
                // In case we cannot get the text of the response.
                {
                    error: `UnknownServerError`,
                    error_details: `${res.statusText} - Could not get the error message: ${err}`
                }
            )).catch(err => {
                // alert(err)
                AlertHelper.show(err,
                () => AlertHelper.hide())
            })
        ));
        return jsonResponse.then((jsonResult: any): any => {
            if (jsonResult.error) {
                // AlertHelper.show(`${jsonResult.error}\n${jsonResult.error_details}`,
                // () => AlertHelper.hide())

                if (!res.ok) {
                    if (res.status >= 500) {
                        console.error(res);
                    } else {
                        console.warn(res);
                    }
                    throw jsonResult;
                }
            }
            return jsonResult;
        });
    })
);
