import {
    CARDS,
    CLOVER,
    DIAMOND,
    EXTRA,
    FORM_TYPE,
    HEART,
    HEBREW_DATE_AND_TIME,
    HEBREW_DUPLICATE_FORM,
    HEBREW_FORM_COST,
    HEBREW_GAME_AND_LOTTERY_NUMBER,
    HEBREW_NUMBER,
    HEBREW_PULL_STATUS,
    HEBREW_SUM_IN_SHEK, ID,
    LEAF,
    MULTI_LOTTERY,
    NUMBERS,
    PARTICIPANT_AMOUNT,
    STRONG_NUMBERS,
    TABLES
} from "./consts";
import {MarksType123} from "./lotto/regular_lotto/regular123Form";

export type TableType = {
    [NUMBERS]: number[],
    [STRONG_NUMBERS]: number[]
}
export type LottoMarksType = {
    [TABLES]: TableType[],
    [EXTRA]: boolean,
    [MULTI_LOTTERY]: MultiLotteryType
}

export type UserFormType<T> = {
    marks: T,
    tableIndex: number,
    tablesNumber: number,
    numbersLength: number
    strongNumbersLength: number,
    isReadOnly?: boolean,
}
export type ChanceMarksType = {
    [FORM_TYPE]: number,
    [PARTICIPANT_AMOUNT]: number,
    [MULTI_LOTTERY]: MultiLotteryChanceType,
    [CARDS]: {
        [LEAF]: string[],
        [HEART]: string[],
        [DIAMOND]: string[],
        [CLOVER]: string[]
    }
}
export type ShitatiMarksType = LottoMarksType & {
    [FORM_TYPE]: number | string
}

export type LevelType = 'strict' | 'lax'
export type LottoNumberType = number | string
export type MultiLotteryType = -1 | 2 | 4 | 6 | 8 | 10
export type MultiLotteryChanceType = -1 | 2 | 3 | 4 | 5 | 7 | 10 | 12
export type MultiLotterySevenType = -1 | 2 | 3 | 4
export type MyPullingsHeadersType = ['מספר', 'שעת שליחת הטופס במערכת', 'סה"כ בשקלים', "טופס"]
export type MyActiveFormsHeadersType = ['מספר', 'שעת שליחת הטופס במערכת', 'עלות הטופס', 'סטטוס', "שכפל טופס","טופס"]
export type MyFormsHistoryTypeHeaders = ['משחק ומס׳ הגרלה', 'שעת שליחת הטופס במערכת', 'עלות הטופס', 'שכפל טופס']
export type MyWinsTypeHeaders = ['מספר זכיה', 'שעת שליחת הטופס במערכת', 'סה"כ בשקלים', 'משחק',  "טופס"]
export type MyPullingsRows = {
    [HEBREW_NUMBER]: number
    [HEBREW_DATE_AND_TIME]: string,
    [HEBREW_SUM_IN_SHEK]: number,
    [HEBREW_PULL_STATUS]: string
    [ID]: string
}[]
export type MyPullingsType = {
    headers: MyPullingsHeadersType,
    rows: MyPullingsRows,
    cur_page: number,
    total: number,
    has_next: boolean,
    has_prev: boolean,
}
export type RegularSevenFormType = {
    calcPriceFunction: CalcPriceFunction<SevenMarksType>,
    postFormFunction: PostFormFunction<SevenMarksType>,
    isShitati?: boolean,
    initForm: SevenMarksType,
    formType:FormType,
    color: string
}
export type SevenActionType = {
    type: string,
    value: number | string,
    tableIndex: number,
    multiLotteryValue?: MultiLotterySevenType,
    numbersLength: number
    formType?: number
}
export type SumAndSubmitSevenSectionType = {
    bottonColor: string,
    calcPriceFunction: () => Promise<{ price: number }>,
    postFunction: () => Promise<Response>
    validateForm: (lvl?: 'strict' | 'lax') => boolean,
    color: string,
    marks: SevenMarksType,
    formType:FormType,
    fullTables:number,
}
export type SevenTables = { [NUMBERS]: number[] }
export type SevenMarksType = {
    [TABLES]: SevenTables[],
    [MULTI_LOTTERY]: MultiLotterySevenType,
    [FORM_TYPE]: number
}
export type GenericFormType = LottoMarksType | ChanceMarksType | ShitatiMarksType | SevenMarksType | MarksType123
export type FormType =
    'regular_lotto' | 'regular_double_lotto' | 'double_lotto_shitati' | 'double_lotto_shitati_hazak'
    | 'lotto_shitati'
    | 'lotto_shitati_hazak'
    | 'regular_chance'
    | 'rav_chance'
    | 'chance_shitati' | 'regular_777' | "shitati_777" | "123"
export const EnglishHebrewFormTypeMapping = {
        'regular_lotto' : 'לוטו רגיל',
        'regular_double_lotto': 'דאבל לוטו רגיל',
        'double_lotto_shitati': 'דאבל לוטו שיטתי',
        'double_lotto_shitati_hazak': 'דאבל לוטו שיטתי חזק',
        'lotto_shitati': 'לוטו שיטתי',
        'lotto_shitati_hazak': 'לוטו שיטתי חזק',
        'regular_chance': "צ'אנס רגיל",
        'rav_chance': "רב צ'אנס",
        'chance_shitati': "צ'אנס שיטתי",
        'regular_777': '777 רגיל',
        "shitati_777": '777 שיטתי',
        "123": "הגרלת 123"
    }
export type MyFormsHistoryRowType = {
    [HEBREW_GAME_AND_LOTTERY_NUMBER]: number
    [HEBREW_DATE_AND_TIME]: string,
    [HEBREW_FORM_COST]: number,
    [HEBREW_DUPLICATE_FORM]: string,
    [FORM_TYPE]: FormType,
    marks: GenericFormType,
    [ID]: string
}
export type MyFormsHistoryRowsType = MyFormsHistoryRowType[]
export type MyFormsHistoryType = {
    headers: MyFormsHistoryTypeHeaders,
    rows: MyFormsHistoryRowsType
}
export type CalcPriceFunction<T> = (marks: T, jwtToken: string) => Promise<{ price: number }>
export type PostFormFunction<T> = (marks: T, jwtToken: string) => Promise<Response>
export type RegularLottoFormType = {
    calcPriceFunction: CalcPriceFunction<LottoMarksType>,
    postFormFunction: PostFormFunction<LottoMarksType>,
    tablesNumber: number,
    initForm: LottoMarksType,
    numbersLength: number
    strongNumbersLength: number,
    formType:  FormType,
    color: string,
    isMobile?: boolean
}
