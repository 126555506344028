import React, {useState} from 'react'
import {Button, StyleSheet, Text, View} from "react-native";
import {BLUE_COLOR, FB_SPACER_BOLD, FB_SPACER_REGULAR, getFontCss, RED_COLOR, WHITE_COLOR} from "../styles";
import {SignInField} from "./utils";
import {Auth} from "aws-amplify";
import {useAsync} from "../server/utils";
import {useHistory} from "react-router-dom";
import {SIGN_IN_PATH} from "../consts";
import {CONFIRMATION_ERROR_MESSAGE} from "./signUp";

const FORGOT_PASSWORD_ERROR_MESSAGE = "המייל לא תקין"
const CONFIRMATION_CODE_MESSAGE = "קוד אימות נשלח לטלפון, אנא הזן אותו כאן"

const ForgotPassword = ({isMobile}: {isMobile?: boolean}) => {

    const style = StyleSheet.create({
        container: {
            // width: '34.3%',
            marginHorizontal: 450,
            backgroundColor: BLUE_COLOR,
            alignItems: "center",
            justifyContent: "center",
            marginVertical: 50,
            alignSelf: "center",
            borderRadius: 12,
            height: 'fit-content',
            paddingBottom: 50,
        },
        containerMobile: {
            width: '100%',
            backgroundColor: BLUE_COLOR,
            alignItems: "center",
            justifyContent: "center",
            marginVertical: 50,
            alignSelf: "center",
            borderRadius: 12,
            height: 'fit-content',
            paddingBottom: 50,
        }
    })
    const [userData, setUserData] = useState<{ [key: string]: string }>({
        email: '',
        confirmationCode: '',
        newPassword: '',

    })

    const [sentMail, setSentMail] = useState(false)
    const history = useHistory()

    const onChangeText = (key: string, value: string) => {
        setUserData({...userData, [key]: value})
    }

    const confirmCode = async () => {
        return await Auth.forgotPasswordSubmit(userData['email'].toLowerCase(), userData['confirmationCode'], userData['newPassword']).then(() => history.push(SIGN_IN_PATH))
    }

    const forgotPassword = async () => {
        return await Auth.forgotPassword(userData['email'].toLowerCase()).then(() => {
            setSentMail(true)
        })
        .catch((e) => {
            console.log(`err - ${e.message}`)
        })
    }
    const {
        status: forgotPasswordStatus,
        execute: forgotPasswordExecutor,
        error: forgotPasswordError
    } = useAsync<any, { message: string }>(forgotPassword, false)
    const {
        status: confirmCodeStatus,
        execute: confirmCodeExecutor,
        error: confirmCodeError
    } = useAsync<void, { message: string }>(confirmCode, false)

    return (
        <View style={isMobile ? style.containerMobile : style.container}>
            <View style={{width: '75%', alignItems: "center"}}>
                <Text style={isMobile ? {
                    ...getFontCss(FB_SPACER_BOLD, 55, WHITE_COLOR),
                    marginBottom: 50,
                    marginTop: 10,
                    alignSelf: "flex-end",
                    marginRight: '2%'
                } : {
                    ...getFontCss(FB_SPACER_BOLD, 35, WHITE_COLOR),
                    marginBottom: 50,
                    marginTop: 10,
                    alignSelf: "flex-end",
                    marginRight: '2%'
                }}>שכחת את הסיסמה?</Text>
                {forgotPasswordStatus !== 'success' && forgotPasswordError && <Text
                    style={isMobile ? {marginTop: 10, ...getFontCss(FB_SPACER_REGULAR, 45, RED_COLOR)} : {marginTop: 10, ...getFontCss(FB_SPACER_REGULAR, 14, RED_COLOR)}}>{FORGOT_PASSWORD_ERROR_MESSAGE}</Text>}
                {confirmCodeStatus !== 'success' && confirmCodeError && <Text
                    style={isMobile ? {marginTop: 10, ...getFontCss(FB_SPACER_REGULAR, 45, RED_COLOR)} : {marginTop: 10, ...getFontCss(FB_SPACER_REGULAR, 14, RED_COLOR)}}>{CONFIRMATION_ERROR_MESSAGE}</Text>}

                <SignInField onChangeText={(value) => onChangeText('email', value)} placeholder={'אימייל'}
                             autoCompleteType={'email'} isMobile={isMobile}/>
                {
                    sentMail && confirmCodeStatus !== 'success' && <>
                        <SignInField onChangeText={(value) => onChangeText('confirmationCode', value)}
                                     placeholder={'קוד אימות'} isMobile={isMobile}/>
                        <Text
                            style={isMobile ? {margin: 10, ...getFontCss(FB_SPACER_REGULAR, 45, RED_COLOR)} : {margin: 10, ...getFontCss(FB_SPACER_REGULAR, 14, RED_COLOR)}}>{CONFIRMATION_CODE_MESSAGE}
                        </Text>
                        <SignInField onChangeText={(value) => onChangeText('newPassword', value)}
                                     placeholder={'סיסמה חדשה'} secureTextEntry={true} isMobile={isMobile}/>
                    </>
                }
            </View>
            {forgotPasswordStatus !== 'success' && <Button title={'שלח סמס'} onPress={forgotPasswordExecutor}/>}
            {forgotPasswordStatus === 'success' && <Button title={'אישור'} onPress={confirmCodeExecutor}/>}

        </View>
    )
}
export default ForgotPassword