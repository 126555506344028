import React, {useCallback, useContext, useEffect, useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {Button, ButtonGroup, TextField} from "@material-ui/core";
import {BLUE_COLOR, FB_SPACER_BOLD, FB_SPACER_REGULAR, getFontCss, RED_COLOR} from "../styles";
import {Text, View} from "react-native";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import {UserContext} from "../appSwitch";
import { HasTokenType, has_credit_token, refund, refund_token, SuccessType} from "../server/payment";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import { IframeDialog } from './iframeDialog';

import { AlertHelper } from '../../web/AlertHelper';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(1),
            height: 40,
            width: 120,
            backgroundColor: BLUE_COLOR
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            height: 250,
            width: 400,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
    }),
);


type ChargeModalType = {
    updateSrc: (val:string)=>void,
    updateIframeOpen:  (val:boolean)=>void,
    handleClose: ()=>void,
}

const ChargeModal: React.FC<ChargeModalType> = ({ handleClose, updateSrc, updateIframeOpen}) => {
    const classes = useStyles();
    const [amount, setAmount] = useState<number | undefined>()
    const { jwtToken } = useContext(UserContext)
    const [creditToken, setCreditToken] = useState<HasTokenType>({token: false})
    const [errMsg, setErrMsg] = useState('')

    useEffect(() => {
        if (jwtToken) {
            has_credit_token(jwtToken).then(res => {
                if(res.token == 'not found'){
                    setCreditToken({token: false})
                }else {
                    setCreditToken({
                        token: true,
                        end_digits: res.end_digits
                    })
                }
            })
        }
    }, [jwtToken])
    const onClick = useCallback(() => {
        if (amount && jwtToken) {
            if(creditToken.token){
                if (amount > 5.9) {
                    refund_token(jwtToken, amount ).then((res) => {
                        AlertHelper.show(`${(res as SuccessType).result}`,
                        () => AlertHelper.hide())
                    })
                }
                else {
                    setErrMsg('ניתן למשוך קרדיט מעל 5.90')
                }

            }
            else {
                setErrMsg('ניתן למשוך קרדיט רק לכרטיס שמור במערכת')
            }
        }
        return
    }, [amount, jwtToken, creditToken])

    return <View style={{ height: '100%' , flexDirection: "row-reverse" }}>
        <View style={{ width: '100%' }}>
            <Text style={{ ...getFontCss(FB_SPACER_BOLD, 24, BLUE_COLOR), marginBottom: 24 }}>משיכת קרדיט</Text>
            <Text style={{ ...getFontCss(FB_SPACER_REGULAR, 14, BLUE_COLOR), marginBottom: 16 }}>הכנס את הסכום המבוקש
                למשיכה בשקלים</Text>
            <TextField placeholder={'הכנס סכום מבוקש'} style={{ width: '50%', marginBottom: 16 }} type={'number'}
                onChange={(event) => setAmount(Number(event.target.value))} />      
            {creditToken.token && <Text style={{ ...getFontCss(FB_SPACER_REGULAR, 14, BLUE_COLOR), marginBottom: 16 }}>משיכה לכרטיס שמסתיים ב: {creditToken.end_digits}</Text>}
            {!creditToken.token && <Text style={{ ...getFontCss(FB_SPACER_REGULAR, 14, BLUE_COLOR), marginBottom: 16 }}>לצורך זיהוי ישמר אשראי במערכת</Text>}
            <ButtonGroup>
                <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<AccountBalanceIcon />}
                onClick={onClick}
            >
                משוך קרדיט
            </Button>
            </ButtonGroup> 
            <Text style={{ ...getFontCss(FB_SPACER_REGULAR, 11, RED_COLOR), marginBottom: 10 }}>{errMsg}</Text>
            <Text style={{ ...getFontCss(FB_SPACER_REGULAR, 11, RED_COLOR), marginBottom: 10 }}>משיכה מעל סכום של 50 ש"ח תועבר לאישור מנהל</Text>
            <Text style={{...getFontCss(FB_SPACER_REGULAR, 11, BLUE_COLOR), marginBottom: 16}}>* משיכת הכסף מלווה בעמלת לוטומטיק של 5.9 ש"ח</Text>
        </View>
    </View>
}


export default function PullButton() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [iframeOpen, setIframeOpen] = useState(false);
    const [src, SetSrc] = useState('')
    const handleOpen = () => {
        setOpen(true);
    };
    const updateSrc = (_src:string) =>{
        SetSrc(_src)
    };
    const updateIframeOpen = (_open:boolean) =>{
        setIframeOpen(_open)
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handelCreditClose = (reason:string) => {
        setIframeOpen(false);
    };

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<AccountBalanceIcon/>}
                onClick={handleOpen}
            >
                משוך קרדיט
            </Button>
            <Modal
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <ChargeModal handleClose={handleClose} updateSrc={updateSrc} updateIframeOpen={updateIframeOpen}/>
                    </div>
                </Fade>
            </Modal>
            <IframeDialog open={iframeOpen} src={src} onClose={()=> handelCreditClose() } />
        </>
    );
}
