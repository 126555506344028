import React from "react";
import {Button, Modal, Result, Spin} from "antd";
import {StyleSheet, Text} from "react-native";
import {ResultStatus} from "./lotto/regular_lotto/additionalSections/sumAndSubmitSection";
import {createStyles, makeStyles, Theme} from "@material-ui/core";

interface PaymentModalProps {
    isLoading: boolean,
    isMobile?: boolean,
    showModal: boolean,
    resultStatus: ResultStatus,
    titleName: string,
    resultMsg?: string,
    onClose: () => void,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalStyle: {
            textAlign: 'center',
            direction: 'rtl',
            '& .ant-modal-content': {
                borderRadius: 16,
                display: 'flex',
                justifyContent: 'center',
            }
        },
        mobileFontSize: {
            fontSize: 64
        },
        resultContent: {
            display: 'flex',
            flexDirection: 'column',
            gap: 20
        }
    })
);

export const PaymentModal: React.FC<PaymentModalProps> = ({isLoading, isMobile, showModal, resultStatus, titleName, resultMsg, onClose}) => {
    const style = StyleSheet.create({
        mobileFontSize: {
            fontSize: 64
        },
        resultContent: {
            display: 'flex',
            flexDirection: 'column',
            gap: 20
        }
    })
    const classes = useStyles()
    return (
        <Modal width={isMobile ? '70%' : undefined} closable={!isLoading} onCancel={onClose} className={classes.modalStyle} footer={null} centered visible={showModal}>
            {isLoading && <Spin className={isMobile ? classes.mobileFontSize : ''} size={'large'} tip="שולח טופס ...."/>}
            {!isLoading && <Result status={resultStatus} title={titleName}>
                <div className={classes.resultContent}>
                    {isMobile && <Text style={isMobile ? style.mobileFontSize : {}}>{titleName}</Text>}
                    <Text style={isMobile ? style.mobileFontSize : {}}>{resultMsg}</Text>
                    <Button type="primary" key="console" onClick={onClose} style={{height: '100%'}}>
                        <span className={isMobile ? classes.mobileFontSize : ''}>סגור</span>
                    </Button>
                </div>
            </Result>}
        </Modal>
    )
}