import React from 'react';
import {GestureResponderEvent, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {FB_SPACER_BOLD, getBorder, getFontCss, WHITE_COLOR, YELLOW_COLOR} from "../styles";
import {useHistory} from 'react-router-dom'

type GamePath = string
type FormNavigationType = {
    titles: { [title: string]: GamePath },
    backgroundColor: string,
    isMobile?: boolean
}
type NodeType = {
    text: string,
    onPress: (event: GestureResponderEvent) => void,
    backgroundColor: string,
    isMobile?: boolean
}


const CurrentNode = ({text, onPress, backgroundColor, isMobile}: NodeType) => {

    const style = StyleSheet.create({
        // clickedNavigationNode: {
        //     width: '33.33%',
        //     backgroundColor: backgroundColor,
        //     ...getBorder(),
        // },
        clickedNavigationNode: {
            width: '33.33%',
            backgroundColor: backgroundColor,
            borderRightWidth: 20,
            borderLeftWidth: 20,
            borderBottomWidth: 20,
            borderRightColor: WHITE_COLOR,
            borderLeftColor: WHITE_COLOR,
            borderBottomColor: WHITE_COLOR,
        },
        clickedNavigationNodeMobile: {
            width: '33.33%',
            backgroundColor: backgroundColor,
            borderRightWidth: 13,
            borderLeftWidth: 13,
            borderBottomWidth: 9,
            borderRightColor: WHITE_COLOR,
            borderLeftColor: WHITE_COLOR,
            borderBottomColor: WHITE_COLOR,
        },
        formNavigationText: {
            ...getFontCss(FB_SPACER_BOLD, 25, WHITE_COLOR),
            marginRight: '20%',
            marginTop: 9
        },
        formNavigationTextMobile: {
            ...getFontCss(FB_SPACER_BOLD, 55, WHITE_COLOR),
            marginRight: '20%',
            marginTop: 9
        }
    })

    return (
        <TouchableOpacity style={isMobile ? style.clickedNavigationNodeMobile : style.clickedNavigationNode} onPress={onPress}>
            <Text style={isMobile ? style.formNavigationTextMobile : style.formNavigationText}>{text}</Text>
        </TouchableOpacity>
    )
}

const Node = ({text, onPress, backgroundColor, isMobile}: NodeType) => {

    const style = StyleSheet.create({
        unclickedNavigationNodeContainer: {
            width: '33.33%',
            // ...getBorder(),
            borderRightColor: WHITE_COLOR,
            borderLeftColor: WHITE_COLOR,
            borderRightWidth: 1,
            borderLeftWidth: 1,
            // backgroundColor: WHITE_COLOR
            backgroundColor: backgroundColor
        },//the border color
        unclickedNavigationNode: {
            marginRight: '6.3%',
            backgroundColor: backgroundColor,
            height: 49
        },
        formNavigationText: {
            ...getFontCss(FB_SPACER_BOLD, 25, WHITE_COLOR),
            marginRight: '20%',
            marginTop: 9
        },
        formNavigationTextMobile: {
            ...getFontCss(FB_SPACER_BOLD, 55, WHITE_COLOR),
            marginRight: '20%',
            marginTop: 9
        }
    })

    return (
        <TouchableOpacity style={style.unclickedNavigationNodeContainer} onPress={onPress}>
            <View style={style.unclickedNavigationNode}>
                <Text style={isMobile ? style.formNavigationTextMobile : style.formNavigationText}>{text}</Text>
            </View>
        </TouchableOpacity>
    )
}

const LottoFormNavigation = ({titles, backgroundColor, isMobile}: FormNavigationType) => {
    const history = useHistory()

    const style = StyleSheet.create({
        formNavigationContainer: {
            flexDirection: "row-reverse",
            height: 68,
            marginBottom: 1,
            backgroundColor: WHITE_COLOR,
            ...getBorder()
        },
    })

    const getNodes = () => {
        return Object.entries(titles).map(([title, path]) => history.location.pathname === path ?
            <CurrentNode text={title} onPress={() => {
                history.push(path)
            }} backgroundColor={backgroundColor} isMobile={isMobile}/> :
            <Node text={title} onPress={() => {
                history.push(path)
            }} backgroundColor={backgroundColor} isMobile={isMobile}/>)
    }

    return (
        <View style={style.formNavigationContainer}>
            {getNodes()}
        </View>
    )
}


export default LottoFormNavigation