import {Image, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {FB_SPACER_BOLD, getBorder, getCircleCss, getFontCss, LIGHT_GREEN_COLOR, WHITE_COLOR} from "../../../styles";
import UserInputFormHeader, {UserInputFormHeaderText, UserInputFormUpperHeaderChildren} from "./userInputFormHeader";
import UserInputNumbersForm, {PickStrongNumber} from "./userInputNumbersForm";
import {SubInstructionsText} from "../additionalSections/firstSectionInstructions";
import React from "react";
import {PipeLine} from "../../../genaricComponents";
import {LottoNumberType, TableType} from "../../../types";


type TableActionType = {
    backgroundColor?: string,
    text: string,
    borderColor?: string,
    onPress: () => void,
    isMobile? : boolean
}
type UserInputStrongNumbersFormType = {
    color: string,
    table: TableType,
    removeStrongNumber: (number: LottoNumberType) => void,
    addStrongNumber: (number: LottoNumberType) => void,
    strongNumbersLength: number,
    isMobile?: boolean

}
export type UserInputFormType = {
    tableIndex: number,
    isReadOnly?: boolean,
    color: string,
    addNumber: (lottoNumber: LottoNumberType) => void,
    removeNumber: (lottoNumber: LottoNumberType) => void,
    removeStrongNumber: (strongLottoNumber: LottoNumberType) => void,
    addStrongNumber: (strongLottoNumber: LottoNumberType) => void,
    incrementTableIndex: () => void,
    decrementTableIndex: () => void,
    popTableNumbers: () => void,
    tableAutoInsertion: () => void
    table: TableType,
    numbersLength: number,
    strongNumbersLength: number,
    isMobile?: boolean,
}

const TableAction = ({text, borderColor, onPress, backgroundColor, isMobile}: TableActionType) => {

    const style = StyleSheet.create({
        container: {
            backgroundColor: backgroundColor || undefined,
            borderStyle: "solid",
            borderColor: borderColor || WHITE_COLOR,
            borderWidth: 1,
            ...getCircleCss(20, '30%', 10),
            alignItems: "center",
            justifyContent: "center"
        },
        text: {
            ...getFontCss(FB_SPACER_BOLD, 12, WHITE_COLOR)
        },
        containerMobile: {
            backgroundColor: backgroundColor || undefined,
            borderStyle: "solid",
            borderColor: borderColor || WHITE_COLOR,
            borderWidth: 1,
            ...getCircleCss(53, '35%', 10),
            alignItems: "center",
            justifyContent: "center"
        },
        textMobile: {
            ...getFontCss(FB_SPACER_BOLD, 52, WHITE_COLOR)
        }
    })


    return (
        <TouchableOpacity style={isMobile ? style.containerMobile : style.container} onPress={onPress}>
            <Text style={isMobile ? style.textMobile : style.text}>{text}</Text>
        </TouchableOpacity>
    )
}

export const DeleteAndFillNumbersSection = ({color, popTableNumbers, tableAutoInsertion, isMobile}: {
    tableAutoInsertion: () => void,
    popTableNumbers: () => void,
    color: string,
    isMobile?: boolean
}) => {

    const style = StyleSheet.create({
        fillDeleteCommandsContainer: {
            flexDirection: "row-reverse",
            height: 50,
            ...getBorder(),
            justifyContent: "center",
            alignItems: "center"
        },
        fillDeleteCommandsContainerMobile: {
            flexDirection: "row-reverse",
            height: 72,
            paddingTop: 18,
            marginTop: 11,
            marginBottom: 16,
            ...getBorder(),
            justifyContent: "center",
            alignItems: "center"
        },
        image: {
            height: 28,
            width: 28,
            marginLeft: '1%'
        },
        imageMobile: {
            height: 38,
            width: 38,
            marginLeft: '27%'
        }
    })

    return (
        <View style={isMobile ? style.fillDeleteCommandsContainerMobile : style.fillDeleteCommandsContainer}>
            <TouchableOpacity onPress={tableAutoInsertion}>
                <Image source={require('../../../../../assets/FillTable.png')} style={isMobile ? style.imageMobile : style.image}/>
            </TouchableOpacity>
            <TableAction backgroundColor={color} text={'מלא טבלה אוטומטית'} borderColor={LIGHT_GREEN_COLOR}
                         onPress={tableAutoInsertion} isMobile={isMobile}/>

            <PipeLine marginLeft={'3.8%'} marginRight={'3.8%'}/>

            <TouchableOpacity onPress={popTableNumbers}>
                <Image source={require('../../../../../assets/EmptyTable.png')} style={isMobile ? style.imageMobile : style.image}/>
            </TouchableOpacity>
            <TableAction text={'מחק טבלה אוטומטית'} onPress={popTableNumbers} isMobile={isMobile}/>
        </View>
    )

}
const UserInputStrongNumbersForm = ({color, addStrongNumber, removeStrongNumber, table, strongNumbersLength, isMobile}: UserInputStrongNumbersFormType) => {

    const style = StyleSheet.create({
        choseStrongNumberContainer: {
            justifyContent: "center",
            height: 43,
            ...getBorder()
        },
        choseStrongNumberContainerMobile: {
            justifyContent: "center",
            height: 63,
            ...getBorder()
        },
        strongNumbersContainer: {
            height: 97,
            ...getBorder()
        },
        strongNumbersContainerMobile: {
            ...getBorder()
        }
    })

    return (
        <>
            <View style={isMobile ? style.choseStrongNumberContainerMobile : style.choseStrongNumberContainer}>
                <SubInstructionsText marginRight={'8%'} text={'בחר מספר אחד'} isMobile={isMobile}/>
            </View>
            <View style={isMobile ? style.strongNumbersContainerMobile : style.strongNumbersContainer}>
                <UserInputFormHeader isMobile={isMobile}>
                    <UserInputFormHeaderText text={'מספר חזק'} color={color} isMobile={isMobile}/>
                </UserInputFormHeader>
                <PickStrongNumber addStrongNumber={addStrongNumber} removeStrongNumber={removeStrongNumber}
                                  table={table} strongNumbersLength={strongNumbersLength} isMobile={isMobile}/>
            </View>
        </>
    )
}


const UserInputForm = ({
                           tableIndex,
                           isReadOnly,
                           color,
                           numbersLength,
                           addNumber,
                           addStrongNumber,
                           incrementTableIndex,
                           decrementTableIndex,
                           popTableNumbers,
                           tableAutoInsertion,
                           table,
                           removeNumber,
                           removeStrongNumber,
                           strongNumbersLength,
                           isMobile,
                       }: UserInputFormType) => {

    const style = StyleSheet.create({
        innerFormTablesContainer: {
            width: '50%',
            paddingRight: '1%',
            ...getBorder()
        },
        innerFormTablesContainerMobile: {
            width: '100%',
            paddingRight: '1%',
            ...getBorder()
        },
        numbersFillerContainer: {
            height: 396,
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: WHITE_COLOR,
            borderRadius: 15
        },
        numbersFillerContainerMobile: {
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: WHITE_COLOR,
            borderRadius: 15
        },
        fillDeleteCommandsContainer: {
            flexDirection: "row-reverse",
            height: 50,
            ...getBorder(),
            justifyContent: "center",
            alignItems: "center"
        },
        choseStrongNumberContainer: {
            justifyContent: "center",
            height: 43,
            ...getBorder()
        },
        strongNumbersContainer: {
            height: 97,
            ...getBorder()
        }
    })

    return (
        <View style={isMobile ? style.innerFormTablesContainerMobile : style.innerFormTablesContainer}>
            <View style={isMobile ? style.numbersFillerContainerMobile : style.numbersFillerContainer}>
                {!isReadOnly && <UserInputFormHeader isMobile={isMobile}>
                    <UserInputFormUpperHeaderChildren incrementTableIndex={incrementTableIndex}
                                                      decrementTableIndex={decrementTableIndex} color={color}
                                                      tableIndex={tableIndex} isMobile={isMobile}/>
                </UserInputFormHeader>}
                {!isReadOnly && <DeleteAndFillNumbersSection color={color} popTableNumbers={popTableNumbers}
                                                             tableAutoInsertion={tableAutoInsertion} isMobile={isMobile}/>}
                <UserInputNumbersForm isReadOnly={isReadOnly} addNumber={addNumber} table={table}
                                      removeNumber={removeNumber}
                                      numbersLength={numbersLength} numbersAmount={37} numbersPerRow={7} isMobile={isMobile}/>
            </View>
            <UserInputStrongNumbersForm addStrongNumber={addStrongNumber} table={table}
                                        removeStrongNumber={removeStrongNumber}
                                        strongNumbersLength={strongNumbersLength} color={color} isMobile={isMobile}/>
        </View>
    )
}


export default UserInputForm