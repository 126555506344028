import {KeyboardTypeOptions, StyleSheet, TextInput} from "react-native";
import React, {useState, useRef} from "react";
import {FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography} from "@material-ui/core";
import {BLUE_COLOR, FB_SPACER_REGULAR, getFontCss, WHITE_COLOR} from "../styles";
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import { inputHelper } from "../administration/inputHelper";

export const SignInField = ({
                                keyboardType,
                                width,
                                placeholder,
                                onChangeText,
                                secureTextEntry,
                                autoCompleteType,
                                textContentType,
                                defaultValue,
                                maxLength,
                                isMobile,
                                disabled,
                            }: {
    width?: string | number
    textContentType?: | 'none'
        | 'URL'
        | 'addressCity'
        | 'currency'
        | 'addressCityAndState'
        | 'addressState'
        | 'countryName'
        | 'creditCardNumber'
        | 'emailAddress'
        | 'familyName'
        | 'fullStreetAddress'
        | 'givenName'
        | 'jobTitle'
        | 'location'
        | 'middleName'
        | 'name'
        | 'namePrefix'
        | 'nameSuffix'
        | 'nickname'
        | 'organizationName'
        | 'postalCode'
        | 'streetAddressLine1'
        | 'streetAddressLine2'
        | 'sublocality'
        | 'telephoneNumber'
        | 'username'
        | 'password'
        | 'newPassword'
        | 'oneTimeCode',
    autoCompleteType?: | 'cc-csc'
        | 'cc-exp'
        | 'cc-exp-month'
        | 'cc-exp-year'
        | 'cc-number'
        | 'email'
        | 'name'
        | 'password'
        | 'postal-code'
        | 'street-address'
        | 'tel'
        | 'username'
        | 'off', placeholder: string, onChangeText: (value: string) => void, secureTextEntry?: boolean, defaultValue?: string, keyboardType?: KeyboardTypeOptions, maxLength?: number, isMobile?: boolean, disabled?: boolean
}) => {

    const style = StyleSheet.create({
        input: {
            height: 50,
            borderBottomWidth: 2,
            borderBottomColor: '#2196f3',
            margin: 10,
            borderRadius: 12,
            width: width || '75%',
            backgroundColor: 'rgb(232,240,254)',
            textAlign: "right"
        },
        inputMobile: {
            height: 90,
            borderBottomWidth: 2,
            borderBottomColor: '#2196f3',
            margin: 10,
            fontSize: 64,
            borderRadius: 12,
            width: '85%',
            backgroundColor: 'rgb(232,240,254)',
            textAlign: "right"
        }
    })

    const [value, setValue] = useState(defaultValue)

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'ILS',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      })

    const onChange = (value: string) => {
        if (keyboardType === "numeric" && textContentType != "currency") {
            onChangeText(value.replace(/[^0-9]/g, ''))
            setValue(value.replace(/[^0-9]/g, ''))
        } else if(textContentType == 'currency'){
            let valueIls;
            value = value.replace('₪', '')
            value = value.replace(/[^0-9]/g, '')
            valueIls = formatter.format(+value)
            onChangeText(value)
            setValue(valueIls)
        }
        else {
            onChangeText(value)
            setValue(value)
        }

    }

    return (
        <TextInput ref={ref => inputHelper.setTextInput(ref)} keyboardType={keyboardType} onChangeText={onChange} style={isMobile ? style.inputMobile : style.input} placeholder={placeholder}
                   secureTextEntry={secureTextEntry} autoCompleteType={autoCompleteType}
                   textContentType={textContentType} maxLength={maxLength} value={value} editable={!disabled}/>
    )

}

type GenderSelectionType = {
    onChange: (value: string) => void
}

export const GenderSelection: React.FC<GenderSelectionType> = ({onChange}) => {

    const [value, setValue] = useState('זכר')

    const onPress = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        onChange(value)
        setValue(value)
    }

    return (
        <FormControl component="fieldset">
            <RadioGroup aria-label="מין" name="gender1" value={value} onChange={onPress}
                        style={{flexDirection: "row-reverse"}}>
                <FormControlLabel value="נקבה" control={<Radio/>} label="נקבה"
                                  style={{...getFontCss(FB_SPACER_REGULAR, 16, WHITE_COLOR)}}/>
                <FormControlLabel value="זכר" control={<Radio/>} label="זכר"
                                  style={{...getFontCss(FB_SPACER_REGULAR, 16, WHITE_COLOR)}}/>
            </RadioGroup>
        </FormControl>
    )
}

type CreditSelectionType = {
    onChange: (value: string) => void,
    isMobile?: boolean
}

export const CreditSelection: React.FC<CreditSelectionType> = ({onChange, isMobile}) => {
    console.log(`is mobile: ${isMobile}`);
    const [value, setValue] = useState('true')

    const onPress = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        onChange(value)
        setValue(value)
    }

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            textFont: {
                fontFamily: FB_SPACER_REGULAR,
                fontSize: 16,
                color: BLUE_COLOR,
            },
            textFontMobile: {
                fontFamily: FB_SPACER_REGULAR,
                fontSize: 56,
                color: BLUE_COLOR,
            },
        }),
    );

    const styles = useStyles();

    return (
        <FormControl component="fieldset">
            <RadioGroup aria-label="בחירת כרטיס אשראי" name="credit1" value={value} onChange={onPress}
                        style={{flexDirection: "column"}}>
                <FormControlLabel value="true" control={<Radio size={'medium'}/>} label={<Typography className={isMobile ? styles.textFontMobile : styles.textFont}>חיוב אשראי קיים</Typography>} labelPlacement="start"/>
                <FormControlLabel value="false" control={<Radio/>} label={<Typography className={isMobile ? styles.textFontMobile : styles.textFont}>חיוב כרטיס חדש</Typography>} labelPlacement="start"/>
            </RadioGroup>
        </FormControl>
    )
}

type DateFieldType = {
    onChange: (date: string) => void
    label: string
    isTime?: boolean
    id : string,
    isMobile?: boolean,
}

export const DateField: React.FC<DateFieldType> = ({onChange, label, isTime, id, isMobile}) => {
    const useStyles = makeStyles((theme) => ({
        container: {
            width: '75%',
            margin: 10,
            padding: '1%'
        },
        containerMobile: {
            width: '87%',
            margin: 10,
            padding: '1%',
            height: 88,
            '& .MuiInput-formControl': {
                marginTop: 38,
                marginLeft: 18
            }
        },
        textField: {
            width: '100%',
            backgroundColor: 'rgb(232,240,254)',
            borderRadius: 12
        },
        textFieldMobile: {
            width: '100%',
            backgroundColor: 'rgb(232,240,254)',
            borderRadius: 12,
            '& > *': {
                fontSize: 55,
            }
        }
    }));
    const classes = useStyles();

    const now = new Date()
    const [date, setDate] = useState(`${now.getMonth()}/${now.getDay()}/${now.getFullYear()}`)

    const handleDateChange = (event: any) => {
        setDate(event.target.value);
        onChange(event.target.value)

    }

    return (
        <form className={isMobile ? classes.containerMobile : classes.container} noValidate>
            <TextField
                label={label}
                type={isTime ? "datetime-local" : "date"}
                defaultValue={date}
                className={isMobile ? classes.textFieldMobile : classes.textField}
                InputLabelProps={{
                    shrink: true,
                    style: {marginLeft: '2%', marginTop: '1%'}
                }}
                onChange={handleDateChange}
            />
            <input id={id} type='reset' value='reset' style={{display : 'none'}}/>
            {document.getElementById(id)?.addEventListener('click', ()=>{})}
        </form>
    )
}