import {ChanceMarksType, FormType, GenericFormType, LottoMarksType, SevenMarksType, ShitatiMarksType} from "../types";
import {Image, StyleSheet, Text, View} from "react-native";
import {
    BLUE_COLOR,
    BLUE_GRAY_COLOR,
    FB_SPACER_BOLD,
    FB_SPACER_REGULAR,
    getBorder,
    getCircleCss,
    getFontCss,
    GREEN_COLOR,
    RED_COLOR,
    WHITE_COLOR,
    YELLOW_123_COLOR
} from "../styles";
import {EXTRA, FORM_TYPE, MULTI_LOTTERY, NON_NUMBER, PARTICIPANT_AMOUNT, TABLES} from "../consts";
import {Extra, MultiLottery} from "../lotto/regular_lotto/additionalSections/formAdditions";
import UserForm, {
    ShitatiHazakUserForm,
    StrongNumber,
    UserFormContainer
} from "../lotto/regular_lotto/userForm/userForm";
import {Payment} from "../lotto/regular_lotto/additionalSections/sumAndSubmitSection";
import React, {useEffect, useState} from "react";
import {
    get123Price,
    getChanceShitatiPrice,
    getDoubleLottoShitatiHazakPrice,
    getDoubleLottoShitatiPrice,
    getLottoShitatiHazakPrice,
    getLottoShitatiPrice,
    getRavChancePrice,
    getRegularChancePrice,
    getRegularDoubleLottoPrice,
    getRegularLottoPrice,
    getRegularSevenPrice,
    getShitatiSevenPrice,
    post123Form,
    postChanceShitatiForm,
    postDoubleLottoShitatiForm,
    postDoubleLottoShitatiHazakForm,
    postLottoShitatiForm,
    postLottoShitatiHazakForm,
    postRavChanceForm,
    postRegularChanceForm,
    postRegularDoubleLottoForm,
    postRegularLottoForm,
    postRegularSevenForm,
    postShitatiSevenForm
} from "../server/serverApi";
import {LottoNumber, LottoRowBalls} from "../lotto/lottoBalls";
import {SubInstructionsText} from "../lotto/regular_lotto/additionalSections/firstSectionInstructions";
import {ChanceMediumText, ChanceRowBalls, PickCardTable} from "../lotto/regular_lotto/regularChanceForm";
import {SevenUserForm} from "../lotto/regular_lotto/regular777Form";
import {MarksType123, UserForm123} from "../lotto/regular_lotto/regular123Form";
import {get123Result, get777Result, getChanceResult, getLottoResult} from "../server/results";

import {AlertHelper} from '../../web/AlertHelper';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import {normalizeTablesData} from "../server/utils";

type LottoShitatiReadOnlyType = {
    title: string,
    postFunction: () => Promise<Response>,
    calcPriceFunction: () => Promise<{ price: number }>,
    marks: ShitatiMarksType,
    numbersLength: number,
    strongNumbersLength: number,
    tablesNumber: number
    lotteryNumber: number
    isAdmin:boolean,
    showClose?: boolean,
    onClose: () => void
}
type RegularLottoReadOnlyType = {
    title: string,
    postFunction: () => Promise<Response>,
    calcPriceFunction: () => Promise<{ price: number }>,
    marks: LottoMarksType,
    numbersLength: number,
    strongNumbersLength: number,
    tablesNumber: number
    lotteryNumber: number
    isAdmin:boolean,
    showClose?: boolean,
    onClose: () => void,
    lottomaticFormData?: string,
}

const LottoFormReadOnlyTitle = ({text, onClose,showClose=true}: { text: string, onClose?: () => void, showClose:boolean }) => {
    const style = StyleSheet.create({
        title: {
            ...getFontCss(FB_SPACER_BOLD, 25, RED_COLOR),
            backgroundColor: BLUE_COLOR,
            marginBottom: '1%',
            alignSelf: "flex-end"
        },
        exitButton: {
            margin: '1px',
            color: RED_COLOR
        },
        titleContainer: {flexDirection: "row-reverse"},
    })
    return (
        <View style={style.titleContainer}>
            {showClose && <IconButton onClick={() => onClose()} style={{margin: '1px', color: RED_COLOR}} aria-label="סגור" >
                <CancelIcon />
            </IconButton>}
            <Text style={style.title}>{text}</Text>
        </View>
    )
}

const LottoFormReadOnlyAdditions = ({multiLottery, extra}: { multiLottery: GenericFormType, extra: boolean }) => {
    const style = StyleSheet.create({
        formContainer: {alignItems: "center", backgroundColor: BLUE_GRAY_COLOR, borderRadius: 12, overflowX: "scroll", maxHeight: "100%"},
        formAdditionsContainer: {flexDirection: "row-reverse", alignItems: "center"},
    })
    return (
        <View style={style.formAdditionsContainer}>
            {multiLottery !== -1 && <MultiLottery setMultiLottery={() => undefined}
                                                  multiLotteryValue={multiLottery}
                                                  isReadOnly={true}/>}
            <View style={{width: 2}}/>
            {extra && <Extra isExtra={() => undefined} extra={extra}/>}
        </View>
    )
}

const LottoWinRow: React.FC<{ number: number }> = ({number}) => {

    const style = {
        row: {
            marginBottom: '16px',
            width: '50%',
            flexDirection: "row",
            justifyContent: "center",
            ...getBorder(),
        },
        ball: {
            marginRight: '4px',
            backgroundColor: WHITE_COLOR,
            ...getCircleCss(25, 25, 12.5),
            justifyContent: "center",
            alignItems: "center",

        },
        ballText: {
            ...getFontCss(FB_SPACER_REGULAR, 22, RED_COLOR)
        }
    }


    const [winData, setWinData] = useState<any>()

    !winData && getLottoResult(number).then((data) => setWinData(data)).catch(err => {console.log(`no data :>> `)})
    const strongNumbers = winData && (Array.isArray(winData['strong_number']) ? winData['strong_number'] : [winData['strong_number']])

    return <>
        {winData && <Text style={{...getFontCss(FB_SPACER_BOLD, 24, WHITE_COLOR), margin: '16px'}}>תוצאות ההגרלה</Text>}
        <View style={{flexDirection: "row"}}>
            {winData &&
            <StrongNumber strongNumbers={strongNumbers || NON_NUMBER} strongNumbersLength={strongNumbers.length} marginRight={"50px"}/>}
            {winData &&
            <LottoRowBalls ballStyle={style.ball} ballTextStyle={style.ballText} numbers={winData['numbers']}
                           rowLength={6}
                           style={style.row}/>}

        </View>
    </>
}

const RegularLottoReadOnly = ({
                                  title,
                                  marks,
                                  numbersLength,
                                  tablesNumber,
                                  strongNumbersLength,
                                  calcPriceFunction,
                                  postFunction,
                                  lotteryNumber,
                                  isAdmin,
                                  onClose,
                                  showClose, lottomaticFormData
                              }: RegularLottoReadOnlyType): JSX.Element => {

    const style = StyleSheet.create({
        formContainer: {alignItems: "center", backgroundColor: BLUE_GRAY_COLOR, borderRadius: 12, overflowX: "scroll", maxHeight: "100%"},
        paymentContainer: {marginTop: 10, flexDirection: "row", alignItems: "center"}
    })

    return (<>
            <LottoFormReadOnlyTitle text={title}  onClose={() => onClose()} showClose={showClose}/>
            <View style={style.formContainer}>
                <LottoFormReadOnlyAdditions multiLottery={marks[MULTI_LOTTERY]} extra={marks[EXTRA]}/>
                <UserFormContainer backgroundColor={BLUE_GRAY_COLOR} width={'100%'}>
                    <UserForm marks={marks} numbersLength={numbersLength} strongNumbersLength={strongNumbersLength}
                              tablesNumber={tablesNumber} tableIndex={0} isReadOnly={true}/>
                </UserFormContainer>
                <LottoWinRow number={lotteryNumber}/>
            </View>
         {!isAdmin &&   <View style={style.paymentContainer}>
                <Payment bottonColor={RED_COLOR} validateForm={() => true} postFunction={postFunction}
                         calcPriceFunction={calcPriceFunction}
                         marks={marks} lottomaticFormData={lottomaticFormData}/>
            </View>}
        </>
    )
}

const LottoShitatiReadOnly = ({
                                title,
                                marks,
                                calcPriceFunction,
                                postFunction,
                                strongNumbersLength,
                                tablesNumber,
                                numbersLength,
                                lotteryNumber,
                                isAdmin,
                                onClose,
                                showClose
                              }: LottoShitatiReadOnlyType) => {

    const style = StyleSheet.create({
        formContainer: {alignItems: "center", backgroundColor: BLUE_GRAY_COLOR, borderRadius: 12, overflowX: "scroll", maxHeight: "100%"},
        paymentContainer: {marginTop: 10, flexDirection: "row", alignItems: "center"}
    })

    return (
        <>
            <LottoFormReadOnlyTitle text={title} onClose={() => onClose()} showClose={showClose}/>
            <View style={style.formContainer}>
                <LottoFormReadOnlyAdditions multiLottery={marks[MULTI_LOTTERY]} extra={marks[EXTRA]}/>
                <View style={{
                    flexDirection: "row-reverse",
                    width: '100%',
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: '2%',
                    marginTop: '2%'
                }}>
                    <SubInstructionsText text={'סוג טופס'}/>
                    <LottoNumber lottoNumber={marks[FORM_TYPE]} ballStyle={{
                        marginRight: '2%',
                        ...getCircleCss(32, 32, 16), backgroundColor: WHITE_COLOR, justifyContent: 'center',

                        alignItems: 'center'
                    }} ballTextStyle={{...getFontCss(FB_SPACER_BOLD, 23, GREEN_COLOR)}}/>
                </View>
                <UserFormContainer marginBottom={70}>
                    <View>
                        <ShitatiHazakUserForm marks={marks} tableIndex={1} tablesNumber={tablesNumber} isReadOnly={true}
                                              numbersLength={numbersLength} strongNumbersLength={strongNumbersLength}/>
                    </View>
                </UserFormContainer>
                <LottoWinRow number={lotteryNumber}/>
            </View>
            {!isAdmin &&   <View style={style.paymentContainer}>
                <Payment bottonColor={GREEN_COLOR} validateForm={() => true} postFunction={postFunction}
                         calcPriceFunction={calcPriceFunction}
                         marks={marks}/>
            </View>}
        </>
    )
}

const ChanceFormReadOnly = ({isFormType, isShitati, title, marks, calcPriceFunction, postFunction, lotteryNumber, isAdmin, onClose, showClose}: {
    title: string,
    postFunction: () => Promise<Response>,
    calcPriceFunction: () => Promise<{ price: number }>,
    marks: ChanceMarksType,
    isShitati: boolean
    isFormType: boolean
    lotteryNumber: number,
    isAdmin: boolean,
    onClose: () => void,
    showClose?: boolean
}) => {

    const style = StyleSheet.create({
        formContainer: {alignItems: "center", backgroundColor: BLUE_GRAY_COLOR, borderRadius: 12, overflowX: "scroll", maxHeight: "100%"},
        paymentContainer: {marginTop: 10, flexDirection: "row", alignItems: "center"}
    })

    return (
        <>
            <LottoFormReadOnlyTitle text={title} onClose={() => onClose()} showClose={showClose}/>
            <View style={style.formContainer}>
                <LottoFormReadOnlyAdditions multiLottery={marks[MULTI_LOTTERY]} extra={false}/>
                <View style={{
                    flexDirection: "row-reverse",
                    width: '100%',
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: '2%',
                    marginTop: '2%'
                }}>

                    {
                        isFormType && <>
                            <ChanceMediumText marginLeft={'1%'} text={'סוג הטופס'} marginTop={0}/>
                            <ChanceRowBalls setNumber={() => undefined} numbers={marks[FORM_TYPE] === 5 ? [] : [marks[FORM_TYPE]]}
                                            removeNumber={() => undefined}
                                            isClicked={() => false}
                                            isShitati={marks[FORM_TYPE] === 5}/>
                        </>
                    }
                    <ChanceMediumText marginLeft={'1%'} text={'סכום ההשקעה'} marginTop={0}/>
                    <ChanceRowBalls numbers={[marks[PARTICIPANT_AMOUNT]]} setNumber={() => undefined}
                                    removeNumber={() => undefined}
                                    isClicked={() => false} isShitati={false}/>
                </View>
                <View>
                    <PickCardTable marks={marks} cards={['7', '8', '9', '10', 'J', 'Q', 'K', 'A']}
                                   addHeart={() => undefined}
                                   addClover={() => undefined} addDiamond={() => undefined} addLeaf={() => undefined}
                                   removeClover={() => undefined} removeDiamond={() => undefined}
                                   removeHeart={() => undefined}
                                   removeLeaf={() => undefined}/>
                   <ChanceWinRow number={lotteryNumber}/>
                </View>
            </View>
            {!isAdmin && <View style={style.paymentContainer}>
                <Payment bottonColor={RED_COLOR} validateForm={() => true} postFunction={postFunction}
                         calcPriceFunction={calcPriceFunction}
                         marks={marks}/>
            </View>}
        </>
    )
}
const ChanceWinRow: React.FC<{ number: number }> = ({number}) => {

    const [winData, setWinData] = useState<any>()
    const cardMap = ['clover', 'diamond', 'heart', 'leaf'];

    useEffect(() => {
        if (!winData) {
            getChanceResult(number).then((data) => setWinData(data))
        }
    }, [winData, number])

    // !winData && getChanceResult(number).then((data) => setWinData(data))

    if (!winData) {
        return <></>;
    }

    return <>
        {winData && <Text style={{...getFontCss(FB_SPACER_BOLD, 24, WHITE_COLOR), margin: '16px'}}>תוצאות ההגרלה</Text>}
        {winData &&
            <View style={{flexDirection: 'row-reverse', width: '100%', alignItems: "center", justifyContent: "center", marginBottom: '24px'}}>
                {Object.entries(winData.numbers).map(([cardName, cardValue]) => {
                    return <View style={{flexDirection: 'column', width: 'fit-content', alignItems: "center", justifyContent: "center", marginRight: '16px'}}>
                                <Image source={require(`../../../assets/${cardMap[cardName]}.png`)} style={{width: '30px', height: '30px', marginRight: '4px'}}/>
                                {/*// @ts-ignore*/}
                                <Text style={{...getFontCss(FB_SPACER_BOLD, 20, '#000')}}>{cardValue}</Text>
                            </View>
                })}
            </View>
        }
        </>
}


const Form777ReadOnly = ({isShitati, title, marks, calcPriceFunction, postFunction, tablesNumber, lotteryNumber, isAdmin, onClose, showClose}: {
    tablesNumber: number
    title: string,
    postFunction: () => Promise<Response>,
    calcPriceFunction: () => Promise<{ price: number }>,
    marks: SevenMarksType,
    isShitati: boolean
    lotteryNumber: number,
    isAdmin: boolean,
    onClose: ()=> void,
    showClose?: boolean
}) => {

    const style = StyleSheet.create({ 
        formContainer: {alignItems: "center", backgroundColor: BLUE_GRAY_COLOR, borderRadius: 12, overflowX: "scroll", maxHeight: "100%"},
        paymentContainer: {marginTop: 10, flexDirection: "row", alignItems: "center"}
    })

    return (
        <>
            <LottoFormReadOnlyTitle text={title} onClose={() => onClose()} showClose={showClose}/>
            <View style={style.formContainer}>
                <LottoFormReadOnlyAdditions multiLottery={marks[MULTI_LOTTERY]} extra={false}/>
                {
                    isShitati && <>
                        <ChanceMediumText marginLeft={'1%'} text={'סוג הטופס'} marginTop={0}/>
                        <ChanceRowBalls setNumber={() => undefined} numbers={[marks[FORM_TYPE]]}
                                        removeNumber={() => undefined}
                                        isClicked={() => false}
                                        isShitati={false}/>
                    </>
                }
                <View style={{width: '100%', marginBottom: 60}}>
                    <SevenUserForm numbersLength={'' + marks[FORM_TYPE] === '' ? 7 : marks[FORM_TYPE]} marks={marks} tableIndex={0}
                                   strongNumbersLength={0} tablesNumber={tablesNumber} isReadOnly={true}/>
                </View>
                <WinRow777 number={lotteryNumber}/>

            </View>
            {!isAdmin && <View style={style.paymentContainer}>
                <Payment bottonColor={YELLOW_123_COLOR} validateForm={() => true} postFunction={postFunction}
                         calcPriceFunction={calcPriceFunction}
                         marks={marks}/>
            </View>}
        </>
    )
}

const WinRow777: React.FC<{ number: number }> = ({number}) => {

    const [winData, setWinData] = useState<any>()
    const style = {
        row: {
            marginBottom: '16px',
            width: '50%',
            flexDirection: "row",
            justifyContent: "center",
            ...getBorder(),
        },
        ball: {
            marginRight: '4px',
            backgroundColor: WHITE_COLOR,
            ...getCircleCss(25, 25, 12.5),
            justifyContent: "center",
            alignItems: "center",

        },
        ballText: {
            ...getFontCss(FB_SPACER_REGULAR, 22, RED_COLOR)
        }
    }

    !winData && get777Result(number).then((data) => setWinData(data))


    return <>
        {winData && <Text style={{...getFontCss(FB_SPACER_BOLD, 24, WHITE_COLOR), margin: '16px'}}>תוצאות ההגרלה</Text>}
        <View style={{flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
            {winData &&

            <LottoRowBalls ballStyle={style.ball} ballTextStyle={style.ballText} numbers={winData['numbers'].slice(0, 10)}
                           rowLength={10}
                           style={style.row}/>}

        </View>
        <View style={{flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
            {winData &&

            <LottoRowBalls ballStyle={style.ball} ballTextStyle={style.ballText} numbers={winData['numbers'].slice(10,17)}
                           rowLength={7}
                           style={style.row}/>}

        </View>
    </>
}

const Form123ReadOnly = ({title, marks, calcPriceFunction, postFunction, lotteryNumber, isAdmin, onClose, showClose }: {
    title: string,
    postFunction: () => Promise<Response>,
    calcPriceFunction: () => Promise<{ price: number }>,
    marks: MarksType123
    lotteryNumber: number
    isAdmin: boolean,
    onClose: ()=> void,
    showClose?: boolean
}) => {

    const style = StyleSheet.create({
        formContainer: {alignItems: "center", backgroundColor: BLUE_GRAY_COLOR, borderRadius: 12, overflowX: "scroll", maxHeight: "100%"},
        paymentContainer: {marginTop: 10, flexDirection: "row", alignItems: "center"}
    })

    return (
        <>
            <LottoFormReadOnlyTitle text={title} onClose={() => onClose()} showClose={showClose}/>
            <View style={style.formContainer}>
                <LottoFormReadOnlyAdditions multiLottery={marks[MULTI_LOTTERY]} extra={false}/>
                <ChanceMediumText marginLeft={'1%'} text={'סכום ההשקעה'} marginTop={0}/>
                <ChanceRowBalls numbers={[marks[PARTICIPANT_AMOUNT]]} setNumber={() => undefined}
                                removeNumber={() => undefined}
                                isClicked={() => false} isShitati={false} ballTextColor={YELLOW_123_COLOR}/>
                <View style={{width: '100%', marginBottom: 60}}>
                    <UserForm123 tablesNumber={marks[TABLES].length} numbersLength={3} strongNumbersLength={0} marks={marks} tableIndex={0}
                                 isReadOnly={true}/>
                </View>
                <WinRow123 number={lotteryNumber}/>
            </View>
            {!isAdmin && <View style={style.paymentContainer}>
                <Payment bottonColor={YELLOW_123_COLOR} validateForm={() => true} postFunction={postFunction}
                         calcPriceFunction={calcPriceFunction}
                         marks={marks}/>
            </View>}
        </>
    )
}


const WinRow123: React.FC<{ number: number }> = ({number}) => {

    const [winData, setWinData] = useState<any>()
    const style = {
        row: {
            marginBottom: '16px',
            width: '50%',
            flexDirection: "row",
            justifyContent: "center",
            ...getBorder(),
        },
        ball: {
            marginRight: '4px',
            backgroundColor: WHITE_COLOR,
            ...getCircleCss(25, 25, 12.5),
            justifyContent: "center",
            alignItems: "center",

        },
        ballText: {
            ...getFontCss(FB_SPACER_REGULAR, 22, RED_COLOR)
        }
    }

    !winData && get123Result(number).then((data) => setWinData(data))

    return <>
        {winData && <Text style={{...getFontCss(FB_SPACER_BOLD, 24, WHITE_COLOR), margin: '16px'}}>תוצאות ההגרלה</Text>}
        <View style={{flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
            {winData &&

            <LottoRowBalls ballStyle={style.ball} ballTextStyle={style.ballText} numbers={winData['numbers']}
                           rowLength={3}
                           style={style.row}/>}

        </View>
    </>
}

function getLottomaticFormData(data: LottoMarksType): string {
    console.log(`json - ${JSON.stringify({...data, [TABLES]: normalizeTablesData(data[TABLES])})}`)
    return JSON.stringify({...data, [TABLES]: normalizeTablesData(data[TABLES])})
}

export const getFormComponentByType = (type: FormType, marks: GenericFormType, jwtToken: string, lotteryNumber: number, isAdmin:boolean = false, onClose?: ()=> void ,showClose:boolean=true): () => () => JSX.Element => {

    const formTypeToNumbersLengthMapping: { [key: string]: number } = {
        '8': 8,
        '5=6': 5,
        '9': 9,
        '10': 10,
        '11': 11,
        '12': 12
    }
    const formTypeToNumbersLengthMappingShitatiHazak: { [key: string]: number } = {
        '4': 4,
        '5': 5,
        '6': 6,
        '7': 7,
    }

    switch (type) {
        case "regular_lotto":
            return () => () => {
                return <RegularLottoReadOnly marks={(marks as LottoMarksType)} tablesNumber={14}
                                             strongNumbersLength={1} numbersLength={6}
                                             isAdmin={isAdmin}
                                             showClose={showClose}
                                             onClose={()=> onClose()}
                                             calcPriceFunction={() => getRegularLottoPrice((marks as LottoMarksType), jwtToken)}
                                             postFunction={() => postRegularLottoForm((marks as LottoMarksType), jwtToken)}
                                             title={'לוטו רגיל'} lotteryNumber={lotteryNumber} lottomaticFormData={getLottomaticFormData((marks as LottoMarksType))}/>
            }
        case "regular_double_lotto":
            return () => () => <RegularLottoReadOnly marks={(marks as LottoMarksType)} tablesNumber={10}
                                                     strongNumbersLength={1} numbersLength={6}
                                                     isAdmin={isAdmin}
                                                     onClose={()=> onClose()}
                                                     showClose={showClose}
                                                     calcPriceFunction={() => getRegularDoubleLottoPrice((marks as LottoMarksType), jwtToken)}
                                                     postFunction={() => postRegularDoubleLottoForm((marks as LottoMarksType), jwtToken)}
                                                     title={'דאבל לוטו רגיל'} lotteryNumber={lotteryNumber}/>
        case "lotto_shitati":
            return () => () => <LottoShitatiReadOnly marks={(marks as ShitatiMarksType)} tablesNumber={1}
                                                     strongNumbersLength={1}
                                                     isAdmin={isAdmin}
                                                     onClose={()=> onClose()}
                                                     showClose={showClose}
                                                     numbersLength={formTypeToNumbersLengthMapping[(marks as ShitatiMarksType)[FORM_TYPE]]}
                                                     calcPriceFunction={() => getLottoShitatiPrice((marks as ShitatiMarksType), jwtToken)}
                                                     postFunction={() => postLottoShitatiForm((marks as ShitatiMarksType), jwtToken)}
                                                     title={'לוטו שיטתי'} lotteryNumber={lotteryNumber}/>
        case "double_lotto_shitati":
            return () => () => <LottoShitatiReadOnly marks={(marks as ShitatiMarksType)} tablesNumber={1}
                                                     strongNumbersLength={1}
                                                     isAdmin={isAdmin}
                                                     onClose={()=> onClose()}
                                                     showClose={showClose}
                                                     numbersLength={formTypeToNumbersLengthMapping[(marks as ShitatiMarksType)[FORM_TYPE]]}
                                                     calcPriceFunction={() => getDoubleLottoShitatiPrice((marks as ShitatiMarksType), jwtToken)}
                                                     postFunction={() => postDoubleLottoShitatiForm((marks as ShitatiMarksType), jwtToken)}
                                                     title={'דאבל לוטו שיטתי'} lotteryNumber={lotteryNumber}/>
        case "lotto_shitati_hazak":
            return () => () => <LottoShitatiReadOnly marks={(marks as ShitatiMarksType)} tablesNumber={1}
                                                     strongNumbersLength={formTypeToNumbersLengthMappingShitatiHazak[(marks as ShitatiMarksType)[FORM_TYPE]]}
                                                     numbersLength={7}
                                                     onClose={()=> onClose()}
                                                     showClose={showClose}
                                                     isAdmin={isAdmin}
                                                     calcPriceFunction={() => getLottoShitatiHazakPrice((marks as ShitatiMarksType), jwtToken)}
                                                     postFunction={() => postLottoShitatiHazakForm((marks as ShitatiMarksType), jwtToken)}
                                                     title={'לוטו שיטתי חזק'} lotteryNumber={lotteryNumber}/>
        case "double_lotto_shitati_hazak":
            return () => () => <LottoShitatiReadOnly marks={(marks as ShitatiMarksType)} tablesNumber={1}
                                                     strongNumbersLength={formTypeToNumbersLengthMappingShitatiHazak[(marks as ShitatiMarksType)[FORM_TYPE]]}
                                                     numbersLength={7}
                                                     onClose={()=> onClose()}
                                                     showClose={showClose}
                                                     isAdmin={isAdmin}
                                                     calcPriceFunction={() => getDoubleLottoShitatiHazakPrice((marks as ShitatiMarksType), jwtToken)}
                                                     postFunction={() => postDoubleLottoShitatiHazakForm((marks as ShitatiMarksType), jwtToken)}
                                                     title={'דאבל לוטו שיטתי חזק'} lotteryNumber={lotteryNumber}/>
        case "regular_chance":
            return () => () => <ChanceFormReadOnly isFormType={true} isShitati={false} title={"צ'אנס"}
                                                   marks={(marks as ChanceMarksType)}
                                                   isAdmin={isAdmin}
                                                   onClose={()=> onClose()}
                                                   showClose={showClose}
                                                   calcPriceFunction={() => getRegularChancePrice((marks as ChanceMarksType), jwtToken)}
                                                   postFunction={() => postRegularChanceForm((marks as ChanceMarksType), jwtToken)} lotteryNumber={lotteryNumber}/>
        case "rav_chance":
            return () => () => <ChanceFormReadOnly isFormType={false} isShitati={false} title={"רב צ'אנס"}
                                                   marks={(marks as ChanceMarksType)}
                                                   isAdmin={isAdmin}
                                                   onClose={()=> onClose()}
                                                   showClose={showClose}
                                                   calcPriceFunction={() => getRavChancePrice((marks as ChanceMarksType), jwtToken)}
                                                   postFunction={() => postRavChanceForm((marks as ChanceMarksType), jwtToken)} lotteryNumber={lotteryNumber}/>
        case "chance_shitati":
            return () => () => <ChanceFormReadOnly isFormType={true} isShitati={true} title={"צ'אנס שיטתי"}
                                                   marks={(marks as ChanceMarksType)}
                                                   isAdmin={isAdmin}
                                                   onClose={()=> onClose()}
                                                   showClose={showClose}
                                                   calcPriceFunction={() => getChanceShitatiPrice((marks as ChanceMarksType), jwtToken)}
                                                   postFunction={() => postChanceShitatiForm((marks as ChanceMarksType), jwtToken)} lotteryNumber={lotteryNumber}/>
        case "regular_777":
            return () => () => <Form777ReadOnly marks={(marks as SevenMarksType)}
                                                isAdmin={isAdmin}
                                                onClose={()=> onClose()}
                                                showClose={showClose}
                                                calcPriceFunction={() => getRegularSevenPrice((marks as SevenMarksType), jwtToken)}
                                                postFunction={() => postRegularSevenForm((marks as SevenMarksType), jwtToken)}
                                                isShitati={false} title={'777 רגיל'} tablesNumber={3} lotteryNumber={lotteryNumber}/>
        case "shitati_777":
            return () => () => <Form777ReadOnly marks={(marks as SevenMarksType)}
                                                isAdmin={isAdmin}
                                                onClose={()=> onClose()}
                                                showClose={showClose}
                                                calcPriceFunction={() => getShitatiSevenPrice((marks as SevenMarksType), jwtToken)}
                                                postFunction={() => postShitatiSevenForm((marks as SevenMarksType), jwtToken)}
                                                isShitati={true} title={'777 שיטתי'} tablesNumber={1} lotteryNumber={lotteryNumber}/>
        case "123":
            return () => () => <Form123ReadOnly title={'123'}
                                                isAdmin={isAdmin}
                                                onClose={()=> onClose()}
                                                showClose={showClose}
                                                postFunction={() => post123Form(marks as MarksType123, jwtToken)}
                                                calcPriceFunction={() => get123Price(marks as MarksType123, jwtToken)}
                                                marks={marks as MarksType123} lotteryNumber={lotteryNumber}/>

        default:
            AlertHelper.show("יש בעיה בטעינת הטבלה",
            () => AlertHelper.hide())
            return () => () => <></>
    }
}