import React, {useCallback, useContext, useEffect, useReducer, useState} from "react";
import {StyleSheet, View} from "react-native";
import {getBorder, LIGHT_GREEN_COLOR} from "../../styles";
import {
    CalcPriceFunction,
    FormType,
    LottoMarksType,
    LottoNumberType,
    MultiLotteryChanceType,
    MultiLotteryType,
    PostFormFunction,
    ShitatiMarksType
} from "../../types";
import {FORM_TYPE, INIT_TABLE_INDEX, NON_NUMBER, NUMBERS, STRICT, STRONG_NUMBERS, TABLES} from "../../consts";
import FirstSectionInstructions, {InstructionStep,} from "./additionalSections/firstSectionInstructions";
import UserInputForm from "./inputForm/userInputForm";
import FormAdditions from "./additionalSections/formAdditions";
import SumAndSubmitSection from "./additionalSections/sumAndSubmitSection";
import {shitatiReducer} from "./shitatiReducer";
import {ShitatiUserForm} from "./shitati";
import {UserContext} from "../../appSwitch";

import { AlertHelper } from '../../AlertHelper';

let _ = require('lodash')


type LevelType = 'strict' | 'lax'
type ShitatiFormType = {
    color: string,
    calcPriceFunction: CalcPriceFunction<LottoMarksType>,
    postFormFunction: PostFormFunction<LottoMarksType>,
    tablesNumber: number,
    initForm: ShitatiMarksType,
    numbersLength: number,
    formType:  FormType,
    strongNumbersLength: number,
    isMobile?: boolean,
}

const ShitatiHazak = ({
                          color,
                          strongNumbersLength,
                          tablesNumber,
                          initForm,
                          postFormFunction,
                          calcPriceFunction,
                          formType,
                          numbersLength, isMobile
                      }: ShitatiFormType) => {
    const style = StyleSheet.create({
        formTablesContainer: {
            flexDirection: "row-reverse",
            height: 540,
            ...getBorder()
        },
        formTablesContainerMobile: {
            flexDirection: "column",
            ...getBorder()
        }
    })
    const [tableIndex, setTableIndex] = useState<number>(INIT_TABLE_INDEX)
    const [marks, dispatchMarks] = useReducer(shitatiReducer, initForm)
    const [strongNumbersArrayLength, setstrongNumbersArrayLength] = useState(strongNumbersLength)
    const formTypeToNumbersLengthMapping: { [key: string]: number } = {
        '4': 4,
        '5': 5,
        '6': 6,
        '7': 7,
    }

    const {jwtToken} = useContext(UserContext)

    const postFunction = useCallback(() => postFormFunction(marks, jwtToken), [marks])

    const calcFunction = useCallback(() => calcPriceFunction(marks, jwtToken), [marks])

    const validateForm = (lvl: LevelType = STRICT): boolean => {
        let filledTables = 0
        for (let table of marks[TABLES]) {
            if (table[STRONG_NUMBERS].length === strongNumbersArrayLength && table[NUMBERS].length === numbersLength) {
                filledTables += 1
            } else {
                if ((table[NUMBERS].length > 0 && table[NUMBERS].length < numbersLength) && (table[STRONG_NUMBERS].length < strongNumbersArrayLength && table[STRONG_NUMBERS].length > 0)) {
                    if(lvl === STRICT){
                        AlertHelper.show("יש לפחות שורה אחת ריקה",
                        () => AlertHelper.hide(),'sendGameForm')
                    }
                    return false
                }
            }
        }
        if (filledTables !== 0) {
            return true
        }

        if(lvl === STRICT){
            AlertHelper.show("השלם את מילוי השורה",
                () => AlertHelper.hide(),'sendGameForm')
        }
        return false
    }

    const updateIndex = () => {
        if (marks[TABLES][tableIndex][NUMBERS].length === numbersLength && marks[TABLES][tableIndex][STRONG_NUMBERS].length === strongNumbersArrayLength) {
            if (tableIndex + 1 !== tablesNumber) {
                setTableIndex(Number(tableIndex) + 1)
            }
        }
    }

    const setFormType = (value: string | number) => {
        dispatchMarks({
            strongNumbersLength: strongNumbersArrayLength,
            numbersLength: numbersLength,
            type: 'setFormType',
            tableIndex: tableIndex,
            value: NON_NUMBER,
            formType: value
        })
    }

    const removeFormType = () => {
        dispatchMarks({
            strongNumbersLength: strongNumbersArrayLength,
            numbersLength: numbersLength,
            type: 'resetFormType',
            tableIndex: tableIndex,
            value: NON_NUMBER,
            formType: ''
        })
    }

    const tableAutoInsertion = () => {
        dispatchMarks({
            strongNumbersLength: strongNumbersArrayLength,
            numbersLength: numbersLength,
            type: 'tableAutoInsertion',
            value: NON_NUMBER,
            tableIndex: tableIndex
        })
    }

    const popTableNumbers = useCallback(() => {
        dispatchMarks({
            strongNumbersLength: strongNumbersArrayLength,
            numbersLength: numbersLength,
            type: 'popTableNumbers',
            value: NON_NUMBER,
            tableIndex: tableIndex
        })
    }, [strongNumbersArrayLength, tableIndex])

    const addNumber = useCallback((number: LottoNumberType) => {
        dispatchMarks({
            strongNumbersLength: strongNumbersArrayLength,
            numbersLength: numbersLength,
            type: 'addNumber',
            value: number,
            tableIndex: tableIndex
        })
    }, [tableIndex, strongNumbersArrayLength])

    const removeNumber = useCallback((number: LottoNumberType) => {
        dispatchMarks({
            strongNumbersLength: strongNumbersArrayLength,
            numbersLength: numbersLength,
            type: 'removeNumber',
            value: number,
            tableIndex: tableIndex
        })
    }, [tableIndex, strongNumbersArrayLength])

    const removeStrongNumber = useCallback((number: LottoNumberType) => {
        dispatchMarks({
            strongNumbersLength: strongNumbersArrayLength,
            numbersLength: numbersLength,
            type: 'removeStrongNumber',
            value: number,
            tableIndex: tableIndex
        })
    }, [strongNumbersArrayLength, tableIndex])

    const addStrongNumber = useCallback((strongNumber: LottoNumberType) => {
        dispatchMarks({
            strongNumbersLength: strongNumbersArrayLength,
            numbersLength: numbersLength,
            type: 'addStrongNumber',
            value: strongNumber,
            tableIndex: tableIndex
        })
    }, [strongNumbersArrayLength, tableIndex])

    const incrementTableIndex = useCallback(() => {
        if (Number(tableIndex) + 1 === tablesNumber) {
            setTableIndex(tablesNumber - 1)
            return
        }
        setTableIndex(Number(tableIndex) + 1)
    }, [strongNumbersArrayLength, tableIndex])

    const decrementTableIndex = useCallback(() => {
        if (Number(tableIndex) - 1 < INIT_TABLE_INDEX) {
            setTableIndex(0)
            return
        }
        setTableIndex(Number(tableIndex) - 1)
    }, [strongNumbersArrayLength, tableIndex])

    const setExtra = () => {
        dispatchMarks({
            strongNumbersLength: strongNumbersArrayLength,
            numbersLength: numbersLength,
            type: 'setExtra',
            value: NON_NUMBER,
            tableIndex: tableIndex
        })
    }

    const setMultiLottery = (number: MultiLotteryType | MultiLotteryChanceType) => {
        dispatchMarks({
            strongNumbersLength: strongNumbersArrayLength,
            numbersLength: numbersLength,
            type: 'setMultiLottery',
            value: NON_NUMBER,
            tableIndex: tableIndex,
            multiLotteryValue: (number as MultiLotteryType)
        })
    }

    useEffect(updateIndex, [marks])
    useEffect(() => {
        if (marks[FORM_TYPE] !== NON_NUMBER) {
            setstrongNumbersArrayLength(formTypeToNumbersLengthMapping[String(marks[FORM_TYPE])])
            popTableNumbers()
        }
    }, [marks[FORM_TYPE]])

    return (
        <>
            <FirstSectionInstructions bacgroundColor={color} mainTitle={'מלא את הטופס'}
                                      subTitle={`בחר ${numbersLength} מספרים`} isMobile={isMobile}/>
            <View style={isMobile ? style.formTablesContainerMobile : style.formTablesContainer}>
                <UserInputForm popTableNumbers={popTableNumbers} addNumber={addNumber} addStrongNumber={addStrongNumber}
                               decrementTableIndex={decrementTableIndex} incrementTableIndex={incrementTableIndex}
                               tableAutoInsertion={tableAutoInsertion} table={marks[TABLES][tableIndex]}
                               removeNumber={removeNumber} removeStrongNumber={removeStrongNumber}
                               numbersLength={numbersLength} color={color}
                               strongNumbersLength={strongNumbersArrayLength} tableIndex={tableIndex} isMobile={isMobile}/>
                <ShitatiUserForm marks={marks} tableIndex={tableIndex} tablesNumber={tablesNumber}
                                 numbersLength={numbersLength} setFormType={setFormType}
                                 removeFormType={removeFormType} strongNumbersLength={strongNumbersArrayLength}
                                 formTypeValues={Object.keys(formTypeToNumbersLengthMapping)} isMobile={isMobile}/>
            </View>
            {/*<InstructionStep stepColor={color} marginTop={35} containerHeight={72} stepText={'שדרג את הטופס'}*/}
            {/*                 stepNumber={2}/>*/}
            {/*<FormAdditions isExtra={setExtra} marks={marks} setMultiLottery={setMultiLottery}/>*/}
            <SumAndSubmitSection strongNumbersLength={strongNumbersLength} numbersLength={numbersLength}
                                 bottonColor={LIGHT_GREEN_COLOR} color={color} marks={marks} validateForm={validateForm}
                                 formType={formType}
                                 postFunction={postFunction}
                                 calcPriceFunction={calcFunction} isMobile={isMobile}/>
        </>
    )
}

export default ShitatiHazak
