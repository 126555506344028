import React, {FC, useContext} from "react";
import {FORM_777_TYPE, REGULAR_777_PATH, SHITATI_777_PATH} from "../consts";
import {StyleSheet, View} from "react-native";
import {getBorder} from "../styles";
import NextRegularLotteryInfo from "./regular_lotto/lottoInformation/nextRegularLotteryInfo";
import {Line} from "../genaricComponents";
import LottoFormNavigation from "./lottoFormNavigation";
import {UserContext} from "../appSwitch";


const REGULAR_777_NAVIGATION_TITLES = {
    'הגרלת 777': REGULAR_777_PATH,
    'הגרלת 777 שיטתי': SHITATI_777_PATH
}

const FormSeven = ({FormComponent, color}: { color: string, FormComponent: FC }) => {

    const style = StyleSheet.create({
        lottoFormContainer: {
            height: 970,
            ...getBorder()
        },
        formContainer: {
            backgroundColor: color,
            height: 966,
            marginLeft: '12.4%',
            marginRight: '12.4%',
            ...getBorder()
        },
        imgStyle: {}
    })
    const {times} = useContext(UserContext)

    return (
        <>
            <NextRegularLotteryInfo color={color} formType={FORM_777_TYPE} endTime={times && times[`777`] && times[`777`]['time'] || ''}/>
            <View style={style.lottoFormContainer}>
                <Line height={4} backgroundColor={color}/>
                <View style={style.formContainer}>
                    <LottoFormNavigation titles={REGULAR_777_NAVIGATION_TITLES} backgroundColor={color}/>
                    <FormComponent/>
                </View>
            </View>
        </>
    )
}

export default FormSeven