import React, {FC, useContext, useState} from "react";
import NextRegularLotteryInfo from "./regular_lotto/lottoInformation/nextRegularLotteryInfo";
import {StyleSheet, View} from "react-native";
import LottoFormNavigation from "./lottoFormNavigation";
import {getBorder, getHeightWidthCss} from "../styles";
import FormTypeNavigator from "./regular_lotto/additionalSections/formTypeNavigator";
import {Line} from "../genaricComponents";
import {HEBREW_LOTTO, LOTTO_SHITATI_HAZAK_PATH, LOTTO_SHITATI_PATH, REGULAR_LOTTO_PATH} from "../consts";
import {UserContext} from "../appSwitch";
import {numberWithCommas} from "../homePage";
import {Helmet} from "react-helmet";

const LOTTO_NAVIGATION_TITLES = {
    'לוטו רגיל': REGULAR_LOTTO_PATH,
    'לוטו שיטתי': LOTTO_SHITATI_PATH,
    'לוטו שיטתי חזק': LOTTO_SHITATI_HAZAK_PATH
}

const LottoForm = ({FormComponent, DoubleFormComponent, color, isMobile}: { color: string, FormComponent: FC, DoubleFormComponent: FC, isMobile?: boolean }) => {
    const [isDouble, setIsDouble] = useState(false)
    const style = StyleSheet.create({
        lottoFormContainer: {
            height: 1134,
            ...getBorder()
        },
        lottoFormContainerMobile: {
            marginTop: 23,
            ...getBorder()
        },
        formContainer: {
            backgroundColor: color,
            height: 1130,
            marginLeft: '12.4%',
            marginRight: '12.4%',
            ...getBorder()
        },
        formContainerMobile: {
            backgroundColor: color,
            ...getBorder()
        },
        imgStyle: {
            position: "absolute",
            alignSelf: "center",
            right: '64%',
            ...getHeightWidthCss(128, 128)
        }
    })
    const {times} = useContext(UserContext)


    return (
        <>
            <Helmet>
                <title>שליחת לוטו אונליין</title>
                <meta name={'הגרלת לוטו און ליין בלי לצאת מהבית'} content={'הגרלת הלוטו שמגיעה אוטוטו יכולה להפוך אתכם לעשירים. כדי שזה יקרה אתם לא צריכים לצאת מהבית ולחפש דוכן לוטו. היכנסו כבר עכשיו להגרלת הלוטו הבאה ותגשימו חלומות'}/>
            </Helmet>
            {!isMobile && <NextRegularLotteryInfo color={color} formType={HEBREW_LOTTO} imgStyle={style.imgStyle}
                                    img={require('../../../assets/LottoBalls.png')} price={times && times['לוטו'] && numberWithCommas(times['לוטו']['win_amount'])}
                                    boldText={times && times['לוטו'] && 'עד'} endTime={times && times['לוטו'] && times[`לוטו`]['time'] || ''}/>}
            <View style={isMobile ? style.lottoFormContainerMobile : style.lottoFormContainer}>
                <Line height={4} backgroundColor={color}/>
                <View style={isMobile ? style.formContainerMobile : style.formContainer}>
                    <LottoFormNavigation titles={LOTTO_NAVIGATION_TITLES} backgroundColor={color} isMobile={isMobile}/>
                    <FormTypeNavigator onPress={setIsDouble} isDouble={isDouble} isMobile={isMobile}/>
                    {isDouble ? <DoubleFormComponent/> : <FormComponent/>}
                </View>
            </View>
        </>
    )
}

export default LottoForm