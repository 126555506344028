import ShitatiHazakForm from "./lotto/regular_lotto/shitatiHazak";
import {
    get123Price,
    getChanceShitatiPrice,
    getDoubleLottoShitatiHazakPrice,
    getDoubleLottoShitatiPrice,
    getLottoShitatiHazakPrice,
    getLottoShitatiPrice,
    getRavChancePrice,
    getRegularChancePrice,
    getRegularDoubleLottoPrice,
    getRegularLottoPrice,
    getRegularSevenPrice,
    getShitatiSevenPrice,
    post123Form,
    postChanceShitatiForm,
    postDoubleLottoShitatiForm,
    postDoubleLottoShitatiHazakForm,
    postLottoShitatiForm,
    postLottoShitatiHazakForm,
    postRavChanceForm,
    postRegularChanceForm,
    postRegularDoubleLottoForm,
    postRegularLottoForm,
    postRegularSevenForm,
    postShitatiSevenForm
} from "./server/serverApi";
import {
    INIT_FORM_123,
    LOTTTO_DOUBLE_SHITATI_HAZAK_INIT_FORM,
    LOTTTO_DOUBLE_SHITATI_HAZAK_TABLES_NUMBER,
    LOTTTO_DOUBLE_SHITATI_INIT_FORM,
    LOTTTO_DOUBLE_SHITATI_TABLES_NUMBER,
    LOTTTO_SHITATI_HAZAK_INIT_FORM,
    LOTTTO_SHITATI_HAZAK_TABLES_NUMBER,
    LOTTTO_SHITATI_INIT_FORM,
    LOTTTO_SHITATI_TABLES_NUMBER,
    RAV_CHANCE_INIT_FORM,
    REGULAR_CHANCE_INIT_FORM,
    REGULAR_DOUBLE_LOTTO_INIT_FORM,
    REGULAR_DOUBLE_LOTTTO_TABLES_NUMBER,
    REGULAR_LOTTO_INIT_FORM,
    REGULAR_LOTTTO_TABLES_NUMBER,
    REGULAR_SEVEN_INIT_FORM,
    SHITATI_CHANCE_INIT_FORM,
    SHITATI_SEVEN_INIT_FORM
} from "./consts";
import {GREEN_COLOR, ORANGE_COLOR, PINK_COLOR_SEVEN, RED_COLOR} from "./styles";
import React from "react";
import RegularLottoForm from "./lotto/regular_lotto/regularLottoForm";
import LottoShitatiForm from "./lotto/regular_lotto/shitati";
import RegularChanceForm from "./lotto/regular_lotto/regularChanceForm";
import Regular123Form from "./lotto/regular_lotto/regular123Form";
import RegularSevenForm from "./lotto/regular_lotto/regular777Form";
import ChanceShitatiForm from "./lotto/regular_lotto/chanceShitati";
import AutoSizeText from "./global/autoSizeText";
import Bottomdetails from "./global/bottomDetails";


const TEXT_777 = "777 – כל אחד זוכה!\n" +
    "הגרלת 777 ייחודית במינה ואחד המשחקים הפופולאריים ביותר. \n" +
    "להגרלת ה777 מספר יתרונות מובהקים.\n" +
    "•\tסיכויי זכייה גבוהים במיוחד. \n" +
    "•\tהזכייה בפרס הראשון הינה קבועה ואינה מתחלקת בין הזוכים, כלומר ניחשתם נכונה שבעה מספרים הסכום קבוע והוא שלכם בלבד. \n" +
    "•\tכל אחד זוכה, ניחשתם נכונה שבעה מספרים זכיתם ב70,000 ש״ח, ניחשתם נכונה 6 מספרים זכיתם ב500 ש״ח, ניחשתם נכונה 5 מספרים זכיתם ב50 ש״ח ניחשתם נכונה 4 מספרים זכיתם ב20 ש״ח, לא ניחשתם אף מספר עדין זכיתם בחמישה שקלים- כן כן לא ניחשתם אף מספר ואילו עדין זכיתם! \n" +
    "מתי זה קורה? \n" +
    "ההגרלה מתקיימת שבעה ימים בשבוע, בימים א׳-ה׳ ההגרלה מתקיימת פעמיים ביום (13:30 ו19:30) ביום ו׳ בשעה 13:00 ואילו בצאת השבת בשעה 22:25\n" +
    "איך זה עובד?\n" +
    "ישנה טבלה ובה שבעים מספרים, על המשתמש לבחור שבעה מספרים ולמלא בטבלה. \n" +
    "ניתן למלא שלוש טבלאות שונות כאשר ממלאים שבעה מספרים בכל טבלה. \n" +
    "קיימת גם האפשרות של סימון לוטומטי מלא – כל הטופס מתמלא באופן אוטוטמי. \n" +
    "או מילוי לוטומטי חלקי, כלומר לבחור חלק מהמספרים באופן ידני והיתרה תתמלא בצורה אוטומטית. \n" +
    "גם מי שאינם אוהבים להשאיר את ידם למזל וסומכים על נתונים וסטטיסטיקות, שכן קיים מידע סטטיסטי אודות המספרים שעלו הכי הרבה בגורל ועל פיהם אפשר למלא את הטופס.\n" +
    "סכומי הזכייה והפרסים הינם קבועים ואינם משתנים מהגרלה להגרלה. \n" +
    "ישנה גם האפשרות של פיס 777 שיטתי\n" +
    "777 שיטתי 8 (לפיו בוחרים שמונה מספרים ומסמנים בטבלת שבעים המספרים ועל בסיס המספרים שנבחרו יוצר המחשב שמונה צירופים של שבעה מספרים)  הפרס במצב בו כל שמונת המספרים שסימנתם עלו בגורל הינו 560,000!  70,000 ש״ח כפול 8. \n" +
    "777 שיטתי 9 (לפיו בוחרים תשעה מספרים ומסמנים בטבלת שבעים המספרים ועל בסיס המספרים שנבחרו יוצר המחשב שלושים ושישה צירופים של שבעה מספרים) הפרס במצב בו תשעת המספרים שסימנתם עלו בגורל הינו 2,520,000! \n" +
    "\n" +
    "בכדי לדעת את תוצאות ההגרלה והמספרים שעלו בגורל וכמו כן את תוצאות המשחקים שכבר התקיימו ניתן להיכנס לאתר ולהתעדכן בזמן אמת ובכל רגע נתון."
const CHANCE_TEXT = "הרגע הזה שפשוט לוקחים צ׳אנס וזוכים- איזה אושר! \n" +
    "הגרלת צ׳אנס הינה הגרלת כדורים מבוססת קלפים. \n" +
    "הגרלת הצא׳נס נחשקת במיוחד ואחד מהמשחקים המועדפים על פני רבים וזאת ממספר סיבות\n" +
    "•\tאפשרויות זכייה רבות. \n" +
    "•\tסיכויי זכייה משמעותיים של פרסים שווים הנעים בין עשרות שקלים לעשרות אלפי שקלים. \n" +
    "•\tמספר רב של טפסים/ משחקים ואופציות בחירה. \n" +
    "•\tתדירות גבוה של הגרלות ביום.\n" +
    "מתי זה קורה?\n" +
    "ההגרלה מתקיימת שבעה ימים בשבוע. בימים א׳-ה׳ ההגרלה מתקיימת שבע פעמים ביום (מ10:00 עד 22:00 כל שעתיים). \n" +
    "ביום ו׳ ההגרלה מתקיימת שלוש פעמים ואילו בצאת השבת ההגרלה מתקיימת פעמיים. \n" +
    "איך זה עובד? \n" +
    "צ׳אנס הינו משחק קלפים, בו משתתפת חפיסה בת 32 קלפים. \n" +
    "במשחק ארבעה סוגי סדרות ( עלה, תלתן, לב ויהלום). בכל סדרה ישנם שמונה קלפים משבע ועד אס. \n" +
    "בכל הגרלה עולים ארבעה קלפים, אחד מכל סדרה. על השחקן לנחש אילו ארבעה קלפים עלו בגורל. \n" +
    "חישובי סכומי הזכייה מושפעים מסכום ההשקעה וסוג טופס הצ׳אנס שאותו משחקים שכן לכל טופס ישנם מכפלות פרסים שונות. \n" +
    "סוגי הטפסים הינם: \n" +
    "צ׳אנס 1 \n" +
    "במשחק זה עליך לנחש קלף אחד מסדרה אחת בלבד ואותו לסמן בטופס. \n" +
    "במידה וניחשת נכונה וזה הקלף שעלה בגורל- זכית! \n" +
    "סכום הזכייה הינו סכום ההשקעה בטופס כפול חמש. \n" +
    "צ׳אנס 2 \n" +
    "במשחק זה עליך לנחש שני קלפים משתי סדרות שונות ולסמנם בטופס. \n" +
    "במידה ושני הקלפים שניחשתם משתי הסדרות עלו בגורל- זכיתם בפרס שווה במיוחד שמורכב מסכום ההשקעה כפול 30. \n" +
    "במידה וניחשתם קלף אחד נכונה עדין זכיתם בפרס, כאשר הפרס מורכב מסכום ההשקעה כפול 0.5 \n" +
    "צ׳אנס 3 \n" +
    "במשחק זה עליך לנחש שלושה קלפים משלוש סדרות שונות ולסמנם בטופס. \n" +
    "במידה ושלושת הקלפים שניחשתם משלושת הסדרות עלו בגורל- זכיתם בפרס שווה בטירוף שמורכב מסכום ההשקעה כפול 300. \n" +
    "במידה וניחשתם שני קלפים נכונה משתי סדרות שונות נכונה זכיתם בפרס, כאשר הפרס מורכב מסכום ההשקעה כפול 7/10.\n" +
    "במידה וניחשת קלף אחד נכונה עדין זכיתם בפרס כאשר הפרוס מורכב מסכום ההשקעה כפול 3/10. \n" +
    "צ׳אנס 4 \n" +
    "במשחק זה עליך לנחש ארבעה קלפים מארבע סדרות שונות ולסמנם בטופס. \n" +
    "במידה וכל ארבעת הקלפים שניחשתם מארבעת הסדרות השונות עלו בגורל- זכיתם בפרס בלתי נתפס שמורכב מסכום ההשקעה בטופס כפול 2,000!! \n" +
    "במידה וניחשתם שלושה קלפים נכונה משלוש סדרות שונות גם זכיתם בפרס, כאשר הפרס מורכב מסכום ההשקעה בטופס כפול 8/10. \n" +
    "במידה וניחשתם שני קלפים נכונה משתי סדרות שונות גם זכיתם בפרס, כאשר הפרס מורכב מסכום ההשקעה בטופס כפול 5/10. \n" +
    "במידה וניחשתם קלף אחד נכונה עדין זכיתם בפרס, כאשר הפרס מורכב מסכום ההשקעה בטופס כפול 2/10. \n" +
    "\n" +
    "אז מהרו לקחת צ׳אנס ותתחילו לזכות!"

const TEXT_123 = "הגרלת 123 הכי פשוט הכי קליל \n" +
    "הגרלת 123 הינה אחד המשחקים הכי פשוטים נוחים וקלים שיש. \n" +
    "סיכויי הזכייה ב123 הינם אדירים\n" +
    "כל שעליך לעשות הוא לנחש שלושה מספרים מתוך עשרה (מ0 ועד 9) וכמו כן את סדר עלייתם בגורל משמאל לימין.  \n" +
    "מתי זה קורה? \n" +
    "ההגרלה מתקיימת כל יום פעם ביום. בימים א׳-ה׳ בשעה 21:00. ביום ו׳ בשעה 13:00 ואילו בצאת השבת בשעה 22:25. \n" +
    "איך זה עובד?\n" +
    "ההגרלה נערכת על ידי שלוש מכונות, כאשר המכונה השמאלית מגרילה את המספר הראשון המכונה האמצעית מגרילה את המספר השני ואילו המכונה הימנית מגרילה את המספר השלישי. \n" +
    "בהגרלה זו עליך לנחש את המספרים שיעלו בגורל ובהתאם לסדר שעלו משמאל לימין. \n" +
    "את המספרים יש לבחור מתוך עשרה מספרים מהספרה 0 ועד הספרה 9. \n" +
    "בכל טופס של 123 ישנם חמש טבלאות כאשר בכל טבלה שלוש שורות כאשר בכל שורה מופיעות הספרות 0 עד 9. \n" +
    "ניתן למלא בכל טופס מטבלה אחת ועד חמש, בכל טבלה ישנם שלוש שורות- עליך לסמן בכל שורה ספרה אחת- סה״כ שלוש ספרות בטבלה לפי הסדר שלדעתך יעלה בגורל. כאשר הספרה שנבחרה בשורה הראשונה תהיה המספר השמאלי ביותר ואילו השורה השניה תהיה המספר האמצעי ואילו השורה השלישית תהיה המספר הימני. \n" +
    "סכום הזכייה מורכב מסכום ההשקעה כפול 600. ניתן למלא בכל סכום משקל בודד ועד 500 ש״ח. \n" +
    "אין זכייה חלקית או פרס ביניים אלא זכייה מלאה בלבד. בכדי לזכות עליך לנחש את שלושת המספרים שיעלו בגורל על פי סדרם. \n" +
    "מלבד סימון ידני קיימת האפשרות גם לסימון לוטומטי מלא או חלקי ( כלומר חלק לסמן ידני ואת השאר לתת למכונה להשלים).\n" +
    "אפשרות הרב הגרלתי- השתתפות במספר הגרלות מראש על פי בחירה,  המספרים שסימנתם בטופס יוכלו להשתתף באופן אוטומטי גם בהגרלות הבאות ומספרם יקבע על פי החלטתכם. \n" +
    "\n" +
    "אז 1..2.. ו3 מהרו לשלוח טופס המזל איתכם!"

const AboutLotto = () => {

    return <Bottomdetails>
                <AutoSizeText title={'הסבר על הגרלות לוטו'}
                              isExpendButton={false}
                              text={'לאחר התשלום אנו בלוטומטיק מקבלים את הטופס שמילאתם ושולחים אותו עבורכם בנקודת מכירה מורשית של מפעל הפיס, את הטופס שמילאנו עבורכם בנקודה אנו סורקים ושולחים לכם לתיבת הדואר האלקטרוני ומעלים את הטופס הסרוק לאזור האישי שלכם באפליקציה. הטופס המקורי יישמר אצלנו במשרדי החברה ובמידה וזכיתם בסכום העולה על 11,000 ש״ח יימסר לכם הטופס באופן אישי. במידה וסכום הזכייה שלכם הינו מ11,000 ש״ח או נמוך ממנו החשבון שלכם באתר יזוכה באופן אוטומטי ותקבלו על כך הודעה, אתם תוכלו למשוך את הכסף מהחשבון שכלם באתר לתוך חשבון הבנק שלכם או שתבחרו לנצל את היתרה לשליחת טפסים חדשים. אנו מאחלים לכם חוויה שימוש והנאה מרבית מהשירות שלנו ומלאי תקווה שנביא לכם את המזל. בהצלחה!'}/>
            </Bottomdetails>
}

const AboutChance = () => {

    return <Bottomdetails>
                <AutoSizeText isExpendButton={false} title={`הסבר על הגרלות צ'אנס`}
                              text={CHANCE_TEXT}/>
            </Bottomdetails>
}

const About777 = () => {

    return <Bottomdetails>
                <AutoSizeText isExpendButton={false} title={'הסבר על הגרלות 777'}
                              text={TEXT_777}/>
            </Bottomdetails>
}
const About123 = () => {

    return <Bottomdetails>
                <AutoSizeText isExpendButton={false} title={'הסבר על הגרלות 123'}
                              text={TEXT_123}/>
            </Bottomdetails>
}


export const RegularLotto = ({isMobile}: {isMobile?: boolean}) => {
    return (
        <>
            <RegularLottoForm calcPriceFunction={getRegularLottoPrice}
                              postFormFunction={postRegularLottoForm}
                              formType="regular_lotto"
                              initForm={REGULAR_LOTTO_INIT_FORM}
                              tablesNumber={REGULAR_LOTTTO_TABLES_NUMBER} numbersLength={6}
                              strongNumbersLength={1} color={RED_COLOR}
                              isMobile={isMobile}/>
            <AboutLotto/>
        </>
    )
}


export const RegularDoubleLotto = ({isMobile}: {isMobile?: boolean}) => {
    return (<>
            <RegularLottoForm calcPriceFunction={getRegularDoubleLottoPrice}
                              postFormFunction={postRegularDoubleLottoForm}
                              formType="regular_double_lotto"
                              tablesNumber={REGULAR_DOUBLE_LOTTTO_TABLES_NUMBER}
                              initForm={REGULAR_DOUBLE_LOTTO_INIT_FORM} numbersLength={6}
                              strongNumbersLength={1} color={RED_COLOR} isMobile={isMobile}/>
            <AboutLotto/>
        </>
    )

}
export const LottoShitati = ({isMobile}: {isMobile?: boolean}) => {
    return (<>
            <LottoShitatiForm calcPriceFunction={getLottoShitatiPrice}
                              postFormFunction={postLottoShitatiForm}
                              formType="lotto_shitati"
                              tablesNumber={LOTTTO_SHITATI_TABLES_NUMBER}
                              initForm={LOTTTO_SHITATI_INIT_FORM} numbersLength={8}
                              strongNumbersLength={1} color={RED_COLOR} isMobile={isMobile}/>
            <AboutLotto/>
        </>
    )
}
export const DoubleLottoShitati = ({isMobile}: {isMobile?: boolean}) => {
    return (
        <>
            <LottoShitatiForm calcPriceFunction={getDoubleLottoShitatiPrice}
                              postFormFunction={postDoubleLottoShitatiForm}
                              formType="double_lotto_shitati"
                              tablesNumber={LOTTTO_DOUBLE_SHITATI_TABLES_NUMBER}
                              initForm={LOTTTO_DOUBLE_SHITATI_INIT_FORM} numbersLength={8}
                              strongNumbersLength={1} color={RED_COLOR} isMobile={isMobile}/>
            <AboutLotto/>
        </>
    )
}
export const LottoShitatiHazak = ({isMobile}: {isMobile?: boolean}) => {
    return (
        <>
            <ShitatiHazakForm calcPriceFunction={getLottoShitatiHazakPrice}
                              postFormFunction={postLottoShitatiHazakForm}
                              formType="lotto_shitati_hazak"
                              tablesNumber={LOTTTO_SHITATI_HAZAK_TABLES_NUMBER}
                              initForm={LOTTTO_SHITATI_HAZAK_INIT_FORM} numbersLength={7}
                              strongNumbersLength={4} color={RED_COLOR} isMobile={isMobile}/>
            <AboutLotto/>
        </>
    )
}
export const DoubleLottoShitatiHazak = ({isMobile}: {isMobile?: boolean}) => {
    return (
        <>
            <ShitatiHazakForm calcPriceFunction={getDoubleLottoShitatiHazakPrice}
                              postFormFunction={postDoubleLottoShitatiHazakForm}
                              formType="double_lotto_shitati_hazak"
                              tablesNumber={LOTTTO_DOUBLE_SHITATI_HAZAK_TABLES_NUMBER}
                              initForm={LOTTTO_DOUBLE_SHITATI_HAZAK_INIT_FORM}
                              numbersLength={7} strongNumbersLength={4}
                              color={RED_COLOR} isMobile={isMobile}/>
            <AboutLotto/>
        </>
    )
}
export const RegularChance = () => {
    return (
        <>
            <RegularChanceForm color={GREEN_COLOR} initForm={REGULAR_CHANCE_INIT_FORM} isFormType={true}
                               formType="regular_chance"
                               isShitati={false} calcPriceFunction={getRegularChancePrice}
                               postFormFunction={postRegularChanceForm}/>
            <AboutChance/>
        </>
    )

}
export const RavChance = () => {
    return (
        <>
            <RegularChanceForm color={GREEN_COLOR} initForm={RAV_CHANCE_INIT_FORM} isFormType={false} isShitati={false}
                               formType="rav_chance"
                               postFormFunction={postRavChanceForm} calcPriceFunction={getRavChancePrice}/>
            <AboutChance/>
        </>
    )
}
export const ChanceShitati = () => {
    return (
        <>
            <ChanceShitatiForm initForm={SHITATI_CHANCE_INIT_FORM} color={GREEN_COLOR} isFormType={true}
                               isShitati={true} calcPriceFunction={getChanceShitatiPrice}
                               formType="chance_shitati"
                               postFormFunction={postChanceShitatiForm}/>
            <AboutChance/>
        </>
    )
}
export const Regular777 = () => {
    return (
        <>
            <RegularSevenForm isShitati={false} color={PINK_COLOR_SEVEN} initForm={REGULAR_SEVEN_INIT_FORM}
                              formType="regular_777"
                              calcPriceFunction={getRegularSevenPrice} postFormFunction={postRegularSevenForm}/>
            <About777/>
        </>
    )

}
export const Shitati777 = () => {
    //TODO: Chance functions.
    return (
        <>
            <RegularSevenForm isShitati={true} color={PINK_COLOR_SEVEN} initForm={SHITATI_SEVEN_INIT_FORM}
                                formType="shitati_777"
                              calcPriceFunction={getShitatiSevenPrice} postFormFunction={postShitatiSevenForm}/>
            <About777/>
        </>
    )
}
export const Regular123 = () => {
    return (
        <>
            <Regular123Form numbersLength={3} tablesNumber={5} color={ORANGE_COLOR} initForm={INIT_FORM_123}
                            formType="123"
                            calcPriceFunction={get123Price} postFormFunction={post123Form}/>
            <About123/>
        </>
    )
}