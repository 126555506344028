import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {UserContext} from "../appSwitch";
import {getAllFormsOwners, getAllUsers, OwnersFormType, ResellerUser, User} from "../server/owners";
import {Menu, Space, Table, Typography} from 'antd';
// @ts-ignore
import { sum } from 'lodash';
import {Stats} from "../owner-board/stats";
import {OverviewTable} from "../owner-board/overviewTable";
import Text from "antd/es/typography/Text";
import Paragraph from "antd/es/typography/Paragraph";
import {FormOutlined, TransactionOutlined, UserOutlined} from "@ant-design/icons";
import {UsersTable} from "../owner-board/usersTable";
import {TransactionsTable} from "../owner-board/transactionsTable";
import {BatchesTable} from "../owner-board/batchesTable";


const { Link } = Typography;

export const ResellersContainer: React.FC = () => {
    const { jwtToken, user } = useContext(UserContext)
    const [allForms, setAllForms] = useState<OwnersFormType[]>([])
    const [resellerUsers, setAllResellerUsers] = useState<ResellerUser[]>([])
    const [isLoading, setIsLoading] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [numberOfForms, setNumberOfForms] = useState(0);
    const [currentTab, setCurrentTab] = useState('gameForms')

    const allFormsGetter = useCallback(async () => {
        if (jwtToken) {
            const af =  await getAllFormsOwners(jwtToken, user.username)
            const users =  await getAllUsers(jwtToken, user.attributes['custom:reseller_code'])
            setTotalPrice(sum(af.map(form => form.price)))
            setNumberOfForms(af.length);
            setAllForms(af)
            setAllResellerUsers(users as unknown as ResellerUser[])
        }
    }, [jwtToken, setIsLoading])

    useEffect( () => {
        setIsLoading(true)
        allFormsGetter().then(() => setIsLoading(false))
    }, [allFormsGetter])

    const handleClick = (e: { key: any; }) => {
        console.log('click ', e);
        setCurrentTab(e.key);
    };

    const getTable = useMemo(() => {
        if (currentTab === 'gameForms') {
            return (
                <>
                    <OverviewTable
                        allForms={allForms}
                        isLoading={isLoading}
                        setTotalPrice={setTotalPrice}
                        setNumberOfForms={setNumberOfForms}/>
                </>
            )
        }
        else if (currentTab === 'users') {
            return (
                <>
                    <Table dataSource={resellerUsers} loading={isLoading} rowKey={(record) => record.created}>
                        <Table.Column title="Name" dataIndex="full_name" key="full_name" />
                        <Table.Column title="Created" dataIndex="created" key="created" />
                    </Table>
                </>
            )
        }
        else {
            return <div>Invalid choise</div>
        }
    }, [currentTab, allForms, isLoading])

    return (
        <>
            <Menu onClick={handleClick} selectedKeys={[currentTab]} mode="horizontal">
                <Menu.Item key="gameForms" icon={<FormOutlined />}>
                    Games Forms
                </Menu.Item>
                <Menu.Item key="users" icon={<UserOutlined />}>
                    Users
                </Menu.Item>
            </Menu>
            <Space direction={"vertical"} size="large" style={{ display: 'flex', padding: 24 }}>
                <Space style={{display: 'flex', justifyContent: 'space-around'}}>
                    <Stats totalPrice={totalPrice} numberOfForms={numberOfForms} isLoading={isLoading}/>
                    {user.attributes && <Text>Your reseller code: <Paragraph copyable>{user.attributes['custom:reseller_code']}</Paragraph></Text>}
                    {user.attributes && <Text>Your invite link: <Paragraph><Link copyable>https://www.lottomatic.io/login?resellerCode={user.attributes['custom:reseller_code']}</Link></Paragraph></Text>}
                </Space>
                {getTable}
            </Space>
        </>
    )
}
