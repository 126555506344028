import {handleErrors} from "./utils";
import {
    GET_123_RESULTS_PATH,
    GET_777_RESULTS_PATH,
    GET_CHANCE_RESULTS_PATH,
    GET_LOTTO_RESULTS_PATH,
    GET_TIMES_PATH, SERVER_URL,
    GET_EXTRA_RESULTS_PATH
} from "./configurations";
import {TimesType} from "../appSwitch";

export const getLottoResults = async (): Promise<{}> => {
    return await handleErrors(fetch(GET_LOTTO_RESULTS_PATH.href))
};
export const getExtraResults = async (): Promise<{}> => {
    return await handleErrors(fetch(GET_EXTRA_RESULTS_PATH.href))
};

export const getChanceResults = async (): Promise<{}> => {
    return await handleErrors(fetch(GET_CHANCE_RESULTS_PATH.href))
};

export const get777Results = async (): Promise<{}> => {
    return await handleErrors(fetch(GET_777_RESULTS_PATH.href))
};

export const get123Results = async (): Promise<{}> => {
    return await handleErrors(fetch(GET_123_RESULTS_PATH.href))
};

export const getLottoResult = async (id: number): Promise<{}> => {
    const url = new URL(`/results/lotto/${id}`, SERVER_URL)
    return await handleErrors(fetch(url.href))
};

export const getChanceResult = async (id: number): Promise<{}> => {
    const url = new URL(`results/chance/${id}`, SERVER_URL)
    return await handleErrors(fetch(url.href))
};

export const get777Result = async (id: number): Promise<{}> => {
    const url = new URL(`/results/777/${id}`, SERVER_URL)
    return await handleErrors(fetch(url.href))
};

export const get123Result = async (id: number): Promise<{}> => {
    const url = new URL(`/results/123/${id}`, SERVER_URL)
    return await handleErrors(fetch(url.href))
};

export const getTimes = async (): Promise<TimesType> => {
    return await handleErrors(fetch(GET_TIMES_PATH.href))
};