import React, {useCallback, useContext, useEffect, useReducer, useState} from "react";
import {StyleSheet, Text, View} from "react-native";
import {
    FB_SPACER_BOLD,
    FB_SPACER_REGULAR,
    getBorder,
    getCircleCss,
    getFontCss,
    LIGHT_GREEN_COLOR,
    RED_COLOR,
    WHITE_COLOR
} from "../../styles";
import {
    CalcPriceFunction,
    FormType,
    LottoMarksType,
    LottoNumberType,
    MultiLotteryChanceType,
    MultiLotteryType,
    PostFormFunction,
    ShitatiMarksType,
    UserFormType
} from "../../types";
import {FORM_TYPE, INIT_TABLE_INDEX, NON_NUMBER, NUMBERS, STRICT, STRONG_NUMBERS, TABLES} from "../../consts";
import FirstSectionInstructions, {
    InstructionStep,
    SubInstructionsText
} from "./additionalSections/firstSectionInstructions";
import UserInputForm from "./inputForm/userInputForm";
import {ShitatiHazakUserForm, UserFormContainer} from "./userForm/userForm";
import FormAdditions from "./additionalSections/formAdditions";
import SumAndSubmitSection from "./additionalSections/sumAndSubmitSection";
import {shitatiReducer} from "./shitatiReducer";
import {TouchableLottoRowBalls} from "../lottoBalls";
import {UserContext} from "../../appSwitch";

import { AlertHelper } from '../../AlertHelper';

let _ = require('lodash')


type LevelType = 'strict' | 'lax'
type ShitatiFormType = {
    color: string,
    calcPriceFunction: CalcPriceFunction<LottoMarksType>,
    postFormFunction: PostFormFunction<LottoMarksType>,
    tablesNumber: number,
    initForm: ShitatiMarksType,
    numbersLength: number,
    formType:  FormType,
    strongNumbersLength: number,
    isMobile?: boolean
}
type PickFormTypeType = {
    marks: ShitatiMarksType,
    tableIndex: number,
    removeFormType: () => void,
    formTypeValues: (number | string)[],
    setFormType: (value: string | number) => void,
    isMobile?: boolean
}
const PICK_FORM_TITLE = 'בחר את סוג הטופס'

// TODO: Add formType component.
// TODO: Add validation on formType.
const Shitati = ({
                     strongNumbersLength,
                     tablesNumber,
                     initForm,
                     postFormFunction,
                     calcPriceFunction,
                     numbersLength,
                     formType,
                     color, isMobile
                 }: ShitatiFormType) => {
    const style = StyleSheet.create({
        formTablesContainer: {
            flexDirection: "row-reverse",
            height: 540,
            ...getBorder()
        },
        formTablesContainerMobile: {
            flexDirection: "column",
            ...getBorder()
        }
    })
    const [tableIndex, setTableIndex] = useState<number>(INIT_TABLE_INDEX)
    const [marks, dispatchMarks] = useReducer(shitatiReducer, initForm)
    const [numbersArrayLength, setNumbersArrayLength] = useState(numbersLength)
    const formTypeToNumbersLengthMapping: { [key: string]: number } = {
        '8': 8,
        '5=6': 5,
        '9': 9,
        '10': 10,
        '11': 11,
        '12': 12
    }
    const {jwtToken} = useContext(UserContext)

    const postFunction = useCallback(() => postFormFunction(marks, jwtToken), [marks])

    const calcFunction = useCallback(() => calcPriceFunction(marks, jwtToken), [marks])

    const validateForm = (lvl: LevelType = STRICT): boolean => {
        let filledTables = 0
        for (let table of marks[TABLES]) {
            if (table[STRONG_NUMBERS].length === strongNumbersLength && table[NUMBERS].length === numbersArrayLength) {
                filledTables += 1
            } else {
                if ((table[NUMBERS].length > 0 && table[NUMBERS].length < numbersLength) && (table[STRONG_NUMBERS].length < strongNumbersLength && table[STRONG_NUMBERS].length > 0)) {
                    if(lvl === STRICT){
                        AlertHelper.show("יש לפחות שורה אחת ריקה",
                        () => AlertHelper.hide(),'sendGameForm')
                    }
                    return false
                }
            }
        }
        if (marks[FORM_TYPE] === NON_NUMBER) {
            if(lvl === STRICT){
                AlertHelper.show("בחר סוג טופס",
                () => AlertHelper.hide(),'sendGameForm')
            }
            return false
        }

        if (filledTables !== 0) {
            return true
        }

        if(lvl === STRICT){
            AlertHelper.show("השלם את מילוי השורה",
            () => AlertHelper.hide(),'sendGameForm')
        }
        return false
    }

    const updateIndex = () => {
        if (marks[TABLES][tableIndex][NUMBERS].length === numbersLength && marks[TABLES][tableIndex][STRONG_NUMBERS].length === strongNumbersLength) {
            if (tableIndex + 1 !== tablesNumber) {
                setTableIndex(Number(tableIndex) + 1)
            }
        }
    }

    const setFormType = (value: string | number) => {
        dispatchMarks({
            strongNumbersLength,
            numbersLength: numbersArrayLength,
            type: 'setFormType',
            tableIndex: tableIndex,
            value: NON_NUMBER,
            formType: value
        })
    }

    const removeFormType = () => {
        dispatchMarks({
            strongNumbersLength,
            numbersLength: numbersArrayLength,
            type: 'resetFormType',
            tableIndex: tableIndex,
            value: NON_NUMBER
        })
    }

    const tableAutoInsertion = () => {
        dispatchMarks({
            strongNumbersLength,
            numbersLength: numbersArrayLength,
            type: 'tableAutoInsertion',
            value: NON_NUMBER,
            tableIndex: tableIndex
        })
    }

    const popTableNumbers = useCallback(() => {
        dispatchMarks({
            strongNumbersLength,
            numbersLength: numbersArrayLength,
            type: 'popTableNumbers',
            value: NON_NUMBER,
            tableIndex: tableIndex
        })
    }, [numbersArrayLength, tableIndex])

    const addNumber = useCallback((number: LottoNumberType) => {
        dispatchMarks({
            strongNumbersLength,
            numbersLength: numbersArrayLength,
            type: 'addNumber',
            value: number,
            tableIndex: tableIndex
        })
    }, [tableIndex, numbersArrayLength])

    const removeNumber = useCallback((number: LottoNumberType) => {
        dispatchMarks({
            strongNumbersLength,
            numbersLength: numbersArrayLength,
            type: 'removeNumber',
            value: number,
            tableIndex: tableIndex
        })
    }, [tableIndex, numbersArrayLength])

    const removeStrongNumber = useCallback((number: LottoNumberType) => {
        dispatchMarks({
            strongNumbersLength,
            numbersLength: numbersArrayLength,
            type: 'removeStrongNumber',
            value: number,
            tableIndex: tableIndex
        })
    }, [numbersArrayLength, tableIndex])

    const addStrongNumber = useCallback((strongNumber: LottoNumberType) => {
        dispatchMarks({
            strongNumbersLength,
            numbersLength: numbersArrayLength,
            type: 'addStrongNumber',
            value: strongNumber,
            tableIndex: tableIndex
        })
    }, [numbersArrayLength, tableIndex])

    const incrementTableIndex = useCallback(() => {
        if (Number(tableIndex) + 1 === tablesNumber) {
            setTableIndex(0)
            return
        }
        setTableIndex(Number(tableIndex) + 1)
    }, [numbersArrayLength, tableIndex])

    const decrementTableIndex = useCallback(() => {
        if (Number(tableIndex) - 1 < INIT_TABLE_INDEX) {
            setTableIndex(tablesNumber - 1)
            return
        }
        setTableIndex(Number(tableIndex) - 1)
    }, [numbersArrayLength, tableIndex])

    const setExtra = () => {
        dispatchMarks({
            strongNumbersLength,
            numbersLength: numbersArrayLength,
            type: 'setExtra',
            value: NON_NUMBER,
            tableIndex: tableIndex
        })
    }

    const setMultiLottery = (number: MultiLotteryType | MultiLotteryChanceType) => {
        dispatchMarks({
            strongNumbersLength, numbersLength: numbersArrayLength,
            type: 'setMultiLottery',
            value: NON_NUMBER,
            tableIndex: tableIndex,
            multiLotteryValue: (number as MultiLotteryType)
        })
    }

    useEffect(updateIndex, [marks])

    useEffect(() => {
        if (marks[FORM_TYPE] !== NON_NUMBER) {
            setNumbersArrayLength(formTypeToNumbersLengthMapping[String(marks[FORM_TYPE])])
            popTableNumbers()
        }
    }, [marks[FORM_TYPE]])

    return (
        <>
            <FirstSectionInstructions mainTitle={'מלא את הטופס'} subTitle={`בחר ${numbersArrayLength} מספרים`}
                                      bacgroundColor={color} isMobile={isMobile}/>
            <View style={isMobile ? style.formTablesContainerMobile : style.formTablesContainer}>
                <UserInputForm popTableNumbers={popTableNumbers} addNumber={addNumber} addStrongNumber={addStrongNumber}
                               decrementTableIndex={decrementTableIndex} incrementTableIndex={incrementTableIndex}
                               tableAutoInsertion={tableAutoInsertion} table={marks[TABLES][tableIndex]}
                               removeNumber={removeNumber} removeStrongNumber={removeStrongNumber}
                               numbersLength={numbersArrayLength} strongNumbersLength={strongNumbersLength}
                               color={color} tableIndex={tableIndex} isMobile={isMobile}/>
                <ShitatiUserForm marks={marks} tableIndex={tableIndex} tablesNumber={tablesNumber}
                                 numbersLength={numbersArrayLength} setFormType={setFormType}
                                 removeFormType={removeFormType} strongNumbersLength={strongNumbersLength}
                                 formTypeValues={Object.keys(formTypeToNumbersLengthMapping)} isMobile={isMobile}/>
            </View>
            {/*<InstructionStep marginTop={35} containerHeight={72} stepText={'שדרג את הטופס'} stepNumber={2}*/}
            {/*                 stepColor={color} isMobile={isMobile}/>*/}
            {/*<FormAdditions isExtra={setExtra} marks={marks} setMultiLottery={setMultiLottery} isMobile={isMobile}/>*/}
            <SumAndSubmitSection marks={marks} validateForm={validateForm} postFunction={postFunction}
                                 formType={formType}
                                 calcPriceFunction={calcFunction} color={color} bottonColor={LIGHT_GREEN_COLOR}
                                 numbersLength={numbersLength} strongNumbersLength={strongNumbersLength} isMobile={isMobile}/>
        </>
    )
}

export const ShitatiUserForm = ({
                                    numbersLength,
                                    tablesNumber,
                                    tableIndex,
                                    marks,
                                    setFormType,
                                    removeFormType,
                                    strongNumbersLength,
                                    formTypeValues, isMobile
                                }: UserFormType<ShitatiMarksType> & { formTypeValues: (number | string)[], removeFormType: () => void, setFormType: (value: string | number) => void, isMobile?: boolean }) => {
    return (
        <UserFormContainer width={isMobile ? '100%' : undefined}>
            <View>
                <PickFormType marks={marks} tableIndex={tableIndex} removeFormType={removeFormType}
                              setFormType={setFormType} formTypeValues={formTypeValues} isMobile={isMobile}/>
            </View>
            <View>
                <ShitatiHazakUserForm marks={marks} tableIndex={tableIndex} tablesNumber={tablesNumber}
                                      numbersLength={numbersLength} strongNumbersLength={strongNumbersLength} isMobile={isMobile}/>
            </View>

        </UserFormContainer>
    )
}

export const PickFormType = ({marks, tableIndex, removeFormType, setFormType, formTypeValues, isMobile}: PickFormTypeType) => {
    const style = StyleSheet.create({
        container: {
            marginLeft: '14.5%',
            ...getCircleCss(164, '85%', 12),
            borderColor: WHITE_COLOR,
            borderStyle: "solid",
            borderWidth: 1,
            marginBottom: 12,
        },
        containerMobile: {
            marginLeft: '6.5%',
            ...getCircleCss('100%', '85%', 12),
            borderColor: WHITE_COLOR,
            borderStyle: "solid",
            borderWidth: 1,
            marginBottom: 12,
        },
        textContainer: {
            flexDirection: "row-reverse",
            alignItems: "center",
            ...getBorder()
        },
        bigText: {
            ...getFontCss(FB_SPACER_REGULAR, 69, WHITE_COLOR)
        },
        descriptionText: {
            ...getFontCss(FB_SPACER_BOLD, 23, WHITE_COLOR)
        },
        bigTextMobile: {
            ...getFontCss(FB_SPACER_REGULAR, 82, WHITE_COLOR)
        },
        descriptionTextMobile: {
            ...getFontCss(FB_SPACER_BOLD, 55, WHITE_COLOR)
        }
    })
    
    const formTypeToTable: { [key: string]: number } = {
        '4': 28,
        '5': 35,
        '6': 42,
        '7': 49,
        '8': 28,
        '5=6': 32,
        '9': 84,
        '10': 210,
        '11': 462,
        '12': 924
    }
    
    return (
        <View style={isMobile ? style.containerMobile : style.container}>
            <SubInstructionsText marginRight={'4%'} text={PICK_FORM_TITLE} marginBottom={4} isMobile={isMobile}/>
            <ShitatiPickFormType marks={marks} tableIndex={tableIndex} setFormType={setFormType}
                                 formTypeValues={formTypeValues} removeFormType={removeFormType} isMobile={isMobile}/>
            <View style={style.textContainer}>
                <Text style={isMobile ? style.bigTextMobile : style.bigText}>{formTypeToTable[String(marks[FORM_TYPE])]}</Text>
                <Text style={isMobile ? style.descriptionTextMobile : style.descriptionText}>מספר הצירופים<br/>שמשתתפים בהגרלה</Text>
            </View>
        </View>
    )
}

export const ShitatiPickFormType = ({isReadOnly, marks, tableIndex, removeFormType, setFormType, formTypeValues, isMobile}: {
    marks: ShitatiMarksType,
    tableIndex: number,
    removeFormType: () => void,
    formTypeValues: (number | string)[],
    setFormType: (value: string | number) => void
    isReadOnly?: boolean,
    isMobile?: boolean
}) => {
    const ballsStyle = {
        ballsContainer: {
            flexDirection: 'row',
            height: 32,
            ...getBorder()
        },
        ballsContainerMobile: {
            flexDirection: 'row',
            ...getBorder()
        },
        ballStyle: {
            ...getCircleCss('100%', '9.6%', '100%'),
            backgroundColor: WHITE_COLOR,
            marginLeft: '3.9%',
            justifyContent: 'center',
            alignItems: 'center'
        },
        ballTextStyle: {
            ...getFontCss(FB_SPACER_REGULAR, 23, RED_COLOR)
        },
        ballTextStyleMobile: {
            ...getFontCss(FB_SPACER_REGULAR, 70, RED_COLOR)
        }
    }

    return (
        <>
            <TouchableLottoRowBalls rowLength={6} numbers={formTypeValues} style={isMobile ? ballsStyle.ballsContainerMobile : ballsStyle.ballsContainer}
                                    ballStyle={ballsStyle.ballStyle} ballTextStyle={isMobile ? ballsStyle.ballTextStyleMobile : ballsStyle.ballTextStyle}
                                    onPress={setFormType}
                                    disabled={!isReadOnly && false} removeNumber={removeFormType}
                                    table={marks[TABLES][tableIndex]}
                                    isClicked={(number) => marks[FORM_TYPE] === number}/>
        </>
    )
}

export default Shitati
