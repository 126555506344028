import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Card, CardContent, CircularProgress, createStyles, Fade, makeStyles, Modal, Theme, Backdrop } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';

import { BLUE_COLOR, BLUE_GRAY_COLOR, GREEN_COLOR, LIGHT_BLUE_COLOR, ORANGE_COLOR, RED_COLOR } from '../styles';
import { UserContext } from "../appSwitch";
import { getFormToValidate, setInalidateBatchState, setFormValidateState } from '../server/admin';
import { Linking, View, StyleSheet, Text } from 'react-native';
import { getFormComponentByType } from '../mySpace/utils';
import { FORM_TYPE } from '../consts';
import { AlertHelper } from '../AlertHelper';
import { PrintedFormAction } from './adminPage';

type ValidateFormType = {
    open: boolean,
    onClose: (event, reason) => void,
    id: number,
    operation?: string
}


export const ValidateForm: React.FC<ValidateFormType> = ({ open, id, onClose, operation }) => {

    const [isLoading, setIsLoading] = useState(true)
    const [uploadImage, setUploadImage] = useState('')
    const [formComponent, setFormComponent] = useState<() => JSX.Element | undefined>(() => () => undefined)
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            button: {
                margin: theme.spacing(1),
                height: 40,
                width: 120,
                backgroundColor: LIGHT_BLUE_COLOR
            },
            exitButton: {
                margin: theme.spacing(1),
                color: RED_COLOR
            },
            greenButton: {
                margin: theme.spacing(1),
                height: 40,
                width: 120,
                backgroundColor: GREEN_COLOR
            },
            redButton: {
                margin: theme.spacing(1),
                height: 40,
                width: 120,
                backgroundColor: RED_COLOR
            },
            orangeButton: {
                margin: theme.spacing(1),
                height: 40,
                width: 120,
                backgroundColor: ORANGE_COLOR
            },
            modal: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            paper: {
                width: '100%',
                marginBottom: 10,
                backgroundColor: LIGHT_BLUE_COLOR,
                height: '100%'
            },
            card: {
                backgroundColor: BLUE_COLOR,
                height: '80vh',
                width: '100%',
                borderRadius: 12,
                marginBottom: 10,
                marginRight: 10
            },
            details: {
                display: 'flex',
                flexDirection: 'column',
                WebkitAlignItems: 'flex-end',
                margin: '0 16px',
                alignSelf: 'flex-start',
                alignItems: "flex-end",
                backgroundColor: BLUE_GRAY_COLOR,
                borderRadius: 12
            },
        }),
    );
    const style = StyleSheet.create({
        buttons: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: "row"
        },
        topButtons: {
            display: 'flex',
            alignItems: 'flex-end',

        },


    })
    const classes = useStyles();
    const { jwtToken } = useContext(UserContext)
    const onUploadImage = (url:string) =>{
        setUploadImage(url)
    }
    const handlePress = useCallback(async (url) => {
        // Checking if the link is supported for links with custom URL scheme.
        const supported = await Linking.canOpenURL(url);

        if (supported) {
            // Opening the link with some app, if the URL scheme is "http" the web link should be opened
            // by some browser in the mobile
            await Linking.openURL(url);
        } else {
            // Alert.alert(`Don't know how to open this URL: ${url}`);
        }
    }, []);

    const [data, setData] = useState({});

    const getFormData = useCallback(async () => {
        if (jwtToken) {
            return await getFormToValidate(jwtToken, id).catch(err => {
                throw err;
            })
        }
    }, [jwtToken, id])

    const validateForm = useCallback(async (status) => {
        if (jwtToken) {
            return await setFormValidateState(jwtToken, id, status).then(() => {
                onClose()
            })
        }
    }, [jwtToken, data])

    const invalidateBatch = useCallback(async (batch_id) => {
        if (jwtToken) {
            return await setInalidateBatchState(jwtToken, batch_id).then(() => {
                onClose("1", "refresh")
            })
        }
    }, [jwtToken, data])

    useEffect(() => {
        getFormData().then(res => {
            setData(res)
            setIsLoading(false)
            setFormComponent(getFormComponentByType(res[FORM_TYPE], res['marks'], jwtToken, res["lottery_number"], true, () => '', false))
        }).catch(err => {
            // AlertHelper.show(err,
            // () => AlertHelper.hide())
            console.log(`err :>> `, err)
            setIsLoading(false)
        })

    }, [id]);


    return (
        <>

            <Modal
                className={classes.modal}
                open={open}
                closeAfterTransition
                onClose={onClose}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open} className={classes.paper}>
                    <>
                        {isLoading && <CircularProgress color="secondary" />}


                        {
                            formComponent() &&
                            <Card className={classes.card} >
                                <View style={style.topButtons}>
                                    <IconButton onClick={() => onClose()} className={classes.exitButton} aria-label="סגור" >
                                        <CancelIcon />
                                    </IconButton>

                                </View>
                                <View style={{ flexDirection: "row-reverse", height: '80%' }}>
                                    <CardContent style={{ display: "flex", width: '100%', flexDirection: "row-reverse", flexWrap: "wrap" }}>
                                        <CardContent style={{ flexGrow: 1, height: '100%' }}> {
                                            formComponent()
                                        }
                                        </CardContent>

                                        <View >
                                            <CardContent className={classes.details}>
                                                <Text>
                                                    פרטי משתמש:
                                                </Text>
                                                <Text>
                                                    תעודת זהות: {data.user_id}
                                                </Text>
                                                <Text>
                                                    מחיר ששולם: {data.origin_price}
                                                </Text>
                                                <Text>
                                                    מספר הגרלה: {data.lottery_number}
                                                </Text>

                                            </CardContent>
                                            {!operation && <View style={style.buttons}>
                                                <Button onClick={() => invalidateBatch(data.batch_id)} variant="contained" className={classes.redButton}  >ביטול שיוך batch</Button>
                                                <Button onClick={() => validateForm(false)} variant="contained" className={classes.orangeButton}  >ביטול שיוך טופס</Button>
                                                <Button onClick={() => validateForm(true)} variant="contained" className={classes.greenButton}  >אימות</Button>
                                            </View>}
                                            {operation == 'upload' && <PrintedFormAction rowId={id+""} onClose={onClose} OnUploadImage={onUploadImage}/>}
                                        </View>
                                        {!operation && <div style={{ overflow: "auto", maxHeight: '100%', flexGrow: 1, width: '30vh' }}>
                                            <img src={data.form_link} style={{ width: '100%' }} />
                                        </div>}
                                        {operation == 'upload' && uploadImage && <div style={{ overflow: "auto", maxHeight: '100%', flexGrow: 1, width: '30vh' }}>
                                            <img src={uploadImage} style={{ width: '100%' }} />
                                        </div>}
                                    </CardContent>
                                </View>

                            </Card>
                        }
                    </>
                </Fade>
            </Modal>
        </>
    )
}

